<template>
    <SlideLayout
        :closeLink="null"
        :logoLink="null"
        class="c-overdue-container"
    >
        <OverdueIntroSlide
            v-show="showIntro && !overduePaymentSuccess"
            @intro-viewed="showIntro = false"
        />
        <OverdueBillingSlide
            v-show="!showIntro || overduePaymentSuccess"
            :initialBillingAddress="billingAddress"
            :overduePaymentSuccess="overduePaymentSuccess"
            @previous-clicked="showIntro = true"
        />
    </SlideLayout>
</template>

<script>
import { mapState } from 'vuex';

import accountMetaMixin from '~routes/account/js/account-meta';
import { SUBSCRIPTIONS_MODULE_NAME } from '~routes/account/js/subscriptions-store';
import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

import { genericRouteNames } from '~coreModules/core/js/router-constants';
import { USER_PREFERENCE_KEY_OVERDUE_PAYMENT_SUCCESS } from '~modules/site-settings/js/site-settings-constants';

import SlideLayout from '~modules/core/components/layouts/SlideLayout.vue';
import OverdueBillingSlide from '~routes/account/components/overdue/OverdueBillingSlide.vue';
import OverdueIntroSlide from '~routes/account/components/overdue/OverdueIntroSlide.vue';

export default {
    name: 'OverdueContainer',
    components: {
        OverdueIntroSlide,
        OverdueBillingSlide,
        SlideLayout,
    },
    mixins: [accountMetaMixin],
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (!vm.isOverdue) {
                vm.$router.replace({ name: genericRouteNames.home });
            }
        });
    },
    data() {
        return {
            showIntro: true,
        };
    },
    computed: {
        ...mapState(SITE_SETTINGS_MODULE_NAME, {
            preferences: state => state.preferences,
        }),
        ...mapState(SUBSCRIPTIONS_MODULE_NAME, ['billingAddress']),
        overduePaymentSuccess() {
            return this.preferences[USER_PREFERENCE_KEY_OVERDUE_PAYMENT_SUCCESS] === true;
        },
    },
};
</script>

<style lang="scss">
    .c-overdue-container {
        @include modal-drawer('.c-overdue-container');
        background-color: $nu-secondary;
        height: var(--vh100);
    }
</style>
