<template>
    <button
        :type="type"
        :aria-label="labelText"
        :class="buttonClasses"
        :disabled="$attrs.disabled"
        @click="onClick"
    >
        <slot></slot>
    </button>
</template>

<script>
import buttonProps from '~coreModules/core/components/mixins/buttonProps';

export default {
    name: 'BaseButton',
    mixins: [buttonProps],
    props: {
        type: {
            type: String,
            required: false,
            default: 'button',
        },
        labelText: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: ['click', 'submit'],
    methods: {
        onClick(e) {
            this.$emit('click', e);
        },
        onSubmit(e) {
            this.$emit('submit', e);
        },
    },
};
</script>

<style lang="scss">
    .c-button {
        $this: &;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        &--primary, &--secondary, &--link {
            outline: none;
            cursor: pointer;
            white-space: nowrap;
            border: 1px solid $nu-primary;
            border-radius: 2px;
            height: 40px;
            width: 202px;
            padding: $nu-spacer-0pt5 $nu-spacer-6;
            transition: all 0.2s ease;
        }

        &--link {
            background-color: $transparent;
            border: none;
            color: $nu-primary;
            padding: $nu-spacer-0pt5 0;
            width: auto;

            &:hover {
                text-decoration: underline;
            }

            &:active {
                color: $nu-gray;
                text-decoration: underline;
            }

            &:disabled {
                cursor: default;
                color: $nu-gray--medium;
            }

            &#{$this}--light {
                color: $nu-white;
            }

            &#{$this}--dark {
                color: $nu-primary;
            }
        }

        &--small {
            height: 32px;
        }

        &--font-small {
            font-size: 1.2rem;
            line-height: 20px;
        }

        &--primary:not(#{$this}--is-loading) {
            &, #{$this}--is-loaded {
                background-color: $nu-primary;
                color: $nu-white;

                svg, path {
                    fill: $nu-white;
                }

                &#{$this}--light {
                    background-color: $nu-white;
                    border-color: $nu-white;
                    color: $nu-primary;

                    svg, path {
                        fill: $nu-primary;
                    }
                }

                &#{$this}--dark {
                    background-color: $nu-primary;
                    border-color: $nu-primary;
                    color: $nu-white;
                }
            }
        }

        &--secondary:not(#{$this}--is-loading) {
            &, #{$this}--is-loaded {
                background-color: $transparent;
                color: $nu-primary;

                svg, path {
                    fill: $nu-primary;
                }

                &#{$this}--light {
                    border-color: $nu-white;
                    color: $nu-white;
                }

                &#{$this}--dark {
                    border-color: $nu-primary;
                    color: $nu-primary;
                }
            }
        }

        &:focus[data-focus-visible-added]:not(&--none):not(&--link):not(&--is-loaded):not(:disabled),
        &:active:not(&--none):not(&--link):not(&--is-loaded):not(:disabled),
        &--is-loading {
            background-color: $nu-white;
            border-color: $nu-primary;
            color: $nu-primary;
        }

        @include non-touch-device {
            &:hover:not(&--none):not(&--link):not(&--is-loaded):not(:disabled) {
                background-color: $nu-white;
                border-color: $nu-primary;
                color: $nu-primary;
            }
        }

        &--no-border {
            border: none;
        }

        &:disabled:not(&--none):not(&--link) {
            background-color: transparent;
            color: $nu-gray--medium;
            border-color: $nu-gray--medium;
        }

        svg {
            vertical-align: middle;
            margin-right: $nu-spacer-1pt5;
        }
    }

</style>
