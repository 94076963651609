<i18n>
[
    "nav__menuDropdown--accountOverview",
    "account__rentalHistory--title",
    "nav__menuDropdown--subscription",
    "account__menuItem--billing",
    "account__profileTab--title",
    "account__redeemTab--title",
    "nav_menuDropdown--giveGetOffer",
    "nav__menuDropDown--gifting",
]
</i18n>

<template>
    <div class="c-header-links">
        <BaseLink
            v-for="({ name, displayName, badgeText }, index) in links"
            :key="index"
            :to="{ name }"
            class="c-header-links__item o-text--body-copy u-text--black"
        >
            {{ displayName }}
            <span v-if="badgeText" class="c-header-links__badge o-text--label">
                {{ badgeText }}
            </span>
        </BaseLink>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useGetters } from '~coreModules/core/js/composables/vuexHelpers';

import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';

import { rentalRouteNames } from '~modules/core/js/router-constants';

const { t } = useI18n();

const { isNonSubscriber } = useGetters(PROFILE_MODULE_NAME, ['isNonSubscriber']);

const links = computed(() => ([
    {
        displayName: t('nav__menuDropdown--accountOverview'),
        name: rentalRouteNames.account.overview,
    },
    ...(!isNonSubscriber.value ? [{
        displayName: t('account__rentalHistory--title'),
        name: rentalRouteNames.account.history,
    }] : []),
    {
        displayName: t('nav__menuDropdown--subscription'),
        name: rentalRouteNames.account.subscription,
    },
    ...(!isNonSubscriber.value ? [{
        displayName: t('account__menuItem--billing'),
        name: rentalRouteNames.account.billing,
    }] : []),
    {
        displayName: t('account__profileTab--title'),
        name: rentalRouteNames.account.profile,
    },
    ...(!isNonSubscriber.value ? [{
        displayName: t('account__redeemTab--title'),
        name: rentalRouteNames.account.redeem,
        badgeText: t('nav_menuDropdown--giveGetOffer'),
    }] : []),
    {
        displayName: t('nav__menuDropDown--gifting'),
        name: rentalRouteNames.account.giftCards.base,
    },
]));

</script>

<style lang="scss">
.c-header-links {
    &__item {
        width: 100%;
        text-align: left;
        outline-offset: -4px;
        cursor: pointer;
        padding: $nu-spacer-2;
        background-color: $nu-white;
        transition: all 0.2s ease-in-out;

        border-bottom: 1px solid $nu-gray--light;

        &:hover {
            background-color: $nu-secondary-20;
            padding-left: $nu-spacer-3;
        }
    }

    &__badge {
        padding: $nu-spacer-1 $nu-spacer-1pt5;
        margin-left: $nu-spacer-1pt5;
        border-radius: $nu-spacer-0pt5;
        background-color: $placeholder-background;
    }
}
</style>
