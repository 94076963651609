import {
    NOTIFICATIONS_GLOBAL_DISALLOWED_ROUTES,
} from '~coreModules/notifications/js/anonymous-notifications-constants';

// eslint-disable-next-line import/prefer-default-export
export function isNotificationPageBlocked(routePath, notification) {
    const { allowedPages, blockedPages = [] } = notification;

    let isBlockedPage = false;

    // First check if the page is in the list of allowed pages
    if (allowedPages) {
        isBlockedPage = !allowedPages.find(allowedPage => routePath.includes(allowedPage));
    }

    if (!isBlockedPage) {
        // Then check if the page is in the list of blocked pages
        // This should override any allowedPages
        const allBlockedPages = blockedPages.concat(NOTIFICATIONS_GLOBAL_DISALLOWED_ROUTES);
        isBlockedPage = !!allBlockedPages.find(blockedPage => routePath.includes(blockedPage));
    }

    return isBlockedPage;
}
