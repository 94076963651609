import { get, isEmpty } from 'lodash-es';

import {
    interpolateContentfulMarkdown,
    normalizeContentfulRowModules,
} from '~coreModules/contentful/js/contentful-utils';
import { SUBSCRIPTION_STATUS_ANONYMOUS } from '~modules/contentful/js/contentful-constants';

import { BOX_MODULE_NAME } from '~modules/box/js/box-store';

/**
 * @param  {Object} options - Options object
 * @param {Object} options.eligibilityRules      Rules to determine if content should be showed
 * @param {boolean} options.isLoggedIn           Whether or not user is logged in
 * @param {String} options.boxOrderStatus        The current state of a users box or order
 * @param {String} options.subscriptionStatus    ENUM representing the subscription status of the user
 * @param {boolean} options.hasRecentOrder       Whether a user has a recent box order
 * @returns {boolean}                    Returns true if user is allowed to view contentful content
 */

export function userIsEligibleToViewContent({
    eligibilityRules,
    isLoggedIn,
    boxOrderStatus,
    subscriptionStatus,
    hasRecentOrder,
}) {
    const boxOrderStatusReqs = get(eligibilityRules, 'boxOrderStatus', []);
    const mustHaveNeverOrdered = eligibilityRules?.hasNeverOrdered;
    const subscriptionStatusReqs = get(eligibilityRules, 'subscriptionStatus', []);
    const mustBeAnonymous = Boolean(subscriptionStatusReqs
        .find(status => status === SUBSCRIPTION_STATUS_ANONYMOUS) && subscriptionStatusReqs.length === 1);
    const effectiveSubscriptionStatus = !isLoggedIn && !subscriptionStatus ?
        SUBSCRIPTION_STATUS_ANONYMOUS : subscriptionStatus;

    if (mustBeAnonymous) {
        return !isLoggedIn;
    }

    if (!isEmpty(boxOrderStatusReqs) && !boxOrderStatusReqs.includes(boxOrderStatus)) {
        return false;
    }

    if (!isEmpty(subscriptionStatusReqs) &&
        !subscriptionStatusReqs.includes(effectiveSubscriptionStatus)) {
        return false;
    }

    if (mustHaveNeverOrdered && hasRecentOrder) {
        return false;
    }

    return true;
}

export function interpolateMarkdown(source, root) {
    return interpolateContentfulMarkdown(source, root, BOX_MODULE_NAME);
}

/**
 *
 * @param {Object} giftCardPage   contentful gift card PDP content
 * @returns {Object} The shaped contentful giftCardPage
 */
export function shapeGiftCardPageContent(giftCardPage) {
    if (!isEmpty(giftCardPage)) {
        return {
            ...giftCardPage,
            ...(giftCardPage.bottomContent && {
                bottomContent: normalizeContentfulRowModules(giftCardPage.bottomContent),
            }),
        };
    }

    return giftCardPage;
}
