import script from "./ContentCardCarouselButtons.vue?vue&type=script&setup=true&lang=js"
export * from "./ContentCardCarouselButtons.vue?vue&type=script&setup=true&lang=js"

import "./ContentCardCarouselButtons.vue?vue&type=style&index=0&id=bd5b06f8&lang=scss"
/* custom blocks */
import block0 from "./ContentCardCarouselButtons.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__