import {
    SECONDS_IN_1_MINUTE,
    SECONDS_IN_1_WEEK,
    SECONDS_IN_1_MONTH,
    SECONDS_IN_1_YEAR,
} from '~coreModules/core/js/date-constants';

import { NUU_QUERY_FACEBOOK_CLICK_ID } from '~coreModules/core/js/query-constants';
import { NUU_HEADER_VENDOR_CLICK_ID } from '~coreModules/core/js/constants';

export const NUU_COOKIE_AB_TESTS_PREFIX = 'x_nuuly_ab_tests_';
export const NUU_COOKIE_TRACKING = 'x_nuuly_tracking';
export const NUU_COOKIE_NOTIFICATIONS = 'x_nuuly_notifications';
export const NUU_COOKIE_MOBILE_WEBVIEW = 'x_nuuly_mobile_webview';

export const MAX_AGE_15_MINUTES = SECONDS_IN_1_MINUTE * 15;
export const MAX_AGE_2_WEEKS = SECONDS_IN_1_WEEK * 2;
export const MAX_AGE_1_MONTH = SECONDS_IN_1_MONTH;
export const MAX_AGE_2_MONTHS = SECONDS_IN_1_MONTH * 2;
export const MAX_AGE_3_MONTHS = SECONDS_IN_1_MONTH * 3;
export const MAX_AGE_6_MONTHS = SECONDS_IN_1_MONTH * 6;
export const MAX_AGE_5_YEARS = SECONDS_IN_1_YEAR * 5;
// used directly with express before app & $cookies plugin is created, so time is needed in milliseconds
export const MAX_AGE_5_YEARS_IN_MS = SECONDS_IN_1_YEAR * 5 * 1000;
export const MAX_AGE_100_YEARS = SECONDS_IN_1_YEAR * 100;

export const GLOBAL_QUERY_PARAM_TO_COOKIE_MAP = {
    [NUU_QUERY_FACEBOOK_CLICK_ID]: {
        queryKey: NUU_QUERY_FACEBOOK_CLICK_ID,
        cookieKey: NUU_HEADER_VENDOR_CLICK_ID,
        maxAge: MAX_AGE_3_MONTHS,
    },
};
