<template>
    <div class="c-form__field-container">
        <Field
            v-model="selected"
            :name="name"
            :value="modelValue"
            :rules="{ required: isRequired }"
            v-slot="{ field, errorMessage }"
        >
            <div
                :class="{
                    'c-form__input-container--error': !!(errorText || errorMessage),
                    'c-form__input-container--sort': selectType === SELECT_STYLE_TYPES.SORT,
                    'c-form__input-container--dirty': selected,
                    [selectClass]: !!selectClass,
                }"
                class="c-form__input-container"
            >
                <select
                    :id="id"
                    v-bind="field"
                    :aria-label="labelText"
                    :disabled="isDisabled"
                    :tabindex="tabIndex"
                    :class="{
                        'c-form__input--error': !!(errorText || errorMessage),
                        'c-form__input--sort': selectType === SELECT_STYLE_TYPES.SORT,
                        'c-form__select--sort': selectType === SELECT_STYLE_TYPES.SORT,
                    }"
                    class="c-form__input c-form__select"
                    @change="$emit('update:modelValue', selected)"
                >
                    <option
                        disabled
                        :selected="selectType !== SELECT_STYLE_TYPES.SORT"
                        value
                    >
                        {{ selectType === SELECT_STYLE_TYPES.SORT ? labelText : '' }}
                    </option>
                    <option
                        v-for="(option) in options"
                        :key="option.value"
                        :value="option.value"
                        :disabled="option.disabled"
                        :selected="option.value === selected"
                    >
                        {{ option.label }}
                    </option>
                </select>
                <BaseIcon
                    :icon="selectType === SELECT_STYLE_TYPES.SORT ? 'global--sort' : 'global--chevron'"
                    size="16px"
                    color="primary"
                    orientation="down"
                    class="c-form__select-dropdown-arrow"
                />
                <div
                    v-if="selectType !== SELECT_STYLE_TYPES.SORT"
                    class="c-form__label c-form__label--dynamic"
                >
                    {{ labelText + (isRequired ? '*' : '') }}
                </div>
            </div>
            <ErrorMessage :name="name" v-slot="{ message }">
                <BaseSupportingTextError
                    :supportingText="supportingText"
                    :errorText="errorText || message"
                />
            </ErrorMessage>
        </Field>
    </div>
</template>

<script>
import { SELECT_STYLE_TYPES } from '~coreModules/core/js/constants';

import BaseSupportingTextError from '~coreModules/core/components/ui/form-elements/BaseSupportingTextError.vue';
import formFieldProps from '~coreModules/core/components/mixins/formFieldProps.js';
import selectProps from '~coreModules/core/components/mixins/selectProps.js';
import { ErrorMessage, Field } from 'vee-validate';

export default {
    name: 'BaseSelect',
    components: {
        BaseSupportingTextError,
        Field,
        ErrorMessage,
    },
    mixins: [formFieldProps, selectProps],
    props: {
        // Only used for v-model, not intended to be passed in
        modelValue: {
            type: [String, Number],
            default: '',
        },
        selectType: {
            type: String,
            default: SELECT_STYLE_TYPES.DEFAULT,
        },
        selectClass: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            SELECT_STYLE_TYPES,
            selected: null || this.modelValue,
        };
    },
    watch: {
        modelValue() {
            this.selected = this.modelValue;
        },
    },
};
</script>

<style lang="scss">

</style>
