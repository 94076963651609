const getLogger = require('@nuuly-ui/core-lib/modules/core/js/logger');

// eslint-disable-next-line global-require
let config = require('../../../../config/config');

if (process.env.VUE_ENV === 'client') {
    // eslint-disable-next-line global-require
    config = require('~config/config');
}

const logger = getLogger(config);

module.exports = logger;
