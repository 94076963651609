// https://github.com/nuxt/nuxt.js/issues/2512#issuecomment-358215583
export function getScrollTop() {
    if (process.env.VUE_ENV === 'client') {
        return Math.max(
            window.pageYOffset,
            document.documentElement ? document.documentElement.scrollTop : 0,
            document.body.scrollTop,
        );
    }

    return 0;
}

export function getClientWidth() {
    if (process.env.VUE_ENV === 'client') {
        return document.documentElement.clientWidth;
    }

    return 0;
}

export function getClientHeight() {
    if (process.env.VUE_ENV === 'client') {
        return document.documentElement.clientHeight;
    }

    return 0;
}

/**
 * @description
 * gets distance from the top of the viewport to given HTML element
 *
 * @param {HTMLElement} el - the target element
 * @returns {Number} distance from the top of the viewport to element
 */
export function getDistanceFromElementToTop(el) {
    if (el instanceof HTMLElement) {
        return (el.getBoundingClientRect().top || 0) + (window.scrollY || 0);
    }
    return 0;
}

/**
 * @description
 * gets scroll height of document
 *
 * @returns {(Number|null)}  scroll height of document
 */
export function getDocumentHeight() {
    const { body, documentElement } = document;

    return Math.max(body.scrollHeight, body.offsetHeight, documentElement.clientHeight,
        documentElement.scrollHeight, documentElement.scrollHeight);
}

/**
 * @description
 * gets users iOS version or null if non iOS
 *
 * @param {String}  userAgent    users device type
 * @returns {(Number|null)}      iOS major and minor version or null if non iOS
 */
export function getIOSVersion(userAgent) {
    if (!userAgent) return null;

    const iosOSRegex = /OS (\d\d?_\d(_\d)?)/;
    const matches = iosOSRegex.exec(userAgent);

    if (!matches) return null;

    const parts = matches[1].split('_').map(str => parseInt(str, 10));

    return parseFloat(`${parts[0]}.${parts[1]}`);
}

/**
 * @description
 * returns true if the browser supports CSS has
 *
 * @returns {Boolean}
 */
export function doesBrowserSupportCssHas() {
    return !!(window.CSS && CSS.supports('selector(html:has(body))'));
}

/**
 * @description
 * highlights text in a given HTML element
 *
  * @param {HTMLElement}   el  the container element
  * @returns {undefined} No return value
 */
export function selectElementText(el) {
    let selection;
    let range;

    if (window?.getSelection && el) {
        selection = window?.getSelection?.();
        range = document?.createRange?.();
        range?.selectNodeContents?.(el);
        selection?.removeAllRanges?.();
        selection?.addRange?.(range);
    }
}
