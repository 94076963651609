<template>
    <VeeForm ref="baseForm" v-slot="{ handleSubmit }" as="div">
        <VeeFormContext
            novalidate
            tag="form"
            :context="getFormContext"
            @submit="handleSubmit($event, safeSubmit)"
        >
            <slot :isLoading="isLoading"></slot>
            <div v-if="submitButton" :class="buttonContainerClass">
                <LoadingButton
                    type="submit"
                    :isLoading="isLoading"
                    :isLoaded="isLoaded"
                    :class="buttonClass"
                    :disabled="isDisabled"
                >
                    {{ submitButton.text }}
                </LoadingButton>
            </div>
        </VeeFormContext>
    </VeeForm>
</template>

<script>
import { Form as VeeForm } from 'vee-validate';

import VeeFormContext from '~coreModules/core/components/ui/forms/FormContext.vue';
import LoadingButton from '~coreModules/core/components/ui/buttons/LoadingButton.vue';

export default {
    name: 'BaseForm',
    components: {
        VeeForm,
        VeeFormContext,
        LoadingButton,
    },
    props: {
        onSubmit: {
            type: Function,
            required: true,
        },
        submitButton: {
            type: Object,
            default: null,
            validator: obj => !!obj.text,
        },
        shouldSetMinLoadTime: {
            type: Boolean,
            default: false,
        },
        shouldAllowResubmission: {
            type: Boolean,
            default: false,
        },
        isLoaded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hasBeenSubmitted: false,
            isLoading: false,
            buttonClass: this.submitButton?.class || '',
            buttonContainerClass: this.submitButton?.buttonContainerClass || '',
            submitDebounce: 500,
            minimumLoadTime: 500,
        };
    },
    computed: {
        isDisabled() {
            return this.submitButton?.disabled || false;
        },
    },
    methods: {
        getFormContext() {
            return this.$refs.baseForm;
        },
        // eslint-disable-next-line consistent-return
        async triggerFormSubmit() {
            const { valid } = await this.$refs.baseForm.validate();

            if (!valid) {
                return Promise.reject();
            }

            await this.safeSubmit?.();
        },
        safeSubmit(...opts) {
            if (!this.hasBeenSubmitted) {
                this.isLoading = true;
                this.hasBeenSubmitted = true;

                return this.onSubmit?.(...opts)
                    .catch((error) => {
                        setTimeout(() => {
                            this.hasBeenSubmitted = false;
                        }, this.submitDebounce);
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        if (this.shouldSetMinLoadTime) {
                            setTimeout(() => {
                                this.isLoading = false;
                            }, this.submitDebounce);
                        } else {
                            this.isLoading = false;
                        }

                        if (this.shouldAllowResubmission) {
                            setTimeout(() => {
                                this.hasBeenSubmitted = false;
                            }, this.submitDebounce);
                        }
                    });
            }

            return Promise.resolve();
        },
        reset() {
            this.$refs.baseForm.resetForm();
        },
    },
};
</script>

<style lang="scss">

</style>
