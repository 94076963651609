export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "account__paymentRequired--heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your last subscription payment failed"])},
        "account__paymentRequired--subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please update your payment information below"])},
        "account__paymentRequired--successHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're On It"])},
        "account__paymentRequired--successSubheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're running your credit card now—it may take a few minutes to complete the process."])},
        "account__paymentRequired--successCta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
        "address__type--billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Details"])},
        "global__save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "global__previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])}
      }
    }
  })
}
