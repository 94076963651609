import createStore, { APP_LOGOUT_ACTIONS } from '~coreModules/core/js/store';
import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';
import loginModule, { LOGIN_MODULE_NAME } from '~coreRoutes/login/js/login-store';
import { BRAZE_MODULE_NAME, RANDOMIZE_BRAZE_USER } from '~coreModules/braze/js/braze-store';
import { NUU_COOKIE_USER_IS_WAITLISTED } from '~modules/core/js/cookie-constants';

import analyticsActionListener from '~modules/analytics/js/analytics-listener';
import brazeActionListener from '~modules/braze/js/braze-listener';

import boxModule, { BOX_MODULE_NAME, REMOVE_BONUS_ITEM_COUNT } from '~modules/box/js/box-store';
import createNavigationModule, { NAVIGATION_MODULE_NAME } from '~modules/nav/js/nav-store';
import productTourModule, { PRODUCT_TOUR_MODULE_NAME } from '~modules/product-tour/js/product-tour-store';
import createProfileModule, { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';
import createSiteSettingsModule, { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';
import { shutdownIntercom } from '~modules/intercom/js/intercom-utils';
import closetModule, { CLOSET_ACTIONS_MODULE_NAME } from '~routes/closet/js/closet-actions-store';

import subscriptionsModule, { SUBSCRIPTIONS_MODULE_NAME } from '~routes/account/js/subscriptions-store';

export const SET_IS_OVERDUE = 'SET_IS_OVERDUE';

// Expose a factory function to ensure a new store per request
export default function createRentStore(initialState, logger, config) {
    const plugins = [];

    const rootState = {
        isOverdue: false,
    };

    const rootActions = {
        async [APP_LOGOUT_ACTIONS]({ commit, dispatch, rootGetters }) {
            const shouldTrack = rootGetters[`${COMPLIANCE_MODULE_NAME}/shouldTrack`];

            this.$cookies.remove(NUU_COOKIE_USER_IS_WAITLISTED);

            commit(`${BOX_MODULE_NAME}/${REMOVE_BONUS_ITEM_COUNT}`, null, { root: true });

            if (config.features.intercom && shouldTrack) { await shutdownIntercom(); }

            if (config.features.brazeSdk) {
                await dispatch(`${BRAZE_MODULE_NAME}/${RANDOMIZE_BRAZE_USER}`, { root: true });
            }
        },
    };

    /* eslint-disable no-param-reassign */
    const rootMutations = {
        [SET_IS_OVERDUE](state, isOverdue) {
            state.isOverdue = isOverdue;
        },
    };
    /* eslint-enable no-param-reassign */

    const rootGetters = {};

    const modules = {
        [BOX_MODULE_NAME]: boxModule,
        [LOGIN_MODULE_NAME]: loginModule,
        [NAVIGATION_MODULE_NAME]: createNavigationModule(logger, config),
        [PRODUCT_TOUR_MODULE_NAME]: productTourModule,
        [PROFILE_MODULE_NAME]: createProfileModule(),
        [SITE_SETTINGS_MODULE_NAME]: createSiteSettingsModule(logger, config),
        [SUBSCRIPTIONS_MODULE_NAME]: subscriptionsModule,
        [CLOSET_ACTIONS_MODULE_NAME]: closetModule,
    };

    plugins.push((store) => {
        store.subscribeAction((action) => {
            if (config.features.googleTagManager) {
                analyticsActionListener(store, action);
            }
            if (store.getters.loggedIn && config.features.brazeSdk) {
                brazeActionListener(store, action);
            }
        });
    });

    const store = createStore({
        initialState,
        logger,
        config,
        plugins,
        rootState,
        rootActions,
        rootMutations,
        rootGetters,
        modules,
    });

    return store;
}
