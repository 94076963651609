import {
    CATALOG_QUERY_PAGE,
    CATALOG_QUERY_SORT,
    CATALOG_QUERY_SEARCH,
} from '~coreModules/catalog/js/catalog-constants';

export const CATALOG_TYPES = {
    BROWSE: 'browse',
    BROWSE_SIMILAR: 'browseSimilar',
    SEARCH: 'search',
    CLOSET: 'closet',
    CLOSET_LIST: 'closetList',
};

export const CATALOG_GRID_LAYOUTS = {
    SMALL: 'SMALL',
    LARGE: 'LARGE',
};

export const DEFAULT_CATALOG_GRID_LAYOUT = CATALOG_GRID_LAYOUTS.LARGE;
export const DEFAULT_CATALOG_AVAILABILITY_FILTER = true;

export const CATALOG_PAGE_SIZE = 96;
export const RECOMMENDATION_TRAY_PAGE_SIZE = 15;
export const PRE_PURCHASE_MAX_PRODUCT_COUNT_DISPLAY = 500;

export const CATALOG_QUERY_IN_STOCK = 'inStock';
export const CATALOG_QUERY_SIZE = 'size';
export const CATALOG_QUERY_ITEMS_PER_PAGE = 'itemsPerPage';
export const CATALOG_QUERY_NUULY_CLASS = 'nuulyClass';
export const CATALOG_QUERY_CUSTOMER_LIFESTYLE_AESTHETIC = 'customerLifestyleAesthetic';
export const CATALOG_QUERY_CONTEXT_IDS = 'contextIds';
export const CATALOG_QUERY_EXCLUDE_CHOICE_IDS = 'excludeChoiceIds';
export const CATALOG_QUERY_EXCLUDE_SUBLIST = 'excludeSublist';
export const CATALOG_QUERY_NUULY_PROGRAMS = 'nuulyPrograms';

export const CATALOG_QUERY_BOX_CONTEXT = {
    subscriber: 'subscriber',
};

export const CATALOG_SORT_OPTIONS = {
    similar: 'similar',
};

export const CATALOG_SHOP_THE_LOOK_TRAY_LIST_NAME = 'pdp_tray_stl';

export const RESET_FILTER_QUERIES = [CATALOG_QUERY_SORT, CATALOG_QUERY_SEARCH];

// query params that we allow to be passed to our catalog service.
// this is merged with the values returned from the contentful
// site config (site-settings/contentfulAppConfig.attributeGroups)
export const CATALOG_QUERY_WHITELIST = [
    CATALOG_QUERY_IN_STOCK,
    CATALOG_QUERY_SORT,
    CATALOG_QUERY_PAGE,
    CATALOG_QUERY_ITEMS_PER_PAGE,
    CATALOG_QUERY_NUULY_CLASS,
    CATALOG_QUERY_CUSTOMER_LIFESTYLE_AESTHETIC,
    CATALOG_QUERY_CONTEXT_IDS,
    CATALOG_QUERY_EXCLUDE_CHOICE_IDS,
    CATALOG_QUERY_EXCLUDE_SUBLIST,
    CATALOG_QUERY_NUULY_PROGRAMS,
];
