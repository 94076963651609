import { ANALYTICS_MODULE_NAME } from '~coreModules/analytics/js/analytics-store';

export default function initializeAnalytics(config, createModule) {
    return {
        created() {
            if (process.env.VUE_ENV === 'client') {
                if (config.features.googleTagManager) {
                    this.$store.registerModule(ANALYTICS_MODULE_NAME, createModule(config));
                }
            }
        },
    };
}
