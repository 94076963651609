export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "payment__ccNumber--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
        "payment__ccNumber--placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXXX XXXX XXXX XXXX"])},
        "payment__expMonth--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Month"])},
        "payment__expMonth--placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
        "payment__expYear--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Year"])},
        "payment__expYear--placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY"])},
        "payment__cvv--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
        "payment__cvv--placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXX"])},
        "onboarding__billingPayment--recurlyValidationErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check + fix"])}
      }
    }
  })
}
