<template>
    <div class="c-lazyload">
        <slot :hasEnteredViewport="hasEnteredViewport"></slot>
    </div>
</template>

<script>
import { isFunction } from 'lodash-es';

export default {
    name: 'LazyLoad',
    props: {
        rootEl: {
            // SSR does not support the HTMLElement type
            type: process.env.VUE_ENV === 'client' ? HTMLElement : undefined,
            default: null,
        },
        bottomMargin: {
            type: String,
            default: '0px',
        },
        callbackThresholds: {
            type: Array,
            default: () => [0, 0.25, 0.5, 0.75, 1.0],
        },
        observerCallback: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            observer: null,
            hasEnteredViewport: false,
        };
    },
    mounted() {
        if ('IntersectionObserver' in window) {
            this.initObserver();
        } else {
            this.$logger.debugError('IntersectionObserver is not supported, and was not polyfilled');
            this.hasEnteredViewport = true;
        }
    },
    unmounted() {
        this.destroyObserver();
    },
    methods: {
        defaultObserverCallback(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.intersectionRatio >= 0.0) {
                        this.hasEnteredViewport = true;
                        // Once we're in the viewport, simply remain that way forever, so we can
                        // destroy the observer
                        this.destroyObserver();
                    }
                }
            });
        },
        initObserver() {
            const observerOptions = {
                root: this.rootEl,
                rootMargin: `0px 0px ${this.bottomMargin} 0px`,
                threshold: this.callbackThresholds,
            };
            this.callback = isFunction(this.observerCallback) ?
                this.observerCallback :
                this.defaultObserverCallback;
            this.observer = new IntersectionObserver(this.callback, observerOptions);
            this.observer.observe(this.$el);
        },
        destroyObserver() {
            if (this.observer) {
                this.observer.unobserve(this.$el);
                this.observer.disconnect();
                this.observer = null;
            }
        },
    },
};
</script>

<style lang="scss">
    .c-lazyload {
        width: 100%;
    }
</style>
