import { get, isEqual } from 'lodash-es';

import { NUULY_BUSINESS_TYPES } from '~coreModules/core/js/constants';
import { APP_BASE_URLS_MAP } from '~coreModules/core/js/router-constants';
import { onlyHashHasChanged } from '~coreModules/core/js/url-utils';

export function getNuulySiteType(path) {
    const appBaseUrlsMinusCommon = Object.entries(APP_BASE_URLS_MAP)
        .filter(entry => entry[0] !== NUULY_BUSINESS_TYPES.common);

    return appBaseUrlsMinusCommon.find(entry => path.startsWith(entry[1]))?.[0] || NUULY_BUSINESS_TYPES.common;
}

/**
 * returns a boolean that tells us if the provided route is an error route
 * @param {Object} route - the route object
 * @returns {Boolean} whether the provided route is an error route
 */
export function isErrorRoute(route) {
    return route?.name === 'error';
}

/**
* returns a boolean that tells us if the route change should proceed without showing the global loader
* @param {Object} toRoute - the destination route
* @param {Object} fromRoute - the active route
* @returns {Boolean} Whether the global loader should be prevented from showing
*/
export function shouldPreventGlobalLoader(toRoute, fromRoute) {
    const ignoreBaseLayout = get(toRoute, 'meta.ignoreBaseLayout', false);
    const preventGlobalLoaderOnQueryChange = get(toRoute, 'meta.preventGlobalLoaderOnQueryChange', false);
    const toPath = get(toRoute, 'path', '')?.split('?')[0];
    const toQuery = get(toRoute, 'query');
    const fromPath = get(fromRoute, 'path', '')?.split('?')[0];
    const fromQuery = get(fromRoute, 'query');
    const onlyQueryHasChanged = fromPath && (toPath === fromPath) && !isEqual(toQuery, fromQuery);

    const isSameParent = fromRoute.matched.some(record => record === toRoute.matched[0]);
    const preventGlobalLoaderOnChildRouteChange =
        !!toRoute?.meta?.preventGlobalLoaderOnChildRouteChange && isSameParent;

    return isErrorRoute(fromRoute) || onlyHashHasChanged(toRoute, fromRoute) || ignoreBaseLayout ||
        preventGlobalLoaderOnChildRouteChange || (preventGlobalLoaderOnQueryChange && onlyQueryHasChanged);
}
