<script>
import { h } from 'vue';

export default {
    name: 'DiscernibleText',
    setup: (_, { slots }) => () => (
        h('span', { class: 'c-discernible-text' }, slots.default())
    ),
};
</script>

<style lang="scss">
.c-discernible-text {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
</style>
