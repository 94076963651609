import { get } from 'lodash-es';

/**
 * Formats address in user friendly way
 * Must render with markdown component to see the line breaks (\n)
 * @param {Object} rawAddress - r15 formatted shipping address
 * @param {String} fullNameClass - optional class to wrap full name
 * @return {String} user friendly formatted address
 */
export function formatAddress(rawAddress, fullNameClass) {
    const line1 = get(rawAddress, 'line1', '');
    const line2 = get(rawAddress, 'line2') ? `${rawAddress.line2}, ` : '';
    const city = get(rawAddress, 'city', '');
    const state = get(rawAddress, 'state', '');
    const zip = get(rawAddress, 'zip', '');
    const firstName = get(rawAddress, 'firstName', '');
    const lastName = get(rawAddress, 'lastName', '');
    let fullName = `${firstName} ${lastName}<br>`;

    if (fullNameClass) {
        fullName = `<span class="${fullNameClass}">${fullName}</span>`;
    }

    if (!line1 || !city || !state || !zip) {
        return '';
    }

    return `${fullName}${line1}, ${line2}<br>${city}, ${state} ${zip}`;
}

export default formatAddress;
