<template>
    <CoreNotificationManager
        :allowNotifications="allowNotifications"
        :modalHashEvents="modalHashEvents"
        :iconComponent="AppIcon"
    >
        <template v-if="brazeIsEnabledAndLoaded && loggedIn" #default>
            <AsyncBrazeNotificationManager
                :allowNotifications="allowNotifications"
                :allowStickyNotifications="allowBrazeStickyNotifications"
            />
        </template>
    </CoreNotificationManager>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineAsyncComponent, markRaw } from 'vue';

import runtimeConfig from '~config/config';

import { getModalHashEvents } from '~modules/modals/js/modal-constants';
import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';

import CoreNotificationManager from '~coreModules/notifications/components/CoreNotificationManager.vue';

import AppIcon from '~modules/core/components/ui/AppIcon.vue';

export default {
    name: 'NotificationManager',
    components: {
        CoreNotificationManager,
        AsyncBrazeNotificationManager: defineAsyncComponent(() => import(
            /* webpackChunkName: "brazeNotificationManager" */
            '~modules/braze/components/BrazeNotificationManager.vue')),
    },
    props: {
        allowNotifications: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const featureConfig = this.$runtimeConfig.features;

        return {
            brazeIsEnabledAndLoaded: false,
            modalHashEvents: getModalHashEvents(featureConfig),
            AppIcon: markRaw(AppIcon),
        };
    },
    computed: {
        ...mapGetters([
            'loggedIn',
        ]),
        ...mapGetters(COMPLIANCE_MODULE_NAME, [
            'isComplianceNotificationActive',
        ]),
        allowBrazeStickyNotifications() {
            return !this.isComplianceNotificationActive && this.brazeIsEnabledAndLoaded;
        },
    },
    mounted() {
        this.brazeIsEnabledAndLoaded = runtimeConfig.features.brazeSdk;
    },
};

</script>

<style lang="scss">

</style>
