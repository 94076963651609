<i18n>
[
    "global_loading",
]
</i18n>
<template>
    <div class="c-global-loader">
        <div
            v-if="showLoader && animationData"
            :aria-busy="showLoader"
            role="alert"
            class="c-global-loader__container o-flex-horizontal-center o-flex-vertical-center"
        >
            <BaseLottie
                ref="lottieAnimation"
                class="c-global-loader__animation"
                :animationData="animationData"
                :autoPlay="true"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import BaseLottie from '~coreModules/core/components/ui/BaseLottie.vue';

defineProps({
    showLoader: {
        type: Boolean,
        default: false,
    },
});

const animationData = ref(null);

(async () => {
    const animationImport = await import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "globalLoader" */
        '~coreModules/core/js/lottie/global_loader.json');

    animationData.value = animationImport.default;
})();

</script>

<style lang="scss">
.c-global-loader {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;

    &__container {
        width: 100vw;
        height: 100%;
    }

    &__animation {
        filter: $nu-filter-color;
        -webkit-filter: $nu-filter-color;
    }
}
</style>
