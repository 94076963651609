<script>
import { h } from 'vue';
import { merge } from 'lodash-es';

import { dynamicSVGRequire } from '~coreModules/core/js/svg-utils';
import { GLOBAL_ICONS } from '~coreModules/core/js/svg-constants';

export default {
    name: 'BaseIcon',
    props: {
        appIcons: {
            type: Map,
            default: new Map([]),
        },
        // icon must be present in the map APP_ICONS (svg-constants.js)
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
            validator(color) {
                return [
                    'primary',
                    'secondary',
                    'black',
                    'gray',
                    'medium-gray',
                    'light-gray',
                    'white',
                ].includes(color);
            },
        },
        size: {
            type: String,
            required: true,
            validator(size) {
                return ['8px', '12px', '16px', '24px'].includes(size);
            },
        },
        isCoreDir: {
            type: Boolean,
            default: true,
        },
        orientation: {
            type: String,
            default: 'up',
            validator(direction) {
                return ['up', 'down', 'left', 'right'].includes(direction);
            },
        },
        // duration of CSS transitions in milliseconds (e.g. color or rotation). Default is 200, 0 is disabled
        transitionDuration: {
            type: [Number, String],
            default: 200,
            validator(ms) {
                return !Number.isNaN(ms);
            },
        },
    },
    watch: {
        $props: {
            immediate: true,
            handler() {
                this.validateIcon();
            },
        },
    },
    methods: {
        validateIcon() {
            if (!(GLOBAL_ICONS.has(this.icon) || this.appIcons.has(this.icon))) {
                this.$logger.warn('Invalid Prop: check failed for prop "icon"');
            }
        },
    },
    render() {
        const imgPath = `icon__${this.icon}.svg`;
        // eslint-disable-next-line max-len
        const svgMarkup = dynamicSVGRequire(imgPath, this.$logger, this.isCoreDir).replace('<svg', `<svg alt="${(this.isCoreDir ? GLOBAL_ICONS : this.appIcons).get(this.icon)}"`);

        const duration = Number.parseFloat(this.transitionDuration);

        const mergedData = merge({}, this.$attrs, {
            class: `c-icon c-icon--${this.size} c-icon--color-${this.color} c-icon--orient-${this.orientation}`,
            innerHTML: svgMarkup,
            style: `--transitionTiming: ${duration}ms;`,
        });

        return h('span', mergedData);
    },
};
</script>

<style lang="scss">
    .c-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &,
        *{
            transition-property: all;
            transition-timing-function: ease;
            transition-duration: var(--transitionTiming);
            overflow: visible;
        }

        & > * {
            height: 100%;
            width: 100%;
            overflow: visible;
        }

        &--8px {
            height: 8px;
            width: 8px;
        }

        &--12px {
            height: 12px;
            width: 12px;
        }

        &--16px {
            height: 16px;
            width: 16px;
        }

        &--24px {
            height: 24px;
            width: 24px;
        }

        &--color-primary {
            fill: $nu-primary;
        }

        &--color-secondary {
            fill: $nu-secondary;
        }

        &--color-black {
            fill: $nu-black;
        }

        &--color-gray {
            fill: $nu-gray;
        }

        &--color-medium-gray {
            fill: $nu-gray--medium;
        }

        &--color-light-gray {
            fill: $nu-gray--light;
        }

        &--color-white {
            fill: $nu-white;
        }

        &--orient-right > * {
            transform: rotate(90deg);
        }

        &--orient-down > * {
            transform: rotate(180deg);
        }

        &--orient-left > * {
            transform: rotate(270deg);
        }
    }
</style>
