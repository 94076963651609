/**
 * Gets date Object From MM/DD/YYYY string
 * @param  {String}  date in MM/DD/YYYY format
 * @returns {Object} Object containing day, month, year
 */
export function getDateObjectFromString(dateString) {
    if (!dateString) {
        return {};
    }

    const date = dateString.replace(/\s/g, '');
    const dateArray = date.split('/');

    if (dateArray.length !== 3) {
        return {};
    }

    return {
        day: dateArray[1],
        month: dateArray[0],
        year: dateArray[2],
    };
}

/**
 * Calculates age from a profile's birthday object
 * @param {Object} birthdayObject - profile birthday object { day, month, year }
 * @param {Date} ageComparisonDate - date to measure age against
 * @returns {Number} - age in years
 */
export function getAgeFromBirthdayObject(birthdayObject = {}, ageComparisonDate = new Date()) {
    const { day, month, year } = birthdayObject;
    if (!day && !month && !year) {
        return null;
    }

    const rawYearsAge = ageComparisonDate.getFullYear() - parseInt(year, 10);
    const monthComparison = (ageComparisonDate.getMonth() + 1) - parseInt(month, 10);
    const dayComparison = ageComparisonDate.getDate() - parseInt(day, 10);

    if (monthComparison < 0 || (monthComparison === 0 && dayComparison < 0)) {
        return rawYearsAge - 1;
    }

    return rawYearsAge;
}

/**
 * Calculates difference in days between 2 dates
 * @param {String} date1String - date to compare
 * @param {String} date2String - date to compare (defaults to today)
 * @returns {Number} - difference in days
 */
export function getDiffDays(date1String, date2String = new Date()) {
    const date1 = new Date(date1String);
    const date2 = new Date(date2String);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

/**
 * Returns the date equavalent of an epoch time
 * @param {Number} epochTime - Epoch time in seconds
 * @returns {Date} - Date that matches epoch time
 */
export function getDateFromEpoch(epochTime) {
    // Multiply by 1000 to get milliseconds
    return new Date(epochTime * 1000);
}

/**
 * Returns the timezone from a date
 * @param {String} dateString - the provided date string
 * @returns {String} - the formatted timezone from the date string
 */
export function getTimezoneFromDate(dateString) {
    const date = new Date(dateString).toString();
    const timezone = date.match(/\(([^)]+)\)/)?.[1];
    if (timezone.includes(' ')) {
        return timezone.split(' ').map(([first]) => first).join('');
    }
    return timezone;
}

/**
 * Determines if a date is today's date
 * @param {String} date1String - date to compare
 * @param {String} date2String - date to compare (defaults to today)
 * @returns {Boolean} - true if date is today's date
 */
export function isDateTodaysDate(date1String, date2String = new Date()) {
    const date1 = new Date(date1String);
    const date2 = new Date(date2String);
    return date1.toDateString() === date2.toDateString();
}

/**
 * Determines if a date is tomorrow's date
 * @param {String} dateString - date to compare
 * @returns {Boolean} - true if date is tomorrow's date
 */
export function isDateTomorrowsDate(dateString) {
    const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    return isDateTodaysDate(dateString, tomorrow);
}
