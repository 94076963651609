import { getCookie, removeCookie, setCookie } from 'tiny-cookie';

// eslint-disable-next-line import/prefer-default-export
export function getBrowserCookieInterface() {
    return {
        get: name => getCookie(name) || undefined,
        set: (name, val, options = {}) => {
            const { maxAge } = options;
            // express cookieParser expects maxAge in ms and tiny-cookie expects 'max-age' in s
            if (maxAge) {
                // eslint-disable-next-line no-param-reassign
                options['max-age'] = maxAge;
            }

            setCookie(name, val, options);
        },
        remove: name => removeCookie(name),
    };
}
