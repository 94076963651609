<i18n>
[
    "header__account--label",
    "header__signOut--label",
    "global__myAccount",
]
</i18n>
<template>
    <div class="c-header-utils">
        <template v-if="loggedIn">
            <div ref="accountIcon" class="c-header-utils__account">
                <BaseTooltip
                    :trigger="tooltipTrigger"
                    :tooltipVerticalMargin="0"
                    :tooltipIconClick="handleAuthenticatedClick"
                    :ariaLabel="$t('global__myAccount')"
                >
                    <template #launcher>
                        <div
                            class="o-flex-center c-header-utils__launcher"
                            @focus="triggerAnimation('accountIcon', true)"
                            @mouseenter="triggerAnimation('accountIcon', true)"
                        >
                            <AppIcon
                                icon="account--my-account"
                                color="primary"
                                size="24px"
                            />
                        </div>
                    </template>
                    <template #body>
                        <HeaderAuthenticatedMenu class="c-header-utils__menu" />
                    </template>
                </BaseTooltip>
            </div>
            <UrbnModalLauncher
                v-if="showClosetNavModal"
                ref="closetIcon"
                :modalOptions="closetNavModal"
                type="link"
                class="o-flex-vertical-center c-header-utils__closet-icon"
                @focus="triggerAnimation('closetIcon', true)"
                @mouseenter="triggerAnimation('closetIcon', true)"
            >
                <div
                    class="c-header-utils__product-tour-closet-lightbox-target"
                    :data-product-tour-lightbox="closetIconLightbox.selector"
                >
                    <AppIcon
                        icon="global--hanger"
                        size="24px"
                        color="primary"
                    />
                </div>
            </UrbnModalLauncher>
            <BaseLink
                v-else
                ref="closetIcon"
                :to="closetRoute"
                class="o-flex-vertical-center c-header-utils__closet-icon"
                @mouseenter="triggerAnimation('closetIcon', true)"
                @focus="triggerAnimation('closetIcon', true)"
            >
                <div
                    class="c-header-utils__product-tour-closet-lightbox-target"
                    :data-product-tour-lightbox="closetIconLightbox.selector"
                >
                    <AppIcon
                        icon="global--hanger"
                        size="24px"
                        color="primary"
                    />
                </div>
            </BaseLink>
            <BoxLauncherButton />
        </template>
        <template v-else>
            <BaseLink
                v-for="(link, index) in topLinks"
                :key="`${link.linkPath}-${index}`"
                :to="link.linkPath"
                :target="link.openInNewWindow ? '_blank' : null"
                class="c-header-utils__link"
            >
                <span class="o-text--link">{{ link.linkTitle }}</span>
            </BaseLink>

            <div ref="accountIcon" class="c-header-utils__account">
                <BaseTooltip
                    :trigger="tooltipTrigger"
                    :tooltipVerticalMargin="0"
                    :tooltipIconClick="handleUnauthenticatedClick"
                    :ariaLabel="$t('global__myAccount')"
                >
                    <template #launcher>
                        <div
                            class="o-flex-center c-header-utils__launcher"
                            @focus="triggerAnimation('accountIcon', true)"
                            @mouseenter="triggerAnimation('accountIcon', true)"
                        >
                            <AppIcon
                                icon="account--my-account"
                                color="primary"
                                size="24px"
                            />
                        </div>
                    </template>
                    <template #body>
                        <HeaderUnauthenticatedMenu class="c-header-utils__menu" />
                    </template>
                </BaseTooltip>
            </div>

            <BaseLink
                :to="anonymousHeaderCTA.linkPath"
                :aria-label="anonymousHeaderCTA.title"
                class="c-header-utils__anonymous-cta"
            >
                <BaseHeaderButton>{{ anonymousHeaderCTA.linkTitle }}</BaseHeaderButton>
            </BaseLink>
        </template>
    </div>
</template>

<script>
import { get } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { NAVIGATION_MODULE_NAME } from '~coreModules/nav/js/nav-store';
import { MODALS_MODULE_NAME, LAUNCH_MODAL } from '~coreModules/modals/js/modals-store';

import { genericRouteNames } from '~coreModules/core/js/router-constants';
import { LOGIN_MODULE_NAME, LOG_OUT } from '~coreRoutes/login/js/login-store';
import { CLOSET_ACTIONS_MODULE_NAME } from '~routes/closet/js/closet-actions-store';

import { TRACK_PRODUCT_SAVED_CHANGE } from '~coreModules/analytics/js/analytics-store';
import { rentalRouteNames } from '~modules/core/js/router-constants';
import { HOMEPAGE_TOUR_LIGHTBOXES } from '~modules/product-tour/js/product-tour-constants';
import { MODALS } from '~modules/modals/js/modal-constants';

import UrbnModalLauncher from '~coreModules/modals/components/UrbnModalLauncher.vue';

import BaseTooltip from '~coreModules/core/components/ui/tooltips/BaseTooltip.vue';
import BaseHeaderButton from '~coreModules/header/components/BaseHeaderButton.vue';
import BoxLauncherButton from '~modules/box/components/BoxLauncherButton.vue';
import HeaderAuthenticatedMenu from '~modules/header/components/HeaderAuthenticatedMenu.vue';
import HeaderUnauthenticatedMenu from '~modules/header/components/HeaderUnauthenticatedMenu.vue';

export default {
    name: 'HeaderUtilities',
    components: {
        BaseTooltip,
        BaseHeaderButton,
        BoxLauncherButton,
        UrbnModalLauncher,
        HeaderAuthenticatedMenu,
        HeaderUnauthenticatedMenu,
    },
    props: {
        loggedIn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            closetIconLightbox: HOMEPAGE_TOUR_LIGHTBOXES.closet,
            closetRoute: { name: rentalRouteNames.closet },
            closetNavModal: MODALS.closetNavModal,
            accountRoute: { name: rentalRouteNames.account.overview },
        };
    },
    computed: {
        ...mapGetters(NAVIGATION_MODULE_NAME, ['topLinks', 'anonymousHeaderCTA']),
        ...mapGetters(CLOSET_ACTIONS_MODULE_NAME, [
            'closetListsPreview',
        ]),
        showClosetNavModal() {
            return (this.closetListsPreview?.length ?? 0) > 0;
        },
        tooltipTrigger() {
            return this.$mediaQueries.isLargish ? 'hover' : 'programmatic';
        },
    },
    mounted() {
        this.$store.subscribeAction((action) => {
            if (action.type.includes(TRACK_PRODUCT_SAVED_CHANGE)) {
                if (action.payload.newStatus) {
                    this.triggerAnimation('closetIcon');
                }
            }
        });
    },
    methods: {
        ...mapActions(LOGIN_MODULE_NAME, {
            logoutUser: LOG_OUT,
        }),
        ...mapActions(MODALS_MODULE_NAME, {
            launchModal: LAUNCH_MODAL,
        }),
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        handleLogout() {
            this.logoutUser();
        },
        handleUnauthenticatedClick() {
            if (!this.$mediaQueries.isLargish) {
                this.launchModal(MODALS.unauthenticatedMenu);
            } else {
                this.$router.push({ name: genericRouteNames.login.phone.base });
            }
        },
        handleAuthenticatedClick() {
            if (!this.$mediaQueries.isLargish) {
                this.launchModal({
                    ...MODALS.authenticatedMenu,
                    props: {
                        isButtonFixed: true,
                    },
                });
            } else {
                this.$router.push({ name: rentalRouteNames.account.overview });
            }
        },

        triggerAnimation(targetRef, isMouseEnterEvent = false) {
            // prevent the "hover" animation on mobile, but still allow closet add to trigger animation

            if (
                (!isMouseEnterEvent || (isMouseEnterEvent && this.$mediaQueries.isLargish)) &&
                ['accountIcon', 'closetIcon'].includes(targetRef)
            ) {
                const getExpression = `$refs.${targetRef}${this.$refs[targetRef] instanceof HTMLElement ? '' : '.$el'}`;

                const { classList } = get(this, getExpression);
                const cssClass = 'is-animating';

                if (!classList.contains(cssClass)) {
                    classList.add(cssClass);

                    setTimeout(() => {
                        this.$nextTick(() => classList.remove(cssClass));
                    }, 700);
                }
            }
        },
    },
};
</script>

<style lang="scss">
    .c-header-utils {
        align-items: center;
        justify-content: space-between;

        &,
        &__closet-icon {
            display: inline-flex;
        }

        &__closet-icon,
        &__account {
            height: 48px;
            width: 40px;

            @include breakpoint(medium) {
                width: 48px;
                margin-right: $nu-spacer-1pt5;
            }
        }

        &__link,
        &__closet-icon,
        &__account-icon {
            justify-content: center;
            align-items: center;
        }

        &__account {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .c-tooltip {
                &__popover {
                    width: 360px;
                }

                &__content {
                    padding-top: 0;
                    box-shadow: none;

                    &::before {
                        display: none;
                    }
                }

                &__body {
                    padding: 0
                }
            }

            .c-icon {
                transform-origin: center bottom;
            }

            &.is-animating {
                .c-icon {
                    animation-name: accountHover;
                    animation-duration: 700ms;
                    animation-iteration-count: 1;
                    animation-timing-function: ease;
                }
            }

            @keyframes accountHover {
                0%   { transform: scale(1)      translateY(0);    }
                27%  { transform: scale(1, 0.9) translateY(0);    }
                45%  { transform: scale(1, 1.1) translateY(-4px); }
                78%  { transform: scale(1, .9)  translateY(0);    }
                100% { transform: scale(1)      translateY(0);    }
            }
        }

        &__launcher {
            height: 48px;
            width: 40px;

            @include breakpoint(medium) {
                width: 48px;
            }
        }

        &__account-link {
            color: $nu-gray;

            &:hover {
                color: $nu-primary;
            }
        }

        &__closet-icon {
            margin-right: 0;

            .c-icon {
                transform-origin: center top;
            }

            @include breakpoint(medium) {
                margin-right: $nu-spacer-1pt5;
            }

            &.is-animating {
                .c-icon {
                    animation-name: closetHover;
                    animation-duration: 700ms;
                    animation-iteration-count: 1;
                    animation-timing-function: ease;
                }

                @keyframes closetHover {
                    0%   { transform: rotate(0);      }
                    25%  { transform: rotate(-17deg); }
                    50%  { transform: rotate(17deg);  }
                    75%  { transform: rotate(-5deg);  }
                    100% { transform: rotate(0);      }
                }
            }
        }

        &__product-tour-closet-lightbox-target {
            pointer-events: none;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
        }

        &__menu {
            border-top: 1px solid $nu-gray--light;
        }

        &__link {
            margin-right: $nu-spacer-3;
            display: none;

            @include breakpoint(large) {
                display: inline-flex;
                white-space: nowrap;

                &:nth-last-child(1 of &) {
                    margin-right: $nu-spacer-1pt5;
                }
            }
        }

        &__anonymous-cta {
            margin: 0 $nu-spacer-1;

            @include breakpoint(medium) {
                margin: 0;
            }
        }
    }
</style>
