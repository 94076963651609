/**
 * returns a number of the total product previews in a closet list
 * @param {Object} closetLists - the closet lists object
 * @param {Array} closetLists.previewChoices - the product previews
 * @param {String} choiceId - the choice id of the desired product to filter
 * @returns {Number} the total amount of products previews in a list
 */
export function getPreviewChoiceCount({ previewChoices } = {}, choiceId) {
    const filteredPreviewChoices = (previewChoices || []).filter(
        previewChoice => previewChoice.choiceId === choiceId);
    return (filteredPreviewChoices || []).length;
}

/**
 * returns an array shaped closet lists with the decremented product preview count
 * @param {Object} closetLists - the closet lists object
 * @param {String} choiceId - the choice id of the desired product to find
 * @returns {Array} the shaped closet with decremented total of product previews
 */
export function getShapedClosetLists(closetLists, choiceId) {
    return Object.values(closetLists)
        .map(({ totalChoiceCount, ...list }) => Object.assign(list, {
            totalChoiceCount: totalChoiceCount - getPreviewChoiceCount(list, choiceId),
        }));
}

/**
 * returns an array of sorted closet lists when there is a matching product previews
 * @param {Object} closetLists - the closet lists object
 * @param {String} choiceId - the choice id of the desired product to find
 * @returns {Array} the sorted closet lists by matching product previews
 */
export function getSortedClosetLists(closetLists, choiceId) {
    const sortByCount = (a, b) => {
        const countA = getPreviewChoiceCount(a, choiceId);
        const countB = getPreviewChoiceCount(b, choiceId);
        // converting boolean to 0 or 1 for proper sorting
        return Number(countB > 0) - Number(countA > 0);
    };
    return getShapedClosetLists(closetLists, choiceId)
        .sort(sortByCount);
}

/**
 * returns an array of filtered product previews with the matching product removed
 * @param {Object} closetLists - the closet lists object
 * @param {Array} closetLists.previewChoices - the product previews
 * @param {String} choiceId - the choice id of the desired product to remove
 * @returns {Array} the filtered closet lists with out the product
 */
export function getFilteredPreviewChoices({ previewChoices } = {}, choiceId) {
    const filteredPreviewChoices = (previewChoices || []).filter(
        previewChoice => previewChoice.choiceId !== choiceId);
    return filteredPreviewChoices;
}

/**
 * returns an object of filtered and shaped closet list data
 * @param {Object} closetLists - the closet lists object
 * @param {String} choiceId - the choice id of the desired product to remove
 * @returns {Object} the filtered closet lists object
 */
export function getFilteredClosetLists(closetLists, choiceId) {
    return Object.fromEntries(getSortedClosetLists(closetLists, choiceId).map(list => [
        list.id,
        Object.assign(list, {
            previewChoices: getFilteredPreviewChoices(list, choiceId),
        }),
    ]));
}
