import { inject } from 'vue';

export const loggerInjectionKey = Symbol('logger');

/**
 * A composable that provides access to the our logger
 * @function
 * @returns {Object} the injected logger made available to the application
 */
export const useLogger = () => inject(loggerInjectionKey);

/**
 * Provides our logger to the application
 * @param {Object} app - the application instance
 * @param {Object} logger - the logger instance to be provided
 * @returns {Object} the provided logger instance
 */
export function provideLogger(app, logger) {
    // provide the logger to the app so it can be used in script setup
    app.provide(loggerInjectionKey, logger);
    return logger;
}
