export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "account__paymentRequired--notificationHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks Like Your Credit Card Isn't Working..."])},
        "account__paymentRequired--notificationSubheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please update your payment information to keep your Nuuly subscription active."])},
        "account__paymentRequired--cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Credit Card"])}
      }
    }
  })
}
