export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "header__signIn--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "login__unauthSignIn--subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have a Nuuly account?"])},
        "login_signIn--cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])}
      }
    }
  })
}
