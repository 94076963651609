import { get } from 'lodash-es';

import { getMissingRequiredFields } from '~coreModules/core/js/validation-utils';

import {
    AB_TEST_GROUP_CONTROL,
    AB_TEST_GROUP_VARIANT,
    AB_TEST_SPLIT_50_50,
    AB_TEST_SPLIT_20_80,
    AB_TEST_SPLIT_80_20,
    AB_TEST_REQUIRED_FIELDS,
    AB_TEST_GLOBAL_REQUIRED_FIELDS,
} from '~coreModules/ab-testing/js/ab-testing-constants';

import { SECONDS_IN_1_DAY } from '~coreModules/core/js/date-constants';

export function validateTestEndDate(title, testEndDate, logger) {
    const now = new Date().getTime();
    const testEnd = new Date(testEndDate).getTime();

    if (testEnd < now) {
        logger.error(`Test incorrectly configured: testEndDate is in the past for ${title}`);
        return false;
    }

    return true;
}

export function calculateTestEndDate(testEndDate) {
    const now = new Date().getTime() / 1000;
    const testEnd = new Date(testEndDate).getTime() / 1000;

    return (testEnd - now) + SECONDS_IN_1_DAY;
}

export function checkRequiredValues(obj, testType, logger = console) {
    const requiredFields = getMissingRequiredFields(obj);

    if (requiredFields?.length) {
        const msg = requiredFields.length > 1 ?
            `These fields are required to initialize an AB Test: ${requiredFields.join(', ')},
                for testType: ${testType}` :
            `${requiredFields[0]} is a required field to initialize an AB Test for testType: ${testType}`;

        logger.error(msg);
        throw new Error(msg);
    }
}

export function validateTestConfig(testConfig, logger) {
    const { testType, title, testEndDate } = testConfig;
    const testSchema = get(AB_TEST_REQUIRED_FIELDS, testType);

    if (!testSchema) {
        logger.error(`Test not configured for type: ${testType}`);
        return false;
    }

    const globalRequiredFields = AB_TEST_GLOBAL_REQUIRED_FIELDS;
    const requiredFields = AB_TEST_REQUIRED_FIELDS[testType];
    const allRequiredFields = [...globalRequiredFields, ...requiredFields];

    try {
        allRequiredFields.forEach((value) => {
            checkRequiredValues({ [value]: testConfig[value] }, testType, logger);
        });
    } catch (error) {
        logger.error(error);
        return false;
    }

    if (!validateTestEndDate(title, testEndDate, logger)) {
        return false;
    }

    return true;
}

export function generateTestGroup(split) {
    let testGroup = AB_TEST_GROUP_CONTROL;
    const randomNumber = Math.random();

    if (split === AB_TEST_SPLIT_50_50) {
        testGroup = `${Math.round(randomNumber)}`;
    } else if (split === AB_TEST_SPLIT_20_80) {
        testGroup = randomNumber > 0.2 ? AB_TEST_GROUP_VARIANT : AB_TEST_GROUP_CONTROL;
    } else if (split === AB_TEST_SPLIT_80_20) {
        testGroup = randomNumber > 0.8 ? AB_TEST_GROUP_VARIANT : AB_TEST_GROUP_CONTROL;
    }

    return testGroup;
}
