import { get, clone, isEmpty } from 'lodash-es';

import {
    NAV_TYPE_CODES,
    NAV_SLUG_MAPPING,
    NON_PAGE_NAV_TYPE_CODES,
    FOOTER_NAV_LEGAL_LINKS_SLUG,
    FOOTER_NAV_LEGAL_LINKS_SLUG_CA,
} from '~coreModules/nav/js/nav-constants';

/**
 * Returns a hierarchical navigation tree for a given slug
 * @param {Object} args                 - Object containing passed in args
 * @param {String} args.slug            - The slug to be used to build the nav tree
 * @param {Object} args.flatNavigation  - Flat Object with all navigation items
*  @param {Object} args.logger          - The logger instance
 * @param {Number} args.depth           - How many levels of children should we build
 * @param {String} args.appBaseUrl      - App path prefix used to build slug paths
 *
 * @returns {Array} Hierarchical navigation tree
 */
// TODO: TYP-14607 refactor prepending of /rent /legal
export function buildNavigationTreeForSlug({
    slug,
    flatNavigation,
    logger,
    depth = 1,
    appBaseUrl,
}) {
    let navigationTree = [];
    const rootNavItem = get(flatNavigation, slug, {});

    function shapeNavItem({ navigationTypeCode: typeCode, ...navItem }) {
        return { ...navItem, ...(!!typeCode && { typeCode }) };
    }

    function getNestedChildren(navSlugs, currentDepth) {
        const nestedChildren = [];

        navSlugs.forEach((navSlug) => {
            const navItem = clone(get(flatNavigation, navSlug, {}));
            const { childSlugs, parentSlug, typeCode } = shapeNavItem(navItem);

            if (childSlugs.length && currentDepth < depth) {
                navItem.children = getNestedChildren(childSlugs, currentDepth + 1);
            }

            const prefix = appBaseUrl === '/' ? '' : appBaseUrl;
            if (Object.keys(NAV_SLUG_MAPPING).includes(typeCode)) {
                navItem.slug = `${prefix}/${NAV_SLUG_MAPPING[typeCode]}/${navItem.slug}`;
            } else if (NAV_TYPE_CODES.DISCOVER === typeCode) {
                navItem.slug = `${prefix}/${navItem.slug}`;
            } else if (!NON_PAGE_NAV_TYPE_CODES.includes(typeCode)) {
                if (parentSlug === FOOTER_NAV_LEGAL_LINKS_SLUG || parentSlug === FOOTER_NAV_LEGAL_LINKS_SLUG_CA) {
                    navItem.slug = `/legal/${navItem.slug}`;
                }
            }

            nestedChildren.push(navItem);
        });

        return nestedChildren;
    }

    if (isEmpty(rootNavItem)) {
        logger.error('Error building navigation tree for slug: ', slug);
        return navigationTree;
    }

    const initialSlugs =
        shapeNavItem(rootNavItem).typeCode === NAV_TYPE_CODES.ROOT ? rootNavItem.childSlugs : [rootNavItem.slug];

    navigationTree = getNestedChildren(initialSlugs, 0);
    return navigationTree;
}

export default buildNavigationTreeForSlug;
