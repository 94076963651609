import { NUU_COOKIE_AB_TESTS_PREFIX } from '~coreModules/core/js/cookie-constants';
import { AB_TEST_GROUP_VARIANT } from '~coreModules/ab-testing/js/ab-testing-constants';
import {
    validateTestConfig,
    generateTestGroup,
    calculateTestEndDate,
} from '~coreModules/ab-testing/js/ab-testing-utils';

import { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';

export const AB_TESTING_MODULE_NAME = 'ab-testing';

export const INITIALIZE_TESTS = 'INITIALIZE_TESTS';
export const SET_TESTS = 'SET_TESTS';

export default {
    namespaced: true,

    state: () => ({
        tests: [],
    }),

    mutations: {
        [SET_TESTS](state, abTests) {
            /* eslint-disable-next-line no-param-reassign */
            state.tests = abTests;
        },
    },

    actions: {
        [INITIALIZE_TESTS]({ commit, rootGetters }) {
            const activeTests = rootGetters[`${SITE_SETTINGS_MODULE_NAME}/activeTests`];

            if (!activeTests.length) {
                this.$logger.debug('No active tests configured.');
                return;
            }

            const tests = [];

            activeTests.forEach((test) => {
                if (!validateTestConfig(test, this.$logger)) {
                    return;
                }

                const { testId, split, testEndDate } = test;

                const cookieName = `${NUU_COOKIE_AB_TESTS_PREFIX}${testId}`;
                let testGroup = this.$cookies.get(cookieName);
                if (!testGroup) {
                    testGroup = generateTestGroup(split);
                }

                this.$cookies.set(
                    cookieName,
                    testGroup,
                    { maxAge: calculateTestEndDate(testEndDate) },
                );

                tests.push({ testGroup, ...test });
            });

            commit(SET_TESTS, tests);
        },
    },

    getters: {
        getSlug(state) {
            return (slug, testType) => {
                let tests = state.tests.filter(test => test.controlSlug === slug);

                if (!tests?.length) {
                    return slug;
                }

                tests = tests.reduce((acc, test) => ({
                    ...acc,
                    [test.testType]: test.testGroup === AB_TEST_GROUP_VARIANT ? test.variantSlug :
                        test.controlSlug,
                }), {});

                return tests[testType] || slug;
            };
        },
    },
};
