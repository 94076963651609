<i18n>
[
    "header__signIn--label",
    "login__unauthSignIn--subtitle",
    "login_signIn--cta",
]
</i18n>

<template>
    <div class="c-header-unauth-menu u-text--center">
        <Illustration
            :illustrationSrc="IllustrationFlowers"
            illustrationSize="64"
            class="u-spacer--2"
        />
        <div class="u-spacer--1 o-text--heading-3 u-text--black">
            {{ $t('header__signIn--label') }}
        </div>
        <div class="u-spacer--3 u-text--black">
            {{ $t('login__unauthSignIn--subtitle') }}
        </div>
        <BaseButton
            variant="secondary"
            class="c-header-unauth-menu__button"
            @click="handleClick"
        >
            {{ $t('login_signIn--cta') }}
        </BaseButton>
    </div>
</template>

<script>
import { genericRouteNames } from '~coreModules/core/js/router-constants';

import Illustration from '~coreModules/core/components/ui/Illustration.vue';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

import IllustrationFlowers from '~coreImages/illustration__flowers.png';

export default {
    name: 'HeaderUnauthenticatedMenu',
    components: {
        Illustration,
        BaseButton,
    },
    data() {
        return {
            IllustrationFlowers,
        };
    },
    methods: {
        handleClick() {
            this.$router.push({ name: genericRouteNames.login.phone.base });
        },
    },
};
</script>

<style lang="scss">
.c-header-unauth-menu {
    padding: $nu-spacer-3;
    background-color: $nu-white;

    &__button {
        width: 100%;
    }
}
</style>
