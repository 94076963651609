<template>
    <div
        v-if="hasError || errorText || successText || supportingText"
        :class="{ 'o-text--error': hasError }"
        class="o-text--caption c-form__supporting-text"
    >
        <span v-if="hasSuccess" class="c-form__subtext-success-container">
            <BaseIcon
                icon="global--checkmark"
                size="12px"
                color="primary"
                class="c-form__subtext-icon"
            />
            {{ successText }}
        </span>
        <span v-else-if="hasError" class="c-form__subtext-error-container">
            <BaseIcon
                icon="global--alert"
                size="12px"
                color="primary"
                class="c-form__subtext-icon"
            />
            {{ errorText }}
        </span>
        <span v-else-if="hasSupportingText" class="u-text--gray">
            {{ supportingText }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'BaseSupportingTextError',
    props: {
        errorText: {
            type: String,
            default: '',
        },
        supportingText: {
            type: String,
            default: '',
        },
        successText: {
            type: String,
            default: '',
        },
    },
    computed: {
        hasError() {
            return this.errorText !== '';
        },
        hasSuccess() {
            return this.successText !== '';
        },
        hasSupportingText() {
            return this.supportingText !== '';
        },
    },
};
</script>

<style lang="scss">

</style>
