export const AVATAR_SIZE = {
    EXTRA_EXTRA_SMALL: 'extra-extra-small',
    EXTRA_SMALL: 'extra-small',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    EXTRA_LARGE: 'extra-large',
};

export const WAITLIST_LOCATIONS = {
    web: 'rent-web-waitlist',
    ios: 'rent-ios-waitlist',
};

export default { AVATAR_SIZE };
