import {
    get,
    isArray,
    isDate,
    isEmpty,
    isEqual,
    isObject,
} from 'lodash-es';
import queryString from 'query-string';

import { isNonEmptyString, isNonEmptyObject } from '~coreModules/core/js/utils';

import { APP_BASE_URLS_MAP } from '~coreModules/core/js/router-constants';
import { NUULY_BUSINESS_TYPES } from '~coreModules/core/js/constants';

/**
 * Add query params to current url
 * @param {Object} newParams - new query params
 * @param {String} currentUrl - current url
 * @param {String} currentHash - current hash
 * @returns {String} - url with query params and hash
 */
export function addQueryParamsToCurrentUrl(newParams, currentUrl, currentHash = '') {
    const parsedUrl = queryString.parseUrl(currentUrl);
    const mergedParams = { ...parsedUrl.query, ...newParams };
    const newQueryString = !isEmpty(mergedParams) ? `?${queryString.stringify(mergedParams)}` : '';

    return `${parsedUrl.url}${newQueryString}${currentHash}`;
}

/**
 * custom encoder to override Axios default encoder
 * doing this because of how it handles reserved characters
 * https://tools.ietf.org/html/rfc3986#section-2.2
 * https://github.com/axios/axios/blob/ae218d0131ecbd683310272c10b0273fd1d97de4/lib/helpers/buildURL.js#L5-L13
 * @param {String} val - value to be encoded
 * @returns {String} - serialized request params
 */
export function encode(val) {
    return encodeURIComponent(val)
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',');
    // not replacing %20 to + so that iOS can use our links
    // .replace(/%20/g, '+');
    /*
        not allowing [], as this needs to be encoded for brands like [Blank NYC]
        https://github.com/axios/axios/issues/2168
        .replace(/%5B/gi, '[')
        .replace(/%5D/gi, ']');
    */
}

/* eslint-disable no-param-reassign */

/**
 * custom serializer to override Axios default serializer
 * https://github.com/axios/axios/blob/ae218d0131ecbd683310272c10b0273fd1d97de4/lib/helpers/buildURL.js#L23
 * @param {Object} params - request params
 * @returns {String} - serialized request params
 */
export function paramsSerializer(params) {
    const parts = [];

    Object.keys(params).forEach((key) => {
        let val = params[key];

        if (val === null || typeof val === 'undefined') {
            return;
        }

        if (isArray(val)) {
            key += '[]';
        } else {
            val = [val];
        }

        val.forEach((v) => {
            if (isDate(v)) {
                v = v.toISOString();
            } else if (isObject(v)) {
                v = JSON.stringify(v);
            }
            parts.push(`${encode(key)}=${encode(v)}`);
        });
    });

    return parts.join('&');
}

/* eslint-enable no-param-reassign */

/**
 * will remove trailing slashes from a given url path
 * @param   {string} path  URL's path
 * @returns {string}       the url without a trailing slash when not '/'
 */
export function removePathsTrailingSlash(path) {
    let result = path || '';

    const trailingSlash = /\/$/;

    /* remove trailing slash if not requesting the homepage */
    if (trailingSlash.test(result) && result !== '/') {
        result = result.replace(trailingSlash, '');
    }

    return result;
}

/**
 * shaped urls which account for removing trailing slashes and any other modifications we need
 * @param   {string} path           URL's path
 * @param   {Object|string} params  URL's query params
 * @param   {string} hash           URL's hash (if any, without the #)
 * @returns {string}                a URL containing the supplied parts
 */
export function generateUrl(path, params, hash) {
    let result = path || '';

    /* remove trailing slash if not requesting the homepage */
    result = removePathsTrailingSlash(result);

    // Add query params
    if (isNonEmptyString(params)) {
        result += params.startsWith('?') ? params : `?${params}`;
    } else if (isNonEmptyObject(params)) {
        result += `?${paramsSerializer(params)}`;
    }

    // Add hash
    if (hash) {
        result += hash.startsWith('#') ? hash : `#${hash}`;
    }

    return result;
}

/**
 * shaped urls which account for removing trailing slashes and any other modifications we need
 * @param   {string} path   URL's path
 * @returns {Boolean}       whether or not path has trailing slash
 */
export function pathHasTrailingSlash(path) {
    const trailingSlash = /\/$/;

    return trailingSlash.test(path) && path !== '/';
}

/**
 * tells us if only the route hash has changed and nothing else
 * @param   {string} to    incoming route object
 * @param   {string} from  last route object
 * @returns {Boolean}      whether or not only the route hash has changed
 */
export function onlyHashHasChanged(to, from) {
    const toPath = get(to, 'path', '')?.split('?')[0];
    const toQuery = get(to, 'query');
    const toHash = get(to, 'hash');
    const fromPath = get(from, 'path', '')?.split('?')[0];
    const fromQuery = get(from, 'query');
    const fromHash = get(from, 'hash');

    return isEqual(toPath, fromPath) && isEqual(toQuery, fromQuery) && toHash !== fromHash;
}

/**
 * tells us if only the sku query parameter has changed and nothing else
 * @param   {string} to    incoming route object
 * @param   {string} from  last route object
 * @returns {Boolean}      whether or not only the sku query parameter has changed
 */
export function onlySkuHasChanged(to, from) {
    const routePath = get(to, 'path');
    const routeColor = get(to, 'query.color');
    const routeSku = get(to, 'query.sku');
    const fromPath = get(from, 'path');
    const fromColor = get(from, 'query.color');
    const fromSku = get(from, 'query.sku');

    return isEqual(routePath, fromPath) && isEqual(routeColor, fromColor) && !isEqual(routeSku, fromSku);
}

/**
 * tells us if the incoming route modalHashEvent cleared the existing query
 * meant to be used in router.js when a route change has been detected
 * @param   {Object} modalHashEvent   modal to be launched by the incoming route
 * @param   {string} to               incoming route object
 * @param   {string} to               last route object
 * @returns {Boolean}                 whether or not only the route hash has changed
 */
export function modalHashEventHasClearedQuery(modalHashEvent, to, from) {
    const toPath = get(to, 'path');
    const fromPath = get(from, 'path');
    const toQuery = get(to, 'query');
    const fromQuery = get(from, 'query');
    const pathIsUnchanged = isEqual(toPath, fromPath);
    const queryHasBeenRemoved = !!(isEmpty(toQuery) && !isEmpty(fromQuery));

    return modalHashEvent ? !!(pathIsUnchanged && queryHasBeenRemoved) : false;
}

/**
 * gets the hash from a vue Router String or Object link
 * @param   {String|Object} to        incoming route object
 * @param   {Boolean} includeHash     whether or not to return the # symbol
 * @returns {String}                  the hash with or without the # symbol
 */
export function getHashFromRouteLink(link, includeHash = true) {
    let hash = '';

    if (typeof link === 'string') {
        ({ 1: hash } = link.split('#'));
    } else if (typeof link === 'object') {
        if (link.hash) {
            if (link.hash.indexOf('#') === 0) {
                ({ 1: hash } = link.hash.split('#'));
            } else {
                ({ hash } = link);
            }
        } else if (link.path) {
            ({ 1: hash } = link.path.split('#'));
        }
    }

    return hash && includeHash ? `#${hash}` : (hash || '');
}

/**
 * @description
 * Returns internal url attributes based on if url is to the current app or other app
 *
 * @param {String} url - url to be evaluated
 * @param {Boolean} appBaseUrl - base url path for the current application
 * @returns {Object} - contains isExternal and to
 */
export function getInternalLinkAttributes(url, appBaseUrl = null, logger = console) {
    const appBaseUrls = Object.values(APP_BASE_URLS_MAP);
    let isExternal = false;

    if (!appBaseUrls.includes(appBaseUrl)) {
        logger?.error?.('must pass in a valid appBaseUrl from const APP_BASE_URLS_MAP');
    }

    // if user is on common app, check if it's a url to another nuuly app
    if (appBaseUrl === APP_BASE_URLS_MAP[NUULY_BUSINESS_TYPES.common]) {
        const urlMinusFirstChar = url.slice(1);
        const appBaseUrlsMinusCommon = appBaseUrls
            .filter(baseUrl => baseUrl !== APP_BASE_URLS_MAP[NUULY_BUSINESS_TYPES.common]);
        isExternal = appBaseUrlsMinusCommon.some(baseUrl => urlMinusFirstChar.startsWith(baseUrl.slice(1)));
    } else {
        isExternal = !url.startsWith(appBaseUrl);
    }

    return {
        isExternal,
        to: url,
    };
}

/**
 * @description
 * Determines if url is internal or external, and the relative or absolute url to be used with BaseLink
 *
 * @param {Object} obj - object of information to be used to return attributes
 * @param {String} obj.href - the url to be tested
 * @param {String} obj.hostname - hostname of the application
 * @param {String} obj.appBaseUrl - base path of the current application
 * @param {Boolean} modalHashEvents array of modals which can be triggered via a hash in the url
 * @returns {Object} - contains isExternal and to (relative or absolute url)
 */
export function getBaseLinkAttributes({
    href,
    hostname,
    appBaseUrl = null,
}, modalHashEvents) {
    const hash = getHashFromRouteLink(href);
    const modalHashEvent = modalHashEvents[hash];
    let linkTarget;
    let linkRel;

    // It's valid for typeof 'to' to be Object, as a RouterLink has prop 'to' that is String | Object
    if (typeof href === 'string') {
        // check if 'to' is a valid URL, and if so, whether or not it's external

        /* this is a hash only link, return the href  */
        if (href[0] === '#') {
            return {
                isExternal: false,
                linkTarget,
                linkRel,
                to: href,
                isHashOnlyLink: true,
                modalHashEvent,
            };
        }

        let isExternal = false;
        let to = href;

        try {
            const url = new URL(href);
            isExternal = !!(url.hostname && url.hostname !== hostname);
            to = isExternal ? href : `${url.pathname}${url.search}${url.hash}`;
        } catch (_) { /* 'to' is not a URL and thus will be treated as a relative path */ }

        linkTarget = isExternal ? '_blank' : '_self';
        linkRel = isExternal ? 'noopener noreferrer' : '';

        if (!isExternal) {
            ({ isExternal, to } = getInternalLinkAttributes(to, appBaseUrl));
        }

        return {
            isExternal,
            linkTarget,
            linkRel,
            to,
            modalHashEvent,
            isHashOnlyLink: false,
        };
    }

    let isHashOnlyLink = false;

    if (typeof href === 'object') {
        const { path } = href;
        const isHashOnlyPath = path ? path.indexOf('#') === 0 : false;
        const hashProperty = href.hash;
        isHashOnlyLink = !!(isHashOnlyPath || (!path && hashProperty && isEmpty(href.query)));
    }

    return {
        isExternal: false,
        linkTarget,
        linkRel,
        to: href,
        modalHashEvent,
        isHashOnlyLink,
    };
}

/**
 * pushes new route for pagination
 * @param {Number} nextPage - page to route to
 * @param {Object} route - route object to navigate to
 * @param {Object} route - vue router instance
 * @returns {undefined} No return value
 */
export function handlePaginationUrlUpdate(nextPage, route, router) {
    const newRoute = addQueryParamsToCurrentUrl({ pageNumber: nextPage }, route.fullPath, route.hash);
    router.push(newRoute);
}
