import { MAX_AGE_1_MONTH } from '~coreModules/core/js/cookie-constants';

import { NUU_QUERY_DISCOUNT_CODE } from '~modules/core/js/query-constants';

export const NUU_COOKIE_DISCOUNT_CODE = 'x-nuuly-discount-code';
export const NUU_COOKIE_PURCHASE_DISCOUNT_CODE = 'x-nuuly-purchase-discount-code';
export const NUU_COOKIE_PRODUCT_TOURS = 'x-nuuly-product-tours';
export const NUU_COOKIE_BONUS_ITEM_COUNTER = 'x_nuuly_bonus_item_counter';
export const NUU_COOKIE_USER_IS_WAITLISTED = 'x-nuuly-user-is-waitlisted';
export const NUU_COOKIE_GIFT_CARD_CODE = 'x-nuuly-gift-card-code';

export const RENTAL_QUERY_PARAM_TO_COOKIE_MAP = {
    [NUU_QUERY_DISCOUNT_CODE]: {
        queryKey: NUU_QUERY_DISCOUNT_CODE,
        cookieKey: NUU_COOKIE_DISCOUNT_CODE,
        maxAge: MAX_AGE_1_MONTH,
    },
};
