<template>
    <div
        v-tab-focus="handleCardClick"
        class="c-content-card"
        :style="cardStyles"
    >
        <div
            class="c-content-card__container"
            :class="{
                'c-content-card__container--no-border': !showContainerBorder,
                'u-text--color-primary': backgroundColor,
                'o-flex-center': showTooltip && !(showSubheading || subheading),
            }"
        >
            <span
                v-if="description"
                class="c-content-card__heading"
                :class="{ [headingClass]: !!headingClass }"
            >
                {{ description }}
            </span>
            <span
                v-if="showSubheading || showTooltip"
                :class="{ 'o-flex-center': !$mediaQueries.isLargish }"
            >
                <span
                    v-if="showSubheading && subheading"
                    class="c-content-card__subheading"
                    :class="subheadingClass || 'c-content-card__subheading--default'"
                >
                    {{ subheading }}
                </span>
                <span
                    v-if="showTooltip && ctaDestination"
                    v-tab-focus="handleTooltipClick"
                    class="
                        o-flex-center
                        c-content-card__tooltip
                        c-content-card__tooltip--mobile"
                >
                    <BaseIcon
                        icon="global--tooltip"
                        size="16px"
                        :color="backgroundColor ? 'primary' : 'white'"
                    />
                </span>
            </span>
        </div>
        <div
            v-if="showTooltip && ctaDestination"
            v-tab-focus="handleTooltipClick"
            class="
                o-flex-center
                c-content-card__tooltip
                c-content-card__tooltip--desktop
            "
            @mouseenter="showContainerBorder = false"
            @mouseleave="showContainerBorder = true"
        >
            <BaseIcon
                icon="global--tooltip"
                size="16px"
                :color="backgroundColor ? 'primary' : 'white'"
            />
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useActions } from '~coreModules/core/js/composables/vuexHelpers';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { CONTENT_CARD_CLICKED } from '~coreModules/core/js/global-event-constants';
import { CONTENT_CARD_TEMPLATES, CONTENT_CARD_COLORS } from '~coreModules/content-cards/js/content-cards-constants';

const props = defineProps({
    description: {
        type: String,
        default: null,
    },
    subheading: {
        type: String,
        default: null,
    },
    templateType: {
        type: String,
        default: null,
    },
    ctaDestination: {
        type: String,
        default: null,
    },
    bannerDestination: {
        type: String,
        default: null,
    },
    backgroundColor: {
        type: String,
        default: null,
    },
    analyticsData: {
        type: Object,
        default: null,
    },
});

const router = useRouter();

const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });

const showContainerBorder = ref(true);

const analyticsData = computed(() => props.analyticsData || {});
const cardStyles = computed(() => ({
    backgroundColor: props.backgroundColor || CONTENT_CARD_COLORS.CONTENT_CARD_COLOR_DEFAULT,
}));
const cardTemplate = computed(() => CONTENT_CARD_TEMPLATES[props.templateType] || {});
const showTooltip = computed(() => cardTemplate.value.showTooltip);
const showSubheading = computed(() => cardTemplate.value.showSubheading);
const headingClass = computed(() => cardTemplate.value.headingClass || '');
const subheadingClass = computed(() => cardTemplate.value.subheadingClass || '');

function trackContentCardClick(destination) {
    trackGlobalEvent({ type: CONTENT_CARD_CLICKED, data: { destination, ...analyticsData.value } });
}
function pushToRoute(to) {
    if (to) {
        router.push(to);
        trackContentCardClick(to);
    }
}
function handleCardClick() { pushToRoute(props.bannerDestination); }
function handleTooltipClick(e) {
    e.stopPropagation();
    pushToRoute(props.ctaDestination);
}
</script>

<style lang="scss">
.c-content-card {
    $this: &;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $nu-white;
    cursor: pointer;

    @include breakpoint(large) {
        flex-direction: row;
    }

    &__subheading {
        @include breakpoint(large) {
            margin-left: $nu-spacer-2;
        }

        &--default {
            @include caption;

            @include breakpoint(large) {
                @include body-copy;
            }
        }
    }

    &__container {
        border-bottom: 1px solid transparent;

        @include non-touch-device {
            &:not(&--no-border) {
                #{$this}:focus &,
                #{$this}:hover & {
                    border-color: currentColor;
                }
            }
        }
    }

    &__tooltip {
        width: 40px;
        height: 40px;
        flex-shrink: 0;

        &--mobile {
            height: 32px;

            @include breakpoint(large) {
                display: none;
            }
        }

        &--desktop {
            display: none;

            @include breakpoint(large) {
                display: flex;
            }
        }
    }
}
</style>
