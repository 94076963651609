export function getGoogleAutocompleteSuggestions(r15Svc, input, sessionToken) {
    const options = {
        params: {
            input,
            sessionToken,
        },
    };

    return r15Svc.get('/googlemaps/place/autocomplete/', options);
}

export function getGooglePlaceDetails(r15Svc, placeId, sessionToken) {
    const options = {
        params: {
            placeId,
            sessionToken,
        },
    };

    return r15Svc.get('/googlemaps/place/details/', options);
}
