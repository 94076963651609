<template>
    <div
        v-tab-focus="handleToastNavigation"
        class="c-toast-notification"
        role="alert"
    >
        <div
            :class="{
                'c-toast-notification__content-wrapper': true,
                'o-flex-vertical-center': true,
                'c-toast-notification__content-wrapper--product-image': showImages,
            }"
        >
            <div v-if="showImages" class="c-toast-notification__image-container">
                <div
                    v-for="(image, index) in imagesWithPlaceholders"
                    :key="`toast-image-container-${index}`"
                    class="c-toast-notification__image-container-inner"
                >
                    <ProductImage
                        v-if="image"
                        :src="image"
                        class="c-toast-notification__image"
                    />
                </div>
            </div>
            <component
                :is="iconComponent"
                v-if="icon"
                :icon="icon"
                :isCoreDir="isCoreDir"
                color="white"
                size="16px"
                class="c-toast-notification__icon"
            />
            <div
                class="c-toast-notification__static-content o-flex-horizontal-space-between o-text--caption"
            >
                <div class="c-toast-notification__description">
                    {{ description }}
                </div>
                <div
                    v-if="ctaText"
                    class="c-toast-notification__link-text o-flex-vertical-center o-text--link is-light"
                >
                    {{ ctaText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';

import { LAUNCH_MODAL, MODALS_MODULE_NAME } from '~coreModules/modals/js/modals-store';
import { NOTIFICATION_DISMISS_DELAY } from '~coreModules/core/js/constants';
import { MAX_TOAST_IMAGES } from '~coreModules/toasts/js/toasts-constants';

import ProductImage from '~coreModules/core/components/ui/ProductImage.vue';

export default {
    name: 'ToastNotification',
    components: { ProductImage },
    props: {
        id: {
            type: String,
            required: true,
        },
        iconComponent: {
            type: Object,
            required: true,
        },
        icon: {
            type: String,
            default: null,
        },
        images: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            required: true,
        },
        to: {
            type: Object,
            default: null,
        },
        ctaText: {
            type: String,
            default: null,
        },
        modalOptions: {
            type: Object,
            default: null,
        },
        delay: {
            type: Number,
            default: NOTIFICATION_DISMISS_DELAY.SHORT,
            validator: prop => Object.values(NOTIFICATION_DISMISS_DELAY).includes(prop),
        },
        isCoreDir: {
            type: Boolean,
            default: true,
        },
        showImagePlaceholders: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['expired'],
    computed: {
        shouldEmit() {
            return !!this.id;
        },
        showImages() {
            return !!this.images?.length;
        },
        imagesWithPlaceholders() {
            return [...this.images, ...Array.from(new Array(this.total > 0 ? this.total : 0), () => false)]
                .slice(0, MAX_TOAST_IMAGES);
        },
        total() {
            return this.showImagePlaceholders ? (MAX_TOAST_IMAGES - get(this.images, 'length', 0)) :
                0;
        },
    },
    mounted() {
        if (this.shouldEmit) {
            setTimeout(() => this.$emit('expired', this.id), this.delay);
        }
    },
    methods: {
        ...mapActions(MODALS_MODULE_NAME, {
            launchModal: LAUNCH_MODAL,
        }),
        handleToastNavigation() {
            if (!this.ctaText) return;

            if (get(this, 'modalOptions')) {
                this.launchModal(this.modalOptions);
            } else {
                this.$router.push(this.to);
            }
        },
    },
};
</script>

<style lang="scss">
.c-toast-notification {
    @include dropshadow;

    width: 100%;
    pointer-events: auto;
    min-height: $nu-spacer-6;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        .o-text--link {
            border-bottom-color: $nu-white;
        }
    }

    &__content-wrapper {
        width: 100%;
        color: $nu-white;
        border-radius: 4px;
        background-color: $nu-primary;
        padding: $nu-spacer-1pt5 $nu-spacer-2;

        &--product-image {
            padding-left: $nu-spacer-1pt5;
        }
    }

    &__static-content {
        width: 100%;
        min-width: 0;
    }

    &__image-container {
        display: flex;
        margin-right: $nu-spacer-2;
    }

    &__image-container-inner {
        width: $nu-spacer-4;
        background-color: $placeholder-background;
    }

    &__image {
        width: 100%;
        height: 100%;
    }

    &__icon {
        margin-right: $nu-spacer-2;
    }

    &__link-text {
        margin-left: $nu-spacer-2pt5;
        white-space: nowrap;
    }

    &__description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }
}
</style>
