<template>
    <div class="c-notification-manager">
        <AsyncComplianceStickyNotification v-if="isComplianceNotificationActive && allowNotifications" />
        <AnonymousNotificationManager
            v-if="!loggedIn"
            :allowNotifications="allowNotifications"
            :modalHashEvents="modalHashEvents"
        />
        <ToastManager :iconComponent="iconComponent" :shouldHideToasts="shouldHideToasts" />
        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';

import { BRAZE_MODULE_NAME } from '~coreModules/braze/js/braze-store';
import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';

import AnonymousNotificationManager from '~coreModules/notifications/components/AnonymousNotificationManager.vue';
import ToastManager from '~coreModules/toasts/components/ToastManager.vue';

export default {
    name: 'CoreNotificationManager',
    components: {
        AnonymousNotificationManager,
        AsyncComplianceStickyNotification: defineAsyncComponent(() => import(
            /* webpackChunkName: "complianceNotification" */
            '~coreModules/compliance/components/ComplianceStickyNotification.vue')),
        ToastManager,
    },
    props: {
        allowNotifications: {
            type: Boolean,
            default: false,
        },
        iconComponent: {
            type: Object,
            required: true,
        },
        modalHashEvents: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'loggedIn',
        ]),
        ...mapGetters(COMPLIANCE_MODULE_NAME, [
            'isComplianceNotificationActive',
        ]),
        hasActiveStickyNotifications() {
            return this.isComplianceNotificationActive ||
                !!this.$store.getters[`${BRAZE_MODULE_NAME}/activeBrazeStickyNotification`];
        },
        shouldHideToasts() {
            return !this.allowNotifications || this.hasActiveStickyNotifications;
        },
    },
};

</script>

<style lang="scss">

</style>
