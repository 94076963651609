export function generatePreferencesFromCookies(requestCookies, userPreferenceCookies) {
    const preferences = {};

    if (requestCookies) {
        const requestCookiesKeys = Object.keys(requestCookies);

        requestCookiesKeys.forEach((cookieKey) => {
            const cookieMatch = userPreferenceCookies[cookieKey];

            if (cookieMatch) {
                const requestCookieValue = requestCookies[cookieKey];

                if (cookieMatch.acceptedValues.indexOf(requestCookieValue) !== -1) {
                    let value = requestCookieValue;

                    if (requestCookieValue === 'false' || requestCookieValue === 'true') {
                        value = requestCookieValue === 'true';
                    }

                    preferences[cookieMatch.vuexKey] = value;
                }
            }
        });
    }

    return preferences;
}

export default generatePreferencesFromCookies;
