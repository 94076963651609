/* eslint-disable no-param-reassign */
export default {
    install: function install(app, logger) {
        app.config.errorHandler = (err, vm, info) => {
            logger.error('Uncaught Global Vue Error:\n', err, info, '\n');

            if (process.env.VUE_ENV === 'client') {
                window?.newrelic?.noticeError?.(err);
            }
        };
    },
};
