export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "global__close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])}
      }
    }
  })
}
