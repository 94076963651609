<template>
    <div class="c-form__field-container">
        <Field
            ref="field"
            v-model="inputValue"
            :name="name"
            :rules="rules"
            v-slot="{ field, errorMessage }"
        >
            <div
                ref="inputContainer"
                :class="{
                    'c-form__input-container--error': !!(errorText || errorMessage),
                    'c-form__input-container--dirty': field.value,
                    'c-form__input-container--loading': isLoading,
                }"
                class="c-form__input-container"
            >
                <BaseInputField
                    :id="id"
                    v-bind="field"
                    v-auto-focus="shouldAutoFocus"
                    :inputType="inputType"
                    :mask="mask"
                    :formTarget="formTarget"
                    :labelText="labelText"
                    :helperText="helperText"
                    :maxLength="maxLength"
                    :isDisabled="isDisabled || isLoading"
                    :autocomplete="autocomplete"
                    @input="emitInput"
                    @focus="$emit('focus')"
                    @blur="$emit('blur')"
                />
                <div class="c-form__label c-form__label--dynamic">
                    {{ labelText + (isRequired ? '*' : '') }}
                </div>
                <div
                    v-if="inlineButton"
                    class="c-form__inline-button c-form__inline-text"
                    @click="$emit('inline-button-click')"
                >
                    <div v-if="inlineButton.hasImage" class="c-form__inline-image">
                        <slot name="inline-button"></slot>
                    </div>

                    <div v-if="isLoading" class="c-form__inline-loader">
                        <LoadingDots :isLoading="isLoading" />
                    </div>
                    <template v-else>
                        {{ inlineButton.text }}
                    </template>
                </div>
            </div>
            <BaseSupportingTextError
                :supportingText="supportingText"
                :successText="successText"
                :errorText="errorText || errorMessage"
            />
        </Field>
    </div>
</template>

<script>
import BaseSupportingTextError from '~coreModules/core/components/ui/form-elements/BaseSupportingTextError.vue';
import formFieldProps from '~coreModules/core/components/mixins/formFieldProps';
import inputProps from '~coreModules/core/components/mixins/inputProps';
import BaseInputField from '~coreModules/core/components/ui/form-elements/text-inputs/BaseInputField.vue';
import LoadingDots from '~coreModules/core/components/ui/LoadingDots.vue';
import { Field } from 'vee-validate';

export default {
    name: 'BaseInput',
    components: {
        BaseSupportingTextError,
        BaseInputField,
        LoadingDots,
        Field,
    },
    mixins: [formFieldProps, inputProps],
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        errorText: {
            type: String,
            default: '',
        },
        rules: {
            type: Object,
            default: () => ({}),
        },
        inlineButton: {
            type: Object,
            default: null,
        },
        formTarget: {
            type: String,
            default: null,
        },
        formatValue: {
            type: Function,
            default: null,
        },
        inputType: {
            type: String,
            default: 'text',
        },
        successText: {
            type: String,
            default: '',
        },
        mask: {
            type: [Array, String, Object],
            default: '',
        },
        shouldAutoFocus: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'blur',
        'focus',
        'update:modelValue',
        'inline-button-click',
    ],
    data() {
        return {
            inputValue: this.modelValue,
        };
    },
    computed: {
        autocompleteValue() {
            return this.autocomplete === 'false' || this.autocomplete === false ||
                this.autocomplete === 'off' ? 'off' : '';
        },
        formattedval() {
            if (this.formatValue) {
                return this.formatValue(this.inputValue);
            }
            return this.modelValue;
        },
    },
    watch: {
        modelValue(newValue) {
            if (newValue !== this.inputValue) {
                this.inputValue = newValue;
            }
        },
    },
    methods: {
        emitInput() {
            if (this.formatValue) {
                return this.$emit('update:modelValue', this.formatValue(this.inputValue));
            }

            return this.$emit('update:modelValue', this.inputValue);
        },
        focus() {
            this.$refs.inputContainer.querySelector('input').focus();
        },
        validate() {
            return this.$refs.field.validate();
        },
    },
};
</script>

<style lang="scss">

</style>
