import { markRaw, defineAsyncComponent } from 'vue';

import { getOnboardingRoutes } from '~coreRoutes/join/js/join-utils';
import { RENTAL_ACCOUNT_CREATE_ATTRIBUTION } from '~coreRoutes/join/js/join-build-profile-constants';
import { NUULY_BUSINESS_TYPES } from '~coreModules/core/js/constants';
import { REVIEW_SCHEMES } from '~routes/review/js/review-constants';

import FlowersIllustration from '~coreImages/illustration__flowers.png';
import BoxUnlockedIllustration from '~images/illustration__box--unlocked.png';

import {
    genericRouteNames,
    catchAllRouteData,
    loginRouteData,
    helpRouteData,
    joinRouteData,
    errorRouteData,
    APP_BASE_URLS_MAP,
} from '~coreModules/core/js/router-constants';

const appPrefix = APP_BASE_URLS_MAP[NUULY_BUSINESS_TYPES.rental];

export const rentalRouteNames = {
    browse: 'rental.browse',
    browseSimilar: 'rental.browseSimilar',
    closet: 'rental.closet',
    closetList: 'rental.closet.list',
    product: 'rental.product',
    search: 'rental.search',
    account: {
        addPayment: 'rental.account.addPayment',
        billing: 'rental.account.billing',
        history: 'rental.account.history',
        overview: 'rental.account.overview',
        paymentRequired: 'rental.account.paymentRequired',
        profile: 'rental.account.profile',
        redeem: 'rental.account.redeem',
        subscription: 'rental.account.subscription',
        giftCards: {
            base: 'rental.account.giftCards.base',
            apply: 'rental.account.giftCards.apply',
            redeemed: 'rental.account.giftCards.redeemed',
        },
    },
    brands: {
        base: 'rental.brands',
        slug: 'rental.brands.slug',
    },
    employee: 'rental.employee',
    feedback: {
        addUsername: 'rental.feedback.addUsername',
        standard: 'rental.feedback.standard',
        product: 'rental.feedback.product',
    },
    join: {
        account: {
            intro: 'rental.join.account.intro',
            firstName: 'rental.join.account.firstName',
            email: 'rental.join.account.email',
            phone: 'rental.join.account.phone',
            sms: 'rental.join.account.sms',
            howDidYouHearAboutUs: 'rental.join.account.howDidYouHearAboutUs',
        },
        subscription: {
            base: 'rental.join.subscription',
            intro: 'rental.join.subscription.intro',
            plan: 'rental.join.subscription.plan',
            shipping: 'rental.join.subscription.shipping',
            billing: 'rental.join.subscription.billing',
            confirm: 'rental.join.subscription.confirm',
        },
        profile: {
            intro: 'rental.join.profile.intro',
            base: 'rental.join.profile',
            heightWeight: 'rental.join.heightWeight',
            basics: 'rental.join.profile.basics',
            birthday: 'rental.join.profile.birthday',
            fits: 'rental.join.profile.fits',
            sizes: 'rental.join.profile.sizes',
            bodyShape: 'rental.join.profile.bodyShape',
            outro: 'rental.join.profile.outro',
        },
    },
    referrals: {
        invite: 'rental.referrals.invite',
        checkInvite: 'rental.referrals.checkInvite',
        referAFriend: 'rental.referrals.referAFriend',
    },
    subscription: 'rental.subscription',
    giftCards: {
        base: 'rental.giftCards',
        checkout: {
            base: 'rental.giftCards.checkout',
            confirm: 'rental.giftCards.checkout.confirm',
        },
        account: {
            base: 'rental.giftCards.account',
            firstName: 'rental.giftCards.account.firstName',
            email: 'rental.giftCards.account.email',
            phone: 'rental.giftCards.account.phone',
            sms: 'rental.giftCards.account.sms',
        },
        redeem: 'rental.giftCards.redeem',
    },
    reportDamage: {
        base: 'rental.reportDamage',
        landing: 'rental.reportDamage.landing',
        item: 'rental.reportDamage.item',
        description: 'rental.reportDamage.description',
        details: 'rental.reportDamage.details',
        photoUpload: 'rental.reportDamage.photoUpload',
        submit: 'rental.reportDamage.submit',
        confirm: 'rental.reportDamage.confirm',
    },
};

export const rentalHomepageRouteData = {
    path: appPrefix,
    name: genericRouteNames.home,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "rentHomepage" */
        '~routes/homepage/components/RentalHomepageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'home-rent',
            pageType: 'home',
        },
    },
};

export const rentalBrowseRouteData = [{
    path: `${appPrefix}/browse/:slug`,
    name: rentalRouteNames.browse,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "browsePage" */
        '~routes/browse/components/BrowsePageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'browse',
            pageType: 'browse',
        },
        preventGlobalLoaderOnQueryChange: true,
    },
}, {
    path: `${appPrefix}/similar/:choiceId`,
    name: rentalRouteNames.browseSimilar,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "browseSimilarPage" */
        '~routes/browse-similar/components/BrowseSimilarPageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'similar',
            pageType: 'similar',
        },
        preventGlobalLoaderOnQueryChange: true,
    },
}];

export const rentalProductRouteData = {
    path: `${appPrefix}/products/:slug`,
    name: rentalRouteNames.product,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "productPage" */
        '~routes/product/components/ProductPageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'product',
            pageType: 'product',
        },
    },
};

export const rentalClosetRouteData = [
    {
        path: `${appPrefix}/closet`,
        name: rentalRouteNames.closet,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "closetPage" */
            '~routes/closet/components/ClosetPageContainer.vue'),
        meta: {
            analytics: {
                pageCategory: 'closet',
                pageType: 'closet',
            },
            requiresAuth: true,
            preventGlobalLoaderOnQueryChange: true,
        },
    },
    {
        path: `${appPrefix}/closet/lists/:listId`,
        name: rentalRouteNames.closetList,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "closetListPage" */
            '~routes/closet/components/ClosetListPageContainer.vue'),
        meta: {
            analytics: {
                pageCategory: 'closet',
                pageType: 'closet',
            },
            preventGlobalLoaderOnQueryChange: true,
        },
    },
];

export const rentalEmployeeRouteData = {
    path: `${appPrefix}/employee`,
    name: rentalRouteNames.employee,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "EmployeeDiscount" */
        '~routes/employee/components/EmployeeDiscount.vue'),
    meta: {
        ignoreBaseLayout: true,
        analytics: {
            pageCategory: 'employee',
            pageType: 'employee',
        },
    },
};

export const rentalFeedbackRouteData = [
    {
        path: `${appPrefix}/feedback/add-username`,
        name: rentalRouteNames.feedback.addUsername,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "AddUsername" */
            '~routes/review/components/AddUsernameSlide.vue'),
        meta: {
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'feedback',
                pageType: 'feedback',
            },
            requiresAuth: true,
        },
    },
    {
        path: `${appPrefix}/feedback/:scheme/:slug?`,
        name: rentalRouteNames.feedback.standard,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "review" */
            '~routes/review/components/ReviewSlide.vue'),
        meta: {
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'feedback',
                pageType: 'feedback',
            },
            requiresAuth: true,
        },
    },
    {
        path: `${appPrefix}/feedback/:scheme/:sku/:colorCode/:slug?`,
        name: rentalRouteNames.feedback.product,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "productReview" */
            '~routes/review/components/ReviewSlide.vue'),
        meta: {
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'feedback',
                pageType: 'feedback',
            },
            requiresAuth: true,
        },
    },
];

// NU_FEATURE: useV2Account
// https://urbnit.atlassian.net/browse/TYP-27731
// remove this (replaced by array below)
export const rentalAccountRouteData = [
    {
        path: `${appPrefix}/account`,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "AccountContainer" */
            '~routes/account/components/AccountContainer.vue'),
        meta: {
            displaySlug: 'account__overviewTab--title',
            analytics: {
                pageCategory: 'account',
                pageType: 'account',
            },
            requiresAuth: true,
            accountNavContainer: true,
        },
        children: [
            {
                path: '',
                name: rentalRouteNames.account.overview,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "AccountOverview" */
                    '~routes/account/components/AccountOverview.vue'),
                meta: {
                    displaySlug: 'account__overviewTab--title',
                    analytics: {
                        pageCategory: 'account',
                    },
                },
            },
            {
                path: 'rental-history',
                name: rentalRouteNames.account.history,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "RentalHistory" */
                    '~routes/account/components/rental-history/RentalHistory.vue'),
                meta: {
                    displaySlug: 'account__rentalHistoryTab--title',
                    analytics: {
                        pageCategory: 'account-rental-history',
                    },
                    hiddenForNonSubscriber: true,
                    requiresBoxHistory: true,
                },
            }, {
                path: 'subscription',
                name: rentalRouteNames.account.subscription,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "SubscriptionManagement" */
                    '~routes/account/components/subscription/SubscriptionManagement.vue'),
                meta: {
                    displaySlug: 'account__subscriptionTab--title',
                    analytics: {
                        pageCategory: 'account-subscription',
                    },
                },
            }, {
                path: 'billing-receipts',
                name: rentalRouteNames.account.billing,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "BillingAndReceipts" */
                    '~routes/account/components/billing/BillingAndReceipts.vue'),
                meta: {
                    displaySlug: 'account__billingReceiptsTab--title',
                    analytics: {
                        pageCategory: 'account-billing-receipts',
                    },
                    hiddenForNonSubscriber: true,
                },
            }, {
                path: 'my-profile',
                name: rentalRouteNames.account.profile,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "MyProfile" */
                    '~routes/account/components/profile/MyProfile.vue'),
                meta: {
                    displaySlug: 'account__profileTab--title',
                    analytics: {
                        pageCategory: 'account-profile',
                    },
                },
            }, {
                path: 'redeem',
                name: rentalRouteNames.account.redeem,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "Redeem" */
                    '~routes/account/components/redeem/Redeem.vue'),
                meta: {
                    displaySlug: 'account__redeemTab--title',
                    analytics: {
                        pageCategory: 'account-redeem',
                    },
                    hiddenForNonSubscriber: true,
                },
            },
            {
                path: 'gift-cards',
                name: rentalRouteNames.account.giftCards.base,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "GiftCards" */
                    '~routes/account/components/gift-cards/GiftCards.vue'),
                meta: {
                    displaySlug: 'account__menuItem--giftCards',
                    analytics: {
                        pageCategory: 'account-gift-cards',
                    },
                },
            },
            // All other child routes that dont match, redirect to the account overview page
            // This must come last in the list of account child routes
            {
                path: ':slug(.*)',
                redirect: { name: rentalRouteNames.account.overview },
            },
        ],
    }, {
        path: `${appPrefix}/account/payment-required`,
        name: rentalRouteNames.account.paymentRequired,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "PaymentRequired" */
            '~routes/account/components/overdue/OverdueContainer.vue'),
        meta: {
            displayName: 'Payment Required',
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'account-payment-required',
                pageType: 'account',
            },
        },
    },
    {
        path: `${appPrefix}/account/add-payment`,
        name: rentalRouteNames.account.addPayment,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "addPaymentContainer" */
            '~routes/account/components/AddPaymentContainer.vue'),
        meta: {
            displayName: 'Payment Required',
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'account-add-payment',
                pageType: 'account',
            },
        },
    },
];

// NU_FEATURE: useV2Account
// https://urbnit.atlassian.net/browse/TYP-27731
// rename this to rentalAccountRouteData
export const rentalAccountRouteDataV2 = [
    {
        path: `${appPrefix}/account`,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "AccountContainerV2" */
            '~routes/account/components/AccountContainerV2.vue'),
        meta: {
            displaySlug: 'account__menuItem--overview',
            analytics: {
                pageCategory: 'account',
                pageType: 'account',
            },
            requiresAuth: true,
            accountNavContainer: true,
            preventGlobalLoaderOnChildRouteChange: true,
        },
        children: [
            {
                path: '',
                name: rentalRouteNames.account.overview,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "AccountOverviewV2" */
                    '~routes/account/components/AccountOverviewV2.vue'),
                meta: {
                    displaySlug: 'account__menuItem--overview',
                    analytics: {
                        pageCategory: 'account',
                    },
                },
            },
            {
                path: 'rental-history',
                name: rentalRouteNames.account.history,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "RentalHistoryV2" */
                    '~routes/account/components/rental-history/RentalHistoryV2.vue'),
                meta: {
                    displaySlug: 'account__menuItem--rentalHistory',
                    analytics: {
                        pageCategory: 'account-rental-history',
                    },
                    hiddenForNonSubscriber: true,
                    requiresBoxHistory: true,
                },
            }, {
                path: 'subscription',
                name: rentalRouteNames.account.subscription,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "SubscriptionManagementV2" */
                    '~routes/account/components/subscription/SubscriptionManagementV2.vue'),
                meta: {
                    displaySlug: 'account__menuItem--subscription',
                    analytics: {
                        pageCategory: 'account-subscription',
                    },
                },
            }, {
                path: 'billing-receipts',
                name: rentalRouteNames.account.billing,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "BillingAndReceiptsV2" */
                    '~routes/account/components/billing/BillingAndReceiptsV2.vue'),
                meta: {
                    displaySlug: 'account__menuItem--billing',
                    analytics: {
                        pageCategory: 'account-billing-receipts',
                    },
                    hiddenForNonSubscriber: true,
                },
            }, {
                path: 'my-profile',
                name: rentalRouteNames.account.profile,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "MyProfileV2" */
                    '~routes/account/components/profile/MyProfileV2.vue'),
                meta: {
                    displaySlug: 'account__menuItem--profile',
                    analytics: {
                        pageCategory: 'account-profile',
                    },
                },
            }, {
                path: 'redeem',
                name: rentalRouteNames.account.redeem,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "Redeem_V2" */
                    '~routes/account/components/redeem/Redeem_V2.vue'),
                meta: {
                    displaySlug: 'account__menuItem--rewards',
                    analytics: {
                        pageCategory: 'account-redeem',
                    },
                    hiddenForNonSubscriber: true,
                },
            },
            {
                path: 'gift-cards',
                name: rentalRouteNames.account.giftCards.base,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "GiftCards" */
                    '~routes/account/components/gift-cards/GiftCards.vue'),
                meta: {
                    displaySlug: 'account__menuItem--giftCards',
                    analytics: {
                        pageCategory: 'account-gift-cards',
                    },
                },
            },
            // All other child routes that dont match, redirect to the account overview page
            // This must come last in the list of account child routes
            {
                path: ':slug(.*)',
                redirect: { name: rentalRouteNames.account.overview },
            },
        ],
    }, {
        path: `${appPrefix}/account/payment-required`,
        name: rentalRouteNames.account.paymentRequired,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "PaymentRequired" */
            '~routes/account/components/overdue/OverdueContainer.vue'),
        meta: {
            displayName: 'Payment Required',
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'account-payment-required',
                pageType: 'account',
            },
        },
    },
    {
        path: `${appPrefix}/account/add-payment`,
        name: rentalRouteNames.account.addPayment,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "addPaymentContainer" */
            '~routes/account/components/AddPaymentContainer.vue'),
        meta: {
            displayName: 'Payment Required',
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'account-add-payment',
                pageType: 'account',
            },
        },
    },
    {
        path: `${appPrefix}/account/gift-cards/apply`,
        name: rentalRouteNames.account.giftCards.apply,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "GiftCardApplySlide" */
            '~routes/account/components/gift-cards/GiftCardApplySlide.vue'),
        meta: {
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'account-gift-cards',
                pageType: 'account',
            },
            requiresAuth: true,
        },

    },
    {
        path: `${appPrefix}/account/gift-cards/redeemed`,
        name: rentalRouteNames.account.giftCards.redeemed,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "GiftCardRedeemedSlide" */
            '~routes/account/components/gift-cards/GiftCardRedeemedSlide.vue'),
        meta: {
            ignoreBaseLayout: true,
            analytics: {
                pageCategory: 'account-gift-cards',
                pageType: 'account',
            },
            requiresAuth: true,
        },
    },
];

export const rentalReferralsRouteData = {
    path: `${appPrefix}/referrals`,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "RouterViewContainer" */
        '~coreModules/core/components/RouterViewContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'referrals',
            pageType: 'referrals',
        },
    },
    children: [
        {
            path: 'invite',
            name: rentalRouteNames.referrals.invite,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "ReferralInvite" */
                '~routes/referrals/components/ReferralInvite.vue'),
        }, {
            path: 'check-invite',
            name: rentalRouteNames.referrals.checkInvite,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "CheckReferralInvite" */
                '~routes/referrals/components/CheckReferralInvite.vue'),
        }, {
            path: 'refer-a-friend',
            name: rentalRouteNames.referrals.referAFriend,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "ReferAFriend" */
                '~routes/referrals/components/ReferAFriend.vue'),
        },
    ],
};

const rentalSearchRouteData = {
    path: `${appPrefix}/search`,
    name: rentalRouteNames.search,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "searchPage" */
        '~routes/search/components/SearchPageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'search',
            pageType: 'search',
        },
        preventGlobalLoaderOnQueryChange: true,
    },
};

export const rentalBrandsRouteData = [{
    path: `${appPrefix}/brands`,
    name: rentalRouteNames.brands.base,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "AllBrands" */
        '~routes/brands/components/AllBrands.vue'),
    meta: {
        analytics: {
            pageCategory: 'brands-all',
            pageType: 'brands-all',
        },
    },
}, {
    path: `${appPrefix}/brands/:slug`,
    name: rentalRouteNames.brands.slug,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "Brand" */
        '~routes/brands/components/Brand.vue'),
    meta: {
        analytics: {
            pageCategory: 'brand',
            pageType: 'brand',
        },
        preventGlobalLoaderOnQueryChange: true,
    },
}];

export const rentalJoinRouteData = {
    ...joinRouteData,
    path: `${appPrefix}${joinRouteData.path}`,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "joinContainer" */
        '~routes/join/components/JoinContainer.vue'),
    children: [
        {
            path: 'account',
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "routerViewContainer" */
                '~coreModules/core/components/RouterViewContainer.vue'),
            meta: {
                analytics: {
                    pageCategory: 'join-account',
                },
            },
            children: [
                {
                    path: '',
                    name: rentalRouteNames.join.account.intro,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinIntroSlide" */
                        '~coreRoutes/join/components/create-account/JoinIntroSlide.vue'),
                    meta: {
                        hideBackButton: true,
                        ignoreBaseLayout: true,
                        illustration: FlowersIllustration,
                    },
                },
                {
                    path: 'name',
                    name: rentalRouteNames.join.account.firstName,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinFirstNameSlide" */
                        '~coreRoutes/join/components/create-account/JoinFirstNameSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'email',
                    name: rentalRouteNames.join.account.email,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinEmail" */
                        '~routes/join/components/create-account/JoinEmailSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'phone',
                    name: rentalRouteNames.join.account.phone,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinPhoneSlide" */
                        '~coreRoutes/join/components/create-account/JoinPhoneSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'sms-token',
                    name: rentalRouteNames.join.account.sms,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinSmsToken" */
                        '~routes/join/components/create-account/JoinSmsTokenSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'how-did-you-hear-about-us',
                    name: rentalRouteNames.join.account.howDidYouHearAboutUs,
                    props: {
                        signUpLocationAttribution: RENTAL_ACCOUNT_CREATE_ATTRIBUTION,
                    },
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinHowDidYouHearAboutUsSlide" */
                        '~routes/join/components/create-account/JoinHowDidYouHearAboutUsSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: ':slug(.*)',
                    redirect: { name: rentalRouteNames.join.account.intro },
                },
            ],
        },
        {
            path: 'subscription',
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "RouterViewContainer" */
                '~coreModules/core/components/RouterViewContainer.vue'),
            meta: {
                analytics: {
                    pageCategory: 'join-subscription',
                },
            },
            children: [
                {
                    path: '',
                    name: rentalRouteNames.join.subscription.intro,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinIntroSlide" */
                        '~routes/join/components/subscribe/JoinSubscriptionIntro.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'plan',
                    name: rentalRouteNames.join.subscription.plan,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinPlan" */
                        '~routes/join/components/subscribe/JoinPlanSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'shipping-address',
                    name: rentalRouteNames.join.subscription.shipping,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinShippingAddress" */
                        '~routes/join/components/subscribe/JoinShippingAddressSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'billing',
                    name: rentalRouteNames.join.subscription.billing,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinBilling" */
                        '~routes/join/components/subscribe/JoinBillingSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'confirm-subscription',
                    name: rentalRouteNames.join.subscription.confirm,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinConfirm" */
                        '~routes/join/components/subscribe/JoinConfirmSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
            ],
        },
        {
            path: 'profile',
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "routerViewContainer" */
                '~coreModules/core/components/RouterViewContainer.vue'),
            meta: {
                analytics: {
                    pageCategory: 'join-profile',
                },
            },
            children: [
                {
                    path: '',
                    name: rentalRouteNames.join.profile.intro,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinProfileIntroSlide" */
                        '~routes/join/components/build-profile/JoinProfileIntroSlide.vue'),
                    meta: {
                        hideBackButton: true,
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'size-preferences',
                    name: rentalRouteNames.join.profile.sizes,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinSizePrefsSlide" */
                        '~routes/join/components/build-profile/JoinSizePrefsSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'birthday',
                    name: rentalRouteNames.join.profile.birthday,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinBirthdaySlide" */
                        '~routes/join/components/build-profile/JoinBirthdaySlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'reviews-height-etc',
                    name: rentalRouteNames.join.profile.heightWeight,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinHeightWeightSlide" */
                        '~routes/join/components/build-profile/JoinHeightWeightSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'reviews-body-shape',
                    name: rentalRouteNames.join.profile.bodyShape,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinBodyShape" */
                        '~routes/join/components/build-profile/JoinBodyShapeSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'outro',
                    name: rentalRouteNames.join.profile.outro,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinOutroSlide" */
                        '~routes/join/components/build-profile/JoinOutroSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                        illustration: BoxUnlockedIllustration,
                    },
                },
            ],
        },
    ],
};

const rentalHelpRouteData = {
    ...helpRouteData,
    path: `${appPrefix}${helpRouteData.path}`,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "HelpPage" */
        '~routes/help/components/HelpPage.vue'),
};

const rentalErrorRouteData = {
    ...errorRouteData,
    path: `${appPrefix}${errorRouteData.path}`,
    props: {
        contentfulContentBlock: markRaw(
            defineAsyncComponent(
                () => import(
                    /* webpackChunkName: "contentful-content-block" */
                    '~modules/contentful/components/ContentfulContentBlock.vue'),
            ),
        ),
    },
};

export const rentalGiftCardsRouteData = [{
    path: `${appPrefix}/gift-cards`,
    name: rentalRouteNames.giftCards.base,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "giftCardsPageContainer" */
        '~routes/gift-cards/components/GiftCardsPageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'gift-cards',
            pageType: 'gift-cards',
        },
    },
}, {
    path: `${appPrefix}/gift-cards/my/checkout`,
    name: rentalRouteNames.giftCards.checkout.base,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "checkoutPageContainer" */
        '~routes/gift-cards/components/CheckoutPageContainer.vue'),
    meta: {
        ignoreBaseLayout: true,
        requiresAuth: true,
        analytics: {
            pageCategory: 'gift-cards',
            pageType: 'gift-cards',
        },
    },
}, {
    path: `${appPrefix}/gift-cards/my/confirmation`,
    name: rentalRouteNames.giftCards.checkout.confirm,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "checkoutConfirmationPageContainer" */
        '~routes/gift-cards/components/CheckoutConfirmationPageContainer.vue'),
    meta: {
        ignoreBaseLayout: true,
        requiresAuth: true,
        analytics: {
            pageCategory: 'gift-cards',
            pageType: 'gift-cards',
        },
    },
}, {
    path: `${appPrefix}/gift-cards/redeem/:giftCardId`,
    name: rentalRouteNames.giftCards.redeem,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "giftCardsRedeemPageContainer" */
        '~routes/gift-cards/components/GiftCardsRedeemPageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'gift-cards',
            pageType: 'gift-cards',
        },
    },
}];

export const rentalGiftCardsJoinRouteData = {
    path: `${appPrefix}/gift-cards/account`,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "giftCardsJoinContainer" */
        '~routes/gift-cards/components/GiftCardsJoinContainer.vue'),
    meta: {
        ignoreBaseLayout: true,
        analytics: {
            pageCategory: 'gift-cards',
            pageType: 'gift-cards',
        },
        sectionSlideCounts: {},
    },
    children: [
        {
            path: '',
            name: rentalRouteNames.giftCards.account.base,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "routerViewContainer" */
                '~coreModules/core/components/RouterViewContainer.vue'),
            meta: {
                analytics: {
                    pageCategory: 'gift-cards',
                },
            },
            children: [
                {
                    path: 'name',
                    name: rentalRouteNames.giftCards.account.firstName,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "GiftCardsJoinFirstNameSlide" */
                        '~routes/gift-cards/components/GiftCardsJoinFirstNameSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'email',
                    name: rentalRouteNames.giftCards.account.email,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "giftCardsJoinEmailSlide" */
                        '~routes/gift-cards/components/GiftCardsJoinEmailSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'phone',
                    name: rentalRouteNames.giftCards.account.phone,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "joinPhoneSlide" */
                        '~coreRoutes/join/components/create-account/JoinPhoneSlide.vue'),
                    props: {
                        labelComponent: null,
                    },
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: 'sms-token',
                    name: rentalRouteNames.giftCards.account.sms,
                    component: /* istanbul ignore next */ () => import(
                        /* webpackChunkName: "giftCardsJoinSmsTokenSlide" */
                        '~routes/gift-cards/components/GiftCardsJoinSmsTokenSlide.vue'),
                    meta: {
                        ignoreBaseLayout: true,
                    },
                },
                {
                    path: ':slug(.*)',
                    redirect: { name: rentalRouteNames.giftCards.account.firstName },
                },
            ],
        },
    ],
};

export const rentalReportDamageRouteData = [
    {
        path: `${appPrefix}/issue-report`,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "reportDamageContainer" */
            '~routes/report-damage/components/ReportDamageContainer.vue'),
        meta: {
            requiresAuth: true,
            analytics: {
                pageCategory: 'issue-report',
                pageType: 'issue-report',
            },
        },
        children: [
            {
                path: '',
                name: rentalRouteNames.reportDamage.base,
                redirect: { name: rentalRouteNames.reportDamage.item },
            },
            {
                path: 'landing',
                name: rentalRouteNames.reportDamage.landing,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "reportDamageLandingSlide" */
                    '~routes/report-damage/components/ReportDamageLandingSlide.vue'),
                meta: {
                    hideBackButton: true,
                    ignoreBaseLayout: true,
                },
            }, {
                path: `${appPrefix}/issue-report/select-item`,
                name: rentalRouteNames.reportDamage.item,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "reportDamageItemSlide" */
                    '~routes/report-damage/components/ReportDamageItemSlide.vue'),
                meta: {
                    ignoreBaseLayout: true,
                },
            }, {
                path: `${appPrefix}/issue-report/description`,
                name: rentalRouteNames.reportDamage.description,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "reportDamageDescriptionSlide" */
                    '~routes/report-damage/components/ReportDamageDescriptionSlide.vue'),
                meta: {
                    ignoreBaseLayout: true,
                },
            }, {
                path: `${appPrefix}/issue-report/details`,
                name: rentalRouteNames.reportDamage.details,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "reportDamageLocationSlide" */
                    '~routes/report-damage/components/ReportDamageLocationSlide.vue'),
                meta: {
                    ignoreBaseLayout: true,
                },
            }, {
                path: `${appPrefix}/issue-report/photo-upload`,
                name: rentalRouteNames.reportDamage.photoUpload,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "reportDamageImageSlide" */
                    '~routes/report-damage/components/ReportDamageImageSlide.vue'),
                meta: {
                    ignoreBaseLayout: true,

                },
            }, {
                path: `${appPrefix}/issue-report/submit`,
                name: rentalRouteNames.reportDamage.submit,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "reportDamageSummarySlide" */
                    '~routes/report-damage/components/ReportDamageSummarySlide.vue'),
                meta: {
                    ignoreBaseLayout: true,
                },
            }, {
                path: `${appPrefix}/issue-report/confirmation`,
                name: rentalRouteNames.reportDamage.confirm,
                component: /* istanbul ignore next */ () => import(
                    /* webpackChunkName: "reportDamageConfirmationSlide" */
                    '~routes/report-damage/components/ReportDamageConfirmationSlide.vue'),
                meta: {
                    hideBackButton: true,
                    ignoreBaseLayout: true,
                },
            },
        ],
    },
];

export const reviewRouteObjects = {
    cancel: {
        name: rentalRouteNames.feedback.standard,
        params: {
            scheme: REVIEW_SCHEMES.CANCEL,
        },
    },
    extendPause: {
        name: rentalRouteNames.feedback.standard,
        params: {
            scheme: REVIEW_SCHEMES.EXTEND_PAUSE,
        },
    },
    pause: {
        name: rentalRouteNames.feedback.standard,
        params: {
            scheme: REVIEW_SCHEMES.PAUSE,
        },
    },
    resumeFromPause: {
        name: rentalRouteNames.feedback.standard,
        params: {
            scheme: REVIEW_SCHEMES.RESUME_FROM_PAUSE,
        },
    },
};

export const catalogPageNames = {
    brand: rentalRouteNames.brands.slug,
    browse: rentalRouteNames.browse,
    browseSimilar: rentalRouteNames.browseSimilar,
    closet: rentalRouteNames.closet,
    closetList: rentalRouteNames.closetList,
    search: rentalRouteNames.search,
};

export const getRentalRouteData = (runtimeConfig) => {
    const routeData = [
        // NU_FEATURE: useV2Account
        // https://urbnit.atlassian.net/browse/TYP-27731
        // uncomment this and remove the check below this line
        // ...rentalAccountRouteData,
        ...(runtimeConfig.features.useV2Account ? rentalAccountRouteDataV2 : rentalAccountRouteData),
        rentalHomepageRouteData,
        ...rentalFeedbackRouteData,
        ...rentalBrandsRouteData,
        ...rentalBrowseRouteData,
        ...rentalClosetRouteData,
        rentalEmployeeRouteData,
        getOnboardingRoutes(rentalJoinRouteData),
        // NU_FEATURE: GIFT_CARDS
        // https://urbnit.atlassian.net/browse/TYP-27405
        // ...rentalGiftCardsRouteData,
        // getOnboardingRoutes(rentalGiftCardsJoinRouteData),
        ...(runtimeConfig.features.giftCards ? [
            ...rentalGiftCardsRouteData,
            getOnboardingRoutes(rentalGiftCardsJoinRouteData),
        ] : []),
        rentalProductRouteData,
        rentalReferralsRouteData,
        // NU_FEATURE: REPORT_DAMAGE
        // https://urbnit.atlassian.net/browse/TYP-30347
        // ...rentalReportDamageRouteData,
        ...(runtimeConfig.features.reportDamage ? rentalReportDamageRouteData : []),
        rentalSearchRouteData,
        rentalHelpRouteData,
        rentalErrorRouteData,
        {
            ...loginRouteData,
            path: `${appPrefix}${loginRouteData.path}`,
        },
        // IMPORTANT - The object below must be last in the list of routes passed to vue-router!
        {
            ...catchAllRouteData,
            path: `${appPrefix}${catchAllRouteData.path}`,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "DiscoverPage" */
                '~modules/discover/components/DiscoverPageContainer.vue'),
        },
        // Unused, but required by Vue router
        { path: '/', redirect: appPrefix },
    ];

    return routeData;
};
