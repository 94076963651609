import { get, isEmpty } from 'lodash-es';

import { MAX_AGE_2_MONTHS } from '~coreModules/core/js/cookie-constants';
import { NUU_COOKIE_PRODUCT_TOURS } from '~modules/core/js/cookie-constants';

/*
The x-nuuly-product-tours cookie's value is an array of objects associating a profile ID with a list of tours that user
is eligible to view. This is necessary to ensure different users on the same device have their product tours
tracked separately.
The value is serialized for consumption by the cookie library, which then URI encodes it to ensure no invalid/control
characters are set in the cookie, so the value of x-nuuly-product-tours is ultimately equivalent to:
    encodeURIComponent(JSON.stringify(
        [{
            profileId: 'some-profile-id',
            eligibleTours: ['tour-name-1', 'tour-name-2']
        }]
    ))
 */

// Serialization is required for the tour data object to be set properly using tiny-cookie
const parse = str => JSON.parse(str);
const serialize = data => JSON.stringify(data);

/**
 * @description
 * Removes a profile from the x-urbn-product-tours cookie which has completed all of their product tours
 * If this was the only profile stored in the cookie, the cookie is removed altogether
 *
 * @param   {String}   profileId        - the profile which has completed all product tours
 * @param   {Object}   cookieInterface - a cookie interface which has 'get', 'set', 'remove' methods (e.g. tiny-cookie)
 * @returns {Boolean}  cookieWasDeleted - returns true if the removed profile was the only one in the cookie and the
 *                                        cookie was subsequently deleted
 */
export function removeProfileFromToursCookie(profileId, cookieInterface) {
    let cookie = cookieInterface.get(NUU_COOKIE_PRODUCT_TOURS);

    if (!cookie) {
        return false;
    }

    cookie = parse(cookie);

    const allUserTours = cookie || [];
    const isSingleUserWithSingleTour = allUserTours.length === 1 && allUserTours[0].profileId === profileId;

    if (isSingleUserWithSingleTour) {
        cookieInterface.remove(NUU_COOKIE_PRODUCT_TOURS);
        return true;
    }

    const filteredUserTours = allUserTours.filter(tourEntry => tourEntry.profileId !== profileId);

    cookieInterface.set(
        NUU_COOKIE_PRODUCT_TOURS,
        serialize(filteredUserTours),
        { maxAge: MAX_AGE_2_MONTHS },
    );

    return false;
}

/**
 * @description
 * Returns an array of tour keys that the given user is eligible to view
 *
 * @param   {String}    profileId       - the profile ID of the user to retrieve tours for
 * @param   {Object}    cookieInterface - a cookie interface which has a 'get' method (e.g. Vue.cookie)
 * @returns {String[]}  a list of tours the given user is eligible to view
 */
export function getEligibleToursForProfile(profileId, cookieInterface) {
    let cookie = cookieInterface.get(NUU_COOKIE_PRODUCT_TOURS);

    if (cookie) {
        cookie = parse(cookie);
    }

    const allUserTours = cookie || [];
    const tourObject = allUserTours.find(tourData => tourData.profileId === profileId);

    return get(tourObject, 'eligibleTours', []);
}

/**
 * @description
 * Sets the list of tours a user is eligible for. This will overwrite the existing value for the supplied user.
 *
 * @param {String}   profileId       - the profile ID of the user whose tour data should be set
 * @param {Object}   cookieInterface - a cookie interface which has a 'get' method (e.g. Vue.cookie)
 * @param {String[]} eligibleTours   - list of tours which the user is eligible for. Passing in an empty array indicates
*                                      that the user is not eligible for any more tours
 */
export function setEligibleToursForProfile(profileId, cookieInterface, eligibleTours) {
    if (!profileId) {
        return;
    }

    let cookie = cookieInterface.get(NUU_COOKIE_PRODUCT_TOURS);

    if (cookie) {
        cookie = parse(cookie);
    }

    const allUserTours = cookie || [];
    const userTourData = allUserTours.find(userData => userData.profileId === profileId);

    if (!userTourData && isEmpty(eligibleTours)) {
        // can't remove data for a profile that already has no data; noop
        return;
    }

    if (userTourData && isEmpty(eligibleTours)) {
        // the new value is an empty array, thus, the user is not eligible to view any more tours
        removeProfileFromToursCookie(profileId, cookieInterface);

        return;
    }

    if (userTourData) {
        userTourData.eligibleTours = eligibleTours;
    } else {
        allUserTours.push({ profileId, eligibleTours });
    }

    cookieInterface.set(
        NUU_COOKIE_PRODUCT_TOURS,
        serialize(allUserTours),
        { maxAge: MAX_AGE_2_MONTHS },
    );
}

/**
 * @description
 * For a given profile, mark the indicated tour as completed (i.e. remove it from the cookie)
 *
 * @param {String}  profileId       - the profile for which to mark the tour as completed
 * @param {Object}  cookieInterface - a cookie interface which has a 'get' method (e.g. Vue.cookie)
 * @param {String}  completedTour   - the key of the tour to be marked completed
 */
export function completeTourForProfile(profileId, cookieInterface, completedTour) {
    const oldEligibleTours = getEligibleToursForProfile(profileId, cookieInterface);
    const newEligibleTours = oldEligibleTours.filter(tour => tour !== completedTour);

    setEligibleToursForProfile(profileId, cookieInterface, newEligibleTours);
}
