import {
    BUTTON_VARIANTS,
    BUTTON_VARIANT_TYPES,
    BUTTON_COLOR_VARIANTS,
    BUTTON_COLOR_VARIANT_TYPES,
    BUTTON_SIZES,
} from '~coreModules/core/js/button-constants';

export default {
    props: {
        colorVariant: {
            type: String,
            default: BUTTON_COLOR_VARIANT_TYPES.none,
            validator(value) {
                return BUTTON_COLOR_VARIANTS.indexOf(value) !== -1;
            },
        },
        variant: {
            type: String,
            default: BUTTON_VARIANT_TYPES.primary,
            validator(value) {
                return BUTTON_VARIANTS.indexOf(value) !== -1;
            },
        },
        isBorderless: {
            type: Boolean,
            default: false,
        },
        isLoadingButton: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isLoaded: {
            type: Boolean,
            default: false,
        },
        useSmallFont: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: BUTTON_SIZES.large,
            validator(value) {
                return Object.values(BUTTON_SIZES).indexOf(value) !== -1;
            },
        },
    },
    computed: {
        buttonClasses() {
            return {
                'o-text--button': true,
                'c-button': true,
                'c-button--none': this.variant === BUTTON_VARIANT_TYPES.none,
                'c-button--primary': this.variant === BUTTON_VARIANT_TYPES.primary,
                'c-button--secondary': this.variant === BUTTON_VARIANT_TYPES.secondary,
                'c-button--link': this.variant === BUTTON_VARIANT_TYPES.link,
                'c-button--dark': this.colorVariant === BUTTON_COLOR_VARIANT_TYPES.dark,
                'c-button--light': this.colorVariant === BUTTON_COLOR_VARIANT_TYPES.light,
                'c-button--small': this.size === BUTTON_SIZES.small,
                'c-button--loading-button': this.isLoadingButton,
                'c-button--is-loading': this.isLoadingButton && this.isLoading,
                'c-button--is-loaded': this.isLoadingButton && this.isLoaded,
                'c-button--no-border': this.isBorderless,
                'c-button--font-small': this.useSmallFont,
            };
        },
    },
};
