<template>
    <span :class="['c-loading-dots', isLoadingClass]">
        <slot></slot>
    </span>
</template>

<script>
export default {
    name: 'LoadingDots',
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isLoadingClass() {
            return this.isLoading && 'c-loading-dots--is-loading';
        },
    },
};
</script>

<style lang="scss">
.c-loading-dots {
    @mixin loading-dot {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: $nu-primary;
    }

    &--is-loading {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        @include loading-dot();
        animation: dotFlashing 1s infinite linear alternate;
        animation-delay: .5s;

        &::before,
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            @include loading-dot();
        }

        &::before {
            left: -8px;
            animation: dotFlashing 1s infinite alternate;
            animation-delay: 0s;
        }

        &::after {
            left: 8px;
            animation: dotFlashing 1s infinite alternate;
            animation-delay: 1s;
        }
    }

    @keyframes dotFlashing {
        0% {
            background-color: $nu-primary;
        }

        50%,
        100% {
            background-color: lighten($nu-primary, 50%);
        }
    }
}
</style>
