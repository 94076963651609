import { isEmpty } from 'lodash-es';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import {
    addProductToClosetList,
    createNewClosetList,
    deleteClosetList,
    deleteProductFromClosetList,
    getAllClosetLists,
    toggleProductClosetStatus,
    editClosetListName,
} from '~routes/closet/js/closet-api';

import { decodeHtmlEntitiesFromString } from '~coreModules/core/js/utils';

import {
    getFilteredClosetLists,
    getFilteredPreviewChoices,
    getPreviewChoiceCount,
} from '~routes/closet/js/closet-utils';
import { CLOSET_MAX_SUBLIST_LIMIT, CLOSET_LIST_TYPES } from '~routes/closet/js/closet-constants';
import { PRODUCT_SAVED_CHANGE } from '~coreModules/core/js/global-event-constants';
import { DELETE_SUBLIST, CREATE_SUBLIST } from '~modules/core/js/global-event-constants';
import { PRODUCT_TOUR_MODULE_NAME, SET_PRODUCT_TOUR_FOR_CLOSET } from '~modules/product-tour/js/product-tour-store';

export const CLOSET_ACTIONS_MODULE_NAME = 'closetActions';

export const REMOVE_CHOICE_ID_FROM_ALL_CLOSET_LISTS = 'REMOVE_CHOICE_ID_FROM_ALL_CLOSET_LISTS';
export const REMOVE_CHOICE_ID_FROM_CLOSET_LIST = 'REMOVE_CHOICE_ID_FROM_CLOSET_LIST';
export const SET_CHOICE_ID_TO_CLOSET_LIST = 'SET_CHOICE_ID_TO_CLOSET_LIST';
export const SET_NEW_CLOSET_LIST = 'SET_NEW_CLOSET_LIST';
export const SET_REMOVE_CLOSET_LIST = 'SET_REMOVE_CLOSET_LIST';
export const SET_ALL_CLOSET_LISTS = 'SET_ALL_CLOSET_LISTS';
export const SET_NEW_CLOSET_LIST_NAME = 'SET_NEW_CLOSET_LIST_NAME';

export const GET_ALL_CLOSET_LISTS = 'GET_ALL_CLOSET_LISTS';
export const GET_CLOSET_LIST_PREVIEW = 'GET_CLOSET_LIST_PREVIEW';
export const CREATE_NEW_CLOSET_LIST = 'CREATE_NEW_CLOSET_LIST';
export const DELETE_CLOSET_LIST = 'DELETE_CLOSET_LIST';
export const ADD_PRODUCT_TO_CLOSET_LIST = 'ADD_PRODUCT_TO_CLOSET_LIST';
export const REMOVE_PRODUCT_FROM_CLOSET_LIST = 'REMOVE_PRODUCT_FROM_CLOSET_LIST';
export const TOGGLE_PRODUCT_CLOSET_STATUS = 'TOGGLE_PRODUCT_CLOSET_STATUS';
export const UPDATE_CLOSET_LIST_NAME = 'UPDATE_CLOSET_LIST_NAME';

export default {
    namespaced: true,

    state: () => ({
        closetLists: {},
    }),
    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_ALL_CLOSET_LISTS](state, lists) {
            if (!isEmpty(lists)) {
                state.closetLists = Object.fromEntries(lists.map(list => [list.id, {
                    id: list.id,
                    name: decodeHtmlEntitiesFromString(list.name),
                    lastModifiedDate: list.lastModifiedDate,
                    previewChoices: list.previewChoices,
                    totalChoiceCount: list.totalChoiceCount,
                }]));
            }
        },
        [SET_NEW_CLOSET_LIST](state, list) {
            state.closetLists = {
                [list.id]: { ...list, previewChoices: [], totalChoiceCount: 0 },
                ...state.closetLists,
            };
        },
        [SET_REMOVE_CLOSET_LIST](state, listId) {
            delete state.closetLists[listId];
        },
        [SET_CHOICE_ID_TO_CLOSET_LIST](state, { listId, choiceId, product }) {
            const list = state.closetLists[listId];

            if (list) {
                const previewChoiceCount = getPreviewChoiceCount(list, choiceId);
                if (!previewChoiceCount) state.closetLists[listId].totalChoiceCount++;

                const filteredPreviewChoices = getFilteredPreviewChoices(list, choiceId);
                const previewChoices = [{ ...product, ...(product.imageUrls && { images: product.imageUrls }) }]
                    .concat(...filteredPreviewChoices || []);

                delete state.closetLists[listId];
                state.closetLists = { [listId]: { ...list, previewChoices }, ...state.closetLists };
            }
        },
        [REMOVE_CHOICE_ID_FROM_CLOSET_LIST](state, { listId, choiceId }) {
            const getPreviewChoice = previewChoices => (
                (previewChoices || []).find(previewChoice => previewChoice.choiceId === choiceId));

            delete getPreviewChoice(state.closetLists[listId]?.previewChoices);
        },
        [REMOVE_CHOICE_ID_FROM_ALL_CLOSET_LISTS](state, choiceId) {
            const lists = state.closetLists;

            if (lists) {
                state.closetLists = getFilteredClosetLists(lists, choiceId);
            }
        },
        [SET_NEW_CLOSET_LIST_NAME](state, list) {
            const closetList = state.closetLists[list.id];

            if (closetList) {
                state.closetLists[list.id] = {
                    ...list,
                    name: list.name,
                };
            }
        },
    },

    /* eslint-enable no-param-reassign */
    actions: {
        // eslint-disable-next-line consistent-return
        async [TOGGLE_PRODUCT_CLOSET_STATUS]({ commit, dispatch }, {
            choiceId,
            isInCloset,
            productAnalyticsData,
        }) {
            try {
                await toggleProductClosetStatus(this.$r15Svc, choiceId, isInCloset);

                const newIsInClosetStatus = !isInCloset;
                if (!newIsInClosetStatus) {
                    commit(REMOVE_CHOICE_ID_FROM_ALL_CLOSET_LISTS, choiceId);
                }

                dispatch(GLOBAL_EVENT, {
                    type: PRODUCT_SAVED_CHANGE,
                    data: {
                        ...productAnalyticsData,
                        newStatus: newIsInClosetStatus,
                        closet: {
                            listId: CLOSET_LIST_TYPES.default,
                            listType: CLOSET_LIST_TYPES.default,
                        },
                    },
                }, { root: true });
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async [GET_ALL_CLOSET_LISTS]({ commit, rootGetters }) {
            const { loggedIn } = rootGetters;

            if (!loggedIn) {
                return Promise.resolve();
            }

            try {
                const res = await getAllClosetLists(this.$r15Svc);
                commit(SET_ALL_CLOSET_LISTS, res.subLists);

                return res.subLists;
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async [CREATE_NEW_CLOSET_LIST]({ commit, dispatch, state }, { listName, choiceId, product }) {
            const isEmptyList = isEmpty(state.closetLists);

            try {
                const res = await createNewClosetList(this.$r15Svc, listName);

                commit(SET_NEW_CLOSET_LIST, res);

                if (choiceId) {
                    await dispatch(ADD_PRODUCT_TO_CLOSET_LIST, {
                        listId: res.id,
                        choiceId,
                        product,
                    });
                }

                if (isEmptyList) {
                    await dispatch(`${PRODUCT_TOUR_MODULE_NAME}/${SET_PRODUCT_TOUR_FOR_CLOSET}`, undefined,
                        { root: true });
                }

                dispatch(GLOBAL_EVENT, { type: CREATE_SUBLIST }, { root: true });

                return Promise.resolve(res);
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async [DELETE_CLOSET_LIST]({ commit, dispatch }, { listId }) {
            try {
                const res = await deleteClosetList(this.$r15Svc, listId);

                commit(SET_REMOVE_CLOSET_LIST, listId);

                dispatch(GLOBAL_EVENT, { type: DELETE_SUBLIST }, { root: true });

                return res;
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async [ADD_PRODUCT_TO_CLOSET_LIST]({ commit, dispatch }, {
            listId,
            choiceId,
            product,
            productAnalyticsData,
        }) {
            // doing this first, Product & UX would like to assume this operation works
            commit(SET_CHOICE_ID_TO_CLOSET_LIST, { listId, choiceId, product });

            dispatch(GLOBAL_EVENT, {
                type: PRODUCT_SAVED_CHANGE,
                data: {
                    ...productAnalyticsData,
                    newStatus: true,
                    closet: {
                        listId,
                        listType: CLOSET_LIST_TYPES.sublist,
                    },
                },
            }, { root: true });

            try {
                const res = await addProductToClosetList(this.$r15Svc, listId, choiceId);

                return res;
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async [REMOVE_PRODUCT_FROM_CLOSET_LIST]({ commit, dispatch }, { listId, choiceId, productAnalyticsData }) {
            // doing this first, Product & UX would like to assume this operation works
            commit(REMOVE_CHOICE_ID_FROM_CLOSET_LIST, { listId, choiceId });

            dispatch(GLOBAL_EVENT, {
                type: PRODUCT_SAVED_CHANGE,
                data: {
                    ...productAnalyticsData,
                    newStatus: false,
                    closet: {
                        listId,
                        listType: CLOSET_LIST_TYPES.sublist,
                    },
                },
            }, { root: true });

            try {
                const res = await deleteProductFromClosetList(this.$r15Svc, listId, choiceId);

                return res;
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async [UPDATE_CLOSET_LIST_NAME]({ commit }, { listId, newListName }) {
            try {
                const res = await editClosetListName(this.$r15Svc, listId, newListName);

                commit(SET_NEW_CLOSET_LIST_NAME, res);

                return res;
            } catch (e) {
                return Promise.reject(e);
            }
        },
    },

    getters: {
        closetListsPreview(state) {
            return Object.values(state.closetLists).map(list => ({
                id: list.id,
                name: list.name,
                previewChoices: list.previewChoices?.slice(0, 3),
                totalChoiceCount: list.totalChoiceCount,
            }));
        },
        closetListProductPreviews(state, getters) {
            return (listId) => {
                const getListsPreview = listsPreview => (
                    listsPreview.find(listPreview => listPreview.id === listId));
                const previewChoices = getListsPreview(getters.closetListsPreview)?.previewChoices;
                return (previewChoices || []).map(previewChoice => previewChoice?.images[0]);
            };
        },
        hasMaxSublists(state, getters) {
            const { closetListsPreview } = getters;
            return closetListsPreview.length >= CLOSET_MAX_SUBLIST_LIMIT;
        },
    },
};
