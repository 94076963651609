import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';
import { shapeContentfulContentPageResponse } from '~coreModules/contentful/js/contentful-utils';
import { CONTENT_TYPES } from '~coreModules/contentful/js/contentful-constants';
import {
    FOOTER_NAV_LEGAL_LINKS_SLUG,
    FOOTER_NAV_LEGAL_LINKS_SLUG_CA,
} from '~coreModules/nav/js/nav-constants';
import { AB_TEST_GATEWAY_PAGE } from '~coreModules/ab-testing/js/ab-testing-constants';

import { SET_404_ERROR } from '~coreModules/core/js/store';
import { AB_TESTING_MODULE_NAME } from '~coreModules/ab-testing/js/ab-testing-store';
import { NAVIGATION_MODULE_NAME } from '~coreModules/nav/js/nav-store';

export const DISCOVER_MODULE_NAME = 'discover';

// mutations
export const SET_DISCOVER_CONTENT = 'SET_DISCOVER_CONTENT';
export const SET_NAV_ITEM = 'SET_NAV_ITEM';

// actions
export const FETCH_DISCOVER_CONTENT = 'FETCH_DISCOVER_CONTENT';
export const CHECK_SLUG = 'CHECK_SLUG';

export default {
    namespaced: true,

    state: () => ({
        navItem: {},
        contentPage: {},
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_NAV_ITEM](state, navItem) {
            state.navItem = navItem;
        },
        [SET_DISCOVER_CONTENT](state, shapedContent) {
            state.contentPage = shapedContent;
        },
    },

    /* eslint-enable no-param-reassign */
    actions: {
        [CHECK_SLUG]({ commit, rootState }, { slug, isLegalPage = false }) {
            const { flatNavigation } = rootState[`${NAVIGATION_MODULE_NAME}`];

            /* eslint-disable no-prototype-builtins */
            if (!flatNavigation.hasOwnProperty(slug)) {
                return Promise.reject(`Slug does not exist for ${slug}`);
            }

            const navItem = flatNavigation[slug];
            const { parentSlug } = navItem;

            if (!isLegalPage &&
                (parentSlug === FOOTER_NAV_LEGAL_LINKS_SLUG || parentSlug === FOOTER_NAV_LEGAL_LINKS_SLUG_CA)) {
                return Promise.reject('Legal pages are only accessible at /legal/:slug');
            }

            commit(SET_NAV_ITEM, navItem);
            return Promise.resolve();
        },
        [FETCH_DISCOVER_CONTENT]({ commit, rootGetters, dispatch }, { slug, isLegalPage = false }) {
            const gatewaySlug = rootGetters[`${AB_TESTING_MODULE_NAME}/getSlug`](slug, AB_TEST_GATEWAY_PAGE);

            return dispatch(CHECK_SLUG, { slug, isLegalPage })
                .then(() => getContentfulPageContentForSlug({
                    contentfulSvc: this.$contentfulSvc,
                    contentType: CONTENT_TYPES.CONTENT_PAGE,
                    slug: gatewaySlug,
                    isTimed: true,
                })
                    .then((res) => {
                        if (!res) {
                            throw Error(`Contentful page content not found for slug ${slug}`);
                        }
                        const shapedContent = shapeContentfulContentPageResponse(res);
                        commit(SET_DISCOVER_CONTENT, shapedContent);
                    })
                    .catch(error => Promise.reject(`Failed to fetch discover contentful content: ${error}`)))
                .catch((error) => {
                    this.$logger.debugError(error);
                    commit(SET_404_ERROR, { message: 'Failed to fetch discover contentful content' }, { root: true });
                });
        },
    },

    getters: {
        headerMargin(state) {
            if (state.contentPage.headerMargin) {
                return Number.parseInt(state.contentPage.headerMargin, 10);
            }

            return null;
        },
    },
};
