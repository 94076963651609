<template>
    <form novalidate class="c-search-form o-flex-vertical-center" @submit.prevent="submitSearch">
        <div
            v-tab-focus.onspace="submitSearch"
            class="c-search-form__icon-container"
            aria-label="Submit search"
        >
            <BaseIcon
                icon="global--search"
                size="12px"
                class="c-search-form__icon"
                :color="searchIconColor"
            />
        </div>
        <input
            ref="searchInput"
            v-model.trim="searchTerms"
            class="c-search-form__input"
            :placeholder="placeholder"
        >
        <div
            v-if="searchTerms.length > 0"
            v-tab-focus.onspace="clearSearch"
            class="c-search-form__icon-container"
            aria-label="Clear search field"
        >
            <BaseIcon
                :icon="clearIcon"
                size="16px"
                color="primary"
                class="c-search-form__icon"
            />
        </div>
        <div
            v-if="showSubmitArrow"
            v-tab-focus.onspace="submitSearch"
            class="c-search-form__icon-container"
            aria-label="Submit search"
        >
            <BaseIcon
                icon="global--right-arrow"
                size="16px"
                color="primary"
                class="c-search-form__icon"
            />
        </div>
    </form>
</template>

<script>
export default {
    name: 'BaseSearchForm',
    props: {
        // Only used for v-model, not intended to be passed in
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        showSubmitArrow: {
            type: Boolean,
            default: false,
        },
        supportingText: {
            type: String,
            default: '',
        },
        searchIconColor: {
            type: String,
            default: 'primary',
        },
        clearIcon: {
            type: String,
            default: 'global--field-clear',
        },
    },
    emits: [
        'update:modelValue',
        'submit',
    ],
    computed: {
        searchTerms: {
            get() {
                return this.modelValue;
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },
    methods: {
        submitSearch() {
            this.$emit('submit');
        },
        focusInput() {
            this.$refs.searchInput.focus();
        },
        blurInput() {
            this.$refs.searchInput.blur();
        },
        clearSearch(blur) {
            this.searchTerms = '';
            this.$refs.searchInput[
                !blur ? 'focus' : 'blur'
            ]();
        },
    },
};
</script>

<style lang="scss">
    .c-search-form {
        height: 32px;
        border-radius: 2px 2px 0 0;
        width: 100%;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 2px;
            background-image:
                linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-primary 50%, $nu-primary 100%);
            background-position-y: 100%;
            background-size: 100% 200%;
            background-repeat: no-repeat;
            transition: background-position-y 0.2s ease;
        }

        &:focus-within {
            &::after {
                background-position-y: 0;
            }
        }

        &__input {
            background-color: $transparent;
            border: none;
            height: 100%;
            width: calc(100% - 32px);

            &:focus {
                outline: 0;
                &::placeholder {
                    color: $nu-gray;
                }
            }

            &::placeholder {
                color: $nu-primary;
            }
        }

        &__icon-container {
            cursor: pointer;
            height: 100%;
            display: inline-flex;
            place-items: center;
        }

        &__icon {
            margin: 0 $nu-spacer-1;
        }
    }
</style>
