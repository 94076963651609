import { isEmpty, difference } from 'lodash-es';

import { getMissingRequiredFields } from '~coreModules/core/js/validation-utils';

import {
    CONTENT_CARD_PRIORITY_MIN,
    CONTENT_CARD_PRIORITY_MAX,
    ContentCardSchemas,
} from '~coreModules/content-cards/js/content-cards-constants';

/**
 * returns specific content card by the card id provided
 * @param {Object} contentCards - the entire content cards object
 * @param {string} contentCardId - the content card id
 * @returns {Object} the content card with the specified card id
 */
export function getContentCardById(contentCards, contentCardId) {
    const flattenedCardValues = Object.values(contentCards || {}).flat();
    const contentCardMap = flattenedCardValues
        .reduce((acc, card) => ({ ...acc, [card.id]: card }), {});
    return contentCardMap?.[contentCardId];
}

/**
 * returns sorted array of content cards based on their priority
 * @param {Array} contentCards - the array of content cards to sort.each card is an object with a
 * `cardPriority` property.
 * @returns {Array} the sorted array of content cards. cards with higher priority come first
 */
export function getSortedContentCards(contentCards) {
    const sortedCards = (contentCards || [])
        .filter(card => +card.cardPriority >= CONTENT_CARD_PRIORITY_MIN && +card.cardPriority <=
            CONTENT_CARD_PRIORITY_MAX)
        .sort((a, b) => +a.cardPriority - +b.cardPriority);
    return sortedCards;
}

/**
 * checks if the required fields are present in the given object
 *
 * @param {Object} obj - the object to check for required fields
 * @param {String} contentCardType - the type of content card
 * @param {Object} logger - the logger instance
 * @throws {Error} throws an error if any required fields are missing
 */
export function checkRequiredValues(obj, contentCardType, logger = console) {
    const requiredFields = getMissingRequiredFields(obj);

    if (requiredFields?.length) {
        const msg = requiredFields.length > 1 ?
            `At least one of the following fields are required
                to launch content card: ${requiredFields.join(', ')}, for contentCardType: ${contentCardType}` :
            `${requiredFields[0]} is a required field to launch a content card`;

        logger.error(msg);
        throw new Error(msg);
    }
}

/**
 * validates a content card object against a predefined schema
 *
 * @param {Object} contentCard - the content card to validate
 * @param {Object} logger - the logger instance
 * @throws {Error} if the content card is empty or if it does not have a contentCardType property
 */
export function validateContentCard(contentCard, logger) {
    if (isEmpty(contentCard)) {
        throw new Error(`error rendering content card, no json was provided
            for following content card: `, contentCard);
    }

    const { contentCardType } = contentCard;

    if (!contentCardType) {
        throw new Error(`error rendering content card,
            contentCardType was not set for following content card: `, contentCard);
    }

    const contentCardSchema = ContentCardSchemas[contentCardType];

    if (!contentCardSchema) {
        throw new Error(`error rendering content card, no JSON schema found for
            contentCardType: ${contentCardType}, in the following content card: \n`, contentCard);
    }

    const requiredFields = contentCardSchema?.requiredFields || [];
    const unrequiredFields = contentCardSchema?.unrequiredFields || [];
    const allDefinedFields = [...requiredFields, ...unrequiredFields];
    const receivedFields = Object.keys(contentCard);
    const nonConfiguredFields = difference(receivedFields, allDefinedFields);

    /* log error but don't prevent rendering message if fields were sent through that are not defined in
       the schema for this contentCardType */
    if (!isEmpty(nonConfiguredFields)) {
        logger.error(`the following fields were sent, but are not defined in the
            schema for this contentCardType: ${nonConfiguredFields.join(', ')}\n`);
    }

    try {
        requiredFields.forEach((value) => {
            checkRequiredValues({ [value]: contentCard[value] }, contentCardType, logger);
        });
    } catch (error) {
        throw new Error(error);
    }
}
