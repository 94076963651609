<template>
    <CoreSlideLayout
        v-bind="$props"
        :contentfulContentBlock="ContentfulContentBlock"
    >
        <slot></slot>
        <template #logo>
            <slot name="logo"></slot>
        </template>
        <template #progressbar>
            <slot name="progressbar"></slot>
        </template>
    </CoreSlideLayout>
</template>

<script setup>
import { markRaw, defineAsyncComponent } from 'vue';

import CoreSlideLayout from '~coreModules/core/components/layouts/CoreSlideLayout.vue';

const ContentfulContentBlock = markRaw(
    defineAsyncComponent(
        () => import(
            /* webpackChunkName: "contentful-content-block" */
            '~modules/contentful/components/ContentfulContentBlock.vue'),
    ));
</script>
