import { FOOTER_NAV_LEGAL_LINKS_SLUG_CA } from '~coreModules/nav/js/nav-constants';
import { nuColorSecondary } from '~coreModules/core/js/temp-colors';

export const HEADER_GLOBAL_PRIVACY_CONTROL = 'Sec-GPC';

const BASE_NOTIFICATION_ID = 'compliance-notification';

const NOTIFICATION_DEFAULTS = {
    bkgColor: nuColorSecondary,
    illustration: null,
    destination: null,
};

export const COMPLIANCE_CONFIGURATION = {
    US: {
        CA: {
            footerLegalLinksSlug: FOOTER_NAV_LEGAL_LINKS_SLUG_CA,
            stickyNotification: {
                ...NOTIFICATION_DEFAULTS,
                title: 'compliance__privacyBannerCA--title',
                subtitle: 'compliance__privacyBannerCA--subtitle',
                cta: 'compliance__privacyBannerCA--cta',
                notificationId: `${BASE_NOTIFICATION_ID}--USA-CA`,
            },
        },
    },
};

export default COMPLIANCE_CONFIGURATION;
