import { NUU_COOKIE_NOTIFICATIONS, MAX_AGE_2_WEEKS } from '~coreModules/core/js/cookie-constants';
import { SECONDS_IN_1_DAY } from '~coreModules/core/js/date-constants';

import { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';

import { isNotificationPageBlocked } from '~coreModules/notifications/js/anonymous-notifications-utils';

export const ANONYMOUS_NOTIFICATIONS_MODULE_NAME = 'anonymous-notifications';
export const SET_ELIGIBLE_NOTIFICATIONS = 'SET_ELIGIBLE_NOTIFICATIONS';
export const GET_ELIGIBLE_NOTIFICATIONS = 'GET_ELIGIBLE_NOTIFICATIONS';
export const GET_ACTIVE_NOTIFICATION = 'GET_ACTIVE_NOTIFICATION';
export const SET_ACTIVE_NOTIFICATION = 'SET_ACTIVE_NOTIFICATION';
export const SET_NOTIFICATION_VIEWED = 'SET_NOTIFICATION_VIEWED';
export const REMOVE_NOTIFICATION = 'SET_NOTIFICATION_VIEWED';

export default {
    namespaced: true,

    state: () => ({
        activeNotification: null,
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_ACTIVE_NOTIFICATION](state, activeNotification) {
            state.activeNotification = activeNotification;
        },
    },
    /* eslint-enable */

    actions: {
        [GET_ACTIVE_NOTIFICATION]({ commit, rootGetters }, routeHref) {
            let notifications = rootGetters[`${SITE_SETTINGS_MODULE_NAME}/notifications`];

            // filter notifications user has seen previously
            notifications =
                notifications?.filter(
                    ({ notificationId }) => !this.$cookies.get(`${NUU_COOKIE_NOTIFICATIONS}_${notificationId}`));

            const activeNotification =
                notifications.find(notification => !isNotificationPageBlocked(routeHref, notification));

            if (activeNotification) {
                commit(SET_ACTIVE_NOTIFICATION, activeNotification);
            }
        },
        [SET_NOTIFICATION_VIEWED]({ commit }, {
            notificationId,
            maxAgeInDays,
            shouldTrackView = true,
        }) {
            const maxAge = maxAgeInDays ? maxAgeInDays * SECONDS_IN_1_DAY : MAX_AGE_2_WEEKS;

            if (shouldTrackView) {
                this.$cookies.set(
                    `${NUU_COOKIE_NOTIFICATIONS}_${notificationId}`,
                    true,
                    { maxAge },
                );
            }

            commit(SET_ACTIVE_NOTIFICATION, null);
        },
    },
    getters: {
        activeNotification(state) {
            return state.activeNotification;
        },
    },
};
