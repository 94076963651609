import { get, merge, set } from 'lodash-es';

import {
    NUU_HEADER_COUNTRY,
    NUU_HEADER_REGION,
    NUU_HEADER_QUERY_VERSION,
    NUU_HEADER_TRACE_ID,
    NUU_HEADER_LOCATION_ID,
} from '~coreModules/core/js/constants';
import { SET_AUTHENTICATED, SET_LOCATION_DC_ID } from '~coreModules/core/js/store';
import { LOGIN_MODULE_NAME, LOG_OUT } from '~coreRoutes/login/js/login-store';
import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

/* Auth Interceptor To Add Auth Cookie To Headers */
/* This is only for SSR requests */
export function addAuthCookie(request, logger, authCookieName, requestConfig) {
    if (process.env.VUE_ENV === 'server') {
        const authToken = get(request, `cookies.${authCookieName}`);

        if (authToken) {
            const authHeaders = {
                headers: {
                    Cookie: `${authCookieName}=${authToken};`,
                },
            };

            return merge(requestConfig, authHeaders);
        }
    }

    return requestConfig;
}

/**
 * Axios response interceptor to add any nuuly specific headers into response data
 *
 * @param  {Object}  response Axios response object
 * @return {Object}  Axios response object
 */
export function responseNuulyHeaderInterceptor(response) {
    const traceId = response?.headers?.[NUU_HEADER_TRACE_ID];

    if (traceId) {
        Object.assign(response.data, {
            responseHeaders: {
                traceId,
            },
        });
    }

    return response;
}

/**
 * Axios request interceptor to handle missing or bad tokens
 *
 * @param  {Object}  responseErr  Axios response error object
 * @param  {Object}  store  vuex store
 * @return {Promise} Promise rejected with the `responseErr` object
 */
export function handleR15TokenErrorInterceptor(responseErr, store) {
    const errorCode = responseErr?.status;
    const phoneLoginRedirectPath = get(store, 'getters.phoneLoginRedirectPath', '/');

    if (errorCode === 401 || errorCode === 403) {
        if (process.env.VUE_ENV === 'server') {
            store.commit(SET_AUTHENTICATED, false);

            // passing rejected error with redirect flag=true to be picked up by express
            return Promise.reject({
                redirect: true,
                status: errorCode,
                url: phoneLoginRedirectPath,
            });
        }

        store.dispatch(`${LOGIN_MODULE_NAME}/${LOG_OUT}`, phoneLoginRedirectPath);
    }
    return Promise.reject(responseErr);
}

/**
 * Axios response interceptor to grab location id header and add to global store
 * @param  {Object} requestConfig - axios interceptor response config
 * @param  {Object} state - vuex store
 * @return {Object} axios interceptor response config
 */
export const setLocationIdHeaderInterceptor = (reponse, store) => {

    if (reponse.headers[NUU_HEADER_LOCATION_ID]) {
        store.commit(SET_LOCATION_DC_ID, reponse.headers[NUU_HEADER_LOCATION_ID]);
    }

    return reponse;
};

/**
 *
 * Converts array of regex-like strings into array of RegExp
 * and evaluates whether any match the request url. If so
 * the appropriate NUU_HEADER_QUERY_VERSION header is set
 * @param  {Object} requestConfig - axios interceptor request config
 * @param  {Object} contentfulAppConfig - contentful site config
 * @return {Object} axios interceptor request config with additional header
 */
export const setQueryVersionHeader = (requestConfig, contentfulAppConfig) => {
    const useV2Header = contentfulAppConfig?.useV2Header || [];
    const regexList = (useV2Header).map(regex => new RegExp(regex));
    const isMatch = regexList.some(regex => regex.test(requestConfig.url || ''));

    if (isMatch) set(requestConfig, `headers[${NUU_HEADER_QUERY_VERSION}]`, '2');

    return requestConfig;
};

/**
 *
 * Axios request interceptor to add r15 specific headers into request config
 * @param  {Object} requestConfig - axios interceptor request config
 * @param  {Object} state - vuex store
 * @return {Object} axios interceptor request config with additional header
 */
export const setR15RequestHeaders = (requestConfig, store) => {
    const { geolocation: { country, region } } = store.state[SITE_SETTINGS_MODULE_NAME];

    if (country) set(requestConfig, `headers[${NUU_HEADER_COUNTRY}]`, country);
    if (region) set(requestConfig, `headers[${NUU_HEADER_REGION}]`, region);

    setQueryVersionHeader(requestConfig, store.state.siteSettings.contentfulAppConfig);

    return requestConfig;
};
