/* eslint-disable no-param-reassign */
export default {
    install: function install(app, userState, store) {
        let state = userState;

        if (process.env.VUE_ENV === 'server') {
            state = JSON.parse(state);
        }

        app.$userState = state;
        store.$userState = state;
        app.config.globalProperties.$userState = state;
    },
};
