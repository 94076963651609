import { get, uniq } from 'lodash-es';

import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';

import {
    PRODUCT_TOURS,
    CLOSET_TOUR,
    PRODUCT_TOUR_NAMES,
} from '~modules/product-tour/js/product-tour-constants';
import {
    completeTourForProfile,
    getEligibleToursForProfile,
    setEligibleToursForProfile,
} from '~modules/product-tour/js/product-tour-utils';

export const PRODUCT_TOUR_MODULE_NAME = 'product-tour';

export const SET_ELIGIBLE_TOURS = 'SET_ELIGIBLE_TOURS';
export const SET_ELIGIBLE_TOURS_FROM_COOKIE = 'SET_ELIGIBLE_TOURS_FROM_COOKIE';
export const MARK_TOUR_COMPLETE = 'MARK_TOUR_COMPLETE';
export const SET_PRODUCT_TOURS_FOR_NEW_USER = 'SET_PRODUCT_TOURS_FOR_NEW_USER';
export const SET_PRODUCT_TOUR_FOR_CLOSET = 'SET_PRODUCT_TOUR_FOR_CLOSET';
export const SET_PRODUCT_TOUR_FOR_CLOSET_LIST = 'SET_PRODUCT_TOUR_FOR_CLOSET_LIST';
export const SET_ACTIVE_TOUR = 'SET_ACTIVE_TOUR';
export const UPDATE_ELIGIBLE_TOURS = 'UPDATE_ELIGIBLE_TOURS';

export default {
    namespaced: true,
    state: () => ({
        eligibleTours: [],
        activeTour: '',
    }),
    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_ELIGIBLE_TOURS](state, eligibleTours) {
            state.eligibleTours = uniq(eligibleTours);
        },
        [UPDATE_ELIGIBLE_TOURS](state, eligibleTours) {
            state.eligibleTours = uniq([
                ...state.eligibleTours,
                ...eligibleTours,
            ]);
        },
        [SET_ACTIVE_TOUR](state, tour) {
            const tourIsValid = tour && Object.keys(PRODUCT_TOUR_NAMES).includes(tour);
            state.activeTour = tourIsValid ? tour : '';
        },
    },
    /* eslint-enable */
    actions: {
        [SET_ELIGIBLE_TOURS_FROM_COOKIE]({ commit, rootState }) {
            const profileId = get(rootState, `${PROFILE_MODULE_NAME}.profileId`);

            if (profileId) {
                commit(SET_ELIGIBLE_TOURS, getEligibleToursForProfile(profileId, this.$cookies));
            }
        },
        [MARK_TOUR_COMPLETE]({ dispatch, rootState }, tour) {
            const profileId = get(rootState, `${PROFILE_MODULE_NAME}.profileId`);

            if (profileId) {
                // a tour is considered 'complete' (i.e. not re-viewable) the moment a user begins the tour, thus
                // removing that tour for that user in both the cookie and the store
                completeTourForProfile(profileId, this.$cookies, tour);
                dispatch(SET_ELIGIBLE_TOURS_FROM_COOKIE, this.$cookies);
            }
        },
        [SET_PRODUCT_TOURS_FOR_NEW_USER]({ dispatch, rootState }) {
            const profileId = get(rootState, `${PROFILE_MODULE_NAME}.profileId`);

            if (profileId) {
                setEligibleToursForProfile(profileId, this.$cookies, PRODUCT_TOURS);
                dispatch(SET_ELIGIBLE_TOURS_FROM_COOKIE, this.$cookies);
            }
        },
        [SET_PRODUCT_TOUR_FOR_CLOSET]({ dispatch, rootState, state }) {
            const profileId = get(rootState, `${PROFILE_MODULE_NAME}.profileId`);

            if (profileId) {
                setEligibleToursForProfile(profileId, this.$cookies, [...state.eligibleTours, ...CLOSET_TOUR]);
                dispatch(SET_ELIGIBLE_TOURS_FROM_COOKIE, this.$cookies);
            }
        },
        [SET_PRODUCT_TOUR_FOR_CLOSET_LIST]({ commit, rootState }) {
            const profileId = get(rootState, `${PROFILE_MODULE_NAME}.profileId`);

            if (profileId) {
                // not setting this product tour in a cookie by request of the product team
                commit(UPDATE_ELIGIBLE_TOURS, [PRODUCT_TOUR_NAMES.closetList]);
            }
        },
    },
    getters: {
        shouldRenderGivenTour(state, getters, rootState) {
            return tour => !rootState.routeIsLoading &&
                (state.eligibleTours.includes(tour) || state.activeTour === tour);
        },

    },
};
