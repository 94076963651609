<template>
    <div v-if="shouldDisplay" class="c-autocomplete-dropdown">
        <div class="c-autocomplete-dropdown__container">
            <ul class="c-autocomplete-dropdown__ul">
                <li
                    v-for="result in searchResults"
                    :key="result.placeId"
                    :data-placeId="result.placeId"
                    class="c-autocomplete-dropdown__li o-flex-vertical-center o-text--caption u-text--gray"
                    @click="onClickResult"
                >
                    <BaseIcon icon="global--map-pin" size="16px" color="gray" />
                    <span class="c-autocomplete-dropdown__main-text">
                        {{ result.mainText }},
                    </span>&nbsp;{{ result.secondaryText }}
                </li>
            </ul>
            <div
                :class="[
                    'c-autocomplete-dropdown__attribution',
                    'o-flex-vertical-center',
                    'o-flex-horizontal-center'
                ]"
            >
                <img :src="GoogleLogo" alt="powered by google">
            </div>
        </div>
    </div>
</template>

<script>

import GoogleLogo from '~coreImages/powered_by_google_on_white.png';

export default {
    name: 'AddressAutocompleteDropdown',
    props: {
        searchResults: {
            type: Array,
            required: false,
            default: null,
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['on-result-selected'],
    data() {
        return {
            GoogleLogo,
        };
    },
    computed: {
        shouldDisplay() {
            return this.isActive && this.searchResults && this.searchResults.length > 0;
        },
    },
    methods: {
        onClickResult(e) {
            const placeId = e.currentTarget.dataset.placeid;
            this.$emit('on-result-selected', placeId);
        },
    },
};

</script>

<style lang="scss">
    .c-autocomplete-dropdown {
        position: relative;
        z-index: 1;

        &__container {
            @include dropshadow;

            position: absolute;
            width: 100%;
            background-color: $nu-white;
            min-height: 50px;
            top: -($nu-spacer-1);
        }

        &__attribution {
            width: 100%;
            height: 40px;
            text-align: center;
            background-color: $nu-gray--light;

            img {
                width: 95px;
                height: auto;
            }
        }

        &__li {
            height: 48px;
            padding: 0 $nu-spacer-2;
            cursor: pointer;

            &:hover {
                background-color: $nu-gray--light;
            }

            img {
                margin-right: $nu-spacer-1;
            }
        }

        &__main-text {
            padding-left: $nu-spacer-1;
            color: $nu-black;
        }
    }

</style>
