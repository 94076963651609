import { isFunction } from 'lodash-es';

import {
    generateMetaTags,
    generateLinkTags,
    combineMetaTags,
    combineLinkTags,
} from '~coreModules/core/js/seo/meta-utils';

export default {
    created() {
        // TODO: Reset meta tags on client side route after we implement global meta tags
        // These global meta tags will be whitelisted from the reset functionality.
        // https://urbnit.atlassian.net/browse/TYP-3037
        if (process.env.VUE_ENV === 'server') {
            const userState = this.$userState;

            let metaTags = [{
                property: 'og:url',
                content: `${userState.baseUrl}${this.$route.path}`,
            }, {
                property: 'og:site_name',
                content: 'Nuuly',
            }, {
                property: 'og:locale',
                content: 'en-US',
            }, {
                property: 'og:type',
                content: 'website',
            }, {
                property: 'og:image',
                content: `${userState.baseUrl}/static/logo_1200x630.png`,
            }];

            if (isFunction(this.$options.getMetaTags)) {
                const routeMetaTags = this.$options.getMetaTags.call(this);
                metaTags = combineMetaTags(metaTags, routeMetaTags);
            }

            this.$ssrContext.metaTags = generateMetaTags(metaTags);

            let linkTags = [{
                rel: 'canonical',
                href: `${userState.baseUrl}${this.$route.path}`,
            }];

            if (isFunction(this.$options.getLinkTags)) {
                const routeLinkTags = this.$options.getLinkTags.call(this);
                linkTags = combineLinkTags(linkTags, routeLinkTags);
            }

            this.$ssrContext.linkTags = generateLinkTags(linkTags);
        }

        let defaultPageTitle = this.$t(this.SITE_META.fallbackTitle);

        if (isFunction(this.$options.getPageTitle)) {
            const pageTitle = this.$options.getPageTitle.call(this);

            if (pageTitle) {
                defaultPageTitle = pageTitle;
            }
        }

        if (process.env.VUE_ENV === 'server') {
            this.$ssrContext.title = defaultPageTitle;
        } else {
            document.title = defaultPageTitle;
        }
    },
};
