/**
 * @description
 * Custom Error for path redirects
 */
class RedirectError extends Error {
    /**
     * Constructor for RedirectError
     *
     * @param   {number} statusCode HTTP status code for the redirect (301, 302, etc.)
     * @param   {string} oldUrl    Path the user is being redirected from
     * @param   {string} newUrl    Path the user should be redirected to
     * @returns {RedirectError}     Custom RedirectError instance
     */
    constructor(statusCode, oldUrl, newUrl) {
        super(`Redirecting ${oldUrl} -> ${newUrl}`);
        // Ensure the name of this error is the same as the class name
        this.name = this.constructor.name;
        this.type = 'RedirectError';
        this.statusCode = statusCode;
        this.oldUrl = oldUrl;
        this.url = newUrl;
    }

    /* istanbul ignore next */
    /**
     * Method to print a pretty error string for this error
     *
     * @returns {string} Error description
     */
    toString() {
        return `Redirecting ${this.oldUrl} -> ${this.newUrl}`;
    }
}

class ClientsideNavigationUncaughtError extends Error {
    /**
     * Constructor for ClientsideNavigationUncaughtError
     *
     * @param   {Error} error    Original Uncaught Error during navigation
     * @returns {ClientsideNavigationUncaughtError} Custom ClientsideNavigationUncaughtError instance
     */
    constructor(error) {
        super(`Clientside uncaught error during navigation: ${error?.message}`);
        this.name = `${error.constructor.name} - ClientsideNavigationUncaughtError`;
        this.stack = `Clientside uncaught error during navigation: ${error.stack}`;
    }

    /* istanbul ignore next */
    /**
     * Method to print a pretty error string for this error
     *
     * @returns {string} Error description
     */
    toString() {
        return this.message;
    }
}

class ClientsideNavigationChunkLoadError extends Error {
    /**
     * Constructor for ClientsideNavigationChunkLoadError
     *
     * @param   {Error} error    Original Uncaught Error during navigation
     * @returns {ClientsideNavigationChunkLoadError} Custom ClientsideNavigationChunkLoadError instance
     */
    constructor(error) {
        super(`Clientside ChunkLoad error during navigation: ${error?.message}`);
        this.name = `${error.constructor.name} - ClientsideNavigationChunkLoadError`;
        this.stack = `Clientside ChunkLoad error during navigation: ${error.stack}`;
    }

    /* istanbul ignore next */
    /**
     * Method to print a pretty error string for this error
     *
     * @returns {string} Error description
     */
    toString() {
        return this.message;
    }
}

module.exports = {
    RedirectError,
    ClientsideNavigationUncaughtError,
    ClientsideNavigationChunkLoadError,
};
