<i18n>
[
    "account__paymentRequired--heading",
    "account__paymentRequired--subheading",
    "account__paymentRequired--successHeading",
    "account__paymentRequired--successSubheading",
    "account__paymentRequired--successCta",
    "address__type--billing",
    "global__save",
    "global__previous",
]
</i18n>

<template>
    <div class="c-overdue-billing">
        <div class="o-row">
            <div class="o-extra-small--12 o-medium--8 o-medium--offset-2 u-text--center">
                <Illustration
                    v-show="wasUpdateSuccessful"
                    :illustrationSrc="CameraIllustration"
                    illustrationSize="128"
                    class="u-spacer--2"
                />
                <h3 class="u-spacer--1">
                    {{ heading }}
                </h3>
                <p class="u-spacer--4">
                    {{ subheading }}
                </p>
            </div>
            <template v-if="!wasUpdateSuccessful">
                <div class="o-extra-small--12 o-medium--8 o-medium--offset-2 u-spacer--2">
                    <CreditCardForm ref="creditCardFormRef" :billingAddress="billingAddress" />
                </div>
                <h5 class="o-extra-small--12 o-medium--8 o-medium--offset-2 u-spacer--4 u-text--center">
                    {{ $t('address__type--billing') }}
                </h5>
                <div class="o-extra-small--12 o-medium--8 o-medium--offset-2">
                    <AutocompleteAddressForm
                        ref="billingAddressFormRef"
                        :handleFormSubmit="onAddressSubmit"
                        :initialFormState="billingAddress"
                        formId="billingAddress"
                    />
                </div>
            </template>
        </div>
        <div class="c-overdue-container__buttons">
            <template v-if="!wasUpdateSuccessful">
                <BaseSupportingTextError
                    v-if="serviceErrorMessage"
                    class="u-spacer--1"
                    :hasError="serviceErrorMessage"
                    :errorText="serviceErrorMessage"
                />
                <LoadingButton class="u-spacer--3" :isLoading="isLoading" @click="onInfoSubmit">
                    {{ $t('global__save') }}
                </LoadingButton>
                <span v-tab-focus="() => $emit('previous-clicked')" class="o-text--link">
                    {{ $t('global__previous') }}
                </span>
            </template>
            <LoadingButton
                v-else
                class="u-spacer--3"
                :isLoading="isLoading"
                @click="onRefresh"
            >
                {{ $t('account__paymentRequired--successCta') }}
            </LoadingButton>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { get } from 'lodash-es';

import { getProfile } from '~coreModules/profile/js/profile-api';
import { updateBillingToken } from '~modules/profile/js/profile-api';

import { MAX_AGE_15_MINUTES } from '~coreModules/core/js/cookie-constants';
import { MILLISECONDS_IN_1_MINUTE } from '~coreModules/core/js/date-constants';
import { SUBSCRIPTIONS_MODULE_NAME, SET_SUBSCRIPTION_INFO } from '~routes/account/js/subscriptions-store';
import { NUU_COOKIE_OVERDUE_PAYMENT_SUCCESS } from '~modules/site-settings/js/site-settings-constants';
import { SUBSCRIPTION_STATUSES } from '~modules/profile/js/profile-constants';
import { SET_IS_OVERDUE } from '~modules/core/js/store';

import AutocompleteAddressForm from '~coreModules/google-maps/components/AutocompleteAddressForm.vue';
import BaseSupportingTextError from '~coreModules/core/components/ui/form-elements/BaseSupportingTextError.vue';
import LoadingButton from '~coreModules/core/components/ui/buttons/LoadingButton.vue';
import Illustration from '~coreModules/core/components/ui/Illustration.vue';
import CreditCardForm from '~modules/recurly/components/CreditCardForm.vue';

import CameraIllustration from '~images/illustration__camera.png';

export default {
    name: 'OverdueBillingSlide',
    components: {
        AutocompleteAddressForm,
        BaseSupportingTextError,
        CreditCardForm,
        LoadingButton,
        Illustration,
    },
    props: {
        initialBillingAddress: {
            type: Object,
            required: true,
        },
        overduePaymentSuccess: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['previous-clicked'],
    data() {
        return {
            CameraIllustration,
            billingAddress: this.initialBillingAddress,
            isLoading: false,
            serviceErrorMessage: '',
            wasUpdateSuccessful: false,
            timeoutOneMin: null,
            timeoutThreeMins: null,
            timeoutFiveMins: null,
        };
    },
    computed: {
        ...mapState({
            isOverdue: state => state.isOverdue,
        }),
        heading() {
            return this.wasUpdateSuccessful ?
                this.$t('account__paymentRequired--successHeading') :
                this.$t('account__paymentRequired--heading');
        },
        subheading() {
            return this.wasUpdateSuccessful ?
                this.$t('account__paymentRequired--successSubheading') :
                this.$t('account__paymentRequired--subheading');
        },
    },
    created() {
        if (this.overduePaymentSuccess) {
            this.wasUpdateSuccessful = true;

            if (!(this.timeoutOneMin || this.timeoutThreeMins || this.timeoutFiveMins)) {
                this.setAutoTimers();
            }
        }

    },
    unmounted() {
        [this.timeoutOneMin, this.timeoutThreeMins, this.timeoutFiveMins].forEach(
            (timer) => { if (timer) { clearTimeout(timer); } },
        );
    },
    methods: {
        ...mapMutations(SUBSCRIPTIONS_MODULE_NAME, {
            updateSubscription: SET_SUBSCRIPTION_INFO,
        }),
        onAddressSubmit(formData) {
            this.billingAddress = formData;
        },
        submitCreditCardForm() {
            return this.$refs.creditCardFormRef.triggerFormSubmit()
                .then(token => updateBillingToken(this.$r15Svc, token)
                    .then((res) => {
                        this.updateSubscription(res.subscription);
                        this.isLoading = false;
                        this.wasUpdateSuccessful = true;
                        this.$cookie.set(
                            NUU_COOKIE_OVERDUE_PAYMENT_SUCCESS,
                            true,
                            { maxAge: MAX_AGE_15_MINUTES },
                        );
                        this.setAutoTimers();
                    }),
                )
                .catch((error) => {
                    this.serviceErrorMessage = get(error, 'data.message', error.message);
                    this.isLoading = false;
                });
        },
        onInfoSubmit() {
            this.isLoading = true;
            this.$refs.billingAddressFormRef.triggerFormSubmit()
                // nextTick required to allow address change to propagate to CreditCardForm
                .then(() => this.$nextTick())
                .then(() => this.submitCreditCardForm())
                .catch((error) => {
                    this.serviceErrorMessage = get(error, 'data.message', error?.message);
                    this.isLoading = false;
                });
        },
        onRefresh(isManual = true) {
            this.isLoading = true;

            getProfile(this.$r15Svc).then(
                (response) => {
                    setTimeout(() => {
                        if (response.status.subscriptionStatus !== SUBSCRIPTION_STATUSES.overdue) {
                            this.$store.commit(SET_IS_OVERDUE, false);

                            return;
                        }

                        if (isManual) {
                            this.setAutoTimers();
                        }

                        this.isLoading = false;
                    }, 750);
                })
                .catch((error) => {
                    this.serviceErrorMessage = get(error, 'data.message', error.message);
                    this.isLoading = false;
                });
        },
        setAutoTimers() {
            if (!this.timeoutOneMin) {
                this.timeoutOneMin = setTimeout(() => {
                    this.timeoutOneMin = null;
                    this.onRefresh(false);
                }, MILLISECONDS_IN_1_MINUTE);
            }
            if (!this.timeoutThreeMins) {
                this.timeoutThreeMins = setTimeout(() => {
                    this.timeoutThreeMins = null;
                    this.onRefresh(false);
                }, MILLISECONDS_IN_1_MINUTE * 3);
            }
            if (!this.timeoutFiveMins) {
                this.timeoutFiveMins = setTimeout(() => {
                    this.timeoutFiveMins = null;
                    this.onRefresh(false);
                }, MILLISECONDS_IN_1_MINUTE * 5);
            }
        },
    },
};
</script>

<style lang="scss">

</style>
