// We ignore the entire file from unit test coverage because it will be
// sufficiently tested via integration tests

/* istanbul ignore file */

// Needed for async/await in browser
import 'regenerator-runtime/runtime';

import { globalMiddleware, initializeClientWithOpts } from '~coreModules/core/js/entry-client-utils';

import createApp from '~modules/core/js/create-app';
import logger from '~modules/core/js/logger';

async function middleware(to, from, store) {
    await globalMiddleware(to, from, store);
}

const opts = {
    logger,
    middleware,
};

initializeClientWithOpts(createApp, opts);
