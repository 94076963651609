<template>
    <div class="c-footer-help">
        <div
            v-for="(linkGroup, index) in footerNav.supportLinks.slice(0, 3)"
            :key="linkGroup.displayName"
            class="c-footer-help__link-group-container"
            :class="{ 'is-open': linkGroupVisible[index] }"
        >
            <div
                v-tab-focus.hasindex="() => isAccordion ? toggleLinkGroup(index) : null"
                :tabindex="isAccordion ? '0' : '-1'"
                class="c-footer-help__link-group-header"
                :aria-expanded="!isAccordion || `${linkGroupVisible[index]}`"
            >
                <h5>
                    {{ linkGroup.displayName }}
                </h5>
                <div class="c-footer-help__link-group-toggle">
                    <BaseIcon
                        :icon="linkGroupVisible[index] ? 'global--minus' : 'global--plus'"
                        size="16px"
                        color="white"
                    />
                </div>
            </div>
            <ul
                :aria-hidden="`${!linkGroupVisible[index]}`"
                :class="{ 'is-open': linkGroupVisible[index] }"
                class="c-footer-help__link-group"
            >
                <li
                    v-for="(link, innerIndex) in linkGroup.children"
                    :key="`link-${innerIndex}`"
                    class="c-footer-help__link-container"
                >
                    <BaseLink
                        :to="getLinkUrl(link)"
                        :target="link.target"
                        :class="{
                            'o-text--link': true,
                            'o-text--caption': true,
                            'is-light': true,
                            'is-active': $route.path === link.targetUrl || $route.path === link.slug
                        }"
                        :tabindex="(!isAccordion || linkGroupVisible[index]) ? '0' : '-1'"
                        @click="emitClick(link)"
                    >
                        {{ link.displayName }}
                    </BaseLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseFooterHelpLinks',
    props: {
        footerNav: {
            type: Object,
            required: true,
        },
    },
    emits: ['click'],
    data() {
        return {
            linkGroupVisible: Array(3).fill(false),
            isAccordion: true,
        };
    },
    watch: {
        '$mediaQueries.isSmallish': function setAccordionState(wasSmall, isSmall) {
            // if the screen size has just changed to/from smallish, reset accordion
            if ((!wasSmall && isSmall) || (wasSmall && !isSmall)) {
                this.linkGroupVisible = Array(3).fill(false);
            }
        },
    },
    mounted() {
        this.isAccordion = this.$mediaQueries.isSmallish;
    },
    methods: {
        toggleLinkGroup(index) {
            this.linkGroupVisible[index] = !this.linkGroupVisible[index];
        },
        getLinkUrl(link) {
            return link.targetUrl || link.slug;
        },
        emitClick(link) {
            this.$emit('click', link);
        },
    },
};
</script>

<style lang="scss">
    .c-footer-help {
        $this: &;

        margin-bottom: $nu-spacer-4;
        flex-direction: column;

        @include breakpoint(medium) {
            flex-direction: row;
            margin-bottom: 0;
        }

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        &,
        &__link-group-container,
        &__link-group-header {
            display: flex;
        }

        &__link-group-container {
            flex-direction: column;
            flex-basis: 100%;

            &.is-open {
                #{$this}__link-group-toggle {
                    &--open {
                        display: none;
                    }

                    &--close {
                        display: block;
                    }
                }
            }

            &:not(.is-open),
            &.is-open {
                @include breakpoint(medium) {
                    #{$this}__link-group-toggle {
                        &--open,
                        &--close {
                            display: none;
                        }
                    }
                }
            }
        }

        &__link-group-header {
            justify-content: space-between;
            align-items: center;
            height: $nu-spacer-6;

            cursor: pointer;

            @include breakpoint(medium) {
                height: auto;
                cursor: auto;
                margin-bottom: $nu-spacer-2;

                &:focus {
                    outline: none;
                }
            }
        }

        &__link-group {
            height: 0;
            overflow: hidden;
            margin: 0 0 0 $nu-spacer-1;

            &.is-open {
                height: auto;
                margin: $nu-spacer-1pt5 0;
            }

            @include breakpoint(medium) {
                height: auto;

                &,
                &.is-open {
                    margin-left: 0;
                }
            }
        }

        &__link-group-toggle {
            display: block;
            margin: $nu-spacer-2;

            @include breakpoint(medium) {
                display: none;
            }
        }

        &__link-container {
            &:not(:last-of-type) {
                margin-bottom: $nu-spacer-1;
            }
        }
    }
</style>
