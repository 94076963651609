import { inject } from 'vue';

export const contentfulSvcInjectionKey = Symbol('contentfulSvc');

/**
 * A composable that provides access to the contentful client
 * @function
 * @returns {Object} the injected contentfulSvc made available to the application
 */
export const useContentfulSvc = () => inject(contentfulSvcInjectionKey);

/**
 * Provides the contentful client to the application
 * @param {Object} app - the application instance
 * @param {Object} contentfulSvc - the contentfulSvc object to be provided
 * @returns {Object} the provided custom contentful client
 */
export function provideContentfulSvc(app, contentfulSvc) {
    // provide the contentfulSvc to the app so it can be used in script setup
    app.provide(contentfulSvcInjectionKey, contentfulSvc);
    return contentfulSvc;
}
