<script>
import { h, resolveDirective, withDirectives } from 'vue';

// This component is used for BaseInput.vue so that we can set directives dynamically;
import formFieldProps from '~coreModules/core/components/mixins/formFieldProps';
import inputProps from '~coreModules/core/components/mixins/inputProps';

export default {
    name: 'BaseInputField',
    mixins: [formFieldProps, inputProps],
    props: {
        inputType: {
            type: String,
            required: true,
        },
        formTarget: {
            type: String,
            default: null,
        },
        mask: {
            type: [Array, String, Object],
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
    },
    render() {
        const input = h('input', {
            class: {
                'c-form__input': true,
                'c-form__input--error': this.errorText !== '',
            },
            type: this.inputType,
            name: this.name,
            id: this.id,
            'aria-label': this.labelText,
            required: this.isRequired,
            disabled: this.isDisabled,
            placeholder: this.helperText,
            tabindex: this.$attrs.tabindex,
            value: this.value,
            form: this.formTarget,
            maxlength: this.maxLength,
            autocomplete: this.autocomplete,
        });
        // we can set directives dynamically;
        const directives = this.mask ? [[resolveDirective('maska'), this.mask]] : [];

        return withDirectives(input, directives);
    },
};
</script>

<style lang="scss">

</style>
