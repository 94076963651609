import { shapeContentfulAppConfig } from '~coreModules/contentful/js/contentful-utils';
import { HEADER_GCLB_GEOLOCATION } from '~coreModules/site-settings/js/site-settings-constants';

export const SITE_SETTINGS_MODULE_NAME = 'siteSettings';

export const GET_CONTENTFUL_SITE_CONFIG = 'GET_CONTENTFUL_SITE_CONFIG';
export const SET_CONTENTFUL_SITE_CONFIG = 'SET_CONTENTFUL_SITE_CONFIG';
export const SET_GEOLOCATION = 'SET_GEOLOCATION';

export default function createSiteSettings(logger, config) {
    return {
        namespaced: true,

        state: {
            contentfulAppConfig: null,
            countryCode: config.defaultCountry,
            currency: config.defaultCurrency,
            geolocation: {
                country: '', // client_region = country (ISO-3166-2 codes)
                region: '', // client_region_subdivision = state or province (ISO-3166-2 standard)
                city: '', // client_city = city
            },
            language: config.defaultLanguage,
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            [SET_CONTENTFUL_SITE_CONFIG](state, contentfulAppConfig) {
                state.contentfulAppConfig = shapeContentfulAppConfig(contentfulAppConfig);
            },
            [SET_GEOLOCATION](state, geolocationHeader) {
                // set default header for local dev
                if (this.$userState?.hostname === 'localhost' && process.env.NUU_GCLB_MOCK_GEOLOCATION_HEADER) {
                    geolocationHeader = process.env.NUU_GCLB_MOCK_GEOLOCATION_HEADER;
                    this.$logger.info(`mocking users ${HEADER_GCLB_GEOLOCATION}: ${geolocationHeader}`);
                }

                // geolocationHeader = {client_region},{client_region_subdivision},{client_city}
                const areas = (geolocationHeader || '').split(',');
                const country = areas?.[0] || ''; // client_region from google load balancer header
                let region = areas?.[1] || ''; // client_region_subdivision from google load balancer header
                const city = areas?.[2] || ''; // client_city from google load balancer header

                if (region && region.length > 2) {
                    // remove country code from client_region_subdivision ex: USPA -> PA (ISO-3166-2 standard)
                    region = region.slice(2, region.length);
                }

                state.geolocation = {
                    country,
                    region,
                    city,
                };
            },
        },

        actions: {},

        getters: {
            activeTests(state) {
                return state.contentfulAppConfig?.activeTests || [];
            },
            complianceActive(state) {
                return state.contentfulAppConfig?.complianceActive || false;
            },
            notifications(state) {
                return state.contentfulAppConfig?.notifications || [];
            },
            anonymousGlobalPromoBanners(state) {
                return state.contentfulAppConfig?.anonymousGlobalPromoBanners || [];
            },
        },
    };
}
