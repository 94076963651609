export default {
    props: {
        // This is the optional text which appears as a placeholder when the input is empty and focused
        helperText: {
            type: String,
            default: '',
        },
        maxLength: {
            type: Number,
            default: null,
        },
        autocomplete: {
            type: [Boolean, String],
            default: '',
        },
    },
};
