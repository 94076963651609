<i18n>
[
    "header__browse--label",
    "header__menu--label",
]
</i18n>

<template>
    <header ref="headerRef" class="c-header">
        <div class="c-header__left-nav">
            <div class="c-header__menu-container">
                <NavigationMenuLauncher :modalOptions="modalOptions" class="c-header__menu-button">
                    <span
                        :class="[
                            'c-header__menu-label-text',
                            'c-header__menu-label-text--desktop',
                        ]"
                    >
                        {{ $t('header__browse--label') }}
                    </span>
                    <span
                        :class="[
                            'o-text--caption',
                            'c-header__menu-label-text',
                            'c-header__menu-label-text--mobile'
                        ]"
                    >
                        {{ $t('header__menu--label') }}
                    </span>
                </NavigationMenuLauncher>
            </div>
            <div class="c-header__search-container">
                <slot name="search"></slot>
            </div>
        </div>
        <div class="c-header__logo-container">
            <slot name="logo"></slot>
        </div>
        <div class="c-header__utils-container">
            <slot name="utils"></slot>
        </div>
    </header>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import { HEADER_MODULE_NAME, SET_HEADER_HEIGHT } from '~coreModules/header/js/header-store';

import NavigationMenuLauncher from '~coreModules/nav/components/NavigationMenuLauncher.vue';

export default {
    name: 'CoreHeader',
    components: {
        NavigationMenuLauncher,
    },
    props: {
        modalOptions: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(HEADER_MODULE_NAME, {
            headerHeight: state => state.headerHeight,
        }),
    },
    watch: {
        '$mediaQueries.currentBreakpoint': 'onBreakpointChange',
    },
    mounted() {
        const currentHeaderHeight = this.$refs.headerRef.offsetHeight;
        this.setHeaderHeight(currentHeaderHeight);
    },
    methods: {
        ...mapMutations(HEADER_MODULE_NAME, {
            setHeaderHeight: SET_HEADER_HEIGHT,
        }),
        onBreakpointChange() {
            const currentHeaderHeight = this.$refs.headerRef.offsetHeight;
            if (currentHeaderHeight !== this.headerHeight) {
                this.setHeaderHeight(currentHeaderHeight);
            }
        },
    },
};

</script>

<style lang="scss">
.c-header {
    $this: &;

    display: flex;
    padding: 0 $nu-spacer-1;
    max-width: 100vw;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 48px;
    z-index: map-get($zindex, topnav);
    background-color: $nu-white;
    color: $nu-primary;

    @include breakpoint(medium) {
        height: 64px;
        padding: 0 $nu-spacer-3;
    }

    &__left-nav,
    &__logo-container,
    &__utils-container {
        height: 100%;

        &,
        #{$this}__menu-container {
            display: flex;
            align-items: center;
        }
    }

    &__logo-container {
        flex-basis: 64px;
    }

    &__left-nav,
    &__utils-container {
        flex-basis: calc(50% - 32px);
    }

    &__left-nav {
        justify-content: flex-start;
        justify-self: flex-start;
    }

    &__search-container {
        display: inline-block;
        margin-left: $nu-spacer-3;
        width: 75%;
    }

    &__logo-container {
        justify-content: center;
        justify-self: center;
    }

    &__utils-container {
        justify-content: flex-end;
        justify-self: flex-end;
    }

    &__logo {
        height: 16px;
        fill: $nu-primary;

        @include breakpoint(medium) {
            height: 24px;
        }
    }
}
</style>
