function getLogger(config) {
    /* eslint-disable no-console */
    let logger;

    if (process.env.VUE_ENV === 'client' || process.env.NODE_ENV === 'local' || process.env.APP_ENV === 'local') {
        const noop = () => {};

        const isProd = process.env.APP_ENV === 'production';

        /* istanbul ignore next */
        logger = {
            debugError:
                !isProd && config.logLevel === 'debug' ?
                    console.error.bind(console) :
                    noop,
            debug:
                !isProd && config.logLevel === 'debug' ?
                    console.debug.bind(console) :
                    noop,
            info:
                !isProd && (config.logLevel === 'debug' ||
                config.logLevel === 'info') ?
                    console.info.bind(console) :
                    noop,
            log:
                !isProd && (config.logLevel === 'debug' ||
                config.logLevel === 'info' ||
                config.logLevel === 'log') ?
                    console.log.bind(console) :
                    noop,
            warn:
                !isProd && (config.logLevel === 'debug' ||
                config.logLevel === 'info' ||
                config.logLevel === 'log' ||
                config.logLevel === 'warn') ?
                    console.warn.bind(console) :
                    noop,
            error:
                (config.logLevel === 'debug' ||
                config.logLevel === 'info' ||
                config.logLevel === 'log' ||
                config.logLevel === 'warn' ||
                config.logLevel === 'error') ?
                    console.error.bind(console) :
                    noop,
        };
    } else {
        // eslint-disable-next-line global-require
        const winston = require('winston');

        logger = winston.createLogger({
            level: config.logLevel,
            levels: {
                error: 0,
                warn: 1,
                info: 2,
                verbose: 3,
                debug: 4,
                debugError: 5,
            },
            format: winston.format.json(),
            defaultMeta: { service: 'ui' },
            transports: [
                new winston.transports.Console(),
            ],
        });
    }

    return logger;
}

module.exports = getLogger;
