<template>
    <BaseIcon
        :appIcons="appIcons"
        :icon="icon"
        :color="color"
        :size="size"
        :isCoreDir="isCoreDir"
        :orientation="orientation"
        :transitionDuration="transitionDuration"
    />
</template>
<script>
import { APP_ICONS } from '~modules/core/js/svg-constants';

export default {
    name: 'AppIcon',
    props: {
        // icon must be present in the map APP_ICONS (svg-constants.js)
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            required: true,
        },
        isCoreDir: {
            type: Boolean,
            default: true,
        },
        orientation: {
            type: String,
            default: 'up',
        },
        // duration of CSS transitions in milliseconds (e.g. color or rotation). Default is 200, 0 is disabled
        transitionDuration: {
            type: [Number, String],
            default: 200,
        },
    },
    data() {
        return {
            appIcons: APP_ICONS,
        };
    },
};
</script>
