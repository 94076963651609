import {
    sendVerificationEmail,
    sendVerificationSms,
    verifyPhoneLogin,
    verifyEmailLogin,
    logoutUser,
} from '~coreRoutes/login/js/login-api';

import { APP_LOGOUT_ACTIONS, GLOBAL_EVENT } from '~coreModules/core/js/store';

import { LOGIN_STARTED, LOGIN_COMPLETED, LOGOUT } from '~coreModules/core/js/global-event-constants';

export const LOGIN_MODULE_NAME = 'login';
export const SET_CLOSE_LINK = 'SET_CLOSE_LINK';
export const SET_LOGIN_PHONE_NUMBER = 'SET_LOGIN_PHONE_NUMBER';
export const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL';

export const SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL';
export const SEND_VERIFICATION_SMS = 'SEND_VERIFICATION_SMS';
export const SUBMIT_PHONE_LOGIN = 'SUBMIT_PHONE_LOGIN';
export const SUBMIT_EMAIL_LOGIN = 'SUBMIT_EMAIL_LOGIN';
export const LOG_OUT = 'LOG_OUT';

export default {
    namespaced: true,

    state: () => ({
        closeLink: '/',
        phoneNumber: '',
        email: '',
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_CLOSE_LINK](state, newCloseLink) {
            state.closeLink = newCloseLink;
        },
        [SET_LOGIN_PHONE_NUMBER](state, phoneNumber) {
            state.phoneNumber = phoneNumber;
        },
        [SET_LOGIN_EMAIL](state, email) {
            state.email = email;
        },
    },

    actions: {
        [SEND_VERIFICATION_EMAIL]({ commit, dispatch }, email) {
            if (!email) {
                throw Error('Expected parameter email');
            }

            commit(SET_LOGIN_EMAIL, email);
            return sendVerificationEmail(this.$r15Svc, email)
                .then(() => {
                    dispatch(GLOBAL_EVENT, {
                        type: LOGIN_STARTED,
                        data: {
                            label: 'email',
                        },
                    },
                    { root: true });
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to send a verification email: ', error);
                    return Promise.reject(error);
                });
        },
        [SEND_VERIFICATION_SMS]({ commit, dispatch }, phoneNumber) {
            if (!phoneNumber) {
                throw Error('Expected parameter phoneNumber');
            }

            commit(SET_LOGIN_PHONE_NUMBER, phoneNumber);
            return sendVerificationSms(this.$r15Svc, phoneNumber)
                .then(() => {
                    dispatch(GLOBAL_EVENT, {
                        type: LOGIN_STARTED,
                        data: {
                            label: 'phone',
                        },
                    },
                    { root: true });
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to send a verification sms: ', error);
                    return Promise.reject(error);
                });
        },
        [SUBMIT_PHONE_LOGIN]({ state, dispatch }, verificationCode) {
            if (!verificationCode) {
                throw Error('Expected parameter verificationCode');
            }

            const { phoneNumber } = state;
            return verifyPhoneLogin(this.$r15Svc, phoneNumber, verificationCode)
                .then(() => {
                    dispatch(GLOBAL_EVENT, {
                        type: LOGIN_COMPLETED,
                        data: {
                            label: 'phone',
                        },
                    }, { root: true });
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to verify a login via sms code: ', error);
                    return Promise.reject(error);
                });
        },
        [SUBMIT_EMAIL_LOGIN]({ dispatch }, { email, verificationCode }) {
            if (!email) {
                throw Error('Expected parameter email');
            }

            if (!verificationCode) {
                throw Error('Expected parameter verificationCode');
            }

            return verifyEmailLogin(this.$r15Svc, email, verificationCode)
                .then(() => {
                    dispatch(GLOBAL_EVENT, {
                        type: LOGIN_COMPLETED,
                        label: 'email',
                    }, { root: true });
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to verify a login via email: ', error);
                    return Promise.reject(error);
                });

        },
        async [LOG_OUT]({ dispatch, rootGetters }, redirectUrl = '') {
            const { appBaseUrl } = rootGetters;
            // TODO: maybe check if user is logged in first?
            // TODO: on failure of logout (service is down), potentially delete the auth cookie via express
            // https://urbnit.atlassian.net/browse/TYP-3035

            await logoutUser(this.$r15Svc);
            await dispatch(GLOBAL_EVENT, { type: LOGOUT }, { root: true });
            await dispatch(APP_LOGOUT_ACTIONS, null, { root: true });

            window.location.href = redirectUrl || appBaseUrl;
        },
    },
};
