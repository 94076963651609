import { NUULY_BUSINESS_TYPES } from '~coreModules/core/js/constants';

export const APP_BASE_URLS_MAP = {
    [NUULY_BUSINESS_TYPES.rental]: '/rent',
    [NUULY_BUSINESS_TYPES.common]: '/',
};

export const genericRouteNames = {
    home: 'home',
    help: {
        slug: 'help.slug',
    },
    login: {
        email: {
            base: 'login.email',
            sent: 'login.email.sent',
            verify: 'login.email.verify',
        },
        phone: {
            base: 'login.phone',
            verify: 'login.phone.verify',
        },
    },
    unsubscribe: 'unsubscribe',
    join: {
        base: 'join.base',
    },
    error: 'error',
};

export const scrollHashIds = {
    catalogActionBar: '#catalog-products',
};

export const errorRouteData = {
    path: '/500',
    name: genericRouteNames.error,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "unexpectedIssuePage" */
        '~coreModules/core/components/errors/Error500.vue'),
    meta: {
        analytics: {
            pageCategory: 'error',
            pageType: 'error',
        },
    },
};

export const loginRouteData = {
    path: '/login',
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "RouterViewContainer" */
        '~coreModules/core/components/RouterViewContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'login',
            pageType: 'login',
        },
    },
    children: [
        {
            path: 'email',
            name: genericRouteNames.login.email.base,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "LoginEmailForm" */
                '~coreRoutes/login/components/LoginEmailForm.vue'),
            meta: {
                analytics: {
                    pageCategory: 'login-email',
                },
                ignoreBaseLayout: true,
            },
        },
        {
            path: 'email/sent',
            name: genericRouteNames.login.email.sent,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "LoginEmailSent" */
                '~coreRoutes/login/components/LoginEmailSent.vue'),
            meta: {
                analytics: {
                    pageCategory: 'login-email',
                },
                ignoreBaseLayout: true,
            },
        },
        {
            path: 'email/verify',
            name: genericRouteNames.login.email.verify,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "LoginEmailVerify" */
                '~coreRoutes/login/components/LoginEmailVerify.vue'),
            meta: {
                analytics: {
                    pageCategory: 'login-email',
                },
                ignoreBaseLayout: true,
            },
        },
        {
            path: 'phone',
            name: genericRouteNames.login.phone.base,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "LoginPhoneForm" */
                '~coreRoutes/login/components/LoginPhoneForm.vue'),
            meta: {
                analytics: {
                    pageCategory: 'login-phone',
                },
                ignoreBaseLayout: true,
            },
        },
        {
            path: 'phone/verify',
            name: genericRouteNames.login.phone.verify,
            component: /* istanbul ignore next */ () => import(
                /* webpackChunkName: "LoginPhoneVerify" */
                '~coreRoutes/login/components/LoginPhoneVerify.vue'),
            meta: {
                analytics: {
                    pageCategory: 'login-phone',
                },
                ignoreBaseLayout: true,
            },
        },
    ],
};

export const helpRouteData = {
    path: '/help/:slug?',
    name: genericRouteNames.help.slug,
    meta: {
        displayName: 'Overview',
        analytics: {
            pageCategory: 'help',
            pageType: 'help',
        },
    },
};

export const joinRouteData = {
    path: '/join',
    name: genericRouteNames.join.base,
    meta: {
        ignoreBaseLayout: true,
        analytics: {
            pageType: 'join',
        },
        sectionSlideCounts: {},
    },
};

export const catchAllRouteData = {
    path: '/:slug(.*)',
    meta: {
        analytics: {
            pageCategory: 'content',
            pageType: 'content',
        },
    },
};
