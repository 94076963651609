import { BOX_RECEIPT_PENDING_STATUSES, SUBSCRIPTION_STATUSES } from '~modules/profile/js/profile-constants';
import { MAX_AGE_2_WEEKS } from '~coreModules/core/js/cookie-constants';

export const NUU_COOKIE_BONUS_ITEM_COUNTER_EXPIRY = MAX_AGE_2_WEEKS;

export const BOX_MIN_ITEM_LIMIT_DEFAULT = 6;
export const BOX_MAX_ITEM_LIMIT_DEFAULT = 6;

export const BOX_INVENTORY_QUERY_BOX_ID = 'boxId';

export const BOX_ORDER_STATUSES = {
    // User has an available box, which can be requested once it is filled
    OPEN: 'OPEN',
    /* Box order has been placed, and is being processed */
    REQUESTED: 'REQUESTED',
    /* Box order has already been processed, and is being shipped */
    SHIPPED: 'SHIPPED',
    /* Box order is en route to the user */
    IN_TRANSIT: 'IN_TRANSIT',
    /* Box order has been delivered to the customer */
    DELIVERED: 'DELIVERED',
    /* Box order is on the way back to the DC */
    RETURN_STARTED: 'RETURN_STARTED',
    /* Box order has been successfully returned to the DC */
    RETURNED: 'RETURNED',
    /* Box order cannot be delivered due to an address issue */
    ADDRESS_EXCEPTION: 'ADDRESS_EXCEPTION',
    /* Box order was returned, but there is at least 1 item missing */
    PRODUCT_EXCEPTION: 'PRODUCT_EXCEPTION',
    /* Box order has been cancelled */
    CANCELLED: 'CANCELLED',
};

export const ORDERED_BOX_STATUSES = [
    BOX_ORDER_STATUSES.REQUESTED,
    BOX_ORDER_STATUSES.SHIPPED,
    BOX_ORDER_STATUSES.IN_TRANSIT,
    BOX_ORDER_STATUSES.DELIVERED,
    BOX_ORDER_STATUSES.RETURN_STARTED,
    BOX_ORDER_STATUSES.RETURNED,
    BOX_ORDER_STATUSES.ADDRESS_EXCEPTION,
    BOX_ORDER_STATUSES.PRODUCT_EXCEPTION,
    BOX_ORDER_STATUSES.CANCELLED,
];

export const BOX_STATUSES = {
    /* box is open, and can have sku's added to it, has not been ordered yet */
    OPEN: 'OPEN',
};

export const BOX_STATUSES_MAP = {
    /* box is open, and can have sku's added to it, has not been ordered yet */
    OPEN: 'BOX_OPEN',
};

export const ORDER_ITEM_STATUSES = {
    /* Box order has been placed, and is being processed */
    OPEN: 'OPEN',
    /* Box order has already been processed, and is being shipped */
    SHIPPED: 'SHIPPED',
    /* Box order has been delivered to the customer */
    DELIVERED: 'DELIVERED',
    /* Box order has some sort of issue with it */
    EXCEPTION: 'EXCEPTION',
    /* Box order has been shipped, and retuned by the user */
    CLOSED: 'CLOSED',
    /* Box order has been successfully returned by the user */
    RETURNED: 'RETURNED',
    /* Box order was lost by carrier or by customer */
    LOST: 'LOST',
    /* Box order was purchased */
    PURCHASED: 'PURCHASED',
    /* Box order has been cancelled */
    CANCELLED: 'CANCELLED',
};

export const BOX_ORDER_CHARGE_TYPES = {
    bonusItem: 'add_on_item',
};

export const BOX_TILE_TYPE_OPEN = 'BOX_TILE_TYPE_OPEN';
export const BOX_TILE_TYPE_REQUESTED = 'BOX_TILE_TYPE_REQUESTED';
export const BOX_TILE_TYPE_AT_HOME = 'BOX_TILE_TYPE_AT_HOME';
export const BOX_TILE_TYPE_PURCHASED = 'BOX_TILE_TYPE_PURCHASED';
export const BOX_TILE_SKU_TYPE_CANCEL = 'BOX_TILE_SKU_TYPE_CANCEL';
export const BOX_TILE_TYPE_REVIEW_SELECT = 'BOX_TILE_TYPE_REVIEW_SELECT';
export const BOX_TILE_TYPE_SELECTED = 'BOX_TILE_TYPE_SELECTED';
export const BOX_TILE_TYPE_RETURNED = 'BOX_TILE_TYPE_RETURNED';
export const BOX_TILE_TYPE_LOST = 'BOX_TILE_TYPE_LOST';
export const BOX_TILE_TYPE_EXCEPTION = 'BOX_TILE_TYPE_EXCEPTION';
export const BOX_TILE_TYPE_NO_BUTTONS = 'BOX_TILE_TYPE_NO_BUTTONS';
export const BOX_TILE_TYPE_REVIEW = 'BOX_TILE_TYPE_REVIEW';
export const BOX_TILE_TYPE_CANCELLED = 'BOX_TILE_TYPE_CANCELLED';
export const BOX_TILE_TYPE_PRE_PURCHASED_OPEN = 'BOX_TILE_TYPE_PRE_PURCHASED_OPEN';
export const BOX_TILE_TYPE_PRE_PURCHASED_PURCHASED = 'BOX_TILE_TYPE_PRE_PURCHASED_PURCHASED';
export const BOX_TILE_TYPE_PRE_PURCHASED_RETURNED = 'BOX_TILE_TYPE_PRE_PURCHASED_RETURNED';
export const BOX_TILE_TYPE_PRE_PURCHASED_LOST = 'BOX_TILE_TYPE_PRE_PURCHASED_LOST';
export const BOX_TILE_TYPE_PRE_PURCHASED_CANCELLED = 'BOX_TILE_TYPE_PRE_PURCHASED_CANCELLED';

export const BOX_TILE_ACTIONS_BUTTON_EDIT = 'BOX_TILE_ACTIONS_BUTTON_EDIT';
export const BOX_TILE_ACTIONS_BUTTON_REMOVE = 'BOX_TILE_ACTIONS_BUTTON_REMOVE';
export const BOX_TILE_ACTIONS_BUTTON_BUY = 'BOX_TILE_ACTIONS_BUTTON_BUY';
export const BOX_TILE_ACTIONS_BUTTON_REVIEW = 'BOX_TILE_ACTIONS_BUTTON_REVIEW';
export const BOX_TILE_ACTIONS_BUTTON_CANCEL = 'BOX_TILE_ACTIONS_BUTTON_CANCEL';
export const BOX_TILE_ACTIONS_BUTTON_SELECT = 'BOX_TILE_ACTIONS_BUTTON_SELECT';
export const BOX_TILE_ACTIONS_BUTTON_SELECTED = 'BOX_TILE_ACTIONS_BUTTON_SELECTED';

const STEP_ONE_LABEL = 'box__checkForDropOffAnimation--stepOneLabel';
const STEP_TWO_LABEL = 'box__checkForDropOffAnimation--stepTwoLabel';

export const BOX_TILE_TYPES = [
    BOX_TILE_TYPE_OPEN,
    BOX_TILE_TYPE_REQUESTED,
    BOX_TILE_TYPE_AT_HOME,
    BOX_TILE_TYPE_PURCHASED,
    BOX_TILE_TYPE_REVIEW_SELECT,
    BOX_TILE_TYPE_SELECTED,
    BOX_TILE_TYPE_RETURNED,
    BOX_TILE_TYPE_LOST,
    BOX_TILE_TYPE_EXCEPTION,
    BOX_TILE_TYPE_NO_BUTTONS,
    BOX_TILE_TYPE_REVIEW,
    BOX_TILE_TYPE_PRE_PURCHASED_OPEN,
    BOX_TILE_TYPE_PRE_PURCHASED_PURCHASED,
    BOX_TILE_TYPE_PRE_PURCHASED_RETURNED,
    BOX_TILE_TYPE_PRE_PURCHASED_LOST,
    BOX_TILE_TYPE_PRE_PURCHASED_CANCELLED,
];

export const boxTileActionButtonsMap = {
    [BOX_TILE_ACTIONS_BUTTON_EDIT]: {
        textSlug: 'box__edit--label',
        eventToEmit: 'edit-item',
    },
    [BOX_TILE_ACTIONS_BUTTON_REMOVE]: {
        textSlug: 'global__remove',
        eventToEmit: 'remove-item',
    },
    [BOX_TILE_ACTIONS_BUTTON_BUY]: {
        textSlug: 'box__buy--label',
        eventToEmit: 'buy-item',
    },
    [BOX_TILE_ACTIONS_BUTTON_REVIEW]: {
        textSlug: 'box__review--label',
        eventToEmit: 'review-item',
    },
    [BOX_TILE_ACTIONS_BUTTON_CANCEL]: {
        textSlug: 'global__cancel',
        eventToEmit: 'cancel-sku',
    },
    [BOX_TILE_ACTIONS_BUTTON_SELECT]: {
        textSlug: 'global__select',
        eventToEmit: 'select-item',
    },
    [BOX_TILE_ACTIONS_BUTTON_SELECTED]: {
        textSlug: 'global__selected',
        eventToEmit: 'select-item',
    },
};

export const BOX_ITEM_LOST_ERROR_CONTENTFUL_SLUG = 'box__lost--label';
export const BOX_ITEM_EXCEPTION_ERROR_CONTENTFUL_SLUG = 'box__itemException--label';

export const BOX_ITEM_RETURNED_STATUS_SLUG = 'box__itemReturned--status';
export const BOX_ITEM_CANCELLED_STATUS_SLUG = 'global__cancelled';
export const BOX_ITEM_PURCHASED_STATUS_SLUG = 'box__purchased--label';
export const BOX_ITEM_REQUESTED_STATUS_SLUG = 'box__requested--status';
export const BOX_ITEM_PRE_PURCHASE_STATUS_SLUG = 'product__prepurchase--itemDetailsCardFinalSaleText';

export const boxTileTypesMap = {
    [BOX_TILE_TYPE_OPEN]: {
        type: BOX_TILE_TYPE_OPEN,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_EDIT],
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_REMOVE],
        ],
    },
    [BOX_TILE_TYPE_REQUESTED]: {
        type: BOX_TILE_TYPE_REQUESTED,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        actionButtons: [],
        statusText: BOX_ITEM_REQUESTED_STATUS_SLUG,
    },
    [BOX_TILE_TYPE_AT_HOME]: {
        type: BOX_TILE_TYPE_AT_HOME,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: true,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_REVIEW],
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_BUY],
        ],
    },
    [BOX_TILE_TYPE_PURCHASED]: {
        type: BOX_TILE_TYPE_PURCHASED,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_REVIEW],
        ],
        statusText: BOX_ITEM_PURCHASED_STATUS_SLUG,
    },
    [BOX_TILE_SKU_TYPE_CANCEL]: {
        type: BOX_TILE_SKU_TYPE_CANCEL,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: true,
        showPrePurchase: false,
        showPrice: false,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_CANCEL],
        ],
    },
    [BOX_TILE_TYPE_REVIEW_SELECT]: {
        type: BOX_TILE_TYPE_REVIEW_SELECT,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        ignoreUserRating: true,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_SELECT],
        ],
    },
    [BOX_TILE_TYPE_SELECTED]: {
        type: BOX_TILE_TYPE_SELECTED,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        ignoreUserRating: true,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_SELECTED],
        ],
        buttonsActive: true,
    },
    [BOX_TILE_TYPE_RETURNED]: {
        type: BOX_TILE_TYPE_RETURNED,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_REVIEW],
        ],
        statusText: BOX_ITEM_RETURNED_STATUS_SLUG,
    },
    [BOX_TILE_TYPE_LOST]: {
        type: BOX_TILE_TYPE_LOST,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: true,
        actionButtons: [],
        errorMessage: BOX_ITEM_LOST_ERROR_CONTENTFUL_SLUG,
    },
    [BOX_TILE_TYPE_EXCEPTION]: {
        type: BOX_TILE_TYPE_EXCEPTION,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: true,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_REVIEW],
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_BUY],
        ],
        errorMessage: BOX_ITEM_EXCEPTION_ERROR_CONTENTFUL_SLUG,
    },
    [BOX_TILE_TYPE_NO_BUTTONS]: {
        type: BOX_TILE_TYPE_NO_BUTTONS,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        actionButtons: [],
    },
    [BOX_TILE_TYPE_REVIEW]: {
        type: BOX_TILE_TYPE_REVIEW,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_REVIEW],
        ],
    },
    [BOX_TILE_TYPE_CANCELLED]: {
        type: BOX_TILE_TYPE_CANCELLED,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: false,
        showPrice: false,
        actionButtons: [],
        statusText: BOX_ITEM_CANCELLED_STATUS_SLUG,
    },
    [BOX_TILE_TYPE_PRE_PURCHASED_OPEN]: {
        type: BOX_TILE_TYPE_PRE_PURCHASED_OPEN,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrice: false,
        showPrePurchase: true,
        actionButtons: [],
        statusText: BOX_ITEM_PRE_PURCHASE_STATUS_SLUG,
    },
    [BOX_TILE_TYPE_PRE_PURCHASED_PURCHASED]: {
        type: BOX_TILE_TYPE_PRE_PURCHASED_PURCHASED,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrice: false,
        showPrePurchase: true,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_REVIEW],
        ],
        statusText: BOX_ITEM_PRE_PURCHASE_STATUS_SLUG,
    },
    [BOX_TILE_TYPE_PRE_PURCHASED_RETURNED]: {
        type: BOX_TILE_TYPE_PRE_PURCHASED_PURCHASED,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrice: false,
        showPrePurchase: true,
        actionButtons: [
            boxTileActionButtonsMap[BOX_TILE_ACTIONS_BUTTON_REVIEW],
        ],
        statusText: BOX_ITEM_RETURNED_STATUS_SLUG,
    },
    [BOX_TILE_TYPE_PRE_PURCHASED_LOST]: {
        type: BOX_TILE_TYPE_PRE_PURCHASED_LOST,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: true,
        showPrice: true,
        actionButtons: [],
        errorMessage: BOX_ITEM_LOST_ERROR_CONTENTFUL_SLUG,
    },
    [BOX_TILE_TYPE_PRE_PURCHASED_CANCELLED]: {
        type: BOX_TILE_TYPE_PRE_PURCHASED_CANCELLED,
        showTitle: true,
        showImage: true,
        showSize: true,
        showViewSimilar: false,
        showPrePurchase: true,
        showPrice: false,
        actionButtons: [],
        statusText: BOX_ITEM_CANCELLED_STATUS_SLUG,
    },
};

export const BOX_MODAL_CONTENT_SLUGS = {
    renewEarly: 'box-renew-early',
};

export const CANCEL_REQUESTED_CONTENTFUL_SLUG = 'box-cancel-requested';
export const CANCELLED_CONTENTFUL_SLUG = 'box-cancelled';
export const NON_SUBSCRIBER_CONTENTFUL_SLUG = 'box-non-subscriber';
export const PAUSE_REQUESTED_CONTENTFUL_SLUG = 'box-pause-requested';
export const PAUSED_SUBSCRIBER_CONTENTFUL_SLUG = 'box-paused';
export const TERMINATED_CONTENTFUL_SLUG = 'box-terminated';
export const ANONYMOUS_CONTENTFUL_SLUG = 'box-anonymous';

export function getContentfulSlugForNonActiveSubscription(slug) {
    const nonActiveSubscriptionContentfulSlugs = {
        [SUBSCRIPTION_STATUSES.cancelled]: CANCELLED_CONTENTFUL_SLUG,
        [SUBSCRIPTION_STATUSES.cancelRequested]: CANCEL_REQUESTED_CONTENTFUL_SLUG,
        [SUBSCRIPTION_STATUSES.nonSubscriber]: NON_SUBSCRIBER_CONTENTFUL_SLUG,
        [SUBSCRIPTION_STATUSES.paused]: PAUSED_SUBSCRIBER_CONTENTFUL_SLUG,
        [SUBSCRIPTION_STATUSES.pauseRequested]: PAUSE_REQUESTED_CONTENTFUL_SLUG,
        [SUBSCRIPTION_STATUSES.terminated]: TERMINATED_CONTENTFUL_SLUG,
    };
    return nonActiveSubscriptionContentfulSlugs[slug] || '';
}

export const OPEN_BOX_CONTENTFUL_SLUGS = {
    empty: 'box-open-empty',
    filling: 'box-open-filling',
    full: 'box-open-full',
};

export const ORDERED_BOX_CONTENTFUL_SLUGS = {
    [BOX_ORDER_STATUSES.REQUESTED]: 'box-requested',
    [BOX_ORDER_STATUSES.SHIPPED]: 'box-shipped',
    [BOX_ORDER_STATUSES.IN_TRANSIT]: 'box-in-transit',
    [BOX_ORDER_STATUSES.DELIVERED]: 'box-delivered',
    [BOX_ORDER_STATUSES.RETURN_STARTED]: 'box-return-started',
    [BOX_ORDER_STATUSES.RETURNED]: 'box-returned',
    [BOX_ORDER_STATUSES.ADDRESS_EXCEPTION]: 'box-address-exception',
    [BOX_ORDER_STATUSES.PRODUCT_EXCEPTION]: 'box-product-exception',
    DELIEVERED_SWAP_ELIGIBLE: 'box-delivered-swap-eligible',
    [BOX_ORDER_STATUSES.CANCELLED]: 'box-order-cancelled',
    FAILED_TO_OPEN: 'box-failed-to-open',
};

export const BOX_RECIEPT_PENDING_STATUS_SLUGS = {
    [BOX_RECEIPT_PENDING_STATUSES.PAUSE_REQUESTED]: 'box-soft-pause',
    [BOX_RECEIPT_PENDING_STATUSES.CANCEL_REQUESTED]: 'box-soft-cancel',
};

export const BOX_CHECK_FOR_DROP_OFF_STATUSES = {
    DELIVERED_SWAP_INELIGIBLE: 'DELIVERED_SWAP_INELIGIBLE',
    DELIVERED_SWAP_ELIGIBLE: 'DELIVERED_SWAP_ELIGIBLE',
};

export const CHECK_FOR_DROP_OFF_CONTENTFUL_SLUGS = {
    [BOX_CHECK_FOR_DROP_OFF_STATUSES.DELIVERED_SWAP_INELIGIBLE]: 'box-check-for-drop-off-not-swap-eligible',
    [BOX_RECEIPT_PENDING_STATUSES.PAUSE_REQUESTED]: 'box-check-for-drop-off-pause-requested',
    [BOX_CHECK_FOR_DROP_OFF_STATUSES.DELIVERED_SWAP_ELIGIBLE]: 'box-check-for-drop-off-swap-eligible',
    [BOX_RECEIPT_PENDING_STATUSES.CANCEL_REQUESTED]: 'box-check-for-drop-off-cancel-requested',
};

export const BOX_STATUS_SLUGS = new Map([
    [ORDER_ITEM_STATUSES.OPEN, 'box__open--status'],
    [ORDER_ITEM_STATUSES.SHIPPED, 'box__shipped--status'],
    [ORDER_ITEM_STATUSES.EXCEPTION, 'box__exception--status'],
    [ORDER_ITEM_STATUSES.CLOSED, 'box__closed--status'],
    [ORDER_ITEM_STATUSES.CANCELLED, 'global__cancelled'],
]);

export const ORDER_BOX_TILE_TYPES = new Map([
    [ORDER_ITEM_STATUSES.OPEN, BOX_TILE_TYPE_REQUESTED],
    [ORDER_ITEM_STATUSES.SHIPPED, BOX_TILE_TYPE_AT_HOME],
    [ORDER_ITEM_STATUSES.RETURNED, BOX_TILE_TYPE_RETURNED],
    [ORDER_ITEM_STATUSES.LOST, BOX_TILE_TYPE_LOST],
    [ORDER_ITEM_STATUSES.EXCEPTION, BOX_TILE_TYPE_EXCEPTION],
    [ORDER_ITEM_STATUSES.PURCHASED, BOX_TILE_TYPE_PURCHASED],
    [ORDER_ITEM_STATUSES.CANCELLED, BOX_TILE_TYPE_CANCELLED],
]);

export const ORDER_BOX_PRE_PURCHASE_TILE_TYPES = new Map([
    [ORDER_ITEM_STATUSES.OPEN, BOX_TILE_TYPE_PRE_PURCHASED_OPEN],
    [ORDER_ITEM_STATUSES.PURCHASED, BOX_TILE_TYPE_PRE_PURCHASED_PURCHASED],
    [ORDER_ITEM_STATUSES.RETURNED, BOX_TILE_TYPE_PRE_PURCHASED_RETURNED],
    [ORDER_ITEM_STATUSES.LOST, BOX_TILE_TYPE_PRE_PURCHASED_LOST],
    [ORDER_ITEM_STATUSES.CANCELLED, BOX_TILE_TYPE_PRE_PURCHASED_CANCELLED],
]);

export const BOX_ICON_NAMES = Object.freeze({
    open: 'open',
    paused: 'paused',
    closed: 'closed',
    alert: 'alert',
    requested: 'requested',
    packed: 'packed',
    transit: 'in-transit',
    delivered: 'at-home',
    swap: 'early-return',
    pendingMessage: 'pending-message',
    bonusItemsMobile: 'bonus-items-mobile',
    bonusItemsDesktop: 'bonus-items-desktop',
});

export const BOX_ICON_NAMES_TO_ANIMATE = [
    BOX_ICON_NAMES.open,
    BOX_ICON_NAMES.bonusItemsMobile,
    BOX_ICON_NAMES.bonusItemsDesktop,
];

export const BOX_ICONS_TO_ANIMATE = BOX_ICON_NAMES_TO_ANIMATE.map(val => `box--${val}`);

export const REVIEWABLE_BOX_ORDER_STATUSES = [
    'DELIVERED',
    'RETURNED',
    'RETURN_STARTED',
];

export const RETURN_BOX_ORDER_STATUSES = [
    BOX_ORDER_STATUSES.RETURNED,
    BOX_ORDER_STATUSES.RETURN_STARTED,
];

export const RETURNABLE_BOX_ORDER_STATUSES = [
    BOX_ORDER_STATUSES.SHIPPED,
];

export const CARRIER_STATUSES = {
    LABEL_CREATED: 'LABEL_CREATED',
    DELIVERED: 'DELIVERED',
    MANIFEST: 'MANIFEST',
    IN_TRANSIT: 'IN_TRANSIT',
};

export const ORDER_TRACKING_STATUSES = {
    REQUESTED: 'REQUESTED',
    SHIPPED: 'SHIPPED',
    DELIVERED: 'DELIVERED',
    RETURN_IN_TRANSIT: 'RETURN_IN_TRANSIT',
    RETURNED: 'RETURNED',
    CANCELLED: 'CANCELLED',
    EXCEPTION: 'EXCEPTION',
};

export const ORDER_TRACKING_CONTENTFUL_SLUGS = {
    REQUESTED: 'global-orderOpen',
    SHIPPED: 'global-orderInTransit',
    DELIVERED: 'global-orderDelivered',
    RETURN_IN_TRANSIT: 'global-orderReturnInTransit',
    RETURNED: 'global-orderReturned',
    CANCELLED: 'global-orderCancelled',
    EXCEPTION: 'global-orderReturnException',
};

export const BOX_LAYOUT_TYPES = {
    DEFAULT: 'DEFAULT',
    CENTER: 'CENTER',
};

export const CHECK_FOR_DROP_OFF_STEPPER_STEPS = {
    [BOX_CHECK_FOR_DROP_OFF_STATUSES.DELIVERED_SWAP_INELIGIBLE]: [
        { step: 1, label: STEP_ONE_LABEL },
        { step: 2, label: STEP_TWO_LABEL },
        { step: 3, label: 'box__checkForDropOffAnimation--deliveredNotSwapEligibleStepThreeLabel' },
    ],
    [BOX_RECEIPT_PENDING_STATUSES.PAUSE_REQUESTED]: [
        { step: 1, label: STEP_ONE_LABEL },
        { step: 2, label: STEP_TWO_LABEL },
        { step: 3, label: 'box__checkForDropOffAnimation--pauseRequestedStepThreeLabel' },
    ],
    [BOX_CHECK_FOR_DROP_OFF_STATUSES.DELIVERED_SWAP_ELIGIBLE]: [
        { step: 1, label: STEP_ONE_LABEL },
        { step: 2, label: STEP_TWO_LABEL },
        { step: 3, label: 'box__checkForDropOffAnimation--deliveredSwapEligibleStepThreeLabel' },
    ],
    [BOX_RECEIPT_PENDING_STATUSES.CANCEL_REQUESTED]: [
        { step: 1, label: STEP_ONE_LABEL },
        { step: 2, label: STEP_TWO_LABEL },
        { step: 3, label: 'box__checkForDropOffAnimation--cancelRequestedStepThreeLabel' },
    ],
};

export const CHECK_FOR_DROP_OFF_ANIMATIONS_MAP = {
    [BOX_CHECK_FOR_DROP_OFF_STATUSES.DELIVERED_SWAP_INELIGIBLE]: async () => import(
        /* webpackMode: "lazy" */
        '~modules/box/js/lottie/eligible_to_renew_early.json'),
    [BOX_RECEIPT_PENDING_STATUSES.PAUSE_REQUESTED]: async () => import(
        /* webpackMode: "lazy" */
        '~modules/box/js/lottie/pause_requested.json'),
    [BOX_CHECK_FOR_DROP_OFF_STATUSES.DELIVERED_SWAP_ELIGIBLE]: async () => import(
        /* webpackMode: "lazy" */
        '~modules/box/js/lottie/eligible_to_unlock_early.json'),
    [BOX_RECEIPT_PENDING_STATUSES.CANCEL_REQUESTED]: async () => import(
        /* webpackMode: "lazy" */
        '~modules/box/js/lottie/cancel_requested.json'),
};
