export function getOnboardingRoutes(rootRoute) {
    const onboardingRoutes = { ...rootRoute };
    let slideOffset = 1;

    // flattening children onboarding routes so we properly decorate the next slide meta property
    const routes = onboardingRoutes.children.slice(0)
        .reduce((arr, { children }) => arr.concat(children), [])
        .filter(route => !route.redirect);

    routes.forEach((route, index) => {
        // eslint-disable-next-line no-param-reassign
        route.meta.nextSlide = routes[index + 1];

        if (routes[index - 1]) {
            // eslint-disable-next-line no-param-reassign
            route.meta.previousSlide = routes[index - 1];
        }
    });

    // generating onboarding routes, decorating meta property with information to render a progress bar
    onboardingRoutes.children.forEach((routeSection) => {
        // filtering out routes that have an empty path (which means they are
        // duplicate routes that only meant to set a default route for the parent route)
        const childRoutes = routeSection.children.filter(route => !route.redirect && route.path !== 'intro');
        const sectionSlideCount = childRoutes.length;

        onboardingRoutes.meta.sectionSlideCounts[routeSection.path] = sectionSlideCount;

        childRoutes.forEach((route, index) => {
            const offsetIndex = index + slideOffset;
            // eslint-disable-next-line no-param-reassign
            route.meta.currentSlide = offsetIndex;
        });

        /* adding offset so currentSlide accounts for previous route sections  */
        /* adding +1 to account for non-route 'confirmation/success' slides */
        slideOffset += (sectionSlideCount + 1);
    });

    return onboardingRoutes;
}

export function formatPhoneNumber(phoneNumber) {
    let formattedNumber = phoneNumber;
    const match = formattedNumber.match(/^(\+1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        formattedNumber = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return formattedNumber;
}
