<template>
    <div class="c-route-container">
        <GlobalLoader :showLoader="showLoader" />
        <RouterView v-slot="{ Component }">
            <Transition
                name="c-route-container__transition"
                mode="out-in"
                @before-enter="beforeEnter"
            >
                <component
                    :is="Component"
                    :key="routerViewKey"
                    :class="{ 'o-view--is-shown': showContent }"
                    class="o-view"
                />
            </Transition>
        </RouterView>
    </div>
</template>

<script>
import { get, isEqual } from 'lodash-es';

import { CANCEL_NAVIGATION } from '~coreModules/core/js/store';
import GlobalLoader from '~coreModules/core/components/ui/GlobalLoader.vue';

export default {
    name: 'RouteContentLoader',
    components: {
        GlobalLoader,
    },
    props: {
        ignoreBaseLayout: {
            type: Boolean,
            default: false,
        },
        routeIsLoading: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['contentIsDoneRendering'],
    data() {
        return {
            contentIsDoneRendering: false,
            minimumLoaderTime: 1000,
            minimumRenderTime: 300,
            minimumLoaderAnimationTime: 500,
            hasMetMinimLoaderAnimationTime: false,
            hasMetMinimLoaderDelay: true,
            hasMetMinimRenderTime: false,
            loaderIsActive: false,
            isInitialRender: true,
            loaderHasMetMinAnimationTime: true,
        };
    },
    computed: {
        routerViewKey() {
            return this.ignoreBaseLayout ||
                this.$route.meta?.preventGlobalLoaderOnChildRouteChange ? '' : this.$route.path;
        },
        showLoader() {
            return ((this.hasMetMinimLoaderDelay && !this.contentIsDoneRendering) ||
                this.isInitialRender ||
                /* make sure loader stays for set amount of time once it shows up */
                (this.hasMetMinimLoaderDelay && !this.loaderHasMetMinAnimationTime && this.contentIsDoneRendering));
        },
        showContent() {
            return (!(!this.loaderHasMetMinAnimationTime && this.showLoader) &&
                (this.contentIsDoneRendering && this.hasMetMinimRenderTime));
        },
    },
    watch: {
        routeIsLoading: {
            handler(routeIsLoading) {
                if (routeIsLoading) {
                    this.contentIsDoneRendering = false;
                    this.hasMetMinimLoaderDelay = this.isInitialRender;
                    this.hasMetMinimRenderTime = false;
                    this.$emit('contentIsDoneRendering', false);

                    setTimeout(() => {
                        this.hasMetMinimLoaderDelay = true;
                    }, this.minimumLoaderTime);

                    setTimeout(() => {
                        this.hasMetMinimRenderTime = true;
                    }, this.minimumRenderTime);
                }
            },
            immediate: true,
        },
        $route: {
            handler(to, from) {
                const toPath = get(to, 'path');
                const toQuery = get(to, 'query');
                const fromPath = get(from, 'path');
                const fromQuery = get(from, 'query');

                if ((toPath !== fromPath || !(isEqual(toQuery, fromQuery)))) {
                    this.contentIsDoneRendering = true;
                }
            },
            immediate: true,
        },
        showLoader(showLoader) {
            if (showLoader) {
                this.loaderHasMetMinAnimationTime = false;

                setTimeout(() => {
                    this.loaderHasMetMinAnimationTime = true;
                }, this.minimumLoaderAnimationTime);
            }
        },
        showContent() {
            if (this.isInitialRender) {
                this.isInitialRender = false;
                this.onRouteDoneRendering();
            }
        },
    },
    mounted() {
        this.subscribeToCancelNavigation();
    },
    unmounted() {
        this.unsubscribeToCancelNavigation?.();
    },
    methods: {
        onRouteDoneRendering() {
            this.contentIsDoneRendering = true;
            this.$store.dispatch('BEFORE_ANIMATE_NEW_ROUTE');
            this.$emit('contentIsDoneRendering', true);
        },
        subscribeToCancelNavigation() {
            this.unsubscribeToCancelNavigation = this.$store.subscribe((mutation) => {
                if (mutation.type === CANCEL_NAVIGATION) {
                    this.onRouteDoneRendering();
                }
            });
        },
        beforeEnter() {
            this.onRouteDoneRendering();
        },
    },
};
</script>

<style lang="scss">
    .c-route-container {
        background: $nu-secondary;
        flex: 1 1 auto;
        min-height: 100%;

        &__transition-enter-active,
        &__transition-leave-active {
            transition: opacity 0.5s ease;
        }

        &__transition-enter-from,
        &__transition-leave-to {
            opacity: 0;
        }
    }

    .o-view {
        opacity: 0;
        transition: opacity 500ms ease;

        &--is-shown {
            opacity: 1;
        }
    }
</style>
