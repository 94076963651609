<script>
/**
 *  A directive that adds keyboard operability to an element. Default functionality (overridable via modifiers):
 *  • adds tabindex="0" (if the element is not disabled)
 *  • adds role="button"
 *  • adds listeners for click + space and escape keys to trigger handler
 *  • respects the disabled attribute
 *
 *  Usage:
 *  <div v-tab-focus="handler"> Focusable element </div>
 *
 *  Handler:
 *  • must be of type 'function'
 *  • to use an inline expression rather than an instance method, simply wrap the expression in an arrow function, e.g.
 *      <div v-tab-focus="() => $emit('click', someData)">
 *
 *  Modifiers (chainable, e.g. v-tab-focus.onspace.hasrole="handler"):
 *  • onspace
 *      Allows the spacebar to trigger the supplied handler (for emulating HTML <button> behavior)
 *  • noenter
 *      Prevents the enter key from triggering the supplied handler
 *  • noclick
 *      Usually enter/space/click trigger the same functionality. This modifier prevents a click from triggering
 *      the supplied handler.
 *  • hasrole
 *      Prevents the directive from setting the role attribute (allowing the consumer to set a different role)
 *  • hasindex
 *      Prevents the directive from setting the tabindex attribute (allowing the consumer to set a different tabindex)
 *      Manually setting a positive tabindex is inadvisable, as it overrides the browser tab ordering logic
 *      This should only be used to dynamically allow/disallow the element from being tab-able via binding, e.g.
 *          <div v-tab-focus.hasindex="handler" :tabindex="someCondition ? '0' : '-1'">
 *
 *  Note:
 *  this directive isn't a cure-all for accessibility. Some other attributes may be necessary depending on the context,
 *  e.g. aria-label, aria-labelledby, aria-haspopup, aria-expanded, title, etc. Please see Accessibility-Guidelines.md
 *  for more info
 *
 */

/* eslint-disable no-param-reassign */
const setElementAttrs = (element, binding) => {
    element.eventListenerObj.hasMethod = typeof binding.value === 'function';
    element.eventListenerObj.isDisabled = Object.prototype.hasOwnProperty.call(element.attributes, 'disabled');

    const { $logger, $options } = binding.instance;
    if (typeof binding.value === 'undefined') {
        $logger.warn('Warning: No handler assigned to v-tab-focus directive. Having a button with no functionality ' +
            'may hurt the user experience.');
    } else if (!element.eventListenerObj.hasMethod) {
        $logger.error(
            `[v-tab-focus] Error in ${$options.name}:\n` +
            'Handler must be a function. To use an inline expression, use an arrow function:\n\n\t' +
            `v-tab-focus="() => ${binding.expression}"`,
        );
    }

    const { hasindex, hasrole, onspace } = binding.modifiers;
    if (!hasindex && !element.eventListenerObj.isDisabled) {
        element.setAttribute('tabindex', '0');
    }

    if (!hasrole) {
        element.setAttribute('role', onspace ? 'button' : 'link');
    }
};

export default {
    name: 'TabFocus',
    beforeMount(element, binding) {
        // create a stateful object implementing EventListener so we can unregister listeners
        element.eventListenerObj = {
            binding,
            handleEvent(event) {
                switch (event.type) {
                case 'keydown':
                    this.keydownHandler(event);
                    break;
                case 'keyup':
                    this.keyupHandler(event);
                    break;
                case 'click':
                    this.clickHandler(event);
                    break;
                default:
                    break;
                }
            },
            keydownHandler(event) {
                if (event.code === 'Space' && binding.modifiers.onspace) {
                    // prevent browser from scrolling the page when the spacebar is pressed
                    event.preventDefault();
                }
            },
            keyupHandler(event) {
                const triggerOnSpace = binding.modifiers.onspace && event.code === 'Space';
                const triggerOnEnter = !binding.modifiers.noenter && event.code === 'Enter';

                if (this.hasMethod && !this.isDisabled && (triggerOnSpace || triggerOnEnter)) {
                    binding.value(event);
                }
            },
            clickHandler(event) {
                if (this.hasMethod && !this.isDisabled && !binding.modifiers.noclick) {
                    binding.value(event);
                }
            },
        };

        setElementAttrs(element, binding);

        element.addEventListener('keyup', element.eventListenerObj);
        element.addEventListener('keydown', element.eventListenerObj);
        element.addEventListener('click', element.eventListenerObj);
    },
    updated(element, binding) {
        setElementAttrs(element, binding);
    },
    unmounted(element) {
        element.removeEventListener('keyup', element.eventListenerObj);
        element.removeEventListener('keydown', element.eventListenerObj);
        element.removeEventListener('click', element.eventListenerObj);
    },
};
</script>
