// List of global routes that the
// eslint-disable-next-line import/prefer-default-export
export const NOTIFICATIONS_GLOBAL_DISALLOWED_ROUTES = [
    '/rent/join',
    '/rent/referrals',
    '/rent/feedback',
    '/thrift/feedback',
    '/login',
    '/unsubscribe',
];
