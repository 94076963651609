<script>
import { find } from 'lodash-es';

import { mapActions, mapMutations, mapState } from 'vuex';

import {
    MODALS_MODULE_NAME,
    LAUNCH_MODAL,
    POP_MODAL,
    POP_MODALS,
} from '~coreModules/modals/js/modals-store';

export default {
    name: 'UrbnModal',
    props: {
        // See modal-utils.js getValidatedModalOptions for modalOptions definition
        modalOptions: {
            type: Object,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:showModal'],
    computed: {
        ...mapState(MODALS_MODULE_NAME, {
            modals: state => state.modals,
        }),
    },
    created() {
        this.launchModal(this.modalOptions)
            .then(() => this.$emit('update:showModal', false));
    },
    beforeUnmount() {
        if (find(this.modals, { key: this.modalOptions.key })) {
            // If we get here - we're being destroyed, but the modal is still
            // active, which means our incoming v-model property was toggled
            // off by the calling context.  This is likely an anti-pattern and
            // should be avoided - the internal app should be using POP_MODAL
            // directly to programmatically close the modal.  However, if we
            // catch this scenario, we should close all modals up to our modal,
            // and then close our modal as well
            this.$logger.warn('Modal closed via setting v-model to false, instead of POP_MODAL');
            this.popModals({ closingModalKey: this.modalOptions.key });
            this.popModal();
        }
    },
    methods: {
        ...mapMutations(MODALS_MODULE_NAME, {
            popModal: POP_MODAL,
        }),
        ...mapActions(MODALS_MODULE_NAME, {
            launchModal: LAUNCH_MODAL,
            popModals: POP_MODALS,
        }),
    },
    render() {
        return null;
    },
};
</script>

<style lang="scss">
</style>
