// A map of icon names to their alt texts
// All icon files should be SVGs which follow BEM conventions, starting with icon__

import GLOBAL_ICONS from '~coreModules/core/js/svg-constants.js';

export const APP_ICONS = new Map([
    ['box--alert', 'alert icon'],
    ['box--at-home', 'nuuly at home icon'],
    ['box--closed', 'nuuly closed icon'],
    ['box--early-return', 'nuuly early return icon'],
    ['box--in-transit', 'nuuly in transit icon'],
    ['box--open', 'nuuly open icon'],
    ['box--pending-message', 'nuuly box notification'],
    ['box--packed', 'nuuly packed icon'],
    ['box--paused', 'nuuly paused icon'],
    ['box--requested', 'nuuly requested icon'],
    ['catalog--four-up', 'four-up icon'],
    ['catalog--one-up', 'one-up icon'],
    ['catalog--size', 'size icon'],
    ['catalog--two-up', 'two-up icon'],
    ['giftCards--gift', 'gift cards gift icon'],
    ['rewards--subscription', 'rewards subscription icon'],
    ['rewards--subscription', 'rewards bonus item icon'],
    ['rewards--bonus-item', 'rewards subscription icon'],
    ['rewards--item-purchase', 'rewards item purchase icon'],
    ['stepper--checkmark', 'checkmark icon'],
    ...GLOBAL_ICONS,
]);

export default APP_ICONS;
