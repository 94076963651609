const { get, set } = require('../modules/core/js/es5/tinydash');

function generateQueryString(params) {
    const entries = Object.entries(params || {});
    if (entries.length === 0) {
        return '';
    }
    const qs = entries
        .sort((a, b) => (a[0] || '').localeCompare(b[0]))
        .map(([k, v]) => `${k}=${v}`).join('&');
    return `?${qs}`;
}

function pluckDataInterceptor(response) {
    return response.data ? response.data : response;
}

/**
 * Axios request interceptor to log outgoing requests
 *
 * @param  {Object}  logger The logger instance
 * @param  {Object}  requestConfig Axios request configuration object
 * @return {Object}  Axios requestConfig object
 */
function requestLoggerInterceptor(logger, requestConfig) {
    const method = get(requestConfig, 'method') || 'unknown-method';
    const url = get(requestConfig, 'url') || 'unknown-url';
    const params = { ...get(requestConfig, 'params') };
    set(requestConfig, 'nuu.startTime', Date.now());

    logger.debug(`${method.toUpperCase()} ${url}${generateQueryString(params)}`);

    return requestConfig;
}

function logResponse(logger, err, response) {
    const config = err ? (get(err, 'config') || err) : response.config;
    const method = get(config, 'method') || 'unknown-method';
    const url = get(config, 'url') || 'unknown-url';
    const params = get(config, 'params') || {};
    const methodAndUrl = `${method.toUpperCase()} ${url}${generateQueryString(params)}`;
    const logData = {
        request_time: Date.now() - get(config, 'nuu.startTime'),
    };
    const res = err ? err.response : response;
    const status = get(res, 'status') || 'unknown-status';

    const logMethod = err ? logger.error : logger.debug;

    logMethod(`Response: ${status} ${methodAndUrl}`, logData);
    // likely a network error
    if (err && !err.response) logger.error(err);

    return err ? Promise.reject(res) : response;
}

/**
 * Axios response interceptor to log incoming responses
 *
 * @param  {Object}  logger The logger instance
 * @param  {Object}  response Axios response object
 * @return {Object}  Axios response object
 */
function responseLoggerInterceptor(logger, response) {
    return logResponse(logger, null, response);
}

/**
 * Axios request interceptor to log incoming error responses
 *
 * @param  {Object}  logger The logger instance
 * @param  {Object}  responseErr  Axios response error object
 * @return {Promise} Promise rejected with the `responseErr` object
 */
function responseErrorLoggerInterceptor(logger, responseErr) {
    return logResponse(logger, responseErr, null);
}

module.exports = {
    pluckDataInterceptor,
    requestLoggerInterceptor,
    logResponse,
    responseLoggerInterceptor,
    responseErrorLoggerInterceptor,
};
