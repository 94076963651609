import { isFinite, isString } from 'lodash-es';

/**
 * Converts cents to dollars
 * @param  {Number|String}  value in cents
 * @param  {Object}  logger the logger instance
 * @returns {Number} value in dollars, without currency symbols
 */
export function convertCentsToDollars(cents, logger) {
    let dollars = 0;
    const safeCents = parseInt(cents, 10);

    if (isFinite(safeCents) || isString(safeCents)) {
        dollars = safeCents / 100;
    } else {
        dollars = null;
        logger.error('Error translating cents to dollars, invalid input');
    }

    return dollars;
}

export default {
    convertCentsToDollars,
};
