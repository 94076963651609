export const CONTENT_CARD_TYPES = {
    pencilBanner: 'pencil-banner',
};

export const CONTENT_CARD_PRIORITY_MIN = 1000;
export const CONTENT_CARD_PRIORITY_MAX = 3000;

export const CONTENT_CARD_CAROUSEL_SCROLL_ANIMATION_DELAY = 8000;

export const CONTENT_CARD_COLOR_BLUSH_60 = 'Blush-60';
export const CONTENT_CARD_COLOR_MELON_60 = 'Melon-60';
export const CONTENT_CARD_COLOR_LILAC_60 = 'Lilac-60';
export const CONTENT_CARD_COLOR_SUNSHINE_60 = 'Sunshine-60';
export const CONTENT_CARD_COLOR_SWIMMING_POOL_60 = 'Swimming Pool-60';
export const CONTENT_CARD_COLOR_HONEYDEW_60 = 'Honeydew-60';

export const CONTENT_CARD_COLORS = {
    [CONTENT_CARD_COLOR_BLUSH_60]: '#F0E7DE',
    [CONTENT_CARD_COLOR_MELON_60]: '#F9DECC',
    [CONTENT_CARD_COLOR_LILAC_60]: '#EDE1EA',
    [CONTENT_CARD_COLOR_SUNSHINE_60]: '#F0E7B4',
    [CONTENT_CARD_COLOR_SWIMMING_POOL_60]: '#E4EAED',
    [CONTENT_CARD_COLOR_HONEYDEW_60]: '#E5EFD9',
    CONTENT_CARD_COLOR_DEFAULT: '#365261',
};

// Headline Only
export const CONTENT_CARD_HEADLINE_ONLY_SHARP_GROTESK = 'Headline Only - Sharp Grotesk';
export const CONTENT_CARD_HEADLINE_ONLY_GINTO = 'Headline Only - Ginto';
export const CONTENT_CARD_HEADLINE_ONLY_AMERICANA = 'Headline Only - Americana';
// Headline + Subhead
export const CONTENT_CARD_HEADLINE_SUBHEAD_SHARP_GROTESK = 'Headline + Subhead - Sharp Grotesk';
export const CONTENT_CARD_HEADLINE_SUBHEAD_GINTO = 'Headline + Subhead - Ginto';
export const CONTENT_CARD_HEADLINE_SUBHEAD_AMERICANA = 'Headline + Subhead - Americana';
// Headline + Subhead + Tooltip Icon
export const CONTENT_CARD_HEADLINE_SUBHEAD_TOOLTIP_SHARP_GROTESK = 'Headline + Subhead + Tooltip Icon - Sharp Grotesk';
export const CONTENT_CARD_HEADLINE_SUBHEAD_TOOLTIP_GINTO = 'Headline + Subhead + Tooltip Icon - Ginto';
export const CONTENT_CARD_HEADLINE_SUBHEAD_TOOLTIP_AMERICANA = 'Headline + Subhead + Tooltip Icon - Americana';
// Tooltip Icon
export const CONTENT_CARD_HEADLINE_TOOLTIP_SHARP_GROTESK = 'Headline + Tooltip Icon - Sharp Grotesk';
export const CONTENT_CARD_HEADLINE_TOOLTIP_GINTO = 'Headline + Tooltip Icon - Ginto';
export const CONTENT_CARD_HEADLINE_TOOLTIP_AMERICANA = 'Headline + Tooltip Icon - Americana';
// Headline Class
export const CONTENT_CARD_HEADLINE_CLASS_SHARP_GROTESK = 'o-text--heading-5';
export const CONTENT_CARD_HEADLINE_CLASS_GINTO = 'o-text--heading-3';
export const CONTENT_CARD_HEADLINE_CLASS_AMERICANA = 'o-text--heading-6';

export const CONTENT_CARD_SUBHEAD_CLASS_AMERICANA = 'o-text--caption';

export const CONTENT_CARD_TEMPLATES = {
    [CONTENT_CARD_HEADLINE_ONLY_SHARP_GROTESK]: { headingClass: CONTENT_CARD_HEADLINE_CLASS_SHARP_GROTESK },
    [CONTENT_CARD_HEADLINE_ONLY_GINTO]: { headingClass: CONTENT_CARD_HEADLINE_CLASS_GINTO },
    [CONTENT_CARD_HEADLINE_ONLY_AMERICANA]:
        { headingClass: CONTENT_CARD_HEADLINE_CLASS_AMERICANA, subheadingClass: CONTENT_CARD_SUBHEAD_CLASS_AMERICANA },
    [CONTENT_CARD_HEADLINE_SUBHEAD_SHARP_GROTESK]:
        { headingClass: CONTENT_CARD_HEADLINE_CLASS_SHARP_GROTESK, showSubheading: true },
    [CONTENT_CARD_HEADLINE_SUBHEAD_GINTO]: { headingClass: CONTENT_CARD_HEADLINE_CLASS_GINTO, showSubheading: true },
    [CONTENT_CARD_HEADLINE_SUBHEAD_AMERICANA]: {
        headingClass: CONTENT_CARD_HEADLINE_CLASS_AMERICANA,
        subheadingClass: CONTENT_CARD_SUBHEAD_CLASS_AMERICANA,
        showSubheading: true,
    },
    [CONTENT_CARD_HEADLINE_SUBHEAD_TOOLTIP_SHARP_GROTESK]:
        { headingClass: CONTENT_CARD_HEADLINE_CLASS_SHARP_GROTESK, showSubheading: true, showTooltip: true },
    [CONTENT_CARD_HEADLINE_SUBHEAD_TOOLTIP_GINTO]:
        { headingClass: CONTENT_CARD_HEADLINE_CLASS_GINTO, showSubheading: true, showTooltip: true },
    [CONTENT_CARD_HEADLINE_SUBHEAD_TOOLTIP_AMERICANA]: {
        headingClass: CONTENT_CARD_HEADLINE_CLASS_AMERICANA,
        subheadingClass: CONTENT_CARD_SUBHEAD_CLASS_AMERICANA,
        showSubheading: true,
        showTooltip: true,
    },
    [CONTENT_CARD_HEADLINE_TOOLTIP_SHARP_GROTESK]:
        { headingClass: CONTENT_CARD_HEADLINE_CLASS_SHARP_GROTESK, showTooltip: true },
    [CONTENT_CARD_HEADLINE_TOOLTIP_GINTO]: { headingClass: CONTENT_CARD_HEADLINE_CLASS_GINTO, showTooltip: true },
    [CONTENT_CARD_HEADLINE_TOOLTIP_AMERICANA]: {
        headingClass: CONTENT_CARD_HEADLINE_CLASS_AMERICANA,
        subheadingClass: CONTENT_CARD_SUBHEAD_CLASS_AMERICANA,
        showTooltip: true,
    },
};

export const ContentCardSchemas = {
    [CONTENT_CARD_TYPES.pencilBanner]: {
        requiredFields: ['contentCardType', 'slug', 'templateType', 'bannerDestination', 'cardPriority'],
        unrequiredFields: ['subhead', 'ctaDestination', 'color', 'description', 'id'],
    },
};
