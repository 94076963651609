import runtimeConfig from '~config/config';
import resourceInjector from '~coreModules/core/js/resource-injector';
import { nuColorBlack, nuColorGray, nuColorPrimary } from '~coreModules/core/js/temp-colors';

const RECURLY_JS_SDK = 'https://js.recurly.com/v4/recurly.js';

export function configureRecurlyJs({
    cardNumberPlaceholder,
    expirationMonthPlaceholder,
    expirationYearPlaceholder,
    cvvPlaceholder,
}) {
    const fieldFormat = placeholder => ({
        format: true,
        style: {
            placeholder: {
                content: placeholder,
                color: `${nuColorGray} !important`,
            },
        },
    });

    // eslint-disable-next-line no-undef
    recurly.configure({
        publicKey: runtimeConfig.recurlyPublicApiKey,
        required: ['cvv'],
        fields: {
            all: {
                style: {
                    fontColor: nuColorBlack,
                    fontFamily: 'sans-serif',
                    fontSize: '16px',
                    invalid: {
                        fontColor: nuColorPrimary,
                    },
                },
            },
            number: fieldFormat(cardNumberPlaceholder),
            month: {
                ...fieldFormat(expirationMonthPlaceholder),
                inputType: 'select',
            },
            year: {
                ...fieldFormat(expirationYearPlaceholder),
                inputType: 'select',
            },
            cvv: fieldFormat(cvvPlaceholder),
        },
        fraud: {
            kount: {
                dataCollector: runtimeConfig.recurlyFraudEnabled,
            },
        },
    });
}

export function requestRecurlyToken(form) {
    return new Promise((resolve, reject) => {
        /* eslint-disable no-undef */
        recurly.token(form, (error, token) => {
            if (error) {
                return reject(error);
            }
            return resolve(token.id);
        });
    });
}

export function initRecurlyJs(placeholders) {
    resourceInjector(RECURLY_JS_SDK, 'js', (() => {
        configureRecurlyJs(placeholders);
    }));
}
