export const BUTTON_VARIANT_TYPES = {
    none: 'none',
    link: 'link',
    primary: 'primary',
    secondary: 'secondary',
};

export const BUTTON_VARIANTS = [
    BUTTON_VARIANT_TYPES.none,
    BUTTON_VARIANT_TYPES.link,
    BUTTON_VARIANT_TYPES.primary,
    BUTTON_VARIANT_TYPES.secondary,
];

export const BUTTON_COLOR_VARIANT_TYPES = {
    none: 'none',
    light: 'light',
    dark: 'dark',
};

export const BUTTON_COLOR_VARIANTS = [
    BUTTON_COLOR_VARIANT_TYPES.none,
    BUTTON_COLOR_VARIANT_TYPES.light,
    BUTTON_COLOR_VARIANT_TYPES.dark,
];

export const BUTTON_SIZES = {
    small: 'small',
    large: 'large',
};
