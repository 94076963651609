import { WAITLIST_LOCATIONS } from '~coreModules/profile/js/profile-constants';

export const BOX_RECEIPT_PENDING_STATUSES = {
    PAUSE_REQUESTED: 'PAUSE_REQUESTED',
    CANCEL_REQUESTED: 'CANCEL_REQUESTED',
};

export const BOX_RECEIPT_PENDING_STATUS_SLUGS = new Map([
    [BOX_RECEIPT_PENDING_STATUSES.PAUSE_REQUESTED, 'global__softPaused'],
    [BOX_RECEIPT_PENDING_STATUSES.CANCEL_REQUESTED, 'global__softCancelled'],
]);

export const SHIPPING_ADDRESS_VERIFICATION_STATUS = {
    CORRECTED: 'CORRECTED',
    FAILURE: 'FAILURE',
};

export const REFERRAL_TYPE = {
    FIXED_CASH: 'FIXED_CASH',
    FIXED_POINTS: 'FIXED_POINTS',
};

export const SUBSCRIPTION_STATUSES = {
    cancelled: 'CANCELLED',
    cancelRequested: 'CANCEL_REQUESTED',
    nonSubscriber: 'NON_SUBSCRIBER',
    overdue: 'OVERDUE',
    paused: 'PAUSED',
    pauseRequested: 'PAUSE_REQUESTED',
    subscriber: 'SUBSCRIBER',
    terminated: 'TERMINATED',
};

export const HAS_SUBSCRIPTION_STATUSES = [
    SUBSCRIPTION_STATUSES.subscriber,
    SUBSCRIPTION_STATUSES.paused,
    SUBSCRIPTION_STATUSES.pauseRequested,
    SUBSCRIPTION_STATUSES.cancelRequested,
];

export const WAITLIST_ELIGIBLE_STATUSES = [
    SUBSCRIPTION_STATUSES.cancelled,
    SUBSCRIPTION_STATUSES.nonSubscriber,
];

export const WAITLIST_SIGNUP_LOCATIONS = [
    WAITLIST_LOCATIONS.web,
    WAITLIST_LOCATIONS.ios,
];

export const SUBSCRIPTION_STATUS_SLUGS = new Map([
    [SUBSCRIPTION_STATUSES.cancelled, 'global__cancelled'],
    [SUBSCRIPTION_STATUSES.cancelRequested, 'global__cancelRequested'],
    [SUBSCRIPTION_STATUSES.nonSubscriber, 'global__nonSubscriber'],
    [SUBSCRIPTION_STATUSES.overdue, 'global__overdue'],
    [SUBSCRIPTION_STATUSES.paused, 'global__paused'],
    [SUBSCRIPTION_STATUSES.pauseRequested, 'global__pauseRequested'],
    [SUBSCRIPTION_STATUSES.subscriber, 'global__subscriber'],
    [SUBSCRIPTION_STATUSES.terminated, 'global__terminated'],
]);
