import axios from 'axios';
import { get } from 'lodash-es';

import {
    pluckDataInterceptor,
    responseLoggerInterceptor,
    responseErrorLoggerInterceptor,
    requestLoggerInterceptor,
} from '~coreServer/server-interceptors';

import { smooshEntry } from '~coreModules/contentful/js/contentful-utils';
import { FILTERED_CONTENT_TYPES } from '~coreModules/contentful/js/contentful-constants';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { SERVICE_ERROR } from '~coreModules/core/js/global-event-constants';

export default function createContentfulRestClient({
    store,
    logger,
    apiUrl,
    accessToken,
    environment,
    request,
}) {
    if (!apiUrl) {
        throw new TypeError('Expected parameter apiUrl');
    }

    if (!accessToken) {
        throw new TypeError('Expected parameter accessToken');
    }

    let baseURL = apiUrl;

    if (environment) {
        baseURL += `/environments/${environment}`;
    }

    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    const contentfulRestClient = axios.create({ baseURL, headers });

    // Axios request interceptors run right-to-left, so list them the reverse of the expected order
    contentfulRestClient.interceptors.request.use(requestLoggerInterceptor.bind(request, logger));

    // Response interceptors run left-to-right, so put in expected order
    contentfulRestClient.interceptors.response.use(res => responseLoggerInterceptor(logger, res),
        (error) => {
            const err = get(error.response, 'data');

            if (err) {
                store.dispatch(GLOBAL_EVENT, {
                    type: SERVICE_ERROR,
                    data: err,
                });
            }
            return responseErrorLoggerInterceptor(logger, error);
        },
    );

    contentfulRestClient.interceptors.response.use(pluckDataInterceptor);

    return contentfulRestClient;
}

export function getContentfulSiteConfig(contentfulSvc) {
    return contentfulSvc
        .get('entries?include=5&fields.slug=typhoona-site-config&limit=1&content_type=siteConfig', {})
        .then(data => smooshEntry(data?.data?.items?.[0], data?.data?.includes));
}

export function getContentfulPageContentForSlug({
    contentfulSvc,
    contentType,
    slug,
    isTimed,
    queryParams = {},
    limit = 1,
}) {
    const options = {
        params: {
            content_type: contentType,
            limit,
            include: 5, // default contentful link depth
            ...queryParams,
        },
    };

    if (slug) {
        options.params['fields.slug'] = slug;
    }

    if (FILTERED_CONTENT_TYPES.includes(contentType)) {
        options.params['fields.channel'] = 'web';
    }

    // handle timed page types
    if (isTimed) {
        const startTime = new Date().toISOString();
        const endTime = new Date().toISOString();
        // if timed, pass times into URL params
        options.params = {
            ...options.params,
            'fields.startTime[lte]': startTime,
            'fields.endTime[gte]': endTime,
            order: '-fields.startTime',
        };
    }

    return contentfulSvc
        .get('entries', options)
        .then((data) => {
            const entries = limit === 1 ? data.items[0] : data.items;

            return smooshEntry(entries, data.includes);
        });
}
