<i18n>
[
    "payment__ccNumber--label",
    "payment__ccNumber--placeholder",
    "payment__expMonth--label",
    "payment__expMonth--placeholder",
    "payment__expYear--label",
    "payment__expYear--placeholder",
    "payment__cvv--label",
    "payment__cvv--placeholder",
    "onboarding__billingPayment--recurlyValidationErrors",
]
</i18n>

<template>
    <form ref="recurlyFormRef" class="c-form o-row-full c-card-form" @submit="onSubmit">
        <div class="o-extra-small--12 u-spacer--3">
            <div class="c-form__label c-form__label--floating">
                {{ $t('payment__ccNumber--label') }}
            </div>
            <div data-recurly="number"></div>
        </div>
        <div class="c-card-form__desktop-row-2 o-extra-small--12">
            <div class="o-row o-row--nested">
                <div
                    class="o-extra-small--6"
                    :class="getGridClasses('month')"
                >
                    <div class="c-form__label c-form__label--floating">
                        {{ $t('payment__expMonth--label') }}
                    </div>
                    <div data-recurly="month"></div>
                </div>
                <div
                    class="o-extra-small--6"
                    :class="getGridClasses('year')"
                >
                    <div class="c-form__label c-form__label--floating">
                        {{ $t('payment__expYear--label') }}
                    </div>
                    <div data-recurly="year"></div>
                </div>
                <div
                    class="o-extra-small--12"
                    :class="getGridClasses('cvv')"
                >
                    <div class="c-form__label c-form__label--floating">
                        {{ $t('payment__cvv--label') }}
                    </div>
                    <div data-recurly="cvv"></div>
                </div>
            </div>
        </div>
        <input type="hidden" name="recurly-token" data-recurly="token">
        <div v-if="recurlyFormErrors" class="c-recurly-errors o-extra-small--12">
            <p class="c-form__error">
                {{ $t('onboarding__billingPayment--recurlyValidationErrors') }}:
            </p>
            <ul>
                <li
                    v-for="(error, index) in recurlyFormErrors.fields"
                    :key="error"
                    class="c-recurly-errors__li c-form__error"
                >
                    {{ error }}<span v-if="index !== (recurlyFormErrors.fields.length - 1)">,&nbsp;</span>
                </li>
            </ul>
        </div>

        <template v-if="hiddenBillingAddress">
            <input
                v-model="hiddenBillingAddress.firstName"
                class="hidden"
                type="hidden"
                name="firstName"
                data-recurly="first_name"
            >
            <input
                v-model="hiddenBillingAddress.lastName"
                class="hidden"
                type="hidden"
                name="lastName"
                data-recurly="last_name"
            >
            <input
                v-model="hiddenBillingAddress.line1"
                class="hidden"
                type="hidden"
                name="line1"
                data-recurly="address1"
            >
            <input
                v-model="hiddenBillingAddress.line2"
                class="hidden"
                type="hidden"
                name="line2"
                data-recurly="address2"
            >
            <input
                v-model="hiddenBillingAddress.city"
                class="hidden"
                type="hidden"
                name="city"
                data-recurly="city"
            >
            <input
                v-model="hiddenBillingAddress.state"
                class="hidden"
                type="hidden"
                name="state"
                data-recurly="state"
            >
            <input
                v-model="hiddenBillingAddress.zip"
                class="hidden"
                type="hidden"
                name="postalCode"
                data-recurly="postal_code"
            >
            <!-- TODO: This is hardcoded to US for now, since we only support US -
                make dynamic once multiple countries are supported -->
            <input
                value="US"
                class="hidden"
                type="hidden"
                name="country"
                data-recurly="country"
            >
        </template>
    </form>
</template>

<script>
import runtimeConfig from '~config/config';
import { requestRecurlyToken, initRecurlyJs } from '~modules/recurly/js/recurly-api';

export default {
    name: 'CreditCardForm',
    props: {
        billingAddress: {
            type: Object,
            required: false,
            default: null,
        },
        /* Override grid classes for field inputs */
        columnWidthOverrides: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            enableRecurlyJs: false,
            recurlyFormErrors: null,
            hiddenBillingAddress: { ...this.billingAddress },
        };
    },
    watch: {
        billingAddress: {
            deep: true,
            handler(address) {
                this.hiddenBillingAddress = address;
            },
        },
    },
    created() {
        if (process.env.VUE_ENV === 'client') {
            this.enableRecurlyJs = runtimeConfig.features.recurlyJs;
        }
    },
    mounted() {
        if (this.enableRecurlyJs) {
            // Initializing recurly js here since associated HTML elements
            // must exist in the dom before recurly is configured
            initRecurlyJs({
                cardNumberPlaceholder: this.$t('payment__ccNumber--placeholder'),
                expirationMonthPlaceholder: this.$t('payment__expMonth--placeholder'),
                expirationYearPlaceholder: this.$t('payment__expYear--placeholder'),
                cvvPlaceholder: this.$t('payment__cvv--placeholder'),
            });
        }
    },
    methods: {
        triggerFormSubmit() {
            return this.handleSubmitCreditCardForm(this.$refs.recurlyFormRef);
        },
        onSubmit(e) {
            e.preventDefault();
            const form = e.currentTarget;

            this.handleSubmitCreditCardForm(form);
        },
        handleSubmitCreditCardForm(form) {
            return requestRecurlyToken(form)
                .then((token) => {
                    this.recurlyFormErrors = null;
                    return Promise.resolve(token);
                })
                .catch((err) => {
                    this.recurlyFormErrors = err;
                    return Promise.reject(err);
                });
        },
        getGridClasses(fieldName) {
            const gridOverride = this.columnWidthOverrides[fieldName];
            return gridOverride || 'o-medium--4';
        },
    },
};
</script>

<style lang="scss">
    .c-card-form {
        margin-top: $nu-spacer-2pt5;

        &__desktop-row-2 {
            margin-top: $nu-spacer-2pt5;
        }
    }

    .c-recurly-errors {
        &__li {
            display: inline-block;
        }
    }

    // THESE STYLES ARE FOR THE INJECTED RECURLY CREDIT CARD FORM
    .recurly-hosted-field {
        &-number,
        &-month,
        &-year,
        &-cvv {
            background-color: $transparent;
            border: 0;
            position: relative;
            height: 30px;
            margin-bottom: $nu-spacer-2pt5;
            padding: 0 $nu-spacer-1pt5;
            box-sizing: border-box;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1px;
                height: 1px;
                background-image:
                    linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-gray 50%, $nu-gray 100%);
                background-position-y: 0;
                background-size: 100% 200%;
                background-repeat: no-repeat;
                transition:
                    background-position-y 0.2s ease,
                    background-image 0.2s ease;
            }
        }
    }

    .recurly-hosted-field {
        &-month,
        &-year {
            margin-bottom: $nu-spacer-8;

            @include breakpoint(medium) {
                margin-bottom: $nu-spacer-2pt5;
            }
        }
    }

    .recurly-hosted-field {
        &-number,
        &-month,
        &-year,
        &-cvv {
            &.recurly-hosted-field-focus {
                &::after {
                    bottom: -2px;
                    height: 2px;
                    background-image:
                        linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-primary 50%, $nu-primary 100%);
                }
            }
        }
    }

    .recurly-hosted-field-invalid {
        border: 1px solid $nu-primary;
    }
</style>
