<i18n>
[
    "browse__skipContentAccessibility--label",
    "browse__skipFooterAccessibility--label",
]
</i18n>

<template>
    <div class="c-accessibility-jump-links">
        <div
            v-tab-focus.hasrole="() => skipToMainContent()"
            :class="[
                'c-accessibility-jump-links__jump-link',
                'c-accessibility-jump-links__jump-to-main-content',
                'o-text--caption'
            ]"
            role="link"
            @focus="() => jumpLinkFocused(true)"
            @blur="() => jumpLinkFocused(false)"
        >
            {{ $t('browse__skipContentAccessibility--label') }}
        </div>
        <div
            v-if="!ignoreBaseLayout"
            v-tab-focus.hasrole="() => skipToFooter()"
            class="c-accessibility-jump-links__jump-link c-accessibility-jump-links__jump-to-footer o-text--caption"
            role="link"
            @focus="() => jumpLinkFocused(true)"
            @blur="() => jumpLinkFocused(false)"
        >
            {{ $t('browse__skipFooterAccessibility--label') }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccessibilityJumpLinks',
    emits: ['focus'],
    computed: {
        ignoreBaseLayout() {
            return this.$route.meta.ignoreBaseLayout;
        },
    },
    methods: {
        jumpLinkFocused(isFocused) {
            this.$emit('focus', isFocused);
        },
        skipToMainContent() {
            let contentContainer = document.querySelector('.c-app__main-content');
            if (this.ignoreBaseLayout) {
                contentContainer = document.querySelector('.c-slide-layout__main-content');
            }
            this.focusElement(contentContainer);
        },
        skipToFooter() {
            const footerContainer = document.querySelector('.c-footer');
            this.focusElement(footerContainer);
        },
        focusElement(element) {
            element.setAttribute('tabIndex', '0');
            element.focus();
            element.removeAttribute('tabIndex');
        },
    },
};
</script>

<style lang="scss">
    .c-accessibility-jump-links {

        &__jump-link {
            position: absolute;
            left: -99999px;

            &:focus {
                position: relative;
                left: 0;
                padding-left: $nu-spacer-3;
                z-index: 999;
                background: $nu-secondary;
                color: $nu-primary;
                text-decoration: underline;
                outline: none !important;

                @include breakpoint(medium) {
                    padding-left: $nu-spacer-4;
                }
            }
        }
    }
</style>
