export const BONUS_ITEM = 'BONUS_ITEM';
export const BOX_LINK_CLICKED = 'BOX_LINK_CLICKED';
export const BOX_ITEM_OUT_OF_STOCK = 'BOX_ITEM_OUT_OF_STOCK';
export const BOX_ITEM_STATUS_UPDATED = 'BOX_ITEM_STATUS_UPDATED';
export const CATALOG_AVAILABILITY_UPDATED = 'CATALOG_AVAILABILITY_UPDATED';
export const CATALOG_GRID_LAYOUT_UPDATED = 'CATALOG_GRID_LAYOUT_UPDATED';
export const CHAT_CLICK = 'CHAT_CLICK';
export const EMAIL_UPDATED = 'EMAIL_UPDATED';
export const PRODUCT_TOUR_VIEWED = 'PRODUCT_TOUR_VIEWED';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const REFERRAL_SENT = 'REFERRAL_SENT';
export const REFERRAL_CLICKED = 'REFERRAL_CLICKED';
export const REFERRAL_LINK_SHARED = 'REFERRAL_LINK_SHARED';
export const RENEW_EARLY = 'RENEW_EARLY';
export const REPRINT_LABEL = 'REPRINT_LABEL';
export const REQUEST_BOX = 'REQUEST_BOX';
export const REVIEW_PHOTO_CLICK = 'REVIEW_PHOTO_CLICK';
export const SHIPPING_ADDRESS_UPDATED = 'SHIPPING_ADDRESS_UPDATED';
export const SOCIAL_SHARE = 'SOCIAL_SHARE';
export const SUBSCRIPTION_COUPON_REDEEMED = 'SUBSCRIPTION_COUPON_REDEEMED';
export const SUBSCRIPTION_CREATE_COMPLETED = 'SUBSCRIPTION_CREATE_COMPLETED';
export const SUBSCRIPTION_STATUS_UPDATED = 'SUBSCRIPTION_STATUS_UPDATED';
export const DELETE_SUBLIST = 'DELETE_SUBLIST';
export const CREATE_SUBLIST = 'CREATE_SUBLIST';
export const CHECK_CARRIER_STATUS = 'CHECK_CARRIER_STATUS';
export const CHECK_CARRIER_SUCCESS = 'CHECK_CARRIER_SUCCESS';
export const CHECK_CARRIER_FAILURE = 'CHECK_CARRIER_FAILURE';
export const REVIEW_FILTER_UPDATED = 'REVIEW_FILTER_UPDATED';
export const REVIEW_SORT_UPDATED = 'REVIEW_SORT_UPDATED';
export const SHARE_CONTENT = 'SHARE_CONTENT';
export const VIEW_SIMILAR_CLICK = 'VIEW_SIMILAR_CLICK';
export const SUBLIST_LINK_SHARED = 'SUBLIST_LINK_SHARED';
export const REDEEM_GIFT_CARD = 'REDEEM_GIFT_CARD';
