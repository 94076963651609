import { isFunction } from 'lodash-es';

import generateJsonLD from '~coreModules/core/js/seo/json-ld-utils';

import SITE_META from '~modules/core/js/seo/meta-constants';

export default {
    created() {
        if (process.env.VUE_ENV === 'server') {
            const userState = this.$userState;
            const { jsonLDName, jsonLDUrlPath } = SITE_META;

            let jsonLDs = [{
                '@context': 'http://schema.org',
                '@type': 'Corporation',
                name: jsonLDName,
                url: `${userState.baseUrl}${jsonLDUrlPath}`,
                logo: `${userState.baseUrl}/static/logo_630x630.png`,
                sameAs: [
                    'https://www.facebook.com/shopnuuly/',
                    'https://www.instagram.com/nuuly',
                    'https://www.pinterest.com/shopnuuly/',
                    'https://twitter.com/shopnuuly',
                    'https://www.tiktok.com/@shopnuuly',
                ],
            }];

            if (isFunction(this.$options.getJsonLD)) {
                const jsonLD = this.$options.getJsonLD.call(this);

                jsonLDs = jsonLDs.concat(jsonLD);
            }

            this.$ssrContext.jsonLDs = generateJsonLD(jsonLDs);
        }
    },
};
