import {
    get,
    isEmpty,
    has,
    mapValues,
    some,
    isObject,
    isFunction,
} from 'lodash-es';

import {
    CONTENTFUL_CUSTOM_MODULE_TYPES,
} from '~coreModules/contentful/js/contentful-constants';

import { interpolateStringWithObjectValues } from '~coreModules/core/js/i18n';

export function flattenContentfulAsset(asset) {
    return {
        fields: {
            ...asset.fields.file,
            ...{ title: asset.fields.title },
        },
        ...asset.sys,
    };
}

/**
 * Take a contentful entry, and recursively strip out all of the cruft:
 *   - Resolve all links to includes.Entry or includes.Asset
 *   - Remove all 'sys' objects
 *   - Flatten all 'fields' properties
 *
 * @param  {Object|Object[]} entry    Contentful entry object
 * @param  {Object}   includes Root level `includes` field from contentful
 *                             response when entries have linked child entries
 *                             or assets
 * @return {Object}   Condensed object
 */
export function smooshEntry(entry, includes) {
    // Look up a given linked Entry/Asset in the includes structure
    const getEntryOrAsset = (obj) => {
        const links = get(includes, get(obj, 'sys.linkType'), []);
        const linkedAsset = links.find(e => e.sys.id === obj.sys.id);

        if (get(linkedAsset, 'sys.type') === 'Asset' && get(linkedAsset, 'fields.file')) {
            return flattenContentfulAsset(linkedAsset);
        }
        return linkedAsset;
    };

    if (Array.isArray(entry)) {
        // This is an array, return a new array with each element having been
        // processed recursively
        return entry.map(e => smooshEntry(e, includes));
    }

    if (typeof entry === 'object' && entry != null) {
        if (has(entry, 'fields')) {
            // This is a sys/fields object, we only care about the 'fields'
            // entries, so recurse on each value of that object, preserving
            // the key names
            return mapValues(smooshEntry(entry.fields, includes));
        }

        if (has(entry, 'sys')) {
            // This only has sys, not fields
            return smooshEntry(getEntryOrAsset(entry), includes);
        }

        // This is just a normal object without 'fields' or 'sys', so we
        // want to recurse on each property directly
        return mapValues(entry, e => smooshEntry(e, includes));
    }

    // This is a primitive value, just return it directly
    return entry;
}

/**
 *
 * @param {Array} rows   contentful rows array
 * @returns {Array} contentful rows with modules property that is forced to be an array
 */
export function normalizeContentfulRowModules(rows) {
    if (rows) {
        return rows.map((row) => {
            const modules = Array.isArray(row.modules) ? row.modules : [row.modules];
            return { ...row, modules };
        });
    }

    return [];
}

/**
 *
 * @param {Object} browsePage   contenful browsePage content
 * @returns {Object} The shaped contentful browsePage
 */
export function shapeBrowsePageContent(browsePage) {
    if (!isEmpty(browsePage)) {
        return {
            ...browsePage,
            ...(browsePage.topContent && {
                topContent: normalizeContentfulRowModules(browsePage.topContent),
            }),
            ...(browsePage.bottomContent && {
                bottomContent: normalizeContentfulRowModules(browsePage.bottomContent),
            }),
        };
    }

    return browsePage;
}

/**
 *
 * @param {Object} contentPage   contenful browsePage content
 * @returns {Object} The shaped contentful contentPage
 */
export function shapeContentfulContentPageResponse(contentPage) {
    const shapedContentPage = { ...contentPage };

    if (contentPage && contentPage.rows) {
        shapedContentPage.rows = normalizeContentfulRowModules(contentPage.rows);
    }

    return shapedContentPage;
}

export function shapeContentfulAppConfig(appConfig) {
    const shapedConfig = { ...appConfig };

    if (!appConfig) {
        return {};
    }

    const { headerContent } = appConfig;

    if (headerContent) {
        if (Array.isArray(headerContent) && headerContent.length) {
            shapedConfig.headerContent = [headerContent[0]];
        } else {
            shapedConfig.headerContent = headerContent ? [headerContent] : [];
        }

        const modules = get(shapedConfig, 'headerContent.0.modules');

        if (modules) {
            shapedConfig.headerContent[0].modules = Array.isArray(modules) ? modules : [modules];
        }
    }

    return shapedConfig;
}

export function validateCustomModuleFields(content, requiredFields) {
    return typeof content === 'object' &&
        requiredFields.every(
            key => Object.prototype.hasOwnProperty.call(content, key) &&
                ![undefined, null].includes(content[key]),
        );
}

export function validateBannerRowModule(module, logger) {
    const { content } = module;

    const requiredFields = [
        'body',
        'label',
        'brands',
        'ctaUrl',
        'ctaTitle',
        'headline',
    ];

    const isValidModule = validateCustomModuleFields(content, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid  configuration for Baners Row Module ${module.title}`);
    }

    return isValidModule;
}

export function validateVideoRowModule(module, logger) {
    const { content } = module;
    const requiredTopLevelKeys = [
        'body',
        'label',
        'ctaUrl',
        'ctaTitle',
        'headline',
        'videoUrl',
        'imageUrls',
        'teaserVideoUrl',
    ];
    const requiredVideoKeys = ['large', 'small'];

    const isValidVideoModule =
        validateCustomModuleFields(content, requiredTopLevelKeys) &&
        validateCustomModuleFields(content.videoUrl, requiredVideoKeys) &&
        validateCustomModuleFields(content.imageUrls, requiredVideoKeys) &&
        validateCustomModuleFields(content.teaserVideoUrl, requiredVideoKeys);

    if (!isValidVideoModule) {
        logger.error(`Invalid configuration for Video Row Module ${module.title}`);
    }

    return isValidVideoModule;
}

export function validateFiveUpGridModule(module, logger) {
    const { content } = module;

    const requiredFields = [
        'body',
        'label',
        'images',
        'linkUrl',
        'headline',
        'linkText',
    ];

    const isValidModule = validateCustomModuleFields(content, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid configuration for Five Up Grid Module ${module.title}`);
    }

    return isValidModule;
}

export function validateTwoUpRowModule(module, logger) {
    const { content } = module;

    const requiredFields = [
        'body',
        'label',
        'ctaUrl',
        'ctaTitle',
        'headline',
        'leftImageUrl',
        'rightImageUrl',
    ];

    const isValidModule = validateCustomModuleFields(content, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid configuration for Two Up Row Module ${module.title}`);
    }

    return isValidModule;
}

export function validatePrivacyOptOut(module, logger) {
    const { content } = module;

    const requiredFields = [
        'anonymousOptedOutTitle',
        'anonymousOptedInTitle',
        'anonymousOptedOutMessage',
        'anonymousOptedInMessage',
        'registeredOptedOutTitle',
        'registeredOptedInTitle',
        'registeredOptedOutMessage',
        'registeredOptedInMessage',
        'optInCta',
        'optOutCta',
    ];

    const isValidModule = validateCustomModuleFields(content.privacyOptOut, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid configuration for Privacy Opt Out Module ${module.title}`);
    }

    return isValidModule;
}

export function validateFerrisWheelRowModule(module, logger) {
    const { content } = module;

    const requiredFields = [
        'body',
        'title',
        'brands',
        'mainLabel',
        'brandsLabel',
        'ferrisWheel',
    ];

    const isValidModule = validateCustomModuleFields(content, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid configuration for Ferris Wheel Row Module ${module.title}`);
    }

    return isValidModule;
}

export function validateBannerRow(module, logger) {
    const { content } = module;

    const requiredFields = [
        'title',
        'cta',
        'destination',
    ];

    const isValidModule = validateCustomModuleFields(content, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid configuration for Banner Row Module ${module.title}`);
    }

    return isValidModule;
}

export function validatePlanSummaryRow(module, logger) {
    const { content } = module;
    const requiredTopLevelKeys = [
        'label',
        'headline',
        'body',
        'column1Bullets',
        'column2Bullets',
        'pill',
    ];
    const requiredPillKeys = [
        'leftNumber',
        'leftLabel',
        'middleNumber',
        'middleLabel',
        'rightNumber',
        'rightLabel',
    ];

    const isValidPlanModule =
        validateCustomModuleFields(content, requiredTopLevelKeys) &&
        validateCustomModuleFields(content.pill, requiredPillKeys);

    if (!isValidPlanModule) {
        logger.error(`Invalid configuration for Plan Summary Module ${module.title}`);
    }

    return isValidPlanModule;
}

export function validateAppRow(module, logger) {
    const { content } = module;

    const requiredFields = [
        'body',
        'label',
        'headline',
        'appStoreUrl',
        'ctaTitle',
        'blobImage',
        'deviceContentImages',
    ];

    const isValidModule = validateCustomModuleFields(content, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid configuration for App Row Module ${module.title}`);
    }

    return isValidModule;
}

export function validateIllustratedListItem(module, logger) {
    const { content } = module;

    const requiredFields = [
        'title',
        'subtitle',
        'illustration',
    ];

    const isValidModule = validateCustomModuleFields(content, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid configuration for Illustrated List Item Module ${module.title}`);
    }

    return isValidModule;
}

export function validatePlanPillRow(module, logger) {
    const { content } = module;

    const requiredFields = [
        'leftNumber',
        'leftLabel',
        'middleNumber',
        'middleLabel',
        'rightNumber',
        'rightLabel',
    ];

    const isValidModule = validateCustomModuleFields(content, requiredFields);

    if (!isValidModule) {
        logger.error(`Invalid configuration for Plan Pill Row Module ${module.title}`);
    }

    return isValidModule;
}

/**
 *
 * @param {string} moduleName   the name of the custom module to be validated (inputs which aren't a member of
 *                              CONTENTFUL_CUSTOM_MODULE_TYPES always return false)
 * @param {Object} module       contentful module to be validated
 * @param {Object} logger       app logger instance
 * @returns {boolean} returns true iff the supplied module has all of the required fields for that type of custom module
 */
export function validateCustomModule(moduleName, module, logger) {
    if (module.moduleType !== moduleName) {
        return false;
    }

    switch (moduleName) {
    case CONTENTFUL_CUSTOM_MODULE_TYPES.BRANDS_ROW:
        return validateBannerRowModule(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.VIDEO_ROW:
        return validateVideoRowModule(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.TWO_UP_ROW:
        return validateTwoUpRowModule(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.FIVE_UP_GRID_MODULE:
        return validateFiveUpGridModule(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.PRIVACY_OPT_OUT:
        return validatePrivacyOptOut(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.FERRIS_WHEEL_ROW:
        return validateFerrisWheelRowModule(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.BANNER_ROW:
        return validateBannerRow(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.PLAN_SUMMARY_ROW:
        return validatePlanSummaryRow(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.APP_ROW:
        return validateAppRow(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.ILLUSTRATED_LIST_ITEM:
        return validateIllustratedListItem(module, logger);
    case CONTENTFUL_CUSTOM_MODULE_TYPES.PLAN_PILL_ROW:
        return validatePlanPillRow(module, logger);
    default:
        return false;
    }
}

/**
 *
 * @param {string} source    markdown source
 * @param {Object} root      reference to vue instance
 * @param {string} namespace desired store namespace
 * @returns {string} markdown source with interpolated values
 */
export function interpolateContentfulMarkdown(source, root, namespace) {
    const contentfulInterpolationValues = get(
        root, `$store.getters[${namespace}/getContentfulInterpolationValues]`, () => {})(root);

    if (some(contentfulInterpolationValues, Boolean)) {
        return interpolateStringWithObjectValues(source, contentfulInterpolationValues);
    }

    return source;
}

/**
 * Returns a string or object that is replaced with variables based on a key/value mapping
 * @param {string} string    string to replace values
 * @param {Object} mapping   the mapping object used to replace the values
 * @returns {string|Object}  final content with variables replaced
 */
export function replaceVariables(string, mapping) {

    // Get all contentful content variables (wrapped with {})
    // example: {username}
    const variables = string.match(/\{(.*?)\}/g);

    if (!variables) {
        return string;
    }

    let newString = string;

    variables.forEach((variable) => {
        const key = variable.replace(/({|})/g, '');
        const value = mapping[key];

        if (isObject(value) || isFunction(value)) {
            newString = value;
            return;
        }

        newString = newString.replace(variable, value);
    });

    return newString;
}
