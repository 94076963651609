<i18n>
[
    "global__previous",
    "global__next",
]
</i18n>

<template>
    <div class="c-content-card-buttons">
        <div
            v-tab-focus="handlePrevClick"
            :aria-label="$t('global__previous')"
            class="
                c-content-card-buttons__button
                c-content-card-buttons__button--left"
        >
            <BaseIcon
                icon="global--chevron"
                size="16px"
                :color="buttonColor"
                orientation="left"
            />
        </div>
        <div
            v-tab-focus="handleNextClick"
            :aria-label="$t('global__next')"
            class="
                c-content-card-buttons__button
                c-content-card-buttons__button--right"
        >
            <BaseIcon
                icon="global--chevron"
                size="16px"
                :color="buttonColor"
                orientation="right"
            />
        </div>
    </div>
</template>

<script setup>
import { PREVIOUS, NEXT } from '~coreModules/core/js/constants';

defineProps({
    buttonColor: {
        type: String,
        default: 'primary',
        validator: value => ['primary', 'white'].includes(value),
    },
});
const emit = defineEmits(['click']);

function emitClick(direction) {
    emit('click', direction);
}
function handleNextClick() {
    emitClick(NEXT);
}
function handlePrevClick() {
    emitClick(PREVIOUS);
}
</script>

<style lang="scss">
.c-content-card-buttons {
    &__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
        width: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &--left {
            left: $nu-spacer-1pt5;
        }

        &--right {
            right: $nu-spacer-1pt5;
        }
    }
}
</style>
