import { getInvoiceHistory, getSubscriptionInfo } from '~routes/account/js/subscriptions-api';
import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';
import { shapeContentfulContentPageResponse } from '~coreModules/contentful/js/contentful-utils';
import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';

import { CONTENT_TYPES } from '~modules/contentful/js/contentful-constants';

export const SUBSCRIPTIONS_MODULE_NAME = 'subscriptions';

export const GET_SUBSCRIPTION_INFO = 'GET_SUBSCRIPTION_INFO';
export const SET_SUBSCRIPTION_INFO = 'SET_SUBSCRIPTION_INFO';
export const GET_SURVEY_QUESTIONS = 'GET_SURVEY_QUESTIONS';
export const SET_SURVEY_QUESTIONS = 'SET_SURVEY_QUESTIONS';
export const GET_INVOICE_HISTORY = 'GET_INVOICE_HISTORY';
export const SET_INVOICE_HISTORY = 'SET_INVOICE_HISTORY';

export default {
    namespaced: true,

    state: () => ({
        billingAddress: {
            firstName: '',
            lastName: '',
            line1: '',
            line2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        },
        cardType: '',
        lastFour: '',
        invoices: [],
        surveyQuestions: {},
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_SUBSCRIPTION_INFO](state, subscriptionData) {
            const {
                billingAddress,
                cardType,
                lastFour,
            } = subscriptionData;

            Object.assign(state, {
                billingAddress,
                cardType,
                lastFour,
            });
        },
        [SET_SURVEY_QUESTIONS](state, questionSet) {
            state.surveyQuestions = questionSet;
        },
        [SET_INVOICE_HISTORY](state, invoiceData) {
            state.invoices = invoiceData;
        },
    },

    /* eslint-enable no-param-reassign */
    actions: {
        [GET_SUBSCRIPTION_INFO]({ commit, rootGetters }) {
            const isAnonymousOrNonSubscriber = rootGetters[`${PROFILE_MODULE_NAME}/isAnonymousOrNonSubscriber`];

            if (isAnonymousOrNonSubscriber) {
                return Promise.resolve();
            }

            return getSubscriptionInfo(this.$r15Svc)
                .then(subscriptionData => commit(SET_SUBSCRIPTION_INFO, subscriptionData.billingInfo))
                .catch((error) => {
                    this.$logger.debugError('Failed to fetch the user\'s subscription info: ', error);
                    return Promise.reject(error);
                });
        },
        [GET_SURVEY_QUESTIONS]({ commit }, slug) {
            return getContentfulPageContentForSlug({
                contentfulSvc: this.$contentfulSvc,
                contentType: CONTENT_TYPES.REVIEWS_QUESTION_SET,
                slug,
                isTimed: false,
            })
                .then((res) => {
                    const shapedContent = shapeContentfulContentPageResponse(res);
                    commit(SET_SURVEY_QUESTIONS, shapedContent);
                })
                .catch((error) => {
                    this.$logger.debugError(`Failed to fetch the survey questions for ${slug}: `, error);
                    return Promise.reject(error);
                });
        },
        [GET_INVOICE_HISTORY]({ commit }) {
            return getInvoiceHistory(this.$r15Svc)
                .then(res => commit(SET_INVOICE_HISTORY, res.invoices))
                .catch((error) => {
                    this.$logger.debugError('Failed to fetch the user\'s invoice history: ', error);
                    return Promise.reject(error);
                });
        },
    },

    getters: {
    },

    modules: {},
};
