import { get } from 'lodash-es';

import { generateUrl } from '~coreModules/core/js/url-utils';

import { SUBSCRIPTION_STATUSES, BOX_RECEIPT_PENDING_STATUSES } from '~modules/profile/js/profile-constants';
import {
    getContentfulSlugForNonActiveSubscription,
    BOX_ORDER_STATUSES,
    BOX_RECIEPT_PENDING_STATUS_SLUGS,
    ORDERED_BOX_CONTENTFUL_SLUGS,
    OPEN_BOX_CONTENTFUL_SLUGS,
    BOX_INVENTORY_QUERY_BOX_ID,
    ANONYMOUS_CONTENTFUL_SLUG,
    BOX_CHECK_FOR_DROP_OFF_STATUSES,
} from '~modules/box/js/box-constants';
import { MODALS } from '~modules/modals/js/modal-constants';

import runtimeConfig from '~config/config';

const { apiUrls } = runtimeConfig;

export function shapeBoxData(rawBoxData) {
    let shapedBoxData = { ...rawBoxData };
    const products = get(rawBoxData, 'products', []);

    const availableBoxItems = products.filter(product => product.availableToRent);
    const unavailableBoxItems = products.filter(product => !product.availableToRent);

    shapedBoxData = {
        ...shapedBoxData,
        boxInfo: {
            ...shapedBoxData.boxInfo,
            availableItemsInBox: availableBoxItems.length,
        },
        availableBoxItems,
        unavailableBoxItems,
    };

    delete shapedBoxData.products;
    return shapedBoxData;
}

export default shapeBoxData;

/**
 * Gets the contentful slug to be used for content to show in the box-modal
 * Based on their subscription status, number of available items in their box
 * @param {Object}  userData - box and subscription data for the user
 * @param {String}  userData.subscriptionStatus - ENUM representing the subscription status of the user
 * @param {Number}  userData.allBoxItemsCount - Count of the number of
    * available or unavailable items the user has in their box
 * @param {String}  userData.boxOrderStatus - The current active state of a users box or order
 * @param {String}  userData.boxReceiptPendingStatus - subscription state the
    * user will be in after they return their box
 * @param {Boolean} userData.openBoxHasMetMinItemLimit - user has open box filled with at least the
                    minimum amount of items to place an order
 * @param {Boolean} userData.boxFailedToOpen - if box is in return state and has a boxBalance > 0
 * @param {Object} logger - the logger instance
 * @returns {String} Contentful slug to be used to fetch contentful content in the box-modal
 */
export function getContentfulSlugForSubscriptionStatus({
    subscriptionStatus,
    allBoxItemsCount,
    boxOrderStatus,
    boxReceiptPendingStatus,
    boxBalance,
    openBoxHasMetMinItemLimit,
    boxFailedToOpen,
}, logger = console) {
    let contentfulSlug;

    if (subscriptionStatus === SUBSCRIPTION_STATUSES.subscriber) {
        const pendingStatusSlug = BOX_RECIEPT_PENDING_STATUS_SLUGS[boxReceiptPendingStatus];

        /* subscribed user has a boxReceiptPendingStatus */
        if (pendingStatusSlug) {
            return pendingStatusSlug;
        }

        const orderedBoxStatusSlug = ORDERED_BOX_CONTENTFUL_SLUGS[boxOrderStatus];

        /* subscribed user has an active order */
        if (orderedBoxStatusSlug) {
            if (boxBalance === 0 && boxOrderStatus === BOX_ORDER_STATUSES.RETURN_STARTED) {
                return ORDERED_BOX_CONTENTFUL_SLUGS.RETURN_STARTED;
            }

            if (boxFailedToOpen) return ORDERED_BOX_CONTENTFUL_SLUGS.FAILED_TO_OPEN;

            if (boxBalance > 0 && boxOrderStatus === BOX_ORDER_STATUSES.DELIVERED) {
                return ORDERED_BOX_CONTENTFUL_SLUGS.DELIEVERED_SWAP_ELIGIBLE;
            }

            return orderedBoxStatusSlug;
        }

        if (boxOrderStatus === BOX_ORDER_STATUSES.OPEN) {

            if (allBoxItemsCount === 0) {
                return OPEN_BOX_CONTENTFUL_SLUGS.empty;
            }

            return openBoxHasMetMinItemLimit ?
                OPEN_BOX_CONTENTFUL_SLUGS.full : OPEN_BOX_CONTENTFUL_SLUGS.filling;
        }

        logger.error('no contentful slug found for boxOrderStatus: ', boxOrderStatus);
    /* user is a not a subscriber, unable to interact with box */
    } else {
        contentfulSlug = getContentfulSlugForNonActiveSubscription(subscriptionStatus);

        if (!contentfulSlug) {
            if (subscriptionStatus) {
                logger.error('no contentful slug found for subscriptionStatus: ', subscriptionStatus);
            } else {
                /* Anonymous User */
                contentfulSlug = ANONYMOUS_CONTENTFUL_SLUG;
            }
        }
    }
    return contentfulSlug;
}

/**
 * gets the correct status to be used for content to show based
 * on the users box receipt status and box balance
 * @param {Object}  userData - box data for the user
 * @param {String}  userData.boxReceiptPendingStatus - subscription state the
    * user will be in after they return their box
 * @param {Number}  userData.boxBalance - box balance for user
 * @returns {String} the status to be used to show various content
 */
export function getStatusForCheckForDropOff({ boxReceiptPendingStatus, boxBalance }) {
    const receiptPendingStatus = BOX_RECEIPT_PENDING_STATUSES[boxReceiptPendingStatus];

    if (!receiptPendingStatus) {
        return boxBalance > 0 ? BOX_CHECK_FOR_DROP_OFF_STATUSES.DELIVERED_SWAP_ELIGIBLE :
            BOX_CHECK_FOR_DROP_OFF_STATUSES.DELIVERED_SWAP_INELIGIBLE;
    }

    return receiptPendingStatus;
}

/**
 * update bonus item count correctly when user adds an item to box;
 * @param {Number} defaultBonusItemCount - number that wil be used for the box counter if the user has not set otherwise
 * @param {Number} userStoredBonusItemCount - number of bonus items a user stored in cookie;
 * @param {Number} maxBonusItemCount - max number of bonus items that be applied to the BoxBonusItemCounter;
 * @param {Number} minBonusItemCount - min number of bonus items that be applied to the BoxBonusItemCounter;
 */
export function updateBonusItemCount(
    defaultBonusItemCount,
    userStoredBonusItemCount,
    maxBonusItemCount,
    minBonusItemCount,
) {
    if (userStoredBonusItemCount || userStoredBonusItemCount === 0) {
        const userStoredBonusItemCountInCookie = parseInt(userStoredBonusItemCount, 10);

        if (userStoredBonusItemCountInCookie < minBonusItemCount) {
            return minBonusItemCount;
        }

        if (userStoredBonusItemCountInCookie > maxBonusItemCount) {
            return maxBonusItemCount;
        }
        if (userStoredBonusItemCountInCookie >= minBonusItemCount &&
            userStoredBonusItemCountInCookie <= maxBonusItemCount) {
            return userStoredBonusItemCountInCookie;
        }
        return defaultBonusItemCount;
    }
    return defaultBonusItemCount;
}

/**
 * find item by sku in recent order;
 * @param {Object} state - the state of box vuex store;
 * @param {String} skuId - the skuId of item;
 */
export function findRecentlyOrderedProductBySku(state, skuId) {
    const recentlyOrderedProducts = get(state, 'recentOrder.products', []);

    return recentlyOrderedProducts.find(product => get(product, 'skuId') === skuId);
}

/**
* @description
* Returns a generic box contentful modal, with contentfulSlug passed into the config
*
* @param   {String} contentfulSlug - slug used to fetch contentful box content
* @returns {Object|undefined}  an object which can be used to launch a modal or undefined
*/
export function getGenericBoxContentfulModal(contentfulSlug) {
    let boxModal;

    if (!contentfulSlug || typeof contentfulSlug !== 'string') {
        return boxModal;
    }

    boxModal = {
        ...MODALS.boxGenericContentful,
        props: {
            contentfulSlug,
        },
    };

    boxModal.id += `--${contentfulSlug}`;
    boxModal.path = `/box/${contentfulSlug}`;

    return boxModal;
}

/**
* @description
* Returns the box inventory url with query
*
* @param   {String} boxId - the box that contains the skuId
* @returns {String} The box inventory url for connecting to wss:
*/
export function boxInventoryUrl(boxId) {
    return generateUrl(apiUrls.inventoryService, { [BOX_INVENTORY_QUERY_BOX_ID]: boxId, authToken: 'x' });
}
