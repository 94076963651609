import { omit, pick } from 'lodash-es';
import queryString from 'query-string';

import { CATALOG_QUERY_PAGE } from '~coreModules/catalog/js/catalog-constants';

/**
 * Adds/removes a filterOption slug for a given filterGroup to/from a given query
 * @param {Object} query - the query which will have option the added/removed
 * @param {String} filterGroupSlug - query parameter to be assessed
 * @param {String} filterOptionSlug - filter option to be added/removed
 * @returns {Object} the updated query with option (or group) added/removed
 */
export function getUpdatedFilterQuery(query = {}, filterGroupSlug, filterOptionSlug) {
    let newQuery = { ...query };

    if (!filterGroupSlug || !filterOptionSlug) {
        return newQuery;
    }

    const selectedOptions = newQuery[filterGroupSlug];

    if (!selectedOptions) {
        newQuery[filterGroupSlug] = filterOptionSlug;
    } else {
        const options = selectedOptions.split(',');
        const slugIndex = options.findIndex(slug => slug === filterOptionSlug);

        if (slugIndex === -1) {
            options.push(filterOptionSlug);
        } else {
            options.splice(slugIndex, 1);
        }

        if (!options.length) {
            newQuery = omit(newQuery, [filterGroupSlug]);
        } else {
            newQuery[filterGroupSlug] = options.join();
        }
    }

    /* remove page param for all new filter requests */
    delete newQuery[CATALOG_QUERY_PAGE];

    return newQuery;
}

/**
 * returns a comma separated string of query parameters to be passed to the catalog api
 * @param {Object} params - query filter params object to be turned into query string
 * @returns {String} filter string to be passed to the catalog api
 */
export function getFilterStringFromObject(params) {
    return queryString.stringify(params, { arrayFormat: 'comma' });
}

/**
 * returns the index of the number of the first item based on page number and page index
 * @param {String} pageNumber - current page number being viewed
 * @param {String} itemsPerPage - number of items being shown per page
 * @returns {Number} offset of the first item based on pageNumber/itemsPerPage
 */
export function getPaginationOffset(pageNumber, itemsPerPage) {
    const onlyNumbers = values => [...values].every(value => typeof value === 'number');
    if (!onlyNumbers([pageNumber, itemsPerPage])) {
        return 0;
    }
    return (pageNumber - 1) * itemsPerPage;
}

/**
 * returns an formatted post body to be used in a catalog filter request
 * @param {Object} query - the un-formatted query params
 * @param {Array} filterWhitelist - allowed filter groups to be used in catalog filter request
 * @returns {Object} whitelisted filters object
 */
export function getWhitelistedFilterObject(query, filterWhitelist) {
    const whiteListedFilters = pick(query, filterWhitelist);

    Object.entries((whiteListedFilters || [])).forEach(([key, value]) => {
        if (typeof value === 'string') {
            if (value.indexOf(',') === -1) return;
            whiteListedFilters[key] = value.split(',');
        } else {
            whiteListedFilters[key] = value;
        }
    });

    return whiteListedFilters;
}
