import brazeInitMixin from '~coreModules/braze/js/braze-init';

import createRentBrazeStore, {
    BRAZE_MODULE_NAME,
    INIT_RENT_BRAZE,
} from '~modules/braze/js/braze-store';

export default {
    mixins: [brazeInitMixin],
    methods: {
        brazeInitCallback() {
            this.$store.registerModule(BRAZE_MODULE_NAME, createRentBrazeStore());
            this.$store.dispatch(`${BRAZE_MODULE_NAME}/${INIT_RENT_BRAZE}`);
        },
    },
};
