<template>
    <CoreApp
        :shouldRenderContents="!isOverdue"
        :contentIsDoneRendering="handleContentIsDoneRenderingChange"
        :additionalIsScrollLockedCondition="!!activeTour && isAtTopOfPage"
    >
        <template v-if="isOverdue" #aboveContainer>
            <OverdueContainer />
        </template>

        <template #header>
            <!-- NU_FEATURE: CONTENT_CARDS -->
            <!-- https://urbnit.atlassian.net/browse/TYP-25489 -->
            <PencilBanner v-if="$runtimeConfig.features.contentCards" :loggedIn="loggedIn" />
            <RentHeader :loggedIn="loggedIn" />
        </template>
        <template #footer>
            <AsyncAnonymousEmailSubscribe
                v-if="renderEmailSubscribe"
                :isLight="!isAnonymousHomepage"
                :optInLocation="optInLocation"
            />
            <BaseFooter
                :footerNav="footerNav"
                :showFooter="routeIsDoneRendering"
                :appStoreLogoLink="appStoreLogoLink"
            >
                <BaseLink :to="appHomeRoute">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 146.9" v-bind:svg-inline="''" v-bind:class="'c-rental-app__logo'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M77.3 17.6c12.8 0 22.5 3.8 29.1 11.5 6.6 7.6 9.8 18.9 9.8 33.7v53.4H76.7v-45c0-14.4-5.9-21.6-17.8-21.6-13 0-19.5 7.4-19.5 22.3v44.4H0V21.8h39.1v23.6c3.3-9.5 8.1-16.5 14.3-21 6.1-4.5 14.1-6.8 23.9-6.8M214.1 21.8h39.6v94.4h-39.6V96.7c-6.5 15.3-18.9 22.9-37.3 22.9-12.6 0-22.2-3.5-28.8-10.6-6.6-7-9.9-17.1-9.9-30.3v-57h39.6V69c0 7.2 1.4 12.5 4.3 15.9 2.8 3.4 7.3 5.1 13.3 5.1 12.6 0 18.9-7.3 18.9-21.8V21.8zM333.3 119.5c-19.5 0-34-4.4-43.5-13.2-9.5-8.8-14.3-20.2-14.3-34.1V21.7h39.6v45.7c0 7.2 1.5 12.5 4.4 15.9 2.9 3.4 7.5 5.1 13.9 5.1s11-1.7 13.9-5.1c2.9-3.4 4.4-8.7 4.4-15.9V21.7h39.6v50.5c0 14-4.8 25.4-14.4 34.1-9.9 8.8-24.3 13.2-43.6 13.2M413.3 0h39.4v116.2h-39.4zM558.2 21.8l-22.8 50.8-21.3-50.8H472l41.3 85.9-21.1 39.2h44.5L600 21.8z"/></svg>
                </BaseLink>
            </BaseFooter>
        </template>

        <template v-if="!isMobileWebview" #belowContainerContents>
            <IntercomLauncher :shouldHideIntercomIcon="shouldHideIntercomIcon" />
            <WebSocketConnector />
            <NotificationManager :allowNotifications="allowNotifications" />
        </template>
    </CoreApp>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { markRaw, defineAsyncComponent } from 'vue';

import brazeInitMixin from '~modules/braze/js/braze-init';
import metaTagsMixin from '~modules/core/js/seo/meta-tags';
import analyticsInitMixin from '~modules/analytics/js/analytics-init';

import { MODALS_MODULE_NAME } from '~coreModules/modals/js/modals-store';
import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';
import { NAVIGATION_MODULE_NAME } from '~coreModules/nav/js/nav-store';
import { BROWSER_MODULE_NAME } from '~coreModules/browser/js/browser-store';
import { REVIEW_MODULE_NAME, SET_CLOSE_LINK } from '~coreRoutes/review/js/review-store';

import { genericRouteNames } from '~coreModules/core/js/router-constants';
import { NUULY_RENT_APP_STORE_LINK, EMAIL_OPT_IN_LOCATIONS } from '~modules/core/js/constants';
import { BRAZE_MODULE_NAME, REQUEST_BRAZE_CONTENT_CARDS_REFRESH } from '~coreModules/braze/js/braze-store';
import { PRODUCT_TOUR_MODULE_NAME } from '~modules/product-tour/js/product-tour-store';
import { PROFILE_MODULE_NAME, SET_USER_IS_WAITLISTED } from '~modules/profile/js/profile-store';
import { rentalRouteNames } from '~modules/core/js/router-constants';
import { REVIEW_WAITLIST_CONFIRMATION_SLUG } from '~routes/review/js/review-constants';
import { MODALS } from '~modules/modals/js/modal-constants';
import { BOX_MODULE_NAME } from '~modules/box/js/box-store';
import { SUBSCRIPTION_STATUSES } from '~modules/profile/js/profile-constants';

// Load this before importing any components, this ensures that all of our global
// classes come first in the generated app.css file, followed by
// any component-specific classes
import '~coreScss/global/global-styles.scss';
import CoreApp from '~coreModules/core/components/CoreApp.vue';
import BaseFooter from '~coreModules/footer/components/BaseFooter.vue';

import PencilBanner from '~modules/content-cards/components/PencilBanner.vue';
import RentHeader from '~modules/header/components/RentHeader.vue';
import WebSocketConnector from '~modules/core/components/WebSocketConnector.vue';
import IntercomLauncher from '~modules/intercom/components/IntercomLauncher.vue';
import OverdueContainer from '~routes/account/components/overdue/OverdueContainer.vue';
import NotificationManager from '~modules/notifications/components/NotificationManager.vue';

export default {
    name: 'App',
    components: {
        CoreApp,
        BaseFooter,
        IntercomLauncher,
        PencilBanner,
        RentHeader,
        WebSocketConnector,
        OverdueContainer,
        NotificationManager,
        AsyncAnonymousEmailSubscribe: markRaw(
            defineAsyncComponent(
                () => import(
                    /* webpackChunkName: "anonymousEmailSubscribe" */
                    '~coreModules/core/components/ui/AnonymousEmailSubscribe.vue'),
            ),
        ),
    },
    mixins: [brazeInitMixin, metaTagsMixin, analyticsInitMixin],
    data() {
        return {
            routeIsDoneRendering: false,
            appStoreLogoLink: NUULY_RENT_APP_STORE_LINK,
            appHomeRoute: {
                name: genericRouteNames.home,
            },
            unwatch: null,
            optInLocation: EMAIL_OPT_IN_LOCATIONS.FOOTER,
        };
    },
    computed: {
        ...mapState({
            isOverdue: state => state.isOverdue,
        }),
        ...mapGetters([
            'loggedIn',
            'isMobileWebview',
        ]),
        ...mapState(REVIEW_MODULE_NAME, {
            closeLink: state => state.closeLink,
        }),
        ...mapState(BOX_MODULE_NAME, {
            boxOrderStatus: state => state.boxOrderStatus,
        }),
        ...mapState(PROFILE_MODULE_NAME, {
            subscriptionStatus: state => state.subscriptionStatus,
        }),
        ...mapState(PRODUCT_TOUR_MODULE_NAME, ['activeTour']),
        ...mapGetters(NAVIGATION_MODULE_NAME, ['footerNav']),
        ...mapGetters(MODALS_MODULE_NAME, { modalIsOpen: 'isOpen' }),
        ...mapGetters(COMPLIANCE_MODULE_NAME, ['isComplianceNotificationActive']),
        ...mapGetters(BROWSER_MODULE_NAME, ['isAtTopOfPage']),
        isAnonymousHomepage() {
            return !this.loggedIn && this.$route.name === genericRouteNames.home;
        },
        ...mapGetters(PROFILE_MODULE_NAME, ['isWaitlistEligible']),
        renderEmailSubscribe() {
            return !this.loggedIn && this.routeIsDoneRendering;
        },
        hasActiveStickyNotifications() {
            return this.isComplianceNotificationActive ||
                !!this.$store.getters[`${BRAZE_MODULE_NAME}/activeBrazeStickyNotification`];
        },
        allowNotifications() {
            return !!(!this.modalIsOpen && this.routeIsDoneRendering);
        },
        shouldHideIntercomIcon() {
            return this.hasActiveStickyNotifications || !this.allowNotifications;
        },
    },
    watch: {
        boxOrderStatus(newStatus, oldStatus) {
            // NU_FEATURE: CONTENT_CARDS
            // https://urbnit.atlassian.net/browse/TYP-25489
            if (!this.$runtimeConfig.features.contentCards) return;

            if (newStatus && newStatus !== oldStatus) {
                this.requestContentCardRefresh();
            }
        },
        subscriptionStatus(newStatus, oldStatus) {
            // NU_FEATURE: CONTENT_CARDS
            // https://urbnit.atlassian.net/browse/TYP-25489
            if (!this.$runtimeConfig.features.contentCards) return;

            if (oldStatus === SUBSCRIPTION_STATUSES.nonSubscriber || oldStatus === '') return;

            if (newStatus && newStatus !== oldStatus) {
                this.requestContentCardRefresh();
            }
        },
    },
    created() {
        if (this.isOverdue) {
            this.$router.replace({ name: rentalRouteNames.account.paymentRequired });
        }
    },
    mounted() {
        if (this.isWaitlistEligible) {
            this.unwatch = this.$watch('$route.path', (path) => {
                if (path.includes(REVIEW_WAITLIST_CONFIRMATION_SLUG)) this.handleAddUserToWaitlist();
            });
        }
    },
    methods: {
        ...mapMutations(PROFILE_MODULE_NAME, {
            setUserIsWaitlisted: SET_USER_IS_WAITLISTED,
        }),
        ...mapMutations(REVIEW_MODULE_NAME, {
            setCloseLink: SET_CLOSE_LINK,
        }),
        ...mapActions(BRAZE_MODULE_NAME, {
            requestContentCardRefresh: REQUEST_BRAZE_CONTENT_CARDS_REFRESH,
        }),
        handleAddUserToWaitlist() {
            this.setUserIsWaitlisted();
            this.setCloseLink(`${this.closeLink}#${MODALS.boxHomeSubscriberGate.id}`);
            this.unwatch();
        },
        handleContentIsDoneRenderingChange(isDoneRendering) {
            this.routeIsDoneRendering = isDoneRendering;
        },
    },
};
</script>

<style lang="scss">
</style>
