export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "address__firstName--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "address__lastName--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "address__line1--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Address"])},
        "address__line2--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building, Apt, #, Suite"])},
        "address__city--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "address__zip--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip"])}
      }
    }
  })
}
