<template>
    <div
        ref="container"
        v-on-clickaway="handleClickaway"
        :class="{
            'c-tooltip': true,
            'c-tooltip--is-visible': showPopover,
        }"
        @mouseenter="handleMouseOver"
        @mouseleave="handleMouseLeave"
    >
        <div
            ref="launcher"
            v-tab-focus.hasindex.hasrole.onspace="(event) => togglePopover(true, event)"
            v-bind="launcherAttributes"
        >
            <slot name="launcher">
                <BaseIcon
                    :id="tooltipLauncherId"
                    icon="global--tooltip"
                    size="16px"
                    color="primary"
                />
            </slot>
        </div>
        <div
            ref="popover"
            :class="{
                'c-tooltip__popover': true,
                'is-visible': showPopover,
                'c-tooltip__popover--left': alignmentX === 'left',
                'c-tooltip__popover--right': alignmentX === 'right',
                'c-tooltip__popover--above': alignmentY === 'above',
                'c-tooltip__popover--below': alignmentY === 'below',
            }"
            :aria-hidden="`${!showPopover}`"
            role="tooltip"
        >
            <div class="c-tooltip__content">
                <div v-if="illustrationSrc" class="o-flex-horizontal-center u-spacer--1pt5">
                    <Illustration
                        :illustrationSrc="illustrationSrc"
                        illustrationSize="64"
                    />
                </div>
                <div v-if="headerText" :id="tooltipHeaderId" class="c-tooltip__header u-text--center">
                    <h5>{{ headerText }}</h5>
                </div>
                <div
                    :class="{
                        'c-tooltip__body': true,
                        'c-tooltip__body--no-bottom': $slots.footer,
                    }"
                >
                    <slot name="body"></slot>
                </div>
                <a
                    v-if="closeText && !$slots.footer"
                    ref="cta"
                    v-tab-focus="() => closePopover()"
                    class="c-tooltip__footer u-text--center"
                >
                    <span class="o-text--link o-text--caption u-text--no-select">
                        {{ closeText }}
                    </span>
                </a>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { uniqueId } from 'lodash-es';
import { mapState } from 'vuex';

import { BROWSER_MODULE_NAME } from '~coreModules/browser/js/browser-store';

import Illustration from '~coreModules/core/components/ui/Illustration.vue';

export default {
    name: 'BaseTooltip',
    components: {
        Illustration,
    },
    directives: {
        ...(process.env.VUE_ENV === 'client' ? {
            // eslint-disable-next-line global-require
            onClickaway: require('vue3-click-away').directive,
        } : {
            onClickaway: {},
        }),
    },
    props: {
        trigger: {
            type: String,
            default: 'click',
            validator(trigger) {
                // the programmatic option means that no user interaction can directly toggle the tooltip
                return ['click', 'hover', 'programmatic'].includes(trigger);
            },
        },
        tooltipIconClick: {
            type: Function,
            default: null,
        },
        headerText: {
            type: String,
            default: '',
        },
        closeText: {
            type: String,
            default: '',
        },
        tooltipVerticalMargin: {
            // the number of extra margin a tooltip should have on the Y axis, in pixels
            // used to prevent the tooltip from overlapping with elements around the tooltip trigger, e.g. the vertical
            // padding of a parent container
            type: Number,
            default: 0,
        },
        illustrationSrc: {
            type: String,
            default: '',
        },
        enableClickaway: {
            type: Boolean,
            default: true,
        },
        ariaLabel: {
            type: String,
            default: '',
        },
    },
    emits: ['dismiss-cta-triggered', 'mouseenter'],
    data() {
        return {
            alignmentX: 'left',
            alignmentY: 'below',
            showPopover: false,
            mutationObserver: null,
            // the minimum amount of space a tooltip should have between it and the edge of the viewport
            popoverClearance: 16,
        };
    },
    computed: {
        ...mapState(BROWSER_MODULE_NAME, [
            'clientHeight',
            'clientWidth',
        ]),
        uuid() {
            return uniqueId();
        },
        userCanTogglePopover() {
            return this.trigger !== 'programmatic';
        },
        tooltipLauncherId() {
            const { uuid } = this;
            return `tooltip-launcher-${uuid}`;
        },
        tooltipHeaderId() {
            const { uuid } = this;
            return `tooltip-header-${uuid}`;
        },
        launcherAttributes() {
            const isInteractable = this.userCanTogglePopover || !!this.tooltipIconClick;

            return {
                class: {
                    'c-tooltip__launcher-container': true,
                    'c-tooltip__launcher-container--not-clickable': !isInteractable,
                },
                tabindex: isInteractable ? '0' : '-1',
                role: isInteractable ? 'button' : undefined,
                'aria-haspopup': 'true',
                'aria-expanded': this.showPopover.toString(),
                'aria-labelledby': `${this.tooltipHeaderId}`,
                ...(this.ariaLabel && { 'aria-label': this.ariaLabel }),
            };
        },
    },
    watch: {
        clientHeight() {
            this.resizeHandler();
        },
        clientWidth() {
            this.resizeHandler();
        },
    },
    mounted() {
        this.setPopoverPosition();

        this.mutationObserver = new MutationObserver(this.resizeHandler);
        this.mutationObserver.observe(
            this.$refs.container,
            { childList: true, subtree: true },
        );
    },
    unmounted() {
        this.mutationObserver.disconnect();
    },
    methods: {
        resetPopoverPosition() {
            const { popover } = this.$refs;

            popover.style.setProperty('--popoverRightOffset', undefined);
            popover.style.setProperty('--triangleOffset', undefined);
            popover.style.setProperty('--popoverTopMargin', undefined);
        },
        togglePopover(toggledByUser, event) {
            if (this.tooltipIconClick) {
                this.tooltipIconClick(event);
            }

            if ((this.userCanTogglePopover && toggledByUser) || !toggledByUser) {
                this.setPopoverPosition();
                this.showPopover = !this.showPopover;
            }
        },
        closePopover() {
            if (this.userCanTogglePopover) {
                this.showPopover = false;
            }
            this.$emit('dismiss-cta-triggered');
        },
        handleClickaway() {
            if (this.enableClickaway && this.showPopover) {
                this.closePopover();
            }
        },
        setPopoverPosition() {
            if (process.env.VUE_ENV === 'client') {
                const launcherElement = this.$refs.launcher.children.length ?
                    this.$refs.launcher.children[0] :
                    this.$el.querySelector(`#${this.tooltipLauncherId}`);
                const launcherRect = launcherElement.getBoundingClientRect();
                const { popover } = this.$refs;

                this.calculatePopoverXPosition(launcherRect, popover);
                this.calculatePopoverYPosition(launcherRect, popover);
            }
        },
        resizeHandler() {
            this.$nextTick(() => {
                this.resetPopoverPosition();
                this.setPopoverPosition();
            });
        },
        calculatePopoverXPosition(launcherRect, popoverElement) {
            const popoverRect = popoverElement.getBoundingClientRect();
            // the center of the launcher element, expressed as a distance from the edge of the viewport
            const launcherMidpointAbsolute = (launcherRect.right + launcherRect.left) / 2;

            // the center of the launcher element, relative to its total width
            const launcherMidpointRelative = launcherRect.width / 2;

            // controls the horizontal position of the tooltip body
            let rightOffset = 0;

            // controls the triangle's distance from the edge of the tooltip, defaulting to 12px
            let popoverTriangleOffset = 12;

            const triangleWidth = 16;

            // the initial distance the entire tooltip should be shifted so that the triangle is centered perfectly on
            // the launcher, given by half the width of the triangle (8px) plus its default distance from the edge of
            // the tooltip (12px). Further calculation may be needed (see below) if the tooltip is partially offscreen
            const popoverTrianglePositionOffset = popoverTriangleOffset + (triangleWidth / 2);

            // if the majority of the target element is on the right side of the screen, orient the tooltip right
            this.alignmentX = window.innerWidth / 2 > launcherMidpointAbsolute ? 'left' : 'right';

            if (this.alignmentX === 'right') {
                // baseline offset required to line the triangle up with the center of the trigger element outside of
                // this conditional, the position may be modified to ensure the tooltip is fully onscreen
                const tooltipInset = (popoverRect.width - popoverTrianglePositionOffset);

                rightOffset = -(tooltipInset - launcherMidpointRelative);
            } else {
                rightOffset = launcherMidpointRelative - popoverTrianglePositionOffset;
            }

            // ensure that the full tooltip is onscreen. Because we don't support any devices narrower than 316px
            // (given by the tooltip max width of 300px + 8px of left/right clearance), it is guaranteed that the whole
            // tooltip fits onscreen if neither edge overflows the viewport
            const leftEdgePosition = popoverRect.left + rightOffset;
            const rightEdgePosition = popoverRect.right + rightOffset;

            if (leftEdgePosition < this.popoverClearance) {
                // tooltip is overflowing on the left
                const offsetDeficit = leftEdgePosition - this.popoverClearance;
                rightOffset -= offsetDeficit;
                popoverTriangleOffset -= offsetDeficit;
            } else if (rightEdgePosition > (window.innerWidth - this.popoverClearance)) {
                // tooltip is overflowing on the right
                const offsetDeficit = rightEdgePosition - window.innerWidth + this.popoverClearance;
                rightOffset -= offsetDeficit;
                popoverTriangleOffset += offsetDeficit;
            }

            popoverElement.style.setProperty('--popoverRightOffset', `${Math.round(rightOffset)}px`);
            popoverElement.style.setProperty('--popoverTriangleOffset', `${Math.round(popoverTriangleOffset)}px`);
        },
        calculatePopoverYPosition(launcherRect, popoverElement) {
            const popoverRect = popoverElement.getBoundingClientRect();

            const popoverBottom =
                launcherRect.bottom + popoverRect.height + this.tooltipVerticalMargin + this.popoverClearance;

            // determine if the popover will be offscreen if it shows below
            const popoverShouldShowBelow = popoverBottom < window.innerHeight;

            this.alignmentY = popoverShouldShowBelow ? 'below' : 'above';

            let topMargin;
            const triangleHeight = 8;
            const isHover = this.trigger === 'hover';
            const baseOffset = triangleHeight + this.tooltipVerticalMargin;

            if (isHover) {
                // create an invisible, hoverable area so that the user can put their cursor on the tooltip without
                // it dismissing (and reduce the vertical offset accordingly)
                popoverElement.style
                    .setProperty('--popoverHoverableBorder', `${baseOffset}px`);
            }

            if (popoverShouldShowBelow) {
                topMargin = isHover ? launcherRect.height : (baseOffset + launcherRect.height);
            } else {
                topMargin = -(baseOffset + popoverRect.height);
            }

            popoverElement.style.setProperty('--popoverTopMargin', `${Math.round(topMargin)}px`);
        },
        handleMouseOver() {
            if (this.trigger === 'hover') {
                this.$emit('mouseenter');
                this.setPopoverPosition();
                this.showPopover = true;
            }
        },
        handleMouseLeave() {
            if (this.trigger === 'hover') {
                this.showPopover = false;
            }
        },
        focusCta() {
            this.$refs.cta.focus();
        },
    },
};
</script>

<style lang="scss">
    .c-tooltip {
        $this: &;
        position: relative;

        &__launcher-container {
            cursor: pointer;

            &--not-clickable {
                cursor: default;
            }
        }

        &__popover {
            // instead of hiding using v-if/v-show, element must be already rendered prior to being visible in order
            // to calculate the right offset (when alignment === right). Instead, popover is rendered offscreen and
            // invisible to users (incl. those using ARIA)
            position: fixed;
            z-index: -9999999;
            top: -99999px;
            visibility: hidden;
            width: 300px;

            &.is-visible {
                position: absolute;
                z-index: map-get($zindex, modal);
                top: var(--popoverTopMargin);
                left: var(--popoverRightOffset);
                bottom: unset;
                visibility: visible;
                border-color: transparent;
                border-style: solid;
            }

            &--left {
                #{$this}__content::before {
                    left: var(--popoverTriangleOffset);
                }
            }

            &--right {
                #{$this}__content::before {
                    right: var(--popoverTriangleOffset);
                }
            }

            &--above {
                border-bottom: var(--popoverHoverableBorder);

                #{$this}__content {

                    &::before {
                        bottom: -8px;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 8px solid $nu-white;
                    }
                }
            }

            &--below {
                border-top: var(--popoverHoverableBorder);

                #{$this}__content {
                    &::before {
                        top: -8px;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 8px solid $nu-white;
                    }
                }
            }
        }

        &__content {
            @include dropshadow;

            position: relative;
            border-radius: 2px;
            background-color: $nu-white;
            padding-top: $nu-spacer-3;

            &::before {
                // CSS Triangle
                position: absolute;
                content: ' ';
                width: 0;
                height: 0;
            }
        }

        &__header,
        &__body {
            padding-left: $nu-spacer-2;
            padding-right: $nu-spacer-2;
        }

        &__header {
            padding-bottom: $nu-spacer-0pt5;
        }

        &__body {
            padding-bottom: $nu-spacer-3;
            text-align: center;

            &--no-bottom {
                padding-bottom: 0;
            }
        }

        &__footer {
            border-top: 1px solid $nu-gray--light;
            padding: $nu-spacer-1 0;
            width: 100%;
        }
    }
</style>
