export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "header__account--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
        "header__signOut--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])}
      }
    }
  })
}
