import {
    flatMap,
    mapValues,
    omitBy,
} from 'lodash-es';

/**
 * Checks all question sets in a review schema to return the next question set and its index in the review
 *
 * @param  {Number}   slideIndex          Current slide index
 * @param  {[Object]} questionSets        All question sets in review schema
 * @param  {Object}   answeredQuestions   Flat object of all currently- or previously-answered questions
 * @param  {Object}   userData            Data about the user
 *
 * @return {Object} The next question set and its schemaIndex
 */
export function recursivelyGetNextQuestion(slideIndex, questionSets, answeredQuestions, userData = {}) {
    const nextSlideIndex = slideIndex + 1;
    const nextQuestionSet = questionSets[nextSlideIndex];

    if (nextQuestionSet?.conditionalQuestion) {
        const {
            conditionalAnswerId,
            conditionalQuestionId: {
                id: conditionalQuestionId,
            },
        } = nextQuestionSet;
        const targetAnswer = answeredQuestions[conditionalQuestionId];
        let answerMatches;

        if (Array.isArray(conditionalAnswerId)) {
            answerMatches = conditionalAnswerId.find(answer => targetAnswer === answer.id);
        } else {
            answerMatches = targetAnswer === conditionalAnswerId.id;
        }
        if (!answerMatches) {
            return recursivelyGetNextQuestion(nextSlideIndex, questionSets, answeredQuestions, userData);
        }
    }

    return { nextSlideIndex, nextQuestionSet };
}

/**
 * Gets the first value of the first answer in a question set
 * @param {Object} questionSetAnswers - form submission object from a QuestionSet
 * @returns {String}
 */
export function getFirstAnswerValue(questionSetAnswers) {
    const answers = Object.values(questionSetAnswers);
    const firstResponse = answers.length ? answers[0] : '';
    return Array.isArray(firstResponse) ? firstResponse[0] : firstResponse;
}

/**
 * Takes an array of answered question sets and converts it to a flat object
 * @param {Array} reviewArray - array of answered question sets in a review
 * @returns {Object} - an object keyed by slug containing all the answered questions
 */
export function flattenReview(reviewArray) {
    // remove slugs from each question set in review object
    const questionSets = flatMap(reviewArray, item => Object.values(item));

    // for each question set, transform into flat object and transform all the answers
    return questionSets.reduce((reviewObj, currentSection) => {
        // transform all review answers to arrays of strings
        const reviewAnswers = mapValues(currentSection, (answer) => {
            let formattedAnswer = answer;
            if (Array.isArray(answer)) {
                formattedAnswer = formattedAnswer.map(currentValue => currentValue.toString());
            } else {
                formattedAnswer = [formattedAnswer.toString()];
            }

            return formattedAnswer;
        });

        return {
            ...reviewObj,
            ...reviewAnswers,
        };
    }, {});
}

/**
 * Given an object of all questions in a set, remove questions that weren't actually answered
 * @param {Object} allQuestions - all questions in a review question set, whether answered or not
 * @returns {Object} questions that have been answered
 */
export function trimEmptyQuestions(allQuestions) {
    return omitBy(allQuestions, (value) => {
        const isEmptyString = value === '';
        const isEmptyArray = Array.isArray(value) && !value.length;
        const isZero = value === 0;
        return isEmptyString || isEmptyArray || isZero || value === null;
    });
}

/**
 * Given an object of all questions in a set, shape the payload to be sent to review service
 * @param {Object} allQuestions - all questions in a review question set, whether answered or not
 * @param {String} questionSetSlug - slug to denote which question-set is being answered
 * @returns {Object} shaped payload to be sent to review service
 */
export function getFlattenedQuestionsPayload(allQuestions, questionSetSlug) {
    const trimmedQuestions = trimEmptyQuestions(allQuestions);

    const flattenedReview = flattenReview([
        {
            questions: {
                ...trimmedQuestions,
                'question-set': questionSetSlug,
            },
        },
    ]);

    return { questions: flattenedReview };
}

/**
 * Replaces the slug in a url path with a new slug or appends it to the path
 * @param {String} newSlug - the new slug to add to the path
 * @param {Object} route - the current route
 * @returns {String} the built url path with the replaced/appended new slug
 */
export function replaceSlugInPath(newSlug, route) {
    const pathParts = route.path.split('/');
    const endIndex = route.params.slug ? pathParts.length - 1 : pathParts.length;
    const pathPartsWithoutSlug = pathParts.slice(0, endIndex);

    return [...pathPartsWithoutSlug, newSlug].join('/');
}

/**
 * Formats a flat review object for usage within Intercom conversations
 * @param {Object} flattenedReview - the review in question
 * @param {String} reviewSchemeSlug - the slug of the review scheme being submitted
 * @param {String} additionalInfo - additionalInfo to attach to intercom submission
 * @returns {String} - an HTML-formatted string for usage in Intercom
 */
export function shapeIntercomReviewBody(flattenedReview, reviewSchemeSlug, additionalInfo = '') {
    const entries = Object.entries(flattenedReview);
    const reviewString = entries.map(([key, value]) => `${key}: ${value.join(', ')}`);
    const additionalEntry = additionalInfo ? `${additionalInfo}<br><br>` : '';
    return `[${reviewSchemeSlug}]<br><br>${additionalEntry}${reviewString.join('<br><br>')}`;
}
