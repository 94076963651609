import { getRobots } from '~coreModules/core/js/seo/meta-utils';
import metaTagsMixin from '~modules/core/js/seo/meta-tags';

export default {
    mixins: [metaTagsMixin],
    getPageTitle() {
        return this.$t('meta__account--title');
    },
    getMetaTags() {
        const noIndex = true;
        const noFollow = true;

        return [{
            name: 'robots',
            content: getRobots(noIndex, noFollow),
        }, {
            property: 'og:title',
            content: this.$t('meta__account--title'),
        }, {
            property: 'og:description',
            content: this.$t('meta__account--description'),
        }];
    },
};
