import runtimeConfig from '~config/config';

export function initializeIntercom(isLoggedIn, profileId, userHash) {
    const intercomObj = {
        app_id: runtimeConfig.intercomAppId,
        alignment: 'left',
        hide_default_launcher: true,
    };

    if (isLoggedIn) {
        intercomObj.user_id = profileId;
        if (userHash) {
            intercomObj.user_hash = userHash;
        }
    }

    window.Intercom('update', intercomObj);
}

export function shutdownIntercom() {
    window.Intercom('shutdown');
    return Promise.resolve();
}
