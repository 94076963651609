<i18n>
[
    "account__paymentRequired--notificationHeading",
    "account__paymentRequired--notificationSubheading",
    "account__paymentRequired--cta",
]
</i18n>

<template>
    <div class="c-overdue-intro">
        <div class="o-row u-spacer--2">
            <div class="o-extra-small--12">
                <Illustration
                    :illustrationSrc="BoxPaymentFailedIllustration"
                    illustrationSize="128"
                    class=""
                />
            </div>
        </div>
        <div class="o-row u-spacer--1">
            <div class="o-extra-small--12">
                <h3>{{ $t('account__paymentRequired--notificationHeading') }}</h3>
            </div>
        </div>
        <div class="o-row u-spacer--6">
            <div class="o-extra-small--12">
                <p>{{ $t('account__paymentRequired--notificationSubheading') }}</p>
            </div>
        </div>
        <div class="o-row">
            <div class="o-extra-small--12">
                <BaseButton v-tab-focus="() => $emit('intro-viewed')" colorVariant="dark">
                    {{ $t('account__paymentRequired--cta') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import Illustration from '~coreModules/core/components/ui/Illustration.vue';

import BoxPaymentFailedIllustration from '~images/illustration__box--payment-failed.png';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'OverdueIntroSlide',
    components: {
        BaseButton,
        Illustration,
    },
    emits: ['intro-viewed'],
    data() {
        return {
            BoxPaymentFailedIllustration,
        };
    },
};
</script>

<style lang="scss">
    .c-overdue-intro {
        text-align: center;
        height: 100%;
    }
</style>
