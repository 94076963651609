import { snakeCase } from 'lodash-es';

export const PRODUCT_TOUR_NAMES = {
    homepage: 'homepage',
    catalog: 'catalog',
    box: 'box',
    closet: 'closet',
    closetList: 'closetList',
};

export const PRODUCT_TOUR_DATA_NAMES = {
    headerBox: 'header-box',
    headerCloset: 'header-closet',
    sizeFilter: 'size-filter',
    availabilityFilter: 'availability-filter',
    productCloset: 'product-closet',
    boxBuy: 'box-buy',
    productSublist: 'product-sublist',
    closetShareSublist: 'closet-share-sublist',
};

const PRODUCT_TOUR_DATA_TYPES = Object.keys(PRODUCT_TOUR_DATA_NAMES)
    .reduce((acc, key) => Object.assign(acc, {
        [key]: snakeCase(PRODUCT_TOUR_DATA_NAMES[key]),
    }), {});

export const PRODUCT_TOUR_ILLUSTRATION_NAMES = {
    closetIllustration: 'closetIllustration',
    closetTourIllustration: 'closetTourIllustration',
    closetListTourIllustration: 'closetListTourIllustration',
    catalogTourSizesIllustration: 'catalogTourSizesIllustration',
    catalogTourAvailabilityIllustration: 'catalogTourAvailabilityIllustration',
    homepageTourBoxIllustration: 'homepageTourBoxIllustration',
    boxTourBuyIllustration: 'boxTourBuyIllustration',
};

export const LIGHTBOX_SHAPES = {
    rectangle: 'rectangle',
    circle: 'circle',
};

export const HOMEPAGE_TOUR_LIGHTBOXES = {
    boxSmall: {
        selector: 'productTour__home--boxSmall',
        shape: LIGHTBOX_SHAPES.circle,
    },
    boxLarge: {
        selector: 'productTour__home--boxLarge',
        shape: LIGHTBOX_SHAPES.rectangle,
    },
    closet: {
        selector: 'productTour__home--closet',
        shape: LIGHTBOX_SHAPES.circle,
    },
};

export const CATALOG_TOUR_LIGHTBOXES = {
    sizesSmall: {
        selector: 'productTour__catalog--sizes-small',
        shape: LIGHTBOX_SHAPES.rectangle,
    },
    sizesMedium: {
        selector: 'productTour__catalog--sizes-medium',
        shape: LIGHTBOX_SHAPES.rectangle,
    },
    sizesLarge: {
        selector: 'productTour__catalog--sizes-large',
        shape: LIGHTBOX_SHAPES.rectangle,
    },
    availabilitySmall: {
        selector: 'productTour__catalog--availability-small',
        shape: LIGHTBOX_SHAPES.rectangle,
    },
    availabilityMedium: {
        selector: 'productTour__catalog--availability-medium',
        shape: LIGHTBOX_SHAPES.rectangle,
    },
    availabilityLarge: {
        selector: 'productTour__catalog--availability-large',
        shape: LIGHTBOX_SHAPES.rectangle,
    },
    closet: {
        selector: 'productTour__catalog--closet-badge-small',
        shape: LIGHTBOX_SHAPES.circle,
    },
};

export const BOX_TOUR_LIGHTBOXES = {
    buyCta: {
        selector: 'productTour__box--buy-cta',
        shape: LIGHTBOX_SHAPES.rectangle,
    },
};

export const CLOSET_TOUR_LIGHTBOXES = {
    closet: {
        selector: 'productTour__closet--closet-badge-small',
        shape: LIGHTBOX_SHAPES.circle,
        offset: 4,
    },
};

export const CLOSET_LIST_TOUR_LIGHTBOXES = {
    shareButton: {
        selector: 'productTour__closet-list--share-button',
        shape: LIGHTBOX_SHAPES.rectangle,
        offset: 0,
    },
};

const HOMEPAGE_TOUR_DATA = [{
    name: PRODUCT_TOUR_DATA_NAMES.headerBox,
    type: PRODUCT_TOUR_DATA_TYPES.headerBox,
    lightboxes: [
        HOMEPAGE_TOUR_LIGHTBOXES.boxSmall,
        HOMEPAGE_TOUR_LIGHTBOXES.boxLarge,
    ],
    headerSlug: 'productTour__boxHeader--title',
    bodySlug: 'productTour__boxHeader--subtitle',
    ctaSlug: 'productTour__proceed--CTA',
    illustration: PRODUCT_TOUR_ILLUSTRATION_NAMES.homepageTourBoxIllustration,
}, {
    name: PRODUCT_TOUR_DATA_NAMES.headerCloset,
    type: PRODUCT_TOUR_DATA_TYPES.headerCloset,
    lightboxes: [HOMEPAGE_TOUR_LIGHTBOXES.closet],
    headerSlug: 'productTour__closetHeader--title',
    bodySlug: 'productTour__closetHeader--subtitle',
    ctaSlug: 'productTour__complete--CTA',
    illustration: PRODUCT_TOUR_ILLUSTRATION_NAMES.closetIllustration,
}];

const CATALOG_TOUR_DATA = [{
    name: PRODUCT_TOUR_DATA_NAMES.sizeFilter,
    type: PRODUCT_TOUR_DATA_TYPES.sizeFilter,
    lightboxes: [
        CATALOG_TOUR_LIGHTBOXES.sizesSmall,
        CATALOG_TOUR_LIGHTBOXES.sizesMedium,
        CATALOG_TOUR_LIGHTBOXES.sizesLarge,
    ],
    headerSlug: 'productTour__sizesFilters--title',
    bodySlug: 'productTour__sizesFilters--subtitle',
    ctaSlug: 'productTour__proceed--CTA',
    illustration: PRODUCT_TOUR_ILLUSTRATION_NAMES.catalogTourSizesIllustration,
}, {
    name: PRODUCT_TOUR_DATA_NAMES.availabilityFilter,
    type: PRODUCT_TOUR_DATA_TYPES.availabilityFilter,
    lightboxes: [
        CATALOG_TOUR_LIGHTBOXES.availabilitySmall,
        CATALOG_TOUR_LIGHTBOXES.availabilityMedium,
        CATALOG_TOUR_LIGHTBOXES.availabilityLarge,
    ],
    headerSlug: 'productTour__availabilityToggle--title',
    bodySlug: 'productTour__availabilityToggle--subtitle',
    ctaSlug: 'productTour__proceed--CTA',
    illustration: PRODUCT_TOUR_ILLUSTRATION_NAMES.catalogTourAvailabilityIllustration,
}, {
    name: PRODUCT_TOUR_DATA_NAMES.productCloset,
    type: PRODUCT_TOUR_DATA_TYPES.productCloset,
    lightboxes: [CATALOG_TOUR_LIGHTBOXES.closet],
    headerSlug: 'productTour__closetTile--title',
    bodySlug: 'productTour__closetTile--subtitle',
    ctaSlug: 'productTour__complete--CTA',
    illustration: PRODUCT_TOUR_ILLUSTRATION_NAMES.closetIllustration,
}];

const BOX_TOUR_DATA = [{
    name: PRODUCT_TOUR_DATA_NAMES.boxBuy,
    type: PRODUCT_TOUR_DATA_TYPES.boxBuy,
    lightboxes: [BOX_TOUR_LIGHTBOXES.buyCta],
    headerSlug: 'productTour__buyHeader--title',
    bodySlug: 'productTour__buyHeader--subtitle',
    ctaSlug: 'global__gotIt',
    illustration: PRODUCT_TOUR_ILLUSTRATION_NAMES.boxTourBuyIllustration,
}];

const CLOSET_TOUR_DATA = [{
    name: PRODUCT_TOUR_DATA_NAMES.productCloset,
    type: PRODUCT_TOUR_DATA_TYPES.productSublist,
    lightboxes: [CLOSET_TOUR_LIGHTBOXES.closet],
    bodySlug: 'productTour__sublistsClosetAndPdp--subtitle',
    ctaSlug: 'productTour__sublistsClosetAndPdp--cta',
    illustration: PRODUCT_TOUR_ILLUSTRATION_NAMES.closetTourIllustration,
}];

export const CLOSET_LIST_TOUR_DATA = [{
    name: PRODUCT_TOUR_DATA_NAMES.closetShareSublist,
    type: PRODUCT_TOUR_DATA_TYPES.closetShareSublist,
    lightboxes: [CLOSET_LIST_TOUR_LIGHTBOXES.shareButton],
    bodySlug: 'productTour__sublistSharing--subtitle',
    ctaSlug: 'productTour__sublistSharing--cta',
    illustration: PRODUCT_TOUR_ILLUSTRATION_NAMES.closetListTourIllustration,
}];

export const PRODUCT_TOUR_DATA = {
    [PRODUCT_TOUR_NAMES.homepage]: HOMEPAGE_TOUR_DATA,
    [PRODUCT_TOUR_NAMES.catalog]: CATALOG_TOUR_DATA,
    [PRODUCT_TOUR_NAMES.box]: BOX_TOUR_DATA,
};

export const CLOSET_PRODUCT_TOUR_DATA = {
    [PRODUCT_TOUR_NAMES.closet]: CLOSET_TOUR_DATA,
};

export const CLOSET_LIST_PRODUCT_TOUR_DATA = {
    [PRODUCT_TOUR_NAMES.closetList]: CLOSET_LIST_TOUR_DATA,
};

export const PRODUCT_TOURS = (() => Object.getOwnPropertyNames(PRODUCT_TOUR_DATA))();
export const CLOSET_TOUR = (() => Object.getOwnPropertyNames(CLOSET_PRODUCT_TOUR_DATA))();

export const getShapedTourData = tourData => (
    [...CATALOG_TOUR_DATA.filter(({ name }) => name !== tourData.name), ...tourData]);
export const getClosetTourData =
    (shouldShape, tourData = CLOSET_TOUR_DATA) => (shouldShape ? getShapedTourData(tourData) : tourData);

export const PRODUCT_TOUR_QUERY_PARAM = 'productTour';
