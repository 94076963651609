import uuidv4 from 'uuid/v4';
import { get } from 'lodash-es';

import {
    CONTENT_CARDS_MODULE_NAME,
    VALIDATE_CONTENT_CARD,
    GET_CONTENT_CARD_DATA,
} from '~coreModules/content-cards/js/content-cards-store';
import { PROFILE_MODULE_NAME } from '~coreModules/profile/js/profile-store';

export const BRAZE_SDK_ENDPOINT = 'sdk.iad-01.braze.com';

export const BRAZE_MODULE_NAME = 'braze';

export const SET_BRAZE_USER = 'SET_BRAZE_USER';

export const INIT_BRAZE = 'INIT_BRAZE';
export const CHANGE_BRAZE_USER = 'CHANGE_BRAZE_USER';
export const RANDOMIZE_BRAZE_USER = 'RANDOMIZE_BRAZE_USER';
export const TRACK_BRAZE_EVENT = 'TRACK_BRAZE_EVENT';
export const TRACK_BRAZE_CONTENT_CARD_IMPRESSION = 'TRACK_BRAZE_CONTENT_CARD_IMPRESSION';
export const REQUEST_BRAZE_CONTENT_CARDS_REFRESH = 'REQUEST_BRAZE_CONTENT_CARDS_REFRESH';
export const SUBSCRIBE_TO_BRAZE_CONTENT_CARDS_UPDATES = 'SUBSCRIBE_TO_BRAZE_CONTENT_CARDS_UPDATES';

export default function createBrazeStore() {
    return {
        namespaced: true,

        state: {
            brazeUser: '',
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            [SET_BRAZE_USER](state, user) {
                state.brazeUser = user;
            },
        },
        /* eslint-enable no-param-reassign */

        /* eslint-disable no-undef */
        actions: {
            [INIT_BRAZE]({ rootState }, callback = () => {}) {
                const profileId = get(rootState, `${PROFILE_MODULE_NAME}.profileId`);

                braze.initialize(this.$runtimeConfig.brazePublicApiKey, {
                    baseUrl: BRAZE_SDK_ENDPOINT,
                    allowUserSuppliedJavascript: true,
                });

                if (process.env.NODE_ENV !== 'production') {
                    braze.toggleLogging();
                }

                if (profileId) {
                    braze.changeUser(profileId);
                }

                callback?.();

                braze.openSession();
            },
            [CHANGE_BRAZE_USER]({ commit }, user) {
                braze.changeUser(user);
                commit(SET_BRAZE_USER, user);
                braze.requestFeedRefresh();
                return Promise.resolve(user);
            },
            async [RANDOMIZE_BRAZE_USER]({ dispatch }) {
                await dispatch(CHANGE_BRAZE_USER, uuidv4());
            },
            // eslint-disable-next-line no-empty-pattern
            [TRACK_BRAZE_EVENT]({ }, eventInfo) {
                const { action } = eventInfo;
                if (action) {
                    // eslint-disable-next-line no-param-reassign
                    delete eventInfo.action;
                    braze.logCustomEvent(action, eventInfo);
                }
            },
            async [TRACK_BRAZE_CONTENT_CARD_IMPRESSION]({ dispatch }, cardId) {
                const card = await dispatch(`${CONTENT_CARDS_MODULE_NAME}/${GET_CONTENT_CARD_DATA}`,
                    cardId, { root: true });

                if (card) braze.logContentCardImpressions([card]);

                return Promise.resolve(card);
            },
            // eslint-disable-next-line no-empty-pattern
            async [REQUEST_BRAZE_CONTENT_CARDS_REFRESH]({ }, shouldDelay = true) {
                if (shouldDelay) await new Promise(resolver => setTimeout(resolver, 10000));

                braze.requestContentCardsRefresh();

                return Promise.resolve(true);
            },
            [SUBSCRIBE_TO_BRAZE_CONTENT_CARDS_UPDATES]({ dispatch }) {
                braze.subscribeToContentCardsUpdates((updates) => {
                    const cards = updates.cards || [];
                    const shapeCard = card => ({
                        ...(card.extras ?? {}),
                        id: card.id,
                        description: card.description,
                        data: card,
                    });

                    cards.forEach((card) => {
                        if (card.isControl) {
                            braze.logContentCardImpressions([card]);
                        } else {
                            dispatch(`${CONTENT_CARDS_MODULE_NAME}/${VALIDATE_CONTENT_CARD}`,
                                shapeCard(card),
                                { root: true },
                            );
                        }
                    });
                });

                return Promise.resolve(true);
            },
        },
    };
}
