import { findKey, isEmpty, uniqueId } from 'lodash-es';

import { getMissingRequiredFields } from '~coreModules/core/js/validation-utils';

import { MODAL_STYLE_TYPES, GLOBAL_DISMISS_MODALS } from '~coreModules/modals/js/modal-constants';

import { MODALS, getModalHashEvents, MODAL_QUERY_PROPS } from '~modules/modals/js/modal-constants';

export function checkRequiredValue(obj, logger = console) {
    const requiredFields = getMissingRequiredFields(obj);

    if (requiredFields?.length) {
        const msg = requiredFields.length > 1 ?
            `At least one of the following fields are required
                to launch a modal: ${requiredFields.join(', ')}` :
            `${requiredFields[0]} is a required field to launch a modal`;

        logger.error(msg);
        throw new Error(msg);
    }
}

export const defaultModalOptions = {
    titleImage: '',
    showTitle: true,
    isFullBleedContent: false,
    styleType: 'BOTTOM',
    isClosingSlide: false,
    focusFirstElOnLoad: true,
    props: {},
    isLight: true,
    isContentCentered: false,
    headerClass: '',
    hasInnerPadding: true,
    isAuthRequired: false,
};

export function getValidatedModalOptions(modalOptions, logger = console) {

    const mergedModalOptions = {
        ...defaultModalOptions,
        ...modalOptions,
        key: uniqueId(`${modalOptions.id}--`),
    };

    const { id, path, component, title, styleType } = mergedModalOptions;

    /* multiple properites in one of the objects denotes at least 1 of the properties is required */
    const requiredFields = [{ component }, { id }, { path }, { title }];

    requiredFields.forEach((value) => {
        checkRequiredValue(value, logger);
    });

    const modalId = id.split('--')[0];

    if (!Object.keys(MODALS).find(modalKey => MODALS[modalKey].id === modalId)) {
        const msg = `modal id (${modalId}) must be one of the following predefined values:
            ${Object.keys(MODALS).map(modalKey => MODALS[modalKey].id).join(', ')}`;
        logger.error(msg);
        throw new Error(msg);
    }

    if (styleType && !MODAL_STYLE_TYPES.includes(styleType)) {
        const msg = `styleType must be one of the following predefined values: ${MODAL_STYLE_TYPES.join(', ')}`;
        logger.error(msg);
        throw new Error(msg);
    }

    return mergedModalOptions;
}

/**
* @description
* Returns a valid prop object for a modal that is constructed from a url query object
*
* @param   {Object} query - the currently applied query params
* @returns {Object}  the valid props taken from the url query to be passed to a modal
*/
export function getModalPropsFromQuery(query = {}) {
    if (isEmpty(query)) {
        return {};
    }

    return Object.entries(query).reduce((acc, [key, value]) => {
        const validPropKey = findKey(MODAL_QUERY_PROPS,
            propValue => propValue.toLowerCase() === key.toLowerCase());

        return validPropKey && key.startsWith('modal-') ? Object.assign(acc, { [validPropKey]: value }) : acc;
    }, {});
}

/**
* @description
* Returns an object representing a modal based on a hash from the url
*
* @param   {Object} routeObject - the vue route object
* @param   {Object} featureConfig - config telling us which features are turned on/off
* @returns {Object|undefined}  an object which can be used to launch a modal or undefined
*/
export function getModalHashEventWithProps(routeObject, featureConfig) {
    const { hash, query } = routeObject;
    const modalProps = getModalPropsFromQuery(query);
    const modalHashEvents = getModalHashEvents(featureConfig);
    const modalHashEvent = modalHashEvents[hash];

    if (!modalHashEvent) {
        return undefined;
    }

    return {
        ...modalHashEvent,
        props: {
            ...modalHashEvent.props,
            ...modalProps,
        },
    };
}

/**
* @description
* Returns a boolean to tell us if the hash event should dismiss a modal
*
* @param   {Object} modalHashEvent - the modal hash event
* @returns {Boolean}  a boolean which can be used to determine when to dismiss a modal
*/
export const isDismissModalHashEvent =
    modalHashEvent => Object.values(GLOBAL_DISMISS_MODALS).some(modal => modal.id === modalHashEvent?.id);
