<i18n>
[
    "box__authenticatedUnsubscribed--label",
    "box__cancelled--label",
    "box__cancelRequested--label",
    "box__paused--label",
    "box__pauseRequested--label",
    "box__subscriberStates--label",
    "box__terminated--label",
    "box__title",
    "global__items",
]
</i18n>

<template>
    <div class="c-box-launcher">
        <div
            v-if="renderMobileProductTourLightbox"
            class="c-box-launcher__product-tour-lightbox-target"
            :data-product-tour-lightbox="boxLauncherLightboxes.boxSmall.selector"
        >
        </div>
        <UrbnModalLauncher
            :modalOptions="modalOptions"
            type="link"
            class="c-box-launcher__launcher-container"
            @click="$emit('click')"
        >
            <div
                :class="{
                    'c-box-launcher__icon-container': true,
                    'c-box-launcher__icon-container--filled': isBoxFull,
                    'is-animating': isAnimating,
                }"
            >
                <div
                    v-if="renderDesktopProductTourLightbox"
                    class="c-box-launcher__product-tour-lightbox-target"
                    :data-product-tour-lightbox="boxLauncherLightboxes.boxLarge.selector"
                >
                </div>
                <div
                    :class="{
                        'c-box-launcher__icon' : true,
                        'c-box-launcher__icon--flipped' : flipIcon,
                    }"
                >
                    <AppIcon
                        ref="launcherIcon"
                        :icon="iconDetails.icon"
                        :isCoreDir="false"
                        :color="iconColor"
                        size="24px"
                        transitionDuration="0"
                    />
                </div>
                <span class="o-text--caption c-box-launcher__label">{{ iconDetails.label }}</span>
            </div>
        </UrbnModalLauncher>
    </div>
</template>

<script>

import { get, isElement } from 'lodash-es';

import { mapGetters, mapState } from 'vuex';

import { getGenericBoxContentfulModal } from '~modules/box/js/box-utils';
import { MODALS } from '~modules/modals/js/modal-constants';
import { BOX_ORDER_STATUSES, BOX_ICON_NAMES, BOX_ICONS_TO_ANIMATE } from '~modules/box/js/box-constants';
import { HOMEPAGE_TOUR_LIGHTBOXES } from '~modules/product-tour/js/product-tour-constants';
import { SUBSCRIPTION_STATUSES } from '~modules/profile/js/profile-constants';

import UrbnModalLauncher from '~coreModules/modals/components/UrbnModalLauncher.vue';

import { BOX_MODULE_NAME } from '~modules/box/js/box-store';
import { BRAZE_MODULE_NAME } from '~modules/braze/js/braze-store';
import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';

export default {
    name: 'BoxLauncherButton',
    components: {
        UrbnModalLauncher,
    },
    emits: ['click'],
    data() {
        return {
            showModal: false,
            iconColor: 'primary',
            isAnimating: false,
            boxLauncherLightboxes: HOMEPAGE_TOUR_LIGHTBOXES,
            isMounted: false,
            MODALS,
        };
    },
    computed: {
        ...mapState(BOX_MODULE_NAME, {
            boxOrderStatus: state => state.boxOrderStatus,
            boxInfo: state => state.boxInfo,
        }),
        ...mapState(PROFILE_MODULE_NAME, {
            subscriptionStatus: state => state.subscriptionStatus,
        }),
        ...mapGetters(BRAZE_MODULE_NAME, [
            'activeBrazeBoxMessageSlug',
        ]),
        ...mapGetters(BOX_MODULE_NAME, [
            'openBoxHasUnavailableItems',
            'allBoxItems',
            'currentBoxCounterMax',
            'minBoxItemCount',
            'bonusItemsInBoxCount',
            'boxFailedToOpen',
        ]),
        ...mapGetters(PROFILE_MODULE_NAME, [
            'isSubscribed',
            'isWaitlistedUser',
        ]),
        flipIcon() {
            return this.isSubscribed && this.boxOrderStatus === BOX_ORDER_STATUSES.RETURN_STARTED;
        },
        modalOptions() {
            /* render box contentful modal if there is an active braze box message */
            if (this.activeBrazeBoxMessageSlug) {
                return getGenericBoxContentfulModal(this.activeBrazeBoxMessageSlug);
            }

            if (this.isWaitlistedUser) {
                return MODALS.boxHomeSubscriberGate;
            }

            return MODALS.boxHome;
        },
        iconDetails() {
            let slug;
            let icon = '';

            if (this.activeBrazeBoxMessageSlug) {
                slug = 'box__title';
                icon = BOX_ICON_NAMES.pendingMessage;
            } else if (this.subscriptionStatus === SUBSCRIPTION_STATUSES.nonSubscriber) {
                slug = 'box__authenticatedUnsubscribed--label';
                icon = BOX_ICON_NAMES.open;
            } else if (this.subscriptionStatus === SUBSCRIPTION_STATUSES.paused) {
                slug = 'box__paused--label';
                icon = BOX_ICON_NAMES.paused;
            } else if (this.subscriptionStatus === SUBSCRIPTION_STATUSES.pauseRequested) {
                slug = 'box__pauseRequested--label';
                icon = BOX_ICON_NAMES.paused;
            } else if (this.subscriptionStatus === SUBSCRIPTION_STATUSES.cancelled) {
                slug = 'box__cancelled--label';
                icon = BOX_ICON_NAMES.closed;
            } else if (this.subscriptionStatus === SUBSCRIPTION_STATUSES.cancelRequested) {
                slug = 'box__cancelRequested--label';
                icon = BOX_ICON_NAMES.closed;
            } else if (this.subscriptionStatus === SUBSCRIPTION_STATUSES.terminated) {
                slug = 'box__terminated--label';
                icon = BOX_ICON_NAMES.closed;
            } else { // user is subscribed
                slug = 'box__subscriberStates--label';

                if (
                    this.boxOrderStatus === BOX_ORDER_STATUSES.ADDRESS_EXCEPTION ||
                    this.boxOrderStatus === BOX_ORDER_STATUSES.PRODUCT_EXCEPTION ||
                    this.boxOrderStatus === BOX_ORDER_STATUSES.CANCELLED ||
                    this.boxFailedToOpen
                ) {
                    icon = BOX_ICON_NAMES.alert;
                } else if (this.boxOrderStatus === BOX_ORDER_STATUSES.REQUESTED) {
                    icon = BOX_ICON_NAMES.requested;
                } else if (this.boxOrderStatus === BOX_ORDER_STATUSES.SHIPPED) {
                    icon = BOX_ICON_NAMES.packed;
                } else if (this.boxOrderStatus === BOX_ORDER_STATUSES.IN_TRANSIT) {
                    icon = BOX_ICON_NAMES.transit;
                } else if (this.boxOrderStatus === BOX_ORDER_STATUSES.DELIVERED) {
                    icon = BOX_ICON_NAMES.delivered;
                } else if (this.boxOrderStatus === BOX_ORDER_STATUSES.RETURN_STARTED) {
                    icon = BOX_ICON_NAMES.transit;
                } else if (this.boxOrderStatus === BOX_ORDER_STATUSES.RETURNED) {
                    icon = BOX_ICON_NAMES.swap;
                } else if (this.openBoxHasUnavailableItems) {
                    icon = BOX_ICON_NAMES.alert;
                } else {
                    icon = this.bonusItemsInBoxCount ? this.bonusItemIcon : BOX_ICON_NAMES.open;
                }
            }

            icon = `box--${icon}`;

            return {
                icon,
                label: this.$t(slug),
            };
        },
        renderDesktopProductTourLightbox() {
            return this.isMounted && !this.$mediaQueries.isSmallish;
        },
        renderMobileProductTourLightbox() {
            return this.isMounted && this.$mediaQueries.isSmallish;
        },
        isBoxFull() {
            return this.allBoxItems.length && this.allBoxItems.length >= this.minBoxItemCount;
        },
        bonusItemIcon() {
            if (this.isMounted) {
                return !this.$mediaQueries.isSmallish ?
                    BOX_ICON_NAMES.bonusItemsDesktop : BOX_ICON_NAMES.bonusItemsMobile;
            }
            return BOX_ICON_NAMES.open;
        },
    },
    watch: {
        currentBoxCounterMax() {
            this.updateCounter(this.allBoxItems.length);
        },
        'allBoxItems.length': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$nextTick(() => {
                        this.updateCounter(newValue, oldValue);
                    });
                }
            },
            immediate: true,
        },
        openBoxHasUnavailableItems(newValue, oldValue) {
            if (!newValue && newValue !== oldValue) {
                this.$nextTick(() => {
                    this.updateCounter();
                });
            }
        },
    },
    mounted() {
        this.isMounted = true;
        this.$nextTick(() => {
            this.updateCounter();
        });
    },
    updated() {
        this.$nextTick(() => {
            this.updateCounter();
        });
    },
    methods: {
        updateCounter(newCount, oldCount) {
            if ((BOX_ICONS_TO_ANIMATE.includes(this.iconDetails.icon) && this.isSubscribed)) {
                const tspan = get(this.$refs, 'launcherIcon.$el', null);

                if (tspan) {
                    const counterText = tspan.querySelector('#nuuly-counter-text');
                    const counterFont = tspan.querySelector('#nuuly-counter-font');

                    const updateText = () => {
                        if (isElement(counterText)) {
                            counterText.innerHTML =
                                    `${this.allBoxItems.length}/${this.currentBoxCounterMax}`;

                            if (this.currentBoxCounterMax > 9) {
                                counterFont.setAttribute('font-size', '9');
                            } else {
                                counterFont.setAttribute('font-size', '10');
                            }
                        }
                    };

                    // if an item was just added, trigger animation. Otherwise, just decrement
                    if (
                        typeof oldCount !== 'undefined' &&
                            typeof newCount !== 'undefined' &&
                            newCount > oldCount
                    ) {
                        this.isAnimating = true;

                        setTimeout(() => {
                            updateText();
                        }, 220);

                        this.$nextTick(() => {
                            setTimeout(() => {
                                this.isAnimating = false;
                            }, 600);
                        });

                    } else {
                        updateText();
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss">
    .c-box-launcher {
        $this: &;
        position: relative;
        height: 48px;
        width: 40px;
        justify-content: center;

        @include breakpoint(medium) {
            height: auto;
            width: auto;
            justify-content: unset;
        }

        &__product-tour-lightbox-target {
            pointer-events: none;
            height: 40px;
            width: 40px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: ' ';

            @include breakpoint(medium) {
                height: 100%;
                width: 100%;
            }
        }

        &,
        &__launcher-container,
        &__icon-container,
        &__icon {
            display: inline-flex;
            align-items: center;
        }

        &__launcher-container {
            color: $nu-primary;
        }

        &__icon-container {
            color: $nu-primary;
            border-radius: 3px;
            height: 100%;
            position: relative;
            transition: all 0.2s ease;

            &--filled {
                .c-icon {
                    fill: $nu-primary !important;

                    #transparent-body {
                        fill-opacity: 1;
                    }

                    #nuuly-counter-font {
                        fill: $nu-white;
                    }
                }
            }

            @include breakpoint(medium) {
                border: 1px solid $nu-secondary;
                padding: $nu-spacer-1 $nu-spacer-1pt5;
                margin-right: $nu-spacer-1;

                #{$this}:hover &,
                &--filled {
                    @include breakpoint(medium) {
                        color: $nu-white;
                        background-color: $nu-primary;
                        border-color: $nu-primary;

                        .c-icon {
                            fill: $nu-white !important;

                            #transparent-body {
                                fill-opacity: 0;
                            }
                        }
                    }
                }
            }
        }

        &__icon {
            &--flipped {
                transform: scaleX(-1);
            }
        }

        &__label {
            display: none;

            @include breakpoint(medium) {
                margin-left: $nu-spacer-1;
                display: inline-block;
            }
        }

        .is-animating {
            #{$this}__icon {
                animation-name: boxItemAddedIcon;
                animation-duration: 600ms;
                animation-iteration-count: 1;
            }
        }

        @keyframes boxItemAddedIcon {
            0%   { transform: scale(1);   }
            27%  { transform: scale(1.3); }
            45%  { transform: scale(1.3); }
            78%  { transform: scale(0.9); }
            100% { transform: scale(1);   }
        }
    }
</style>
