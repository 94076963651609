<template>
    <Vue3Lottie
        ref="lottie"
        v-bind="$props"
        :width="width"
        :autoPlay="autoPlay"
        @on-animation-loaded="$emit('on-animation-loaded')"
        @on-loop-complete="$emit('on-loop-complete')"
    />
</template>

<script setup>
import { defineAsyncComponent, ref } from 'vue';

const Vue3Lottie = defineAsyncComponent(async () => {
    const module = await import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "vue3Lottie" */
        'vue3-lottie');
    return module.Vue3Lottie;
});

// Any of these props can be passed directly to lottie instance
// https://vue3-lottie.vercel.app/api/props
const props = defineProps({
    animationData: {
        type: Object,
        required: true,
    },
    animationSegments: {
        type: Object,
        default: null,
    },
    lastFrame: {
        type: Number,
        default: null,
    },
    autoPlay: {
        type: Boolean,
        default: false,
    },
    width: {
        type: Number,
        default: 200,
    },
});
defineEmits(['on-animation-loaded', 'on-loop-complete']);

const lottie = ref(null);

function playAnimationSegment(step, isImmediate = false) {
    lottie.value?.playSegments(props.animationSegments[step], isImmediate);
}
function endAnimation() {
    lottie.value?.goToAndStop(props.lastFrame.value);
}
defineExpose({ playAnimationSegment, endAnimation });
</script>

<style lang="scss">

</style>
