// A map of icon names to their alt texts
// All icon files should be SVGs which follow BEM conventions, starting with icon__

export const GLOBAL_ICONS = new Map([
    ['account--promo', 'promo icon'],
    ['account--my-account', 'person icon'],
    ['catalog--filter', 'filter icon'],
    ['global--alert', 'alert icon'],
    ['global--heart-unfilled', 'heart icon'],
    ['global--calendar', 'calendar icon'],
    ['global--checkmark', 'checkmark icon'],
    ['global--checkmark-bordered', 'checkmark icon'],
    ['global--chevron', 'chevron icon'],
    ['global--close', 'close icon'],
    ['global--copy', 'copy icon'],
    ['global--cycle', 'cycle icon'],
    ['global--external-link', 'external link icon'],
    ['global--field-clear', 'clear icon'],
    ['global--field-clear-white', 'clear icon'],
    ['global--hanger', 'hanger icon'],
    ['global--map-pin', 'map pin icon'],
    ['global--menu', 'hamburger menu icon'],
    ['global--minus', 'minus icon'],
    ['global--play-button', 'play media icon'],
    ['global--placeholder', 'placeholder icon'],
    ['global--plus', 'plus icon'],
    ['global--plus-thick', 'plus icon'],
    ['global--edit', 'edit icon'],
    ['global--right-arrow', 'right arrow icon'],
    ['global--search', 'search icon'],
    ['global--settings', 'settings icon'],
    ['global--share', 'share icon'],
    ['global--sort', 'dropdown icon icon'],
    ['global--star', 'star icon'],
    ['global--star-half', 'half-star icon'],
    ['global--tooltip', 'tooltip icon'],
    ['message--empty', 'message icon'],
    ['message--notification', 'message notification icon'],
    ['social--facebook', 'facebook icon'],
    ['social--instagram', 'instagram icon'],
    ['social--linkedin', 'linkedin icon'],
    ['social--pinterest', 'pinterest icon'],
    ['social--tiktok', 'tiktok icon'],
    ['social--twitter', 'twitter icon'],
    ['social--youtube', 'youtube icon'],
    ['video--pause', 'pause icon'],
    ['video--play', 'play icon'],
    ['video--replay', 'replay icon'],
]);

export default GLOBAL_ICONS;
