export const CATALOG_IMAGE_VIEWER_MAX_NUM_IMAGES = 10;
export const CATALOG_IMAGE_VIEWER_DESKTOP_SLIDE_SIZE = 1;
export const CATALOG_IMAGE_VIEWER_MEDIUM_SLIDE_SIZE = 1;
export const CATALOG_IMAGE_VIEWER_MOBILE_SLIDE_SIZE = 1;
export const CATALOG_IMAGE_VIEWER_MOBILE_PEEK_PERCENT = 20;

export const CATALOG_QUERY_SIZE = 'size';
export const CATALOG_QUERY_IN_STOCK = 'inStock';
export const CATALOG_QUERY_PAGE = 'pageNumber';
export const CATALOG_QUERY_SORT = 'sort';
export const CATALOG_QUERY_SEARCH = 'q';
export const CATALOG_QUERY_SLUG = 'slug';
