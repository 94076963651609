export default function injectResource(filename, filetype, onLoadCallback) {
    let fileElement;
    const elementId = `injected-resource-${filename}`;

    if (document.getElementById(elementId)) {
        onLoadCallback();
        return;
    }

    if (filetype === 'js') {
        fileElement = document.createElement('script');
        fileElement.setAttribute('type', 'text/javascript');
        if (onLoadCallback) {
            fileElement.onload = onLoadCallback;
        }
        fileElement.setAttribute('src', filename);
    } else if (filetype === 'css') {
        fileElement = document.createElement('link');
        fileElement.setAttribute('rel', 'stylesheet');
        fileElement.setAttribute('type', 'text/css');

        if (onLoadCallback) {
            fileElement.onload = onLoadCallback;
        }
        fileElement.setAttribute('href', filename);
    } else {
        throw new Error(`Injecting filetype of ${filetype} has not been implemented`);
    }
    if (typeof fileElement !== 'undefined') {

        fileElement.setAttribute('id', elementId);
        document.getElementsByTagName('head')[0].appendChild(fileElement);
    }
}
