import { last, omit } from 'lodash-es';

import { getValidatedModalOptions, isDismissModalHashEvent } from '~coreModules/modals/js/modal-utils';

export const MODALS_MODULE_NAME = 'modals';

export const POP_MODAL = 'POP_MODAL';
export const LAUNCH_MODAL = 'LAUNCH_MODAL';
export const POP_MODALS = 'POP_MODALS';
export const UPDATE_MODAL_TITLE = 'UPDATE_MODAL_TITLE';
export const SET_OMIT_PAGEVIEW_EVENT = 'SET_OMIT_PAGEVIEW_EVENT';
export const HANDLE_MODAL_HASH_EVENT = 'HANDLE_MODAL_HASH_EVENT';
export const LAUNCH_MODAL_DEBOUCE_MS = 500;

// Private mutations
const PUSH_MODAL = 'PUSH_MODAL';

// Privately scoped array of promise resolve functions associated with active
// modals.  Assumes that modals are never server side rendered, as this cannot
// be communicated upwards to the client
const modalResolvers = [];
const modalComponents = [];
let isLaunching = false;

export default {
    namespaced: true,
    state: () => ({
        // Array of active modal objects of the format:
        modals: [],
        omitPageviewEvent: false,
    }),
    mutations: {
        [POP_MODAL](state) {
            if (state.modals.length > 0) {
                state.modals.pop();
                // Pop and execute the resolver
                modalResolvers.pop()();
                modalComponents.pop();
            }
        },
        [PUSH_MODAL](state, { opts, resolver }) {
            state.modals.push(omit(opts, 'component'));
            modalResolvers.push(resolver);
            modalComponents.push(opts.component);
        },
        [UPDATE_MODAL_TITLE](state, title) {
            const lastModal = state.modals[state.modals.length - 1];
            lastModal.title = title;
        },
        [SET_OMIT_PAGEVIEW_EVENT](state, omitPageviewEvent) {
            /* eslint-disable-next-line no-param-reassign */
            state.omitPageviewEvent = omitPageviewEvent;
        },
    },
    actions: {
        async [LAUNCH_MODAL]({ state, commit, dispatch, rootGetters, getters }, opts) {
            let resolver;
            // eslint-disable-next-line no-return-assign
            const promise = new Promise(resolve => resolver = resolve);
            let validOptions;

            if (opts.isAuthRequired && !rootGetters.loggedIn) {
                this.$logger.debugError(`user is not logged in and authentication is required,
                    cancelling launch of: ${opts.id}`);
                return Promise.reject();
            }

            if (isLaunching) {
                await new Promise(resolve => setTimeout(resolve, LAUNCH_MODAL_DEBOUCE_MS));
            }

            if (!isLaunching) {
                isLaunching = true;
                const previousModalModalStyleType = getters.activeModal?.styleType;

                if (previousModalModalStyleType && previousModalModalStyleType !== opts.styleType) {
                    await dispatch(POP_MODALS);
                }

                try {
                    validOptions = getValidatedModalOptions(opts, this.$logger);
                } catch (error) {
                    return Promise.reject(error);
                } finally {
                    setTimeout(() => {
                        isLaunching = false;
                    }, LAUNCH_MODAL_DEBOUCE_MS);
                }

                // reset omitPageviewEvent
                if (state.omitPageviewEvent) {
                    commit(SET_OMIT_PAGEVIEW_EVENT, false);
                }

                if (opts.isClosingSlide) {
                    dispatch(POP_MODALS);
                }

                commit(PUSH_MODAL, { opts: validOptions, resolver });
            }

            return promise;
        },
        [POP_MODALS]({ commit, state }, { closingModalKey = null, omitPageviewEvent = false } = {}) {
            if (state.omitPageviewEvent !== omitPageviewEvent) {
                commit(SET_OMIT_PAGEVIEW_EVENT, omitPageviewEvent);
            }

            while (state.modals.length && last(state.modals).key !== closingModalKey) {
                commit(POP_MODAL);
            }
        },
        [HANDLE_MODAL_HASH_EVENT]({ commit, dispatch }, opts) {
            if (isDismissModalHashEvent(opts)) return commit(POP_MODAL);

            return dispatch(LAUNCH_MODAL, opts);
        },
    },
    getters: {
        isOpen(state) {
            return state.modals.length > 0;
        },
        modals(state) {
            return state.modals.map((modal, i) => ({ component: modalComponents[i], ...modal }));
        },
        activeModal(state) {
            return state.modals[state.modals.length - 1];
        },
        activeModalId(state, getters) {
            const { activeModal } = getters;
            return activeModal ? activeModal.id : '';
        },
    },
};
