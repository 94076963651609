<template>
    <LazyLoad
        :observerCallback="handleObserverCallback"
        class="c-content-card-carousel"
    >
        <div class="c-content-card-carousel__container">
            <ContentCard
                v-for="(contentCard, index) in contentCards"
                :key="index"
                inert
                :description="contentCard.description"
                :subheading="contentCard.subhead"
                :templateType="contentCard.templateType"
                :ctaDestination="contentCard.ctaDestination"
                class="c-content-card-carousel__card c-content-card-carousel__card--hidden"
            />
            <Transition
                v-for="(contentCard, index) in contentCards"
                :key="index"
                name="c-content-card-carousel__transition"
            >
                <ContentCardManager
                    v-if="index === currentIndex"
                    :contentCardId="contentCard.id"
                    :description="contentCard.description"
                    :subheading="contentCard.subhead"
                    :ctaDestination="contentCard.ctaDestination"
                    :bannerDestination="contentCard.bannerDestination"
                    :templateType="contentCard.templateType"
                    :analyticsData="analyticsData"
                    :backgroundColor="CONTENT_CARD_COLORS[contentCard.color]"
                    class="c-content-card-carousel__card"
                />
            </Transition>
            <ContentCardCarouselButtons
                v-if="showCarouselButtons"
                :buttonColor="CONTENT_CARD_COLORS[currentContentCard.color] ? 'primary' : 'white'"
                class="c-content-card-carousel__buttons"
                @click="handleButtonClick"
            />
        </div>
    </LazyLoad>
</template>

<script setup>
import { onMounted, onUnmounted, ref, computed, watch, toRefs, nextTick } from 'vue';
import { debounce } from 'lodash-es';

import { useActions, useGetters } from '~coreModules/core/js/composables/vuexHelpers';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { PAGINATION_SCROLL_TYPES } from '~coreModules/analytics/js/analytics-constants';
import { CONTENT_CARD_SCROLL, VIEW_CONTENT_CARD } from '~coreModules/core/js/global-event-constants';

import { MODALS_MODULE_NAME } from '~coreModules/modals/js/modals-store';

import { NEXT, DEBOUNCE_DELAY } from '~coreModules/core/js/constants';
import {
    CONTENT_CARD_COLORS,
    CONTENT_CARD_CAROUSEL_SCROLL_ANIMATION_DELAY,
} from '~coreModules/content-cards/js/content-cards-constants';

import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';
import ContentCard from '~coreModules/content-cards/components/ContentCard.vue';
import ContentCardManager from '~coreModules/content-cards/components/ContentCardManager.vue';
import ContentCardCarouselButtons from '~coreModules/content-cards/components/ContentCardCarouselButtons.vue';

const props = defineProps({
    contentCards: {
        type: Array,
        required: true,
    },
    contentCardType: {
        type: String,
        required: true,
    },
});

const { isModalOpen } = useGetters(MODALS_MODULE_NAME, {
    isModalOpen: 'isOpen',
});
const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });

const currentIndex = ref(0);
const isPaused = ref(false);
const { contentCards, contentCardType } = toRefs(props);
let intervalTimer;

const currentContentCard = computed(() => props.contentCards[currentIndex.value]);
const contentCardSlug = computed(() => currentContentCard.value.slug || '');
const analyticsData =
    computed(() => ({ contentCardType: contentCardType.value, contentCardSlug: contentCardSlug.value }));

const totalContentCards = computed(() => contentCards?.value.length || 0);
const showCarouselButtons = computed(() => totalContentCards.value > 1);

const setCurrentIndex = debounce((direction, scrollType) => {
    const step = direction === NEXT ? 1 : -1;
    currentIndex.value = (currentIndex.value + step + totalContentCards.value) %
        totalContentCards.value;
    trackGlobalEvent({
        type: CONTENT_CARD_SCROLL,
        data: {
            ...analyticsData.value,
            scrollType,
            direction,
            index: currentIndex.value,
            totalContentCards: totalContentCards.value,
            contentModuleId: currentContentCard.value.title,
        },
    });
}, DEBOUNCE_DELAY);

function handleButtonClick(direction) {
    setCurrentIndex(direction, PAGINATION_SCROLL_TYPES.MANUAL);
}
function handlePagination() { setCurrentIndex(NEXT, PAGINATION_SCROLL_TYPES.AUTO); }
function startPagination() {
    if (showCarouselButtons.value && !isPaused.value) {
        intervalTimer = setInterval(handlePagination, CONTENT_CARD_CAROUSEL_SCROLL_ANIMATION_DELAY);
    }
}
function resetPagination() {
    clearInterval(intervalTimer);
    startPagination();
}

function handleObserverCallback(entries) {
    isPaused.value = entries.find(entry => !entry.isIntersecting);
}

watch(currentIndex, (newValue, oldValue) => (
    newValue !== oldValue && newValue > -1 && resetPagination()));
watch([isModalOpen, isPaused], newValues => (
    newValues.some(value => value) ? clearInterval(intervalTimer) : startPagination()));

onMounted(() => {
    nextTick().then(() => trackGlobalEvent({ type: VIEW_CONTENT_CARD, data: analyticsData.value }));
});
onUnmounted(() => clearInterval(intervalTimer));
</script>

<style lang="scss">
.c-content-card-carousel {
    width: 100vw;
    overflow: hidden;
    position: relative;

    &__container {
        display: grid;
    }

    &__card {
        width: 100vw;
        height: 100%;
        text-align: center;
        padding: $nu-spacer-2 $nu-spacer-8;
        grid-row: 1;
        grid-column: 1;

        @include breakpoint(large) {
            padding: $nu-spacer-1pt5 $nu-spacer-8;
        }

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__transition {
        &-enter-active,
        &-leave-active {
            transition: opacity 1s ease-in-out;

            @include breakpoint(medium) {
                transition: opacity 1.5s ease-in-out;
            }
        }

        &-enter-from,
        &-leave-to {
            opacity: 0;
        }

        &-enter-to,
        &-leave-from {
            opacity: 1;
        }
    }
}
</style>
