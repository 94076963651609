<template>
    <div
        class="o-illustration c-illustration"
        :class="illustrationSizeClass"
    >
        <img
            :src="illustrationSrc"
            class="o-illustration__img"
            alt=""
        >
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Illustration',
    props: {
        illustrationSrc: {
            type: String,
            required: true,
        },
        illustrationSize: {
            type: [String, Number],
            default: '80',
            validator(size) {
                return [
                    32, 40, 48, 56, 64, 80, 96, 104, 128, 176, 200, 256, 320,
                    '32', '40', '48', '56', '64', '80', '96', '104', '128', '176', '200', '256', '320',
                ].includes(size);
            },
        },
    },
    computed: {
        illustrationSizeClass() {
            return `o-illustration--${this.illustrationSize.toString()}px`;
        },
    },
};
</script>

<style lang="scss">
    .c-illustration {

    }
</style>
