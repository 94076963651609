<template>
    <component :is="isLoggedIn ? AsyncBrazeContentCard : AsyncContentCard" v-bind="$props" />
</template>

<script setup>
import { defineAsyncComponent, markRaw } from 'vue';

import { useGetters } from '~coreModules/core/js/composables/vuexHelpers';

const AsyncBrazeContentCard = markRaw(
    defineAsyncComponent(
        () => import(
            /* webpackChunkName: "brazeContentCard" */
            '~coreModules/braze/components/BrazeContentCard.vue'),
    ));
const AsyncContentCard = markRaw(
    defineAsyncComponent(
        () => import(
            /* webpackChunkName: "contentCard" */
            '~coreModules/content-cards/components/ContentCard.vue'),
    ));

const { isLoggedIn } = useGetters({ isLoggedIn: 'loggedIn' });
</script>

<style lang="scss">

</style>
