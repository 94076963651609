import { NUU_RENTAL_APPLICATION_CHANNEL as channel } from '~coreModules/core/js/constants';
import createApp from '~coreModules/core/js/create-app';

import runtimeConfig from '~config/config';
import App from '~modules/core/components/App.vue';
import logger from '~modules/core/js/logger';
import createRouter from '~modules/core/js/router';
import createStore from '~modules/core/js/store';
import getValidationLocalizations from '~modules/core/js/validation-localizations';
import AppIcon from '~modules/core/components/ui/AppIcon.vue';

// Isomorphic function used by both the client and the server to ensure we
// create identical Vue app instances
export default function createRentApp(context) {
    const opts = {
        App,
        logger,
        createRouter,
        createStore,
        runtimeConfig,
        channel,
        getValidationLocalizations,
    };

    // destructure whats needed for extending
    const { app, ...rest } = createApp(context, opts);

    // example app specific plugin registration
    // app.use(MyAppSpecificPlugin)

    app
        .component('AppIcon', AppIcon);

    return { app, ...rest };
}
