<script>
import { h } from 'vue';
import { formObserver } from '~coreModules/core/js/provider-symbol-constants';

export default {
    name: 'FormContext',
    inject: {
        observer: {
            from: formObserver,
            default: () => ({}),
        },
    },
    props: {
        tag: {
            type: String,
            default: 'div',
        },
        context: {
            type: Function,
            required: true,
        },
    },
    computed: {
        isObserved() {
            return !!Object.keys(this.observer)?.length;
        },
    },
    mounted() {
        if (this.isObserved) this.pushContext(this.context?.());
    },
    beforeUnmount() {
        if (this.isObserved) this.removeContext(this.context?.());
    },
    methods: {
        removeContext(context) {
            return this.observer.contexts?.splice(
                this.observer.contexts?.indexOf(context),
                1,
            );
        },
        pushContext(context) {
            return this.observer.contexts?.push(context);
        },
    },
    render() {
        return h(this.tag, this.$slots.default());
    },
};
</script>

<style lang="scss">

</style>
