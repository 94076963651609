import runtimeConfig from '~config/config';
import { NUU_HEADER_AUTH_CODE_BYPASS } from '~coreModules/core/js/constants';

const { apiUrls, testing } = runtimeConfig;

/**
 * Send verification email
 * @param {string} email - the user's email address
 * @returns {Promise}
 */
export function sendVerificationEmail(r15Svc, email) {
    const requestConfig = {};

    if (testing.useAuthCodeBypass) {
        requestConfig.headers = { [NUU_HEADER_AUTH_CODE_BYPASS]: true };
    }

    const options = {
        email,
        verificationType: 'email',
    };

    return r15Svc.post(`${apiUrls.authService}/code/request`, options, requestConfig);
}

/**
 * Send verification sms to phone
 * @param {string} phoneNumber - the user's phone number
 * @returns {Promise}
 */
export function sendVerificationSms(r15Svc, phoneNumber) {
    const requestConfig = {};

    if (testing.useAuthCodeBypass) {
        requestConfig.headers = { [NUU_HEADER_AUTH_CODE_BYPASS]: true };
    }

    const options = {
        phoneNumber,
        verificationType: 'phone',
    };

    return r15Svc.post(`${apiUrls.authService}/code/request`, options, requestConfig);
}

/**
 * Verify's a user's email login
 * @param {string} email - the user's email address
 * @param {string} verificationCode - the user's verificationCode
 * @returns {Promise}
 */
export function verifyEmailLogin(r15Svc, email, verificationCode) {
    const options = {
        email,
        verificationCode,
        verificationType: 'email',
    };

    return r15Svc.post(`${apiUrls.authService}/verify`, options);
}

/**
 * Verify's a user's phone login
 * @param {string} phoneNumber - the user's phone number
 * @param {string} verificationCode - the user's verificationCode
 * @returns {Promise}
 */
export function verifyPhoneLogin(r15Svc, phoneNumber, verificationCode) {
    const options = {
        phoneNumber,
        verificationCode,
        verificationType: 'phone',
    };

    return r15Svc.post(`${apiUrls.authService}/verify`, options);
}

/**
 * Logs out the user
 * @returns {Promise}
 */
export function logoutUser(r15Svc) {
    return r15Svc.get(`${apiUrls.authService}/logout`);
}
