import { cloneDeep, merge } from 'lodash-es';

/**
 * Updates the nuData object with the new session data and adds a pageview event to the nuEventQueue
 * @param {Object} state - the current session data
 * @param {Object} pageData - page level data
 */
export function trackPageview(state, pageData = {}) {
    let dimensions = cloneDeep(state);
    dimensions = merge({}, dimensions, pageData);

    const eventObj = {
        type: 'page_view',
        data: dimensions,
    };

    window.nuData = window.nuData || {};
    window.nuEventQueue = window.nuEventQueue || [];

    window.nuData = dimensions;
    window.nuEventQueue.push(eventObj);
}

/**
 * Updates the nuData object with the new session data if specified and adds an event to the nuEventQueue
 * @param {Object} state - the current session data
 * @param {Object} eventInfo - the event information (should include action at a minimum)
 * @param {Boolean} setNuData - flag to determine if nuData should be updated with session data
 */
export function trackEvent(state, eventInfo = {}, setNuData = true) {
    let dimensions = cloneDeep(state);

    if (setNuData) {
        window.nuData = dimensions;
    }

    dimensions = merge({}, dimensions, eventInfo);

    const eventObj = {
        type: 'event',
        data: dimensions,
    };

    if (Object.keys(eventInfo).length > 0) {
        eventObj.eventInfo = eventInfo;
    }

    window.nuEventQueue.push(eventObj);
}
