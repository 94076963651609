import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { SIGN_UP_COMPLETED } from '~coreModules/core/js/global-event-constants';

import { SUBSCRIPTION_CREATE_COMPLETED } from '~modules/core/js/global-event-constants';

import {
    BRAZE_MODULE_NAME,
    CHANGE_BRAZE_USER,
} from '~coreModules/braze/js/braze-store';

import { TRACK_BRAZE_EVENT } from '~modules/braze/js/braze-store';

export default function brazeActionListener(store, action) {
    function dispatch(actionToDispatch, actionPayload = {}) {
        return store.dispatch(`${BRAZE_MODULE_NAME}/${actionToDispatch}`, actionPayload);
    }

    const { payload, type } = action;

    if (type === GLOBAL_EVENT) {
        const eventType = payload.type;
        const eventData = payload.data;

        switch (eventType) {
        case SIGN_UP_COMPLETED:
            dispatch(CHANGE_BRAZE_USER, eventData.id);
            break;
        case SUBSCRIPTION_CREATE_COMPLETED:
            dispatch(TRACK_BRAZE_EVENT, {
                action: eventData.action,
                label: eventData.planId,
            });
            break;

        default:
            break;
        }
    }
}
