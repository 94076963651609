<template>
    <div :class="['c-app', isScrollLocked && 'is-scroll-locked' ]">
        <noscript>
            Your browser does not have JS enabled, you are still able to browse
            the website but you wont be able to access advanced features such
            as editing or logging in.
        </noscript>

        <slot name="aboveContainer"></slot>

        <template v-if="shouldRenderContents">
            <div
                ref="contents"
                :tabindex="modalIsOpen ? '-1' : undefined"
                :aria-hidden="modalIsOpen ? 'true' : undefined"
                :style="modalScrollOffsetStyles"
                class="c-app__container"
            >
                <template v-if="!isMobileWebview">
                    <AccessibilityJumpLinks
                        :class="{
                            'c-app__jump-links': true,
                            'c-app__jump-links--focused': isJumpLinkFocused
                        }"
                        @focus="jumpLinkFocused"
                        @unfocus="jumpLinkFocused"
                    />
                    <template v-if="!ignoreBaseLayout">
                        <slot name="header"></slot>
                    </template>
                </template>

                <RouteContentLoader
                    class="c-app__main-content"
                    :ignoreBaseLayout="ignoreBaseLayout"
                    :routeIsLoading="routeIsLoading"
                    @content-is-done-rendering="contentIsDoneRendering"
                />

                <div v-if="renderFooter" class="c-app__footer-container">
                    <slot name="footer"></slot>
                </div>
            </div>

            <UrbnModalSingleton
                :contentEl="$refs.contents"
                :scrollTopWithoutHeader="scrollTopWithoutHeader"
            />

            <slot name="belowContainerContents"></slot>
        </template>
    </div>
</template>

<script>
import {
    debounce,
    isEqual,
    throttle,
} from 'lodash-es';
import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState,
} from 'vuex';

import { DEBOUNCE_DELAY } from '~coreModules/core/js/constants';
import { PAGEVIEW } from '~coreModules/core/js/global-event-constants';

import { getModalHashEventWithProps } from '~coreModules/modals/js/modal-utils';

import { GLOBAL_EVENT, SET_ROUTE_IS_LOADING } from '~coreModules/core/js/store';
import {
    BROWSER_MODULE_NAME,
    GET_SCROLL_INFORMATION,
    GET_CLIENT_DIMENSIONS,
    SET_BROWSER_INFO,
} from '~coreModules/browser/js/browser-store';
import { MODALS_MODULE_NAME, HANDLE_MODAL_HASH_EVENT, POP_MODALS } from '~coreModules/modals/js/modals-store';

import jsonLDMixin from '~coreModules/core/js/seo/json-ld';

// Load this before importing any components, this ensures that all of our global
// classes come first in the generated app.css file, followed by
// any component-specific classes
import '~coreScss/global/global-styles.scss';

import RouteContentLoader from '~coreModules/core/components/RouteContentLoader.vue';
import AccessibilityJumpLinks from '~coreModules/core/components/AccessibilityJumpLinks.vue';
import UrbnModalSingleton from '~coreModules/modals/components/UrbnModalSingleton.vue';

export default {
    name: 'CoreApp',
    components: {
        RouteContentLoader,
        AccessibilityJumpLinks,
        UrbnModalSingleton,
    },
    mixins: [jsonLDMixin],
    props: {
        shouldRenderContents: {
            type: Boolean,
            default: true,
        },
        contentIsDoneRendering: {
            type: Function,
            default: null,
        },
        additionalIsScrollLockedCondition: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            boundResizeListener: null,
            boundScrollListener: null,
            modalScrollOffsetStyles: {},
            isJumpLinkFocused: false,
        };
    },
    computed: {
        ...mapState({
            routeIsLoading: state => state.routeIsLoading,
        }),
        ...mapState(BROWSER_MODULE_NAME, ['scrollTopWithoutHeader']),
        ...mapState(MODALS_MODULE_NAME, ['omitPageviewEvent']),
        ...mapGetters([
            'loggedIn',
            'isMobileWebview',
        ]),
        ...mapGetters(MODALS_MODULE_NAME, {
            modalIsOpen: 'isOpen',
            activeModal: 'activeModal',
        }),
        ignoreBaseLayout() {
            return this.$route.meta.ignoreBaseLayout;
        },
        isScrollLocked() {
            return this.modalIsOpen || this.additionalIsScrollLockedCondition;
        },
        renderFooter() {
            return !this.ignoreBaseLayout && !this.isMobileWebview;
        },
    },
    watch: {
        $route(to, from) {
            const toPath = to?.path?.split('?')?.[0];
            const toQuery = to?.query;
            const toHash = to?.hash;
            const fromPath = from?.path?.split('?')?.[0];
            const fromQuery = from?.query;
            const fromHash = from?.hash;

            // pop modals if the route path is changing
            if (this.modalIsOpen && toPath !== fromPath) {
                this.popModals({ omitPageviewEvent: true });
            }

            // if path and query are equal, it was a hash change
            if ((isEqual(toPath, fromPath) && isEqual(toQuery, fromQuery)) || (toHash && fromHash !== toHash)) {
                this.handleModalFromHash();
            }
        },
        isScrollLocked: {
            handler(isScrollLocked) {
                if (isScrollLocked && this.scrollTopWithoutHeader) {
                    this.modalScrollOffsetStyles = {
                        top: `-${this.scrollTopWithoutHeader}px`,
                        position: 'relative',
                    };
                } else if (!isScrollLocked) {
                    this.modalScrollOffsetStyles = {};
                }

                if (process.env.VUE_ENV === 'client') {
                    if (isScrollLocked) {
                        document.getElementsByTagName('html')[0].classList.add('scroll-lock');
                    } else {
                        document.getElementsByTagName('html')[0].classList.remove('scroll-lock');
                    }
                }
            },
            immediate: true,
        },
        /* Emit PAGE_VIEW events for modals */
        activeModal(newModal, oldModal) {
            if (!isEqual(newModal, oldModal) && !this.omitPageviewEvent) {
                const parents = this.$route.matched.map(parent => ({ ...parent }));
                let currentPage = this.getModalRoute();

                if (!currentPage) {
                    currentPage = this.$route;
                    parents.pop();
                }

                this.trackGlobalEvent({
                    type: PAGEVIEW,
                    data: {
                        currentPage,
                        parents,
                    },
                });
            }
        },
    },
    created() {
        if (process.env.VUE_ENV === 'client') {
            this.setBrowserInfo();
        }
    },
    mounted() {
        this.bindScrollListener();
        this.bindResizeListener();
        this.isMounted = true;

        /* eslint-disable-next-line global-require */
        require('focus-visible/dist/focus-visible.min.js');

        this.setRouteIsLoading(false);

        // Load any direct route hash-modals
        this.handleModalFromHash();
    },
    unmounted() {
        this.unbindScrollListener();
        this.unbindResizeListener();
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        ...mapActions(BROWSER_MODULE_NAME, {
            getScrollInformation: GET_SCROLL_INFORMATION,
            getClientDimensions: GET_CLIENT_DIMENSIONS,
        }),
        ...mapActions(MODALS_MODULE_NAME, {
            handleModalHashEvent: HANDLE_MODAL_HASH_EVENT,
            popModals: POP_MODALS,
        }),
        ...mapMutations({
            setRouteIsLoading: SET_ROUTE_IS_LOADING,
        }),
        ...mapMutations(BROWSER_MODULE_NAME, {
            setBrowserInfo: SET_BROWSER_INFO,
        }),
        getModalRoute() {
            if (this.activeModal) {
                const { path, analytics } = this.activeModal;
                let modalPath = path;

                if (!/^\//.test(modalPath)) {
                    const { path: routePath } = this.$route;
                    modalPath = `${routePath}/${modalPath}`;
                }

                return {
                    path: modalPath,
                    meta: {
                        analytics,
                    },
                };
            }
            return null;
        },
        bindScrollListener() {
            if (process.env.VUE_ENV === 'client' && !this.boundScrollListener) {
                this.boundScrollListener = throttle(this.getScrollInformation, 30);
                window.addEventListener('scroll', this.boundScrollListener);
                this.getScrollInformation();
            }
        },
        unbindScrollListener() {
            if (this.boundScrollListener) {
                window.removeEventListener('scroll', this.boundScrollListener);
                this.boundScrollListener = null;
            }
        },
        bindResizeListener() {
            if (process.env.VUE_ENV === 'client' && !this.boundResizeListener) {
                this.boundResizeListener = debounce(
                    () => {
                        this.getClientDimensions();
                        this.setRootVh();
                    },
                    DEBOUNCE_DELAY,
                );
                window.addEventListener('resize', this.boundResizeListener);
                this.getClientDimensions(true);
                this.setRootVh();
            }
        },
        unbindResizeListener() {
            if (this.boundResizeListener) {
                window.removeEventListener('resize', this.boundResizeListener);
                this.boundResizeListener = null;
            }
        },
        setRootVh() {
            const documentStyle = document.documentElement.style;
            const vh = window.innerHeight;

            documentStyle.setProperty('--vh100', `${vh}px`);
            documentStyle.setProperty('--vh', `${vh * 0.01}px`);
        },
        handleModalFromHash() {
            const modalHashEvent = getModalHashEventWithProps(this.$route, this.$runtimeConfig.features);

            if (modalHashEvent) {
                this.handleModalHashEvent(modalHashEvent);
            }
        },
        jumpLinkFocused(isFocused) {
            this.isJumpLinkFocused = isFocused;
        },
    },
};
</script>

<style lang="scss">
    @include js-breakpoint-support();

    html.scroll-lock {
        &, body {
            height: var(--vh100);
            overflow: hidden;
            box-sizing: border-box;
        }
    }

    .c-app {
        &.is-scroll-locked {
            position:fixed;
            height: var(--vh100);
            overflow: hidden;
            left: 0;
            right: 0;
        }

        &__container {
            $this: &;

            display: flex;
            flex-direction: column;
            min-height: var(--vh100);

            &__jump-links {
                &--focused ~ #{$this}__header,
                &--focused + #{$this}__main-content .c-slide-layout__header {
                    top: $nu-spacer-3;
                }
            }
        }
    }
</style>
