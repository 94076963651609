<template>
    <button class="c-header-button">
        <span class="c-header-button__label-text o-text--caption">
            <slot></slot>
        </span>
    </button>
</template>

<script>
export default { name: 'BaseHeaderButton' };
</script>

<style lang="scss">
    .c-header-button {
        outline: none;
        cursor: pointer;
        padding: 0 $nu-spacer-1;
        background-color: $nu-primary;
        border: 1px solid $nu-primary;
        border-radius: 2px;
        color: $nu-white;
        height: 28px;
        white-space: nowrap;
        transition: all 0.2s ease;

        @include breakpoint(medium) {
            padding: 0 $nu-spacer-4;
            height: 40px;
            display: inline-block;
        }

        &:hover,
        &:active {
            background-color: $nu-white;
            border: 1px solid $nu-primary;
            color: $nu-primary;
        }

        &__label-text {
            @include breakpoint(medium) {
                font-size: 1.4rem;
                letter-spacing: 0.4px;
                line-height: 24px;
                text-transform: none;
            }
        }
    }
</style>
