<script>
import { get } from 'lodash-es';

/*
    This directive, used as v-auto-focus, will give focus to an element when it is rendered.
    While it can technically be used on anything, it is most useful for use directly on <input>
    elements OR components which have a method called focus() (for cases where an input is a child
    of a component, such as BaseInput, and focus requires custom handling).

    The directive can be used with no argument, e.g.
        <input type="text" v-auto-focus>
    or it can be passed a boolean to programmatically toggle the autofocus, e.g.
        <input type="text" v-auto-focus="!$mediaQueries.isSmallish" />
    Useful for changing behavior on mobile or for when you have a component which will need autofocus sometimes but
    not others, so can control it with a prop

    In general, consult UX before using this, since it has the potential to both improve and damage the user experience
    depending on the context
 */
export default {
    name: 'AutoFocus',
    mounted(el, binding, vnode) {
        // only short circuit iff Boolean false; if there is no arg, the focus logic should trigger
        if (binding.value !== false) {
            const vmFocus = get(vnode, 'componentInstance.focus', null);

            if (typeof vmFocus === 'function') {
                vmFocus();
            } else {
                el.focus();
            }
        }
    },
};
</script>
