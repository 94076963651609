// TODO: Replace this with a non-duplicated pure css solution
// when removing this file, also remove the reference in the site color change instructions in _colors.scss
export const nuColorPrimary = '#365261';
export const nuColorSecondary = '#FAF7F4'; // use -20 hex value
export const nuColorBlack = '#2C2C2C';
export const nuColorGray = '#6C6C6C';
export const nuColorMediumGray = '#D7D7D7';
export const nuColorLightGray = '#F7F7F7';
export const nuColorWhite = '#FFFFFF';
export const nuColorTransparent = 'rgba(0,0,0,0)';
export const nuColorModalOverlay = 'rgba($nu-black, 0.2)';
