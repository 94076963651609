<template>
    <div class="c-pencil-banner">
        <Transition name="c-pencil-banner__transition">
            <div v-if="shouldRenderCarousel" class="c-pencil-banner__container">
                <ContentCardCarousel
                    :contentCards="activeContentCards"
                    :contentCardType="CONTENT_CARD_IDS.GLOBAL_PENCIL_BANNER"
                />
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';

import { useActions, useGetters } from '~coreModules/core/js/composables/vuexHelpers';

import { CONTENT_CARD_IDS } from '~coreModules/analytics/js/analytics-constants';
import { CONTENT_CARD_TYPES } from '~coreModules/content-cards/js/content-cards-constants';
import {
    CONTENT_CARDS_MODULE_NAME,
    VALIDATE_CONTENT_CARD,
} from '~coreModules/content-cards/js/content-cards-store';
import { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';

import ContentCardCarousel from '~coreModules/content-cards/components/ContentCardCarousel.vue';

const props = defineProps({
    loggedIn: {
        type: Boolean,
        default: false,
    },
});

const { anonymousPromoBanners } = useGetters(SITE_SETTINGS_MODULE_NAME, {
    anonymousPromoBanners: 'anonymousGlobalPromoBanners',
});
const { getActiveContentCards } = useGetters(CONTENT_CARDS_MODULE_NAME, ['getActiveContentCards']);
const { validateContentCard } = useActions(CONTENT_CARDS_MODULE_NAME, { validateContentCard: VALIDATE_CONTENT_CARD });

const activeContentCards = computed(() => getActiveContentCards(CONTENT_CARD_TYPES.pencilBanner));
const shouldRenderCarousel = computed(() => activeContentCards.value?.length > 0);

const shapedCards = computed(() => anonymousPromoBanners.value.map(card => ({
    subhead: card.bannerSubhead,
    description: card.bannerHeadlineAndDestination?.[0]?.linkTitle,
    bannerDestination: card.bannerHeadlineAndDestination?.[0]?.linkPath,
    ...card,
    bannerSubhead: undefined,
    bannerHeadlineAndDestination: undefined,
})));

onMounted(() => {
    if (!props.loggedIn) shapedCards.value.forEach(card => validateContentCard(card));
});
</script>

<style lang="scss">
.c-pencil-banner {
    &__container {
        display: grid;
        grid-template-rows: 1fr;
        width: 100vw;
    }

    &__transition {
        &-enter-active {
            overflow: hidden;
            transition: grid-template-rows 0.5s ease, opacity 0.4s ease-in;
            transition-delay: 1s;
        }

        &-enter-from {
            grid-template-rows: 0fr;
            opacity: 0;
        }

        &-enter-to {
            grid-template-rows: 1fr;
            opacity: 1;
        }
    }
}
</style>
