import { get, isEmpty, merge } from 'lodash-es';

import createBrazeStore, {
    INIT_BRAZE,
    SUBSCRIBE_TO_BRAZE_CONTENT_CARDS_UPDATES,
    REQUEST_BRAZE_CONTENT_CARDS_REFRESH,
} from '~coreModules/braze/js/braze-store';

import { validateBrazeInAppMessage } from '~modules/braze/js/braze-utils';

import {
    BRAZE_PREDEFINED_STICKY_NOTIFICATIONS,
    BRAZE_NOTIFICATION_DISPLAY_TYPES,
    BRAZE_NOTIFICATION_TYPES,
} from '~modules/braze/js/braze-constants';

export { BRAZE_MODULE_NAME } from '~coreModules/braze/js/braze-store';

export const DISMISS_BRAZE_BOX_MESSAGE = 'DISMISS_BRAZE_BOX_MESSAGE';
export const DISMISS_BRAZE_MODAL = 'DISMISS_BRAZE_MODAL';
export const DISMISS_BRAZE_STICKY_NOTIFICATION = 'DISMISS_BRAZE_STICKY_NOTIFICATION';

export const SET_BRAZE_IN_APP_MESSAGE_DATA = 'SET_BRAZE_IN_APP_MESSAGE_DATA';

export const SUBSCRIBE_TO_BRAZE_IN_APP_MESSAGES = 'SUBSCRIBE_TO_BRAZE_IN_APP_MESSAGES';

export const INIT_RENT_BRAZE = 'INIT_RENT_BRAZE';
export const TRACK_BRAZE_BOX_MESSAGE_IMPRESSION = 'TRACK_BRAZE_BOX_MESSAGE_IMPRESSION';
export const TRACK_BRAZE_MODAL_IMPRESSION = 'TRACK_BRAZE_MODAL_IMPRESSION';
export const TRACK_BRAZE_STICKY_NOTIFICATION_IMPRESSION = 'TRACK_BRAZE_STICKY_NOTIFICATION_IMPRESSION';
export const TRACK_BRAZE_STICKY_NOTIFICATION_CLICK = 'TRACK_BRAZE_STICKY_NOTIFICATION_CLICK';
export const TRACK_BRAZE_EVENT = 'TRACK_BRAZE_EVENT';

// Privately scoped object which maintains the current in-app message for each
// notification display type recieved from braze. Storing this here
// instead of in vuex state because the object is fairly large, and we only need this
// original message object to send analytics 'impressions' to braze
// This assumes that everything braze related is never server side rendered, as this cannot
// be communicated upwards to the client (Braze relies on the window so will only ever exist client-side)
// only exporting for unit testing, should not be used outside of this file
export const activeBrazeInAppMessage = {
    [BRAZE_NOTIFICATION_DISPLAY_TYPES.box]: {},
    [BRAZE_NOTIFICATION_DISPLAY_TYPES.modal]: {},
    [BRAZE_NOTIFICATION_DISPLAY_TYPES.sticky]: {},
};

/* eslint-disable no-undef */
function trackBrazeImpressionForDisplayType(displayType, logger) {
    const inAppMessage = activeBrazeInAppMessage[displayType];
    if (!isEmpty(inAppMessage)) {
        braze.logInAppMessageImpression(inAppMessage);
    } else {
        logger.debugError(`attempted to logInAppMessageImpression
            but no activeBrazeInAppMessage[${displayType}] is set in braze-store`);
    }
}

function trackBrazeClickForDisplayType(displayType, logger) {
    const inAppMessage = activeBrazeInAppMessage[displayType];

    if (!isEmpty(inAppMessage)) {
        braze.logInAppMessageClick(inAppMessage);
    } else {
        logger.debugError(`attempted to logInAppMessageClick for braze,
            but no activeBrazeInAppMessage[${displayType}] is set in braze-store`);
    }
}
/* eslint-enable no-undef */

export default function createRentBrazeStore() {
    return merge(createBrazeStore(), {
        namespaced: true,

        state: {
            activeInAppMessageData: {
                [BRAZE_NOTIFICATION_DISPLAY_TYPES.box]: {},
                [BRAZE_NOTIFICATION_DISPLAY_TYPES.modal]: {},
                [BRAZE_NOTIFICATION_DISPLAY_TYPES.sticky]: {},
            },
        },

        /* eslint-disable no-param-reassign */

        mutations: {
            [SET_BRAZE_IN_APP_MESSAGE_DATA](state, message) {
                const messageData = get(message, 'extras', {});
                let keyToEdit;

                switch (messageData.notificationType) {
                case BRAZE_NOTIFICATION_TYPES.stickyNotification:
                case BRAZE_NOTIFICATION_TYPES.predefinedStickyNotification:
                    keyToEdit = BRAZE_NOTIFICATION_DISPLAY_TYPES.sticky;
                    break;
                case BRAZE_NOTIFICATION_TYPES.box:
                    keyToEdit = BRAZE_NOTIFICATION_DISPLAY_TYPES.box;
                    break;
                case BRAZE_NOTIFICATION_TYPES.modal:
                    keyToEdit = BRAZE_NOTIFICATION_DISPLAY_TYPES.modal;
                    break;
                default:
                    break;
                }

                if (!keyToEdit) {
                    this.$logger.debugError(`no braze notification display type found for
                        the following notificationType: ${messageData.notificationType}`);
                } else {
                    activeBrazeInAppMessage[keyToEdit] = message;
                    state.activeInAppMessageData[keyToEdit] = messageData;
                }
            },
            [DISMISS_BRAZE_BOX_MESSAGE](state) {
                activeBrazeInAppMessage[BRAZE_NOTIFICATION_DISPLAY_TYPES.box] = {};
                state.activeInAppMessageData[BRAZE_NOTIFICATION_DISPLAY_TYPES.box] = {};
            },
            [DISMISS_BRAZE_MODAL](state) {
                activeBrazeInAppMessage[BRAZE_NOTIFICATION_DISPLAY_TYPES.modal] = {};
                state.activeInAppMessageData[BRAZE_NOTIFICATION_DISPLAY_TYPES.modal] = {};
            },
            [DISMISS_BRAZE_STICKY_NOTIFICATION](state) {
                activeBrazeInAppMessage[BRAZE_NOTIFICATION_DISPLAY_TYPES.sticky] = {};
                state.activeInAppMessageData[BRAZE_NOTIFICATION_DISPLAY_TYPES.sticky] = {};
            },
        },
        /* eslint-enable no-param-reassign */

        /* eslint-disable no-undef */
        actions: {
            async [INIT_RENT_BRAZE]({ dispatch }) {
                await dispatch(INIT_BRAZE, () => {
                    dispatch(SUBSCRIBE_TO_BRAZE_IN_APP_MESSAGES);
                    // NU_FEATURE: CONTENT_CARDS
                    // https://urbnit.atlassian.net/browse/TYP-25489
                    if (this.$runtimeConfig.features.contentCards) {
                        dispatch(SUBSCRIBE_TO_BRAZE_CONTENT_CARDS_UPDATES);
                        dispatch(REQUEST_BRAZE_CONTENT_CARDS_REFRESH, false);
                    }
                });
            },
            [SUBSCRIBE_TO_BRAZE_IN_APP_MESSAGES]({ commit, rootGetters }) {
                const { loggedIn } = rootGetters;

                braze.subscribeToInAppMessage((inAppMessage) => {
                    try {
                        validateBrazeInAppMessage(inAppMessage, loggedIn, this.$logger);
                        commit(SET_BRAZE_IN_APP_MESSAGE_DATA, inAppMessage);
                    } catch (error) {
                        this.$logger.debugError(error);
                    }
                });

                return Promise.resolve(true);
            },
            [TRACK_BRAZE_BOX_MESSAGE_IMPRESSION]() {
                trackBrazeImpressionForDisplayType(BRAZE_NOTIFICATION_DISPLAY_TYPES.box, this.$logger);
                return Promise.resolve(true);
            },
            [TRACK_BRAZE_MODAL_IMPRESSION]() {
                trackBrazeImpressionForDisplayType(BRAZE_NOTIFICATION_DISPLAY_TYPES.modal, this.$logger);
                return Promise.resolve(true);
            },
            [TRACK_BRAZE_STICKY_NOTIFICATION_IMPRESSION]() {
                trackBrazeImpressionForDisplayType(BRAZE_NOTIFICATION_DISPLAY_TYPES.sticky, this.$logger);
                return Promise.resolve(true);
            },
            [TRACK_BRAZE_STICKY_NOTIFICATION_CLICK]() {
                trackBrazeClickForDisplayType(BRAZE_NOTIFICATION_DISPLAY_TYPES.sticky, this.$logger);
                return Promise.resolve(true);
            },
            // eslint-disable-next-line no-empty-pattern
            [TRACK_BRAZE_EVENT]({ }, eventInfo) {
                const { action } = eventInfo;
                if (action) {
                    // eslint-disable-next-line no-param-reassign
                    delete eventInfo.action;
                    braze.logCustomEvent(action, eventInfo);
                }
            },
        },

        getters: {
            /* Object used to render a braze notification */
            activeBrazeStickyNotification(state) {
                const stickyNotificationData =
                    get(state, `activeInAppMessageData.${BRAZE_NOTIFICATION_DISPLAY_TYPES.sticky}`);
                if (isEmpty(stickyNotificationData)) {
                    return null;
                }
                if (stickyNotificationData.notificationType === BRAZE_NOTIFICATION_TYPES.predefinedStickyNotification) {
                    const stickyNotification = BRAZE_PREDEFINED_STICKY_NOTIFICATIONS[stickyNotificationData.slug];
                    return !stickyNotification ? null : {
                        ...stickyNotification,
                        ...stickyNotificationData,
                    };
                }
                return stickyNotificationData;
            },
            activeBrazeModalId(state) {
                return get(state, `activeInAppMessageData.${BRAZE_NOTIFICATION_DISPLAY_TYPES.modal}.slug`, '');
            },
            activeBrazeBoxMessageSlug(state) {
                return get(state, `activeInAppMessageData.${BRAZE_NOTIFICATION_DISPLAY_TYPES.box}.slug`, '');
            },
        },
    });
}
