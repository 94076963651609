import { isEmpty, difference } from 'lodash-es';

import { getMissingRequiredFields } from '~coreModules/core/js/validation-utils';

import {
    BrazeInAppMessageSchemas,
    BRAZE_LOGIN_STATUSES,
} from '~modules/braze/js/braze-constants';

/**
 * checks if the required fields are present in the given object
 *
 * @param {Object} obj - the object to check for required fields
 * @param {String} notificationType - the type of notification
 * @param {Object} logger - the logger instance
 * @throws {Error} throws an error if any required fields are missing
 */
export function checkRequiredValues(obj, notificationType, logger = console) {
    const requiredFields = getMissingRequiredFields(obj);

    if (requiredFields?.length) {
        const msg = requiredFields.length > 1 ?
            `At least one of the following fields are required
                to launch a braze in app message: ${requiredFields.join(', ')}, for
                    notificationType: ${notificationType}` :
            `${requiredFields[0]} is a required field to launch a braze in app message`;

        logger.error(msg);
        throw new Error(msg);
    }
}

/**
 * validates a braze in app message object against a predefined schema
 *
 * @param {Object} brazeMessage - the brazeMessage object to validate
 * @param {Boolean} loggedIn - indicates whether the user is logged in
 * @param {Object} logger - the logger instance
 * @throws {Error} if the in app message is empty or if it does not have a notificationType property
 */
export function validateBrazeInAppMessage(brazeMessage, loggedIn, logger) {
    const messageData = brazeMessage?.extras ?? {};

    if (isEmpty(messageData)) {
        throw new Error(`error rendering braze in app message, no json was
            provided for following in-app message: `, brazeMessage);
    }

    const { notificationType, loginStatus } = messageData;

    if (!notificationType) {
        throw new Error(`error rendering braze in app message,
            notificationType was not set for following in-app message: `, brazeMessage);
    }

    if (loginStatus) {
        if (loggedIn && loginStatus === BRAZE_LOGIN_STATUSES.anonymous) {
            throw new Error(`error rendering braze in app message,
                user is authenticated and only anonymous users can see the following message: `, brazeMessage);
        } else if (!loggedIn && loginStatus === BRAZE_LOGIN_STATUSES.authenticated) {
            throw new Error(`error rendering braze in app message,
                user is anonymous and only authenticated users can see the following message: `, brazeMessage);
        }
    }

    const messageSchema = BrazeInAppMessageSchemas[notificationType];

    if (!messageSchema) {
        throw new Error(`error rendering braze in app message, no JSON schema found for
            notificationType: ${notificationType}, in the following message: \n`, brazeMessage);
    }

    const requiredFields = messageSchema?.requiredFields || [];
    const unrequiredFields = messageSchema?.unrequiredFields || [];
    const allDefinedFields = [...requiredFields, ...unrequiredFields];
    const receivedFields = Object.keys(messageData);
    const nonConfiguredFields = difference(receivedFields, allDefinedFields);

    /* log error but dont prevent rendering message if fields were sent through braze that are not defined in
       the schema for this notificationType */
    if (!isEmpty(nonConfiguredFields)) {
        logger.error(`the following fields were sent from braze, but are not defined in the
            schema for this notificationType: ${nonConfiguredFields.join(', ')}\n`);
    }

    try {
        requiredFields.forEach((value) => {
            checkRequiredValues({ [value]: messageData[value] }, notificationType, logger);
        });
    } catch (error) {
        throw new Error(error);
    }
}
