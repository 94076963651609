<template>
    <div class="c-slide-layout">
        <component
            :is="slideHeader"
            v-if="!isMobileWebview || showSlideHeader || showProgressBar"
            :showProgressBar="showProgressBar"
            :showBackButton="showBackButton"
            :closeLink="closeLink"
            :logoLink="logoLink"
            :reloadPageOnExit="reloadPageOnExit"
            class="c-slide-layout__header"
        >
            <template #logo>
                <slot name="logo"></slot>
            </template>
            <template #progressbar>
                <slot name="progressbar"></slot>
            </template>
        </component>
        <main
            class="c-slide-layout__main-content o-flex-horizontal-center o-flex-vertical-center"
            :class="{
                'c-slide-layout__main-content--centered': !Number.isInteger(topMargin),
                'c-slide-layout__main-content--mobile-webview': isMobileWebview
            }"
            :style="topMarginOverride"
        >
            <AsyncError500 v-if="is400" :contentfulContentBlock="contentfulContentBlock" />
            <AsyncError404 v-else-if="is404" :contentfulContentBlock="contentfulContentBlock" />
            <AsyncError500 v-else-if="is500" :contentfulContentBlock="contentfulContentBlock" />
            <div v-else class="c-slide-layout__inner">
                <slot></slot>
            </div>
        </main>
    </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { get } from 'lodash-es';
import { mapGetters } from 'vuex';

import SlideHeader from '~coreModules/core/components/ui/SlideHeader.vue';

export default {
    name: 'CoreSlideLayout',
    components: {
        // Async import so it's only pulled client side if a 400, 404 or 500 actually occurs
        AsyncError404: defineAsyncComponent(() => import('~coreModules/core/components/errors/Error404.vue')),
        AsyncError500: defineAsyncComponent(() => import('~coreModules/core/components/errors/Error500.vue')),
    },
    props: {
        contentfulContentBlock: {
            type: [Function, Object],
            required: true,
        },
        slideHeader: {
            type: [Function, Object],
            default: markRaw(SlideHeader),
        },
        closeLink: {
            type: [Object, String, Function],
            default: '/',
        },
        logoLink: {
            type: String,
            default: '/',
        },
        /* if you should refresh the page when clicking the X close icon */
        reloadPageOnExit: {
            type: Boolean,
            default: false,
        },
        showBackButton: {
            type: Boolean,
            default: false,
        },
        showSlideHeader: {
            type: Boolean,
            default: false,
        },
        topMargin: {
            type: Number,
            default: null,
        },
    },
    computed: {
        ...mapGetters([
            'isMobileWebview',
        ]),
        topMarginOverride() {
            return this.topMargin ? { marginTop: `${this.topMargin}px` } : {};
        },
        is400() {
            return get(this.$store, 'state.routingError.code') === 400;
        },
        is404() {
            return get(this.$store, 'state.routingError.code') === 404;
        },
        is500() {
            return get(this.$store, 'state.routingError.code') === 500;
        },
        showProgressBar() {
            return this.$slots?.progressbar?.({}).some(vnode => !!vnode.children?.length);
        },
    },
};
</script>

<style lang="scss">
    .c-slide-layout {
        $this: &;

        // create new stacking context to avoid z-index issues
        position: relative;

        &__header {
            z-index: 1;
        }

        &__main-content {

            // TODO: this is to offset the header height, consider using a var to set this
            padding-top: $nu-spacer-6;
            z-index: 0;

            @include breakpoint(medium) {
                padding-top: $nu-spacer-8;
            }

            &--centered {
                overflow: scroll;
                min-height: 100vh;

                #{$this}__inner {
                    padding: $nu-spacer-8 0;
                    flex: 1;

                    @include breakpoint(medium) {
                        padding: $nu-spacer-12 0;
                    }
                }
            }

            &--mobile-webview {
                padding-top: 0;
            }
        }

        &__inner {
            flex: 1;
        }
    }
</style>
