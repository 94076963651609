import { createI18n } from 'vue-i18n';

import { loadRealTimeTranslations } from '~coreModules/core/js/es5/contentful';

export function loadTranslations(app, realtimeContentfulSlugs, runtimeConfig) {
    /* eslint-disable no-param-reassign */
    const { contentful } = runtimeConfig;
    const {
        spaceId,
        accessToken,
        environment,
    } = contentful;

    const { language: locale } = app.$store.state.siteSettings;
    const { $logger: logger } = app;

    logger.debug('Loading real time translations for locale ', locale);
    return loadRealTimeTranslations({
        spaceId,
        accessToken,
        environment,
        locale,
        slugs: realtimeContentfulSlugs,
    })
        .then((translations) => {
            // Set the initial set of real-time translations for the active locale
            logger.debug('Setting initial locale to ', locale);
            app.$i18n.locale = locale;
            app.$i18n.global.setLocaleMessage(locale, translations);
        })
        .catch((e) => {
            // TODO: What to do here?  Swallowing the error for now so we
            // still render the page?  But we may consider throwing a 500?
            logger.error('Error fetching translations: ', e);
        });
}

export function interpolateStringWithObjectValues(string, values) {
    return string.replace(/{(.*?)}/g, (match, offset) => values[offset]);
}

// Overriding default pluralization rules used by .tc() method
// default logic can be found here https://github.com/intlify/vue-i18n-next/blob/6f24b629367261dd17fbc833259458fb0e63589e/packages/core-base/src/runtime.ts#L126-L137
export function customPluralizationRules(choice, choicesLength) {
    // rounding down choice value, because any fractions between 0 - 1 and 1 - 2 will cause vue-i18n to error
    // everything else is the same as default behavior
    choice = Math.abs(Math.floor(choice));

    if (choicesLength === 2) {
        if (!choice) return 1;
        return choice > 1 ? 1 : 0;
    }
    return choice ? Math.min(choice, 2) : 0;
}

export default function createI18nInstance(locale, translations, runtimeConfig) {
    const opts = {
        locale,
        global: true,
        allowComposition: true,
        numberFormats: runtimeConfig.numberFormats,
        datetimeFormats: runtimeConfig.dateTimeFormats,
        // Only mix in initial translations when sent along (client side)
        ...(translations ? {
            messages: {
                [locale]: translations,
            },
        } : {}),
        pluralizationRules: {
            [locale]: customPluralizationRules,
        },
        silentFallbackWarn: runtimeConfig.logLevel !== 'debug',
        silentTranslationWarn: runtimeConfig.logLevel !== 'debug',
    };

    return createI18n(opts);
}
