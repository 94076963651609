<template>
    <div class="c-toast-manager">
        <div class="o-row">
            <div
                :class="[
                    'c-toast-manager__column',
                    'o-extra-small--12',
                    'o-small--8',
                    'o-small--offset-2',
                    'o-large--6',
                    'o-large--offset-3',
                ]"
            >
                <TransitionGroup name="toast-notification">
                    <ToastNotification
                        v-for="toast in toasts"
                        :id="toast.id"
                        :key="toast.id"
                        :icon="toast.icon"
                        :images="toast.images"
                        :description="toast.description"
                        :to="toast.to"
                        :ctaText="toast.ctaText"
                        :modalOptions="toast.modalOptions"
                        :delay="toast.delay"
                        :iconComponent="iconComponent"
                        :showImagePlaceholders="toast.showImagePlaceholders"
                        class="c-toast-manager__notification"
                        @expired="removeToast"
                    />
                </TransitionGroup>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import {
    TOASTS_MODULE_NAME,
    LAUNCH_TOAST,
    REMOVE_TOAST,
    REMOVE_ALL_TOASTS,
} from '~coreModules/toasts/js/toasts-store';

import ToastNotification from '~coreModules/toasts/components/ToastNotification.vue';

export default {
    name: 'ToastManager',
    components: { ToastNotification },
    props: {
        iconComponent: {
            type: Object,
            required: true,
        },
        shouldHideToasts: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(TOASTS_MODULE_NAME, {
            toasts: state => state.activeToasts,
        }),
    },
    watch: {
        shouldHideToasts(shouldHideToasts) {
            if (shouldHideToasts) {
                this.removeAllToasts();
            }
        },
        '$route.path': {
            handler() {
                this.removeAllToasts();
            },
        },
    },
    methods: {
        ...mapMutations(TOASTS_MODULE_NAME, {
            launchToast: LAUNCH_TOAST,
            removeActiveToast: REMOVE_TOAST,
            removeAllToasts: REMOVE_ALL_TOASTS,
        }),
        removeToast(id) {
            this.removeActiveToast({ id });
        },
    },
};
</script>

<style lang="scss">
.c-toast-manager {
    width: 100%;
    position: fixed;
    z-index: map-get($zindex, toast-notifications);
    bottom: $nu-spacer-3;
    pointer-events: none;

    &__column {
        position: relative;
    }

    &__notification {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

// toast animations
.toast-notification {
    &-move, /* apply transition to moving elements */
    &-enter-active,
    &-leave-active {
        transition: all 0.5s ease;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
        transform: translateY(100px);
        -webkit-transform: translateY(100px);
    }
}
</style>
