import { defineAsyncComponent, markRaw } from 'vue';

export const BRAZE_NOTIFICATION_DISPLAY_TYPES = {
    sticky: 'sticky',
    modal: 'modal',
    box: 'box',
};

export const BRAZE_NOTIFICATION_TYPES = {
    stickyNotification: 'sticky-notification',
    predefinedStickyNotification: 'predefined-sticky-notification',
    modal: 'modal',
    box: 'box',
};

export const BRAZE_NOTIFICATION_IDS = {
    emailSubscribe: 'notification-email-subscribe',
    closetListSharing: 'notification-sublist-sharing-announcement',
};

export const BRAZE_PREDEFINED_STICKY_NOTIFICATIONS = {
    [BRAZE_NOTIFICATION_IDS.emailSubscribe]: {
        notificationId: BRAZE_NOTIFICATION_IDS.emailSubscribe,
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "emailSubscribeBrazeNotification" */
                '~modules/braze/components/EmailSubscribeBrazeNotification.vue'))),
    },
    [BRAZE_NOTIFICATION_IDS.closetListSharing]: {
        notificationId: BRAZE_NOTIFICATION_IDS.closetListSharing,
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetListSharingNotification" */
                '~modules/braze/components/ClosetListSharingNotification.vue'))),
    },
};

export const BRAZE_LOGIN_STATUSES = {
    anonymous: 'ANONYMOUS',
    authenticated: 'AUTHENTICATED',
};

export const BrazeInAppMessageSchemas = {
    [BRAZE_NOTIFICATION_TYPES.stickyNotification]: {
        requiredFields: ['notificationType', 'title', 'slug'],
        unrequiredFields: ['subtitle', 'illustration', 'destination', 'cta', 'loginStatus'],
    },
    [BRAZE_NOTIFICATION_TYPES.predefinedStickyNotification]: {
        requiredFields: ['notificationType', 'slug'],
        unrequiredFields: ['loginStatus'],
    },
    [BRAZE_NOTIFICATION_TYPES.modal]: {
        requiredFields: ['notificationType', 'slug'],
        unrequiredFields: ['loginStatus'],
    },
    [BRAZE_NOTIFICATION_TYPES.box]: {
        requiredFields: ['notificationType', 'slug'],
        unrequiredFields: ['loginStatus'],
    },
};
