/**
 * @module core/store-utils
 *
 * @description
 * The core/store-utils module contains utility functions for dealing with Vuex stores
 */

import {
    isArray,
    isFunction,
    isPlainObject,
    isString,
    last,
    mapValues,
} from 'lodash-es';

// mapIntanceState/etc. are alternative implementations to mapState/etc. for
// page views that use instance-aware vuex modules.  Instead of a static string
// module namespace, these methods accept a function that will be passed the
// current component instance, so parameters can be used to return an instance-aware
// vuex module name.
//
// This is most critical for route-level components that need to animate to a
// second instance of the same component (ie.., category -> category, pdp -> pdp)
// routings, because we can't afford to import the new route data until the
// animation is complete, so there exists a point where both sets of data are
// required because both components are visible during the animation.
//
// It is also used in other scenarios, such as cart, where a store may need to be
// prop-specific based on the instance (i.e., TRANSACTIONAL or LATER cart store).
//
// This functionality has been requested and denied by the Vuex authors in the
// past, but we can keep an eye on https://github.com/vuejs/vuex/issues/863 and
// https://github.com/vuejs/vuex/pull/1510 in case they reconsider.
//
// Example:
//
//   function getModuleName(vm) {
//       return `product--${vm.$route.params.slug}`;
//   }
//
//   ...
//
//   vuex: {
//       moduleName: getModuleName,
//       module: productModule,
//   },
//   computed: {
//       ...mapInstanceState(getModuleName, {
//           displayName: state => state.displayName,
//       },
//   }
//
// Do not use arrow functions in these 4 mapInstance* methods here, as we
// don't want to lock in the "this" context, since we need it to come in as the
// component instance when Vue calls the computed property or method function
/* eslint-disable prefer-arrow-callback */

/**
 * @description
 * Utility mapState-esque function allowing for dynamic Vuex module names.  The
 * first argument is a function that will be called with the current route object
 * and should return the proper Vuex module name
 *
 * @param   {Function} getModuleNameFn Function of $route->moduleName
 * @param   {Object}   mappers         Object of computed property name to
 *                                     mapState-esque functions
 * @returns {Object}   Object of computer property functions
 */
export function mapInstanceState(getModuleNameFn, mappers) {
    if (!isFunction(getModuleNameFn) || !isPlainObject(mappers)) {
        return {};
    }

    return mapValues(mappers, function makeInstanceAware(mapper) {
        if (!isFunction(mapper)) return () => {};

        return function wrappedMapper() {
            const moduleNames = getModuleNameFn(this).split('/');
            const localState = moduleNames.reduce((acc, m) => acc[m], this.$store.state);
            if (!localState) return {};
            return mapper.call(this, localState);
        };
    });
}

/**
 * @description
 * Utility mapMutation-esque function allowing for dynamic Vuex module names.  The
 * first argument is a function that will be called with the current route object
 * and should return the proper Vuex module name
 *
 * @param   {Function} getModuleNameFn Function of $route->moduleName
 * @param   {Object}   mutations       Object of method->mutation names
 * @returns {Object}   Object of mapped mutation functions
 */
export function mapInstanceMutations(getModuleNameFn, mutations) {
    if (!isFunction(getModuleNameFn) || !isPlainObject(mutations)) {
        return {};
    }

    return mapValues(mutations, function makeInstanceAware(mutation) {
        if (!isString(mutation)) return () => {};

        return function wrappedMutation(payload) {
            const moduleName = getModuleNameFn(this);
            return this.$store.commit(`${moduleName}/${mutation}`, payload);
        };
    });
}

/**
 * @description
 * Utility mapActions-esque function allowing for dynamic Vuex module names.  The
 * first argument is a function that will be called with the current route object
 * and should return the proper Vuex module name
 *
 * @param   {Function} getModuleNameFn Function of $route->moduleName
 * @param   {Object}   actions         Object of method->action names
 * @returns {Object}   Object of mapped action functions
 */
export function mapInstanceActions(getModuleNameFn, actions) {
    if (!isFunction(getModuleNameFn) || !isPlainObject(actions)) {
        return {};
    }

    return mapValues(actions, function makeInstanceAware(action) {
        if (!isString(action)) return () => {};

        return function wrappedAction(payload) {
            const moduleName = getModuleNameFn(this);
            return this.$store.dispatch(`${moduleName}/${action}`, payload);
        };
    });
}

/**
 * @description
 * Utility mapGetters-esque function allowing for dynamic Vuex module names.  The
 * first argument is a function that will be called with the current route object
 * and should return the proper Vuex module name
 *
 * @param   {Function} getModuleNameFn Function of $route->moduleName
 * @param   {string[]} getters         Array of getter names to be mapped
 * @returns {Object}   Object of mapped getter functions
 */
export function mapInstanceGetters(getModuleNameFn, getters) {
    if (!isFunction(getModuleNameFn) || !isArray(getters)) {
        return {};
    }

    return getters.reduce(function makeInstanceAware(acc, getter) {
        if (!isString(getter)) return acc;

        return Object.assign(acc, {
            [getter]: function wrappedGetter() {
                const moduleName = getModuleNameFn(this);
                return this.$store.getters[`${moduleName}/${getter}`];
            },
        });
    }, {});
}

/**
 * @description
 * Returns an object with commit/dispatch methods that are restricted to the
 * provided namespace
 *
 * @param   {Object} store Root vuex store
 * @param   {string} ns    Desired namespace
 * @returns {Object}       Object with namespaced commit/dispatch methods
 */
export function getNamespacedMethods(store, ns) {
    return {
        commit: (mutation, payload) => store.commit(`${ns}/${mutation}`, payload),
        dispatch: (action, payload) => store.dispatch(`${ns}/${action}`, payload),
    };
}

/**
 * @description
 * Returns an object with getters that are restricted to the provided namespace
 *
 * @param   {Object} store Root vuex store
 * @param   {string} ns    Desired namespace
 * @returns {Object}       Object with namespaced getters
 */
export function getNamespacedGetters(store, ns) {
    return Object.keys(store.getters).reduce((acc, name) => {
        if (new RegExp(`^${ns}/[^/]+$`).test(name)) {
            Object.defineProperty(acc, last(name.split('/')), {
                get() {
                    return store.getters[name];
                },
            });
        }
        return acc;
    }, {});
}

/* eslint-enable prefer-arrow-callback */
