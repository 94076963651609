export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "box__authenticatedUnsubscribed--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Nuuly"])},
        "box__cancelled--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivate"])},
        "box__cancelRequested--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivate"])},
        "box__paused--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpause"])},
        "box__pauseRequested--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused"])},
        "box__subscriberStates--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Nuuly"])},
        "box__terminated--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
        "box__title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Nuuly"])},
        "global__items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])}
      }
    }
  })
}
