import { isArray, isPlainObject, isString, isEmpty, isElement, isDate } from 'lodash-es';

/**
 * @description
 * Returns whether a given value is a valid date
 *
 * @param   {*}  val  Value to test
 * @returns {boolean} True if the value is a valid date
 */
export function isValidDate(val) {
    return isDate(val) && val.toString() !== 'Invalid Date';
}

/**
 * @description
 * Returns whether a given value is an array and contains at least one element
 *
 * @param   {*}  val  Value to test
 * @returns {boolean} True if the value is an array and non-empty
 */
export function isNonEmptyArray(val) {
    return isArray(val) && val.length > 0;
}

/**
 * @description
 * Returns whether a given value is a plain object and is non empty
 *
 * @param   {*}  val  Value to test
 * @returns {boolean} True if the value is a plain object and contains is not empty
 */
export function isNonEmptyObject(val) {
    return isPlainObject(val) && Object.keys(val).length > 0;
}

/**
 * @description
 * Returns whether a given value is a string type and is not empty or
 * whitespace)
 *
 * @param   {*}  val  Value to test
 * @returns {boolean} True if the value is a string and non-empty or whitespace
 */
export function isNonEmptyString(val) {
    return isString(val) && val.trim().length > 0;
}

/**
 * @description
 * Checks whether a given value is a valid hex color and, if so, returns a formatted value usable in CSS.
 * Can handle 3 or 6 characters, with or without a leading hash
 *
 * @param  {String}  value  the value to be checked for validity as a hex color
 * @returns {(String|null)} formatted hex color with a leading hash and 6 characters.
 *                          Returns null if arg isn't a valid hex
 */
export function formatHexColor(value) {
    let hex = value;

    if (typeof hex !== 'string') {
        return null;
    }

    // remove leading hash if there is one
    hex = hex.replace('#', '');

    const isValidHex = [6, 3].includes(hex.length) && !Number.isNaN(Number(`0x${hex}`));

    if (!isValidHex) {
        return null;
    }

    if (hex.length === 3) {
        // transform 3-character hex to 6 characters
        hex = hex.split('')
            .map(character => `${character}${character}`)
            .join('');
    }

    // prepend hash and capitalize
    return `#${hex.toUpperCase()}`;
}

/**
 * @description
 * Returns the click's event target href
 *
 * @param  {Object}  event  click event object
 * @returns {(String|null)} Returns the url of the event's click target. Returns null if click did not occur on link
 */
export function getClickEventHref(event) {
    if (!isEmpty(event)) {
        const { target = {} } = event;

        if (target.href) {
            return target.href;
        }

        const linkElement = event.composedPath().find(element => element.href);
        if (linkElement) {
            return linkElement.href;
        }
    }

    return null;
}

/**
 * @description
 * Gets an object off of `window.nuu`
 *
 * @param {string} key      The key to pluck off `window.nuu`
 * @param {object} logger   The logger instance
 * @returns {string|object} The window.nuu property
 */
export function getWindowProperty(key, logger) {
    try {
        return window.nuu[key];
    } catch (e) {
        logger.error(`Error getting ${key} object from window.nuu`);
    }

    return null;
}

/**
 * @description
 * Gets the very last elements child
 *
 * @param {HTMLElement|object}   el  the container element
 * @returns {HTMLElement|object} returns the last elements child
 */
export function getLastElementChild(el) {
    let element = el;

    if (isElement(element)) {
        element = element.lastElementChild;

        while (element?.children?.length) {
            element = element.lastElementChild;
        }
    }

    return element;
}

/**
 * @description
 * Checks whether a given value is a valid Unicode emoji code point, if so, returns a formatted string.
 *
 * @param  {String} value   the value to be checked for validity as emoji
 * @returns {(String|null)} formatted emoji string with leading 0x.
 *                          Returns null if arg isn't a valid emoji code point
 */
export function formatEmoji(value, logger) {
    try {
        if (!value || typeof value !== 'string') {
            return null;
        }

        const emojiString = String.fromCodePoint(`0x${value.replace('0x', '')}`);

        return emojiString;
    } catch (e) {
        logger.error(`Error getting emoji for ${value}`);
    }

    return null;
}

/**
 * @description
 * Creates an object keyed by each letter of the given alphabet, with an array value of items whose `itemKey` value
 * begins with that letter. Letter groups with no items are omitted. Non-alphabetical items will be added under
 * the nonAlphaKey. Does not handle sorting alphabetically.
 *
 * @param  {Array} items        array of objects to be grouped
 * @param  {Array} alphabet     array of valid letters we want to group by
 * @param  {String} itemKey     key whose value's first letter will be assessed
 * @param  {String} nonAlphaKey catch-all key for characters that are not in given alphabet
 * @returns {Object}            object grouped letters (or empty)
 */
export function groupByAlpha(items, alphabet, itemKey, nonAlphaKey = '#') {
    if (!isArray(items) || !isArray(alphabet) || !itemKey) {
        return {};
    }

    return items.reduce((groupsByAlpha, currentItem) => {
        const firstLetter = currentItem?.[itemKey]?.[0].toUpperCase?.();
        const firstLetterIsAlpha = alphabet.includes(firstLetter);
        const key = firstLetterIsAlpha ? firstLetter : nonAlphaKey;

        return {
            ...groupsByAlpha,
            [key]: [
                ...(groupsByAlpha[key] || []),
                currentItem,
            ],
        };
    }, {});
}

/**
 * @description
 * Returns a string that is stripped of non numbers
 *
 * @param  {String} string     string to be cleansed
 * @returns {String}           string containing only numbers
 */
export function stripNonNumbersFromString(string) {
    return string.replace(/[^0-9]/g, '');
}

/**
 * * @description
 * Returns a string that has decoded html entities
 *
 * @param  {String} string     string to be decoded
 * @returns {String}           string containing decoded html entities
 */
export function decodeHtmlEntitiesFromString(string) {
    return string.replace(/&#(\d+);/g, (_, code) => String.fromCharCode(code));
}

/**
 * @description
 * Returns a value from local storage
 *
 * @param {string} key      the localStorage cache key
 * @param {object} logger   the logger instance
 * @returns {object}        JSON parsed value from localStorage
 */
export function getLocalStorageValue(key, logger) {
    try {
        const item = JSON.parse(window.localStorage.getItem(key));
        if (item && item.expires > Date.now()) {
            return item.value;
        }
    } catch (e) {
        logger.error(`Error reading from localStorage for ${key}`, e);
    }

    return null;
}

/**
 * @description
 * Sets a value in local storage
 *
 * @param {string} key      the localStorage cache key
 * @param {object} value    the value to be stringified and cached
 * @param {number} ttl      time-to-live (in milliseconds) of the item
 * @param {object} logger   the logger instance
 */
export function setLocalStorageValue(key, value, ttl, logger) {
    try {
        if (!key) {
            throw new Error('key and is required');
        }
        const item = {
            expires: Date.now() + ttl,
            value,
        };
        window.localStorage.setItem(key, JSON.stringify(item));
    } catch (e) {
        logger.error(`Error reading from localStorage for ${key}`, e);
    }
}

/**
 * @description
 * Removes an item from local storage
 *
 * @param {string} key      the localStorage cache key
 * @param {object} logger   the logger instance
 */
export function removeLocalStorageItem(key, logger) {
    try {
        if (!key) {
            throw new Error('key is required');
        }
        window.localStorage.removeItem(key);
    } catch (e) {
        logger.error(`Error reading from localStorage for ${key}`, e);
    }
}
