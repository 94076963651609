const axios = require('axios');

const {
    get,
    has,
    identity,
    mapValues,
} = require('./tinydash');

/**
 * Create an axios HTTP client for contentful API calls
 * @private
 *
 * @param  {String} spaceId     Space ID
 * @param  {String} accessToken Access Token
 * @param  {String} environment Space Environment
 * @return {Object} Axios instance for the given space/accessToken
 */
function createContentfulRestClient(spaceId, accessToken, environment) {
    if (!spaceId) {
        throw new TypeError('Expected parameter spaceId');
    }

    if (!accessToken) {
        throw new TypeError('Expected parameter accessToken');
    }

    let baseURL = `https://cdn.contentful.com:443/spaces/${spaceId}`;

    if (environment) {
        baseURL += `/environments/${environment}`;
    }

    const headers = {
        Authorization: `Bearer ${accessToken}`,

        // Only add these on the server
        /* eslint-disable prefer-template */
        ...(process.env.VUE_ENV === 'server' ? {
            'user-agent': 'node.js/' + (
                process.versions.node ? `v${process.versions.node}` : process.version
            ),
            'Accept-Encoding': 'gzip',
        } : {}),
        /* eslint-enable prefer-template */
    };

    return axios.create({ baseURL, headers });
}

function flattenContentfulAsset(asset) {
    return {
        fields: {
            ...asset.fields.file,
            ...{ title: asset.fields.title },
        },
        ...asset.sys,
    };
}

/**
 * Take a contentful entry, and recursively strip out all of the cruft:
 *   - Resolve all links to includes.Entry or includes.Asset
 *   - Remove all 'sys' objects
 *   - Flatten all 'fields' properties
 *
 * @param  {Object|Object[]} entry    Contentful entry object
 * @param  {Object}   includes Root level `includes` field from contentful
 *                             response when entries have linked child entries
 *                             or assets
 * @param  {Function} [pre]      Optional pre-mapper function
 * @param  {Function} [post]     Optional post-mapper function
 * @return {Object}   Condensed object
 */
function smooshEntry(entry, includes, pre = identity, post = identity) {
    // Look up a given linked Entry/Asset in the includes structure
    const getEntryOrAsset = (obj) => {
        const links = get(includes, get(obj, 'sys.linkType'));
        const linkedAsset = links.find(e => e.sys.id === obj.sys.id);

        if (get(linkedAsset, 'sys.type') === 'Asset' && get(linkedAsset, 'fields.file')) {
            return flattenContentfulAsset(linkedAsset);
        }
        return linkedAsset;
    };

    if (Array.isArray(entry)) {
        // This is an array, return a new array with each element having been
        // processed recursively
        return entry.map(e => post(smooshEntry(pre(e), includes, pre, post)));
    }

    if (typeof entry === 'object' && entry != null) {
        if (has(entry, 'fields')) {
            // This is a sys/fields object, we only care about the 'fields'
            // entries, so recurse on each value of that object, preserving
            // the key names
            return mapValues(smooshEntry(entry.fields, includes, pre, post));
        }

        if (has(entry, 'sys')) {
            // This only has sys, not fields
            return smooshEntry(getEntryOrAsset(entry), includes);
        }

        // This is just a normal object without 'fields' or 'sys', so we
        // want to recurse on each property directly
        return mapValues(entry, e => post(smooshEntry(pre(e), includes, pre, post)));
    }

    // This is a primitive value, just return it directly
    return entry;
}

/**
 * Transform a contentful array of raw localized text objects down into a
 * key-value store of the format `{ slug: "translation" }`
 *
 * @param  {Object[]} items         Array of raw contentful localizedText entries
 * @param  {Function} [debugLogger] Optional debug logging function if you wish
 *                                  to log info about dup slugs
 * @return {Object} Key-Value store of slug -> translation
 */
function transformLocalizedTextToKeyValue(items, debugLogger) {
    const dupTranslations = [];
    const slugTranslations = smooshEntry(items).reduce((acc, o) => {
        // istanbul ignore else
        if (!acc[o.slug]) {
            acc[o.slug] = o.displayText;
        } else {
            dupTranslations.push(o.slug);
        }
        return acc;
    }, {});

    // istanbul ignore if
    if (typeof debugLogger === 'function') {
        debugLogger(
            'Found slugs with multiple entries in Contentful:',
            dupTranslations.join(','),
        );
    }

    return slugTranslations;
}

/**
 * Fetch a key-value store of slug localizations for the given locale
 * @param  {String}   spaceId     Space ID
 * @param  {String}   accessToken Access Token
 * @param  {String}   environment Space Environment
 * @param  {String}   locale      Locale code
 * @param  {String[]} slugs       Array of slugs to fetch
 * @return {Object} Object with slug -> displayText
 */
function loadRealTimeTranslations({
    spaceId,
    accessToken,
    environment,
    locale,
    slugs,
}) {
    const client = createContentfulRestClient(spaceId, accessToken, environment);
    const opts = {
        params: {
            content_type: 'localizedText',
            'fields.slug[in]': slugs.join(','),
            limit: 1000,
            locale,
        },
    };

    // Sample API call
    // curl --include --request GET https://cdn.contentful.com/spaces/8zamwvziwka5/entries?
    // access_token=7c6d83284228d66074704d6f2c0be22f0f1d0eb3254e8092068dfe11774512a5
    return client.get('entries', opts)
        .then(res => transformLocalizedTextToKeyValue(get(res, 'data.items')));
}

module.exports = {
    createContentfulRestClient,
    loadRealTimeTranslations,
    smooshEntry,
    transformLocalizedTextToKeyValue,
};
