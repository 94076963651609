import { merge } from 'lodash-es';

import { GLOBAL_IMAGE_PRESETS } from '~coreModules/core/js/constants';

export const NUULY_RENT_APP_STORE_LINK = 'https://apps.apple.com/us/app/nuuly/id1511548818';

export const NUU_PLAN_ID_1 = '1_nu_monthly_98';
export const NUU_PLAN_ID_2 = '2_nu_monthly';

// Image presets for Adobe Scene7
export const IMAGE_PRESETS = merge(GLOBAL_IMAGE_PRESETS, {
    box: '$box-regular$',
    closet: {
        thumb: '$closet-thumb$',
    },
    content: '$content$',
});

export const SIZE_TYPE_PETITE = 'petite';
export const SIZE_TYPE_REGULAR = 'regular';
export const SIZE_TYPE_PLUS = 'plus';

export const FILTER_GROUP_SIZE = 'Size';

export const BODY_TYPE_LABELS = {
    pear: 'profile__bodyType--pear',
    apple: 'profile__bodyType--apple',
    hourGlass: 'profile__bodyType--hourGlass',
    invertedTriangle: 'profile__bodyType--invertedTriangle',
    straight: 'profile__bodyType--stringNarrow',
};

/* eslint-disable global-require */
export const BODY_TYPE_OPTIONS = [{
    label: BODY_TYPE_LABELS.pear,
    value: 'pear',
    image: require('~images/illustration__bodyShape--pear.png').default,
}, {
    label: BODY_TYPE_LABELS.apple,
    value: 'apple',
    image: require('~images/illustration__bodyShape--apple.png').default,
}, {
    label: BODY_TYPE_LABELS.hourGlass,
    value: 'hourGlass',
    image: require('~images/illustration__bodyShape--hourglass.png').default,
}, {
    label: BODY_TYPE_LABELS.invertedTriangle,
    value: 'invertedTriangle',
    image: require('~images/illustration__bodyShape--inverted-triangle.png').default,
}, {
    label: BODY_TYPE_LABELS.straight,
    value: 'straight',
    image: require('~images/illustration__bodyShape--straight.png').default,
}];

export const PRODUCT_SIZE_TYPES = {
    // REGULAR has been deprecated in favor of STANDARD, but older products may still use the old group name
    petite: 'PETITE',
    short: 'SHORT',
    regular: 'REGULAR',
    standard: 'STANDARD',
    tall: 'TALL',
    long: 'LONG',
    plus: 'PLUS',
};

export const HEIGHT_INCHES = [
    { value: '0', label: '0 in' },
    { value: '1', label: '1 in' },
    { value: '2', label: '2 in' },
    { value: '3', label: '3 in' },
    { value: '4', label: '4 in' },
    { value: '5', label: '5 in' },
    { value: '6', label: '6 in' },
    { value: '7', label: '7 in' },
    { value: '8', label: '8 in' },
    { value: '9', label: '9 in' },
    { value: '10', label: '10 in' },
    { value: '11', label: '11 in' },
];

export const HEIGHT_FEET = [
    { value: '3', label: '3 ft' },
    { value: '4', label: '4 ft' },
    { value: '5', label: '5 ft' },
    { value: '6', label: '6 ft' },
];

export const PAYMENT_FORM_LAYOUT_TYPES = {
    COMPACT: 'COMPACT',
    FULL_WIDTH: 'FULL_WIDTH',
};

export const EMAIL_OPT_IN_LOCATIONS = {
    FOOTER: 'rent-web-footer',
    MODAL: 'rent-web-email-modal',
};
