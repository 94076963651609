/**
 * Returns an svg asset for a given image path
 * @param {String}  path        - the path of the SVG asset to be fetched (relative to ~images)
 * @param {Object}  logger      - the logger instance
 * @param {Boolean} isCoreDir   - true if the asset resides in core-lib/images; else image is in app ~images directory
 *
 * @returns {String} SVGPathElement markup matching the given source
 */
export function dynamicSVGRequire(path, logger, isCoreDir = true) {
    let asset = '';
    try {
        // ~images alias is here so webpack can resolve and cache the alias. Doesn't work if ~images is part of the arg
        /* eslint-disable import/no-dynamic-require, global-require */
        asset = isCoreDir ?
            require('~coreImages/svgs/'.concat(path, '?inline')) : require('~images/svgs/'.concat(path, '?inline'));
    } catch (err) {
        logger.error(`Asset ~${isCoreDir ? 'coreImages' : 'images'}/svgs/${path} not found ${isCoreDir}`);
    }

    return asset;
}

export default dynamicSVGRequire;
