<template>
    <img
        :src="activeImgSrc"
        :data-src="imgSrc"
        :alt="title"
        :class="{
            'c-lazy-image__fade-in': fadeInAfterLoaded,
            'c-lazy-image__fade-in--loaded': fadeInAfterLoaded && isLoaded,
        }"
        @load="onLoaded"
        @error="onError"
    >
</template>

<script>
export default {
    name: 'LazyImage',
    props: {
        title: {
            type: String,
            required: true,
        },
        imgSrc: {
            type: String,
            required: true,
        },
        lazyDefaultSrc: {
            type: String,
            default: '',
        },
        shouldDisplayImage: {
            type: Boolean,
            default: false,
        },
        fadeInAfterLoaded: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['loaded', 'loadError'],
    data() {
        return {
            isLoaded: false,
        };
    },
    computed: {
        activeImgSrc() {
            return this.shouldDisplayImage ? this.imgSrc : this.lazyDefaultSrc;
        },
    },
    watch: {
        activeImgSrc(newImg, oldImg) {
            if (newImg && oldImg && newImg !== oldImg) {
                this.isLoaded = false;
            }
        },
    },
    methods: {
        onLoaded() {
            this.isLoaded = true;
            this.$emit('loaded');
        },
        onError() {
            if (this.activeImgSrc) {
                this.$emit('loadError');
            }
        },
    },
};
</script>

<style lang="scss">
    .c-lazy-image {
        &__fade-in {
            opacity: 0;
            transition: 750ms ease;

            &--loaded {
                opacity: 1;
            }
        }
    }
</style>
