export const HEADER_MODULE_NAME = 'header';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';

export default {
    namespaced: true,

    state: () => ({
        headerHeight: 0,
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_HEADER_HEIGHT](state, headerHeight) {
            if (state.headerHeight !== headerHeight) {
                state.headerHeight = headerHeight;
            }
        },
    },
    /* eslint-enable no-param-reassign */

    actions: {},

    getters: {},
};
