<template>
    <Component
        :is="launcherComponent"
        v-tab-focus="openModal"
        :class="launcherComponentClass"
        v-bind="$attrs"
        aria-haspopup="true"
        :aria-expanded="`${showModal}`"
        :aria-label="ariaLabelText"
    >
        <slot></slot>
        <UrbnModal
            v-if="showModal"
            v-model:showModal="showModal"
            :modalOptions="modalOptions"
        />
    </Component>
</template>

<script>
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';
import UrbnModal from '~coreModules/modals/components/UrbnModal.vue';

export default {
    name: 'UrbnModalLauncher',
    components: {
        BaseButton,
        UrbnModal,
    },
    inheritAttrs: false,
    props: {
        launcherComponentClass: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'button',
        },
        // See modal-utils.js getValidatedModalOptions for modalOptions definition
        modalOptions: {
            type: Object,
            required: true,
        },
        ariaLabelSlug: {
            type: String,
            default: '',
        },
    },
    emits: ['close'],
    data() {
        return {
            showModal: false,
            componentMap: {
                button: 'BaseButton',
                link: 'a',
                li: 'li',
            },
        };
    },
    computed: {
        launcherComponent() {
            return this.componentMap[this.type] || BaseButton;
        },
        ariaLabelText() {
            const text = this.$t(this.ariaLabelSlug || this.modalOptions.title || '');
            return `Modal launcher for ${text}`;
        },
    },
    watch: {
        showModal(shown) {
            if (!shown) {
                this.$emit('close');
            }
        },
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
    },
};
</script>

<style lang="scss">
</style>
