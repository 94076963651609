import { uniqueId } from 'lodash-es';

export const TOASTS_MODULE_NAME = 'toasts';

export const LAUNCH_TOAST = 'LAUNCH_TOAST';
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export default {
    namespaced: true,
    state: () => ({
        activeToasts: [],
    }),
    mutations: {
        [LAUNCH_TOAST](state, { id, ...opts }) {
            const toast = {
                ...opts,
                id: `${id}--${uniqueId()}`,
            };

            state.activeToasts.push(toast);

            if (state.activeToasts.length > 1) {
                state.activeToasts.shift();
            }
        },
        [REMOVE_ALL_TOASTS](state) {
            /* eslint-disable no-param-reassign */
            state.activeToasts = [];
        },
        [REMOVE_TOAST](state, { id }) {
            const index = state.activeToasts.findIndex(toast => toast.id === id);

            if (index > -1) {
                state.activeToasts.splice(index, 1);
            }
        },
    },
    getters: {
        hasActiveToasts(state) {
            return state.activeToasts.length > 0;
        },
    },
};
