<script>
import { mapGetters } from 'vuex';
import { h, resolveComponent } from 'vue';
import { get, merge, invoke } from 'lodash-es';

import { getBaseLinkAttributes } from '~coreModules/core/js/url-utils';

import buttonProps from '~coreModules/core/components/mixins/buttonProps';

import { MODALS_MODULE_NAME, HANDLE_MODAL_HASH_EVENT, POP_MODALS } from '~coreModules/modals/js/modals-store';

import { getModalHashEvents } from '~modules/modals/js/modal-constants';

export default {
    name: 'BaseLink',
    mixins: [buttonProps],
    props: {
        to: {
            type: [String, Object],
            required: true,
        },
        target: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        shouldPopModals: {
            type: Boolean,
            default: true,
        },
        isButton: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click'],
    computed: {
        ...mapGetters([
            'appBaseUrl',
        ]),
    },
    render() {
        const {
            disabled,
            target,
            shouldPopModals,
            appBaseUrl,
            isButton,
            buttonClasses,
        } = this;
        // Since we don't really know who our parent is, it seems safer to grab this from the $root
        // which we know will have a $userState

        const hostname = get(this, '$userState.hostname', {});

        const {
            isExternal,
            linkTarget,
            linkRel,
            to,
            modalHashEvent,
            isHashOnlyLink,
        } = getBaseLinkAttributes({
            hostname,
            href: this.to,
            appBaseUrl,
        }, getModalHashEvents(this.$runtimeConfig.features));

        const mergedData = merge({}, this.$attrs, {
            // Safely open external links
            // https://developers.google.com/web/tools/lighthouse/audits/noopener
            target: target || this.$attrs.target || linkTarget,
            rel: linkRel,
            class: isButton ? buttonClasses : '',
        });

        const defaultSlot = {
            default: () => invoke(this, '$slots.default', []),
        };

        const shouldEmitClick = (e) => {
            const attrs = { ...this.$attrs };
            // delete @click on the component since we're managing click events locally
            delete attrs.onClick;

            this.$emit('click', e);
        };

        // render an anchor tag for external links
        if (isExternal) {
            merge(mergedData, {
                href: to,
                onClick(e) {
                    shouldEmitClick(e);
                },
            });
            return h('a', mergedData, defaultSlot);
        }

        // render an anchor tag for disabled internal links
        if (disabled) {
            merge(mergedData, {
                href: to,
                class: 'disabled',
                onClick(e) {
                    e.preventDefault();
                },
            });
            return h('a', mergedData, defaultSlot);
        }

        const dispatch = get(this, '$store.dispatch', {});
        const routerPush = get(this, '$router.push', {});

        // launch a modal if modalHashEvent exists
        if (modalHashEvent) {
            merge(mergedData, {
                href: to,
                onClick(e) {
                    e.preventDefault();
                    shouldEmitClick(e);
                    /* if the path is not a hash only link, push the route, let App.vue handle the hash modal */
                    if (!isHashOnlyLink) {
                        routerPush(to);
                        return;
                    }

                    /* if the path is not changing, push the hash modal without
                    changing the URL, to avoid back button issues,  */
                    dispatch(`${MODALS_MODULE_NAME}/${HANDLE_MODAL_HASH_EVENT}`, modalHashEvent);
                },
            });
            return h('a', mergedData, defaultSlot);
        }

        // render a RouterLink for internal links
        merge(mergedData, {
            to,
            onClick(e) {
                shouldEmitClick(e);

                if (shouldPopModals) {
                    dispatch(`${MODALS_MODULE_NAME}/${POP_MODALS}`, { omitPageviewEvent: true });
                }
            },
        });

        return h(resolveComponent('router-link'), mergedData, defaultSlot);
    },
};
</script>
