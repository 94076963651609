import {
    validateContentCard,
    getSortedContentCards,
    getContentCardById,
} from '~coreModules/content-cards/js/content-cards-utils';
import { CONTENT_CARD_TYPES } from '~coreModules/content-cards/js/content-cards-constants';

export const CONTENT_CARDS_MODULE_NAME = 'contentCards';

export const SET_CONTENT_CARD = 'SET_CONTENT_CARD';

export const VALIDATE_CONTENT_CARD = 'VALIDATE_CONTENT_CARD';
export const GET_CONTENT_CARD_DATA = 'GET_CONTENT_CARD_DATA';

// Privately scoped object which maintains the current content card for each
// content card type received from braze. Storing this here
// instead of in vuex state because the object is fairly large, and we only need this
// original card object to send analytics 'impressions' to braze
// This assumes that everything braze related is never server side rendered, as this cannot
// be communicated upwards to the client (Braze relies on the window so will only ever exist client-side)
// only exporting for unit testing, should not be used outside of this file
export const activeContentCardsData = {
    [CONTENT_CARD_TYPES.pencilBanner]: [],
};

export default {
    namespaced: true,

    state: () => ({
        activeContentCards: {
            [CONTENT_CARD_TYPES.pencilBanner]: [],
        },
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_CONTENT_CARD](state, { data, ...contentCard }) {
            let keyToEdit;

            switch (contentCard.contentCardType) {
            case CONTENT_CARD_TYPES.pencilBanner:
                keyToEdit = CONTENT_CARD_TYPES.pencilBanner;
                break;
            default:
                break;
            }

            if (!keyToEdit) {
                this.$logger.debugError(`no content card type found for
                    the following contentCardType: ${contentCard.contentCardType}`);
            } else {
                const activeContentCard = state.activeContentCards[keyToEdit]
                    .find(card => card.id && card.id === contentCard.id);

                if (!activeContentCard) {
                    state.activeContentCards[keyToEdit] = [
                        ...state.activeContentCards[keyToEdit],
                        contentCard,
                    ];
                    activeContentCardsData[keyToEdit] = [
                        ...activeContentCardsData[keyToEdit],
                        data,
                    ];
                } else {
                    this.$logger.debugError(`duplicate content card found for
                        the following contentCardType: ${contentCard.contentCardType}`);
                }
            }
        },
    },
    /* eslint-enable no-param-reassign */

    /* eslint-disable no-undef */
    actions: {
        [VALIDATE_CONTENT_CARD]({ commit }, { data, ...contentCard }) {
            try {
                validateContentCard(contentCard, this.$logger);
                commit(SET_CONTENT_CARD, { data, ...contentCard });
            } catch (error) {
                this.$logger.debugError(error);
            }
        },
        // eslint-disable-next-line no-empty-pattern
        [GET_CONTENT_CARD_DATA]({ }, contentCardId) {
            return getContentCardById(activeContentCardsData, contentCardId);
        },
    },

    getters: {
        getActiveContentCards({ activeContentCards }) {
            return contentCardType => getSortedContentCards(activeContentCards[contentCardType]) || [];
        },
    },
};
