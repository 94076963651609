/**
 * returns the fields in the provided object that have falsy values
 *
 * @param {Object} obj - the object to check for missing required fields
 * @returns {Array} missingFields - the fields in the provided object that have falsy values
 */
// eslint-disable-next-line import/prefer-default-export
export function getMissingRequiredFields(obj) {
    if (typeof obj !== 'object' || obj === null) return [];
    const fields = Object.keys(obj);
    const missingFields = fields.filter(key => !obj[key]);
    return missingFields;
}

/**
 * returns if the provided object has the required address fields set
 *
 * @param {Object} obj - the object to check for missing required address fields
 * @returns {Boolean} the object has the required address fields set
 */
export function addressHasAllRequiredFields(address) {
    if (typeof address !== 'object' || address === null) return false;

    return getMissingRequiredFields({
        firstName: address?.firstName,
        lastName: address?.lastName,
        line1: address?.line1,
        city: address?.city,
        state: address?.state,
        zip: address?.zip,
    }).length === 0;
}
