import { defineRule, configure } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';

import enMessages from '@vee-validate/i18n/dist/locale/en.json';

export function initalizeVeeValidate(locale, i18n, getAppSpecificValidationLocalizations) {
    Object.keys(AllRules).forEach((rule) => {
        defineRule(rule, AllRules[rule]);
    });

    const appOverrides = getAppSpecificValidationLocalizations(i18n);

    configure({
        generateMessage: localize({
            'en-US': {
                messages: {
                    ...enMessages.messages,
                    required: i18n.global.t('validationError__required--withLabel'),
                },
                // overwrite validation errors on a per field name basis
                // <Field> element being validated must have name = to value below
                fields: {
                    date: {
                        required: i18n.global.t('validationError__date--inputRequired'),
                    },
                    email: {
                        email: i18n.global.t('validationError__email--format-escaped'),
                    },
                    employeeId: {
                        min: i18n.global.t('employee__invalidId--error'),
                        max: i18n.global.t('employee__invalidId--error'),
                    },
                    firstName: {
                        max: i18n.global.t('validationError__name--format'),
                    },
                    line1: {
                        regex: i18n.global.t('address__error--lineOneRegex'),
                    },
                    phoneNumber: {
                        regex: i18n.global.t('validationError__phone--format'),
                    },
                    smsToken: {
                        digits: i18n.global.t('validationError__smsToken--format'),
                    },
                    username: {
                        regex: i18n.global.t('accountCreate__profile--errorUsernameMinCharacter'),
                    },
                    imageFile: {
                        mimes: i18n.global.t('account__profilePhoto--formatError'),
                        size: i18n.global.t('account__profilePhoto--sizeError'),
                    },
                    name: {
                        required: i18n.global.t('validationError__name--inputRequired'),
                    },
                    // pull in app specific error localizations
                    ...(appOverrides?.fields ? appOverrides.fields : {}),
                },
                // customize field names
                names: {
                    birthday: i18n.global.t('profile__birthday--label'),
                    city: i18n.global.t('address__city--label'),
                    email: i18n.global.t('account__email--label'),
                    employeeId: i18n.global.t('employee__employeeId--label'),
                    firstName: i18n.global.t('account__firstName--label'),
                    lastName: i18n.global.t('account__lastName--label'),
                    username: i18n.global.t('accountCreate__profile--username'),
                    line1: i18n.global.t('address__line1--label'),
                    phoneNumber: i18n.global.t('account__phone--label'),
                    promoCode: i18n.global.t('redeem__couponApplyPromoCode--label'),
                    smsToken: i18n.global.t('account__smsToken--label'),
                    state: i18n.global.t('address__state--label'),
                    zip: i18n.global.t('address__zip--label'),
                    // pull in app specific field name localizations
                    ...(appOverrides?.names ? appOverrides.names : {}),
                },
            },
        }),
        validateOnBlur: false,
        validateOnChange: false,
        validateOnModelUpdate: false,
    });

    setLocale(locale);
}

export default { initalizeVeeValidate };
