import runtimeConfig from '~config/config';

const { apiUrls } = runtimeConfig;

/**
 * Creates, cancels, or updates the pause for the current account
 * @param {Number} monthsToPause - how many months to pause to - 0 will cancel the current pause
 */
export function updateSubscriptionPause(r15Svc, monthsToPause) {
    return r15Svc.post(`${apiUrls.subscriptionService}/pause/${monthsToPause}`);
}
/**
 * Extends a user's pause
 * @param {Number} monthsToExtendPause - how many months to extend pause
 * @return {Promise} service request
 */
export function extendPause(r15Svc, monthsToExtendPause) {
    return r15Svc.post(`${apiUrls.subscriptionService}/pause/extend/${monthsToExtendPause}`);
}

/**
 * Resumes the subscription for a paused account
 */
export function resumeSubscription(r15Svc) {
    return r15Svc.put(`${apiUrls.subscriptionService}/pause/resume`);
}

/**
 * Initiates a cancel for a subscription
 * @return {Promise} service request
 */
export function requestCancel(r15Svc) {
    return r15Svc.put(`${apiUrls.subscriptionService}/cancel`);
}

/**
 * Terminates a cancel for a subscription
 * @return {Promise} service request
 */
export function undoCancel(r15Svc) {
    return r15Svc.put(`${apiUrls.subscriptionService}/cancel/undo`);
}

/**
 * Redeems a gift card for credit
 * @param {String} giftCardCode - code to redeem
 * @returns {Promise} service request
 */
export function redeemGiftCardCredit(r15Svc, giftCardCode) {
    const postBody = {
        giftCardCode,
    };

    return r15Svc.post(`${apiUrls.subscriptionService}/credit/redeem`, postBody);
}

/**
 * Applies a coupon code to the current subscription
 * @param {String} couponCode - code to apply
 * @returns {Promise} service request
 */
export function applyCouponCode(r15Svc, couponCode) {
    const postBody = {
        couponCode,
    };

    return r15Svc.post(`${apiUrls.subscriptionService}/coupon/apply`, postBody);
}
/**
 * Gets information about the current account's subscription
 * @return {Promise} service request
 */
export function getSubscriptionInfo(r15Svc) {
    return r15Svc.get(`${apiUrls.subscriptionService}/info`);
}

/**
 * Gets a preview for renewing early
 */
export function getRenewEarlyPreview(r15Svc) {
    return r15Svc.get(`${apiUrls.subscriptionService}/renew/preview`);
}

/**
 * Renews a users subscription early
 * @return {Promise} service request
 */
export function renewSubscriptionEarly(r15Svc) {
    return r15Svc.post(`${apiUrls.subscriptionService}/renew`, {});
}

/**
 * Gets the invoice history for the current profile
 * @return {Promise} - service request
 */
export function getInvoiceHistory(r15Svc) {
    return r15Svc.get(`${apiUrls.subscriptionService}/invoices`);
}

/**
 * Gets the coupon codes applied to the current subscriptions
 * @return {Promise} - service request
 */
export function getCurrentCoupons(r15Svc) {
    return r15Svc.get(`${apiUrls.subscriptionService}/coupon/active`);
}
