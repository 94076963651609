import { set } from 'lodash-es';
import initializeClient from '@nuuly-ui/core-ssr/src/entry-client';

import { getWindowProperty } from '~coreModules/core/js/utils';
import { shouldPreventGlobalLoader } from '~coreModules/core/js/router-utils';

import {
    CLEAR_ROUTING_ERROR,
    SET_ROUTE_IS_LOADING,
    SET_500_ERROR,
    SET_404_ERROR,
} from '~coreModules/core/js/store';

let errorOccurred;
let unsubscriber;

// Middleware that will run before fetchData on every client side route
export async function globalMiddleware(to, from, store) {
    errorOccurred = false;
    unsubscriber = store.subscribe(({ type }) => {
        if (type === SET_500_ERROR || type === SET_404_ERROR) {
            errorOccurred = true;
        }
    });

    /* prevent global loading state */
    if (!shouldPreventGlobalLoader(to, from)) {
        store.commit(SET_ROUTE_IS_LOADING, true);
    }

    return Promise.resolve();
}

// Middleware that will run after fetchData on every client side route
export function globalPostMiddleware(to, from, store) {
    unsubscriber();
    // Only clear out errors if the last fetchData pass found no errors
    if (!errorOccurred && store.state.routingError) {
        store.commit(CLEAR_ROUTING_ERROR, null);
    }
    store.commit(SET_ROUTE_IS_LOADING, false);
    return Promise.resolve();
}

export function initializeClientWithOpts(createApp, clientOpts) {
    const opts = {
        hmr: process.env.NODE_ENV === 'local',
        logger: console,
        middleware: globalMiddleware,
        postMiddleware: globalPostMiddleware,
        shouldProcessRouteUpdateDefaults: {
            path: true,
            query: true,
            hash: false,
        },
        ...clientOpts,
    };

    initializeClient((context) => {
        Object.assign(context, {
            runtimeConfig: getWindowProperty('runtimeConfig', opts.logger),
            initialState: getWindowProperty('initialState', opts.logger),
            translations: getWindowProperty('initialTranslations', opts.logger),
            userState: getWindowProperty('userState', opts.logger),
            newRelic: window.NREUM || null,
        });

        const app = createApp(context);
        // Add a reference to the app for debugging
        set(window, 'nuu.app', app);

        return app;
    }, opts);
}
