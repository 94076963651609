<i18n>
[
    "header__signOut--label",
]
</i18n>

<template>
    <div class="c-header-auth-menu">
        <HeaderLinks class="c-header-auth-menu__links" />
        <BaseButton
            v-tab-focus="handleLogoutUser"
            variant="link"
            class="c-header-auth-menu__button"
            :class="{ 'c-header-auth-menu__button--fixed': isButtonFixed }"
        >
            {{ $t('header__signOut--label') }}
        </BaseButton>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { LOGIN_MODULE_NAME, LOG_OUT } from '~coreRoutes/login/js/login-store';

import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';
import HeaderLinks from '~modules/header/components/HeaderLinks.vue';

export default {
    name: 'HeaderAuthenticatedMenu',
    components: {
        BaseButton,
        HeaderLinks,
    },
    props: {
        isButtonFixed: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions(LOGIN_MODULE_NAME, {
            logoutUser: LOG_OUT,
        }),
        handleLogoutUser() {
            this.logoutUser();
        },
    },
};
</script>

<style lang="scss">
.c-header-auth-menu {
    height: 100%;

    &__links {
        overflow-y: auto;
        height: calc(100% - $nu-spacer-7);
    }

    &__button {
        height: $nu-spacer-7;
        width: 100%;
        outline-offset: -4px;
        background-color: $nu-white;

        &--fixed {
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            left: 0;
            border-top: 1px solid $nu-gray--light;
        }
    }
}
</style>
