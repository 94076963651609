import { createSSRApp, h } from 'vue';
import VueScrollTo from 'vue-scrollto';
import Maska from 'maska';
import { transformVTDirective } from '@intlify/vue-i18n-extensions';

import createContentfulRestClient from '~coreModules/contentful/js/contentful-client';
import createRestClient from '~coreModules/core/js/rest-client';
import createI18n from '~coreModules/core/js/i18n';
import ContentfulClientPlugin from '~coreModules/core/js/plugins/contentful-client';
import CookiePlugin from '~coreModules/core/js/plugins/cookies';
import I18nPlugin from '~coreModules/core/js/plugins/i18n';
import LoggerPlugin from '~coreModules/core/js/plugins/logger';
import GlobalErrorPlugin from '~coreModules/core/js/plugins/global-error-handler';
import R15ClientPlugin from '~coreModules/core/js/plugins/rest-client';
import RuntimeConfigPlugin from '~coreModules/core/js/plugins/runtime-config';
import SSRContextPlugin from '~coreModules/core/js/plugins/ssr-context';
import UserStatePlugin from '~coreModules/core/js/plugins/user-state';
import MediaQueriesPlugin from '~coreModules/core/js/plugins/media-queries';
import attachCustomVueFilters from '~coreModules/core/js/custom-filters';
import { initalizeVeeValidate } from '~coreModules/core/js/validation-localizations';
import { getBrowserCookieInterface } from '~coreModules/core/js/browser-cookies';
import { provideLogger } from '~coreModules/core/js/composables/useLogger';
import { provideContentfulSvc } from '~coreModules/core/js/composables/useContentfulSvc';
import { provideR15Svc } from '~coreModules/core/js/composables/useR15Svc';

import DiscernibleText from '~coreModules/core/components/ui/DiscernibleText.vue';
import BaseIcon from '~coreModules/core/components/ui/BaseIcon.vue';
import BaseLink from '~coreModules/core/components/ui/BaseLink.vue';
import TabFocus from '~coreModules/core/directives/TabFocus.vue';
import AutoFocus from '~coreModules/core/directives/AutoFocus.vue';
import { getServerCookieInterface } from '~coreServer/server-cookies';

// Isomorphic function used by both the client and the server to ensure we
// create identical Vue app instances
export default function createApp(context, {
    App,
    channel,
    createStore,
    createRouter,
    runtimeConfig,
    logger,
    getValidationLocalizations,
}) {
    const {
        request,
        response,
        initialState,
        translations,
        userState,
    } = context;

    const store = createStore(initialState, logger, runtimeConfig);
    const router = createRouter(store, runtimeConfig);
    const locale = store.state.siteSettings.language;
    const i18n = createI18n(locale, translations, runtimeConfig);

    const app = createSSRApp({
        i18n,
        router,
        store,
        render: () => h(App),
    });

    initalizeVeeValidate(locale, i18n, getValidationLocalizations);

    const cookieInterface = process.env.VUE_ENV === 'server' ?
        getServerCookieInterface(request, response) : getBrowserCookieInterface();

    const r15Svc = createRestClient({
        store,
        request,
        serverBaseApiUrl: runtimeConfig.apiDomains.restServer,
        clientBaseApiUrl: runtimeConfig.apiDomains.rest,
        logger,
        channel,
        cookieInterface,
        authCookieName: runtimeConfig.authCookieName,
    });

    const { contentful } = runtimeConfig;
    const { accessToken, apiUrl, environment } = contentful;

    const contentfulSvc = createContentfulRestClient({
        store,
        logger,
        apiUrl,
        accessToken,
        environment,
        request,
    });

    attachCustomVueFilters(app, store, i18n, logger);

    app
        .use(router)
        .use(store)
        .use(CookiePlugin, cookieInterface, store)
        .use(ContentfulClientPlugin, contentfulSvc, store)
        .use(I18nPlugin, i18n, store)
        .use(LoggerPlugin, logger, store)
        .use(GlobalErrorPlugin, logger)
        .use(R15ClientPlugin, r15Svc, store)
        .use(RuntimeConfigPlugin, runtimeConfig, store)
        .use(SSRContextPlugin, context)
        .use(UserStatePlugin, userState, store)
        .use(MediaQueriesPlugin)
        .use(Maska)
        .use(VueScrollTo);

    provideLogger(app, logger);
    provideContentfulSvc(app, contentfulSvc);
    provideR15Svc(app, r15Svc);

    // necessary for making injections automatically unwrap computed refs.
    // this will be the default behavior in Vue 3.3. this config was
    // introduced to avoid breakage until then. we can remove once updated
    app.config.unwrapInjectedRef = true;

    app
        .component('BaseIcon', BaseIcon)
        .component('BaseLink', BaseLink)
        .component('DiscernibleText', DiscernibleText);

    app
        .directive('v-t', transformVTDirective)
        .directive('tab-focus', TabFocus)
        .directive('auto-focus', AutoFocus);

    return { app, router, store };
}
