import {
    getClientWidth,
    getClientHeight,
    getIOSVersion,
    getScrollTop,
} from '~coreModules/browser/js/browser-utils';

import { IOS_APP_MINIMUM_IOS_VERSION, SCROLL_DIRECTION } from '~coreModules/browser/js/browser-constants';

import { HEADER_MODULE_NAME } from '~coreModules/header/js/header-store';
import { MODALS_MODULE_NAME } from '~coreModules/modals/js/modals-store';

export const BROWSER_MODULE_NAME = 'browser';

export const GET_SCROLL_INFORMATION = 'GET_SCROLL_INFORMATION';
export const SET_SCROLL_INFORMATION = 'SET_SCROLL_INFORMATION';
export const GET_CLIENT_DIMENSIONS = 'GET_CLIENT_DIMENSIONS';
export const SET_CLIENT_DIMENSIONS = 'SET_CLIENT_DIMENSIONS';
export const SET_IS_RESIZING = 'SET_IS_RESIZING';
export const SET_BROWSER_INFO = 'SET_BROWSER_INFO';

let lastScrollTopPosition = 0;
let resizeTimer;

export default {
    namespaced: true,

    state: () => ({
        clientWidth: 0,
        clientHeight: 0,
        scrollTopWithoutHeader: 0,
        scrollDirection: '',
        isResizing: false,
        iOSVersion: null,
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_SCROLL_INFORMATION](state, { scrollTop, scrollDirection }) {
            state.scrollTopWithoutHeader = scrollTop;
            state.scrollDirection = scrollDirection;
        },
        [SET_CLIENT_DIMENSIONS](state, { clientWidth, clientHeight }) {
            state.clientWidth = clientWidth;
            state.clientHeight = clientHeight;
        },
        [SET_IS_RESIZING](state, isResizing) {
            state.isResizing = isResizing;
        },
        [SET_BROWSER_INFO](state) {
            const { navigator: { userAgent } } = window;

            state.iOSVersion = getIOSVersion(userAgent);
        },
    },

    /* eslint-enable no-param-reassign */
    /* eslint-disable no-undef */
    actions: {
        [GET_SCROLL_INFORMATION]({ commit, rootGetters }) {
            if (rootGetters[`${MODALS_MODULE_NAME}/isOpen`]) {
                return;
            }

            const scrollTop = getScrollTop();
            const scrollDirection = scrollTop > lastScrollTopPosition ? SCROLL_DIRECTION.DOWN : SCROLL_DIRECTION.UP;
            lastScrollTopPosition = scrollTop;

            commit(SET_SCROLL_INFORMATION, {
                scrollTop,
                scrollDirection,
            });
        },
        [GET_CLIENT_DIMENSIONS]({ state, commit }, isInitialBinding) {
            const clientWidth = getClientWidth();
            const clientHeight = getClientHeight();

            if (state.clientWidth !== clientWidth || state.clientHeight !== clientHeight) {
                commit(SET_CLIENT_DIMENSIONS, { clientWidth, clientHeight });

                if (!isInitialBinding) {
                    clearTimeout(resizeTimer);

                    if (!state.isResizing) {
                        commit(SET_IS_RESIZING, true);
                    }

                    resizeTimer = setTimeout(() => {
                        commit(SET_IS_RESIZING, false);
                    }, 750);
                }
            }
        },
    },

    getters: {
        scrollBottom(state) {
            const { clientHeight } = state;
            return state.scrollTopWithoutHeader + clientHeight;
        },
        scrollTop(state, getters, rootState) {
            const { headerHeight } = rootState[HEADER_MODULE_NAME];
            return state.scrollTopWithoutHeader + headerHeight;
        },
        isAtTopOfPage(state) {
            return state.scrollTopWithoutHeader === 0;
        },
        isEligibleToUseIOSApp(state) {
            return state.iOSVersion >= IOS_APP_MINIMUM_IOS_VERSION;
        },
    },
};
