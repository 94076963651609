import { ref, computed, reactive } from 'vue';

import { debounce } from 'lodash-es';
import { DEBOUNCE_DELAY } from '~coreModules/core/js/constants';

const breakpointList = [{
    name: 'extra-small',
    getter: 'isExtraSmall',
}, {
    name: 'small',
    getter: 'isSmall',
}, {
    name: 'medium',
    getter: 'isMedium',
}, {
    name: 'large',
    getter: 'isLarge',
}, {
    name: 'extra-large',
    getter: 'isExtraLarge',
}];

/**
 * Provides the current and different breakpoints of the application
 * @param {Object} windowObj - the Window
 * @param {Object} logger - the logger instance to be provided
 * @returns {Object} the provided breakpoint of the app
 */
function getCurrentBreakpoint(windowObj, logger = console) {
    if (process.env.VUE_ENV === 'client') {
        try {
            const [name, ...sizes] = windowObj
                .getComputedStyle(document.body, ':after')
                .getPropertyValue('content')
                // content returns with quotes around the string, strip them off here
                .replace(/["']/g, '')
                .split(',');
            return {
                name,
                sizes: sizes.map(s => parseInt(s.replace(/[^0-9]/g, ''), 10)),
            };
        } catch (e) {
            /* istanbul ignore next */
            logger.warn('[MediaQueries] Could not determine breakpoint settings');
        }
    }

    return {
        name: null,
        sizes: ref([]),
    };
}

/**
 * Creates an object for the calculated media queries in the app
 * @returns {Object} reactive object with the declared breakpoints and current breakpoint in the app
 */
export function useMediaQueries() {
    const windowObj = process.env.VUE_ENV === 'client' ? window : undefined;
    const { name, sizes } = getCurrentBreakpoint(windowObj);
    const currentBreakpoint = ref(name);

    const breakpoints = {
        // Expose isExtraSmall/isSmall/etc. computed properties
        ...breakpointList.reduce((acc, breakpoint) => Object.assign(acc, {
            [breakpoint.getter]: computed(() => (process.env.VUE_ENV === 'client' ?
                currentBreakpoint.value === breakpoint.name :
                true)),
        }), {}),
        // TODO: How do we want to handle multi-breakpoint conditions
        isSmallish: computed(() => breakpoints.isSmall.value || breakpoints.isExtraSmall.value),
        isLargish: computed(() => breakpoints.isLarge.value || breakpoints.isExtraLarge.value),
    };

    if (process.env.VUE_ENV === 'client') {
        sizes.value = {
            ...breakpointList.reduce((acc, bp, i) => Object.assign(acc, {
                [bp.name]: sizes[i],
            }), {}),
        };

        const debouncedSetter = debounce(() => {
            currentBreakpoint.value = getCurrentBreakpoint(windowObj).name;
        }, DEBOUNCE_DELAY);

        // Note: we don't registering removeEventListener calls for these
        // because they are attached once for the root component which
        // shouldn't ever be destroyed
        windowObj.addEventListener('resize', debouncedSetter);
        windowObj.addEventListener('orientationchange', debouncedSetter);
    }

    return reactive({ ...breakpoints, currentBreakpoint });
}

export default {
    useMediaQueries,
};
