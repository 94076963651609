import {
    getProfile,
    updateCommunicationPreferences,
} from '~coreModules/profile/js/profile-api';

export const PROFILE_MODULE_NAME = 'profile';

export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const SET_CORE_PROFILE_DATA = 'SET_CORE_PROFILE_DATA';
export const SET_SMS_OPT_IN = 'SET_SMS_OPT_IN';
export const SET_EMAIL_OPT_IN = 'SET_EMAIL_OPT_IN';
export const SET_TRACKING_PREFERENCE = 'SET_TRACKING_PREFERENCE';
export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';
export const UPDATE_TRACKING_PREFERENCE = 'UPDATE_TRACKING_PREFERENCE';
export const UPDATE_PROFILE_AND_TRACKING_DATA = 'UPDATE_PROFILE_AND_TRACKING_DATA';
export const SET_EMAIL_ADDRESS = 'SET_EMAIL_ADDRESS';

export default function createProfileStore() {
    return {
        namespaced: true,

        state: {
            profileId: '',
            employee: false,
            firstName: '',
            username: '',
            email: '',
            phoneNumber: '',
            profilePicture: '',
            birthday: {
                day: '',
                month: '',
                year: '',
            },
            emailOptIn: false,
            smsOptIn: false,
            smsOptInMarketing: false,
            trackingOptIn: null,
            billingAddress: {
                firstName: '',
                lastName: '',
                line1: '',
                line2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
            },
            createdAt: '',
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            [SET_CORE_PROFILE_DATA](state, profileData) {
                const {
                    id,
                    account,
                    communication,
                    preferences,
                    subscription,
                    createdAt,
                } = profileData;
                const {
                    employee,
                    firstName,
                    username,
                    email,
                    phoneNumber,
                    profilePicture,
                } = account;
                const {
                    emailOptIn,
                    smsOptIn,
                    smsOptInMarketing,
                    trackingOptIn,
                } = communication;
                const { birthday } = preferences;
                const { billingAddress } = subscription;

                Object.assign(state, {
                    profileId: id,
                    employee,
                    firstName,
                    username,
                    email,
                    phoneNumber,
                    profilePicture,
                    birthday,
                    emailOptIn,
                    smsOptIn,
                    smsOptInMarketing,
                    trackingOptIn,
                    billingAddress,
                    createdAt,
                });
            },
            [SET_EMAIL_OPT_IN](state, newPreference) {
                state.emailOptIn = newPreference;
            },
            [SET_SMS_OPT_IN](state, newPreference) {
                state.smsOptIn = newPreference;
            },
            [SET_TRACKING_PREFERENCE](state, trackingOptIn) {
                state.trackingOptIn = trackingOptIn;
            },
            [SET_PROFILE_PICTURE](state, profilePicture) {
                state.profilePicture = profilePicture;
            },
            [SET_EMAIL_ADDRESS](state, email) {
                state.email = email;
            },
        },

        /* eslint-enable no-param-reassign */
        actions: {
            [GET_PROFILE_DATA]({ commit, rootGetters }) {
                const { loggedIn } = rootGetters;

                if (!loggedIn) {
                    return Promise.resolve();
                }

                return getProfile(this.$r15Svc)
                    .then((profileData) => {
                        commit(SET_CORE_PROFILE_DATA, profileData);
                        return profileData;
                    })
                    .catch((error) => {
                        this.$logger.debugError('Failed to fetch the user\'s profile: ', error);
                        return Promise.reject(error);
                    });
            },
            [UPDATE_TRACKING_PREFERENCE]({ commit }, { trackingOptIn }) {
                return updateCommunicationPreferences(this.$r15Svc, { trackingOptIn })
                    .then(() => commit(SET_TRACKING_PREFERENCE, trackingOptIn))
                    .catch((error) => {
                        this.$logger.debugError('Failed to update user\'s communication preferences: ', error);
                        return Promise.reject(error);
                    });
            },
        },
    };
}
