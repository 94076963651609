// This file should be kept very small, and should only contain Node-compatible
// JS, since it's used by both build-time and run-time code

const identity = v => v;

function get(obj, pathString, defaultVal) {
    if (obj == null || typeof pathString !== 'string') {
        return defaultVal;
    }

    let pointer = obj;
    const toks = pathString.split('.');
    for (let i = 0; i < toks.length; i++) {
        // Use `prop in obj` here instead of hasOwnProperty because we want
        // this to work against prototypical ES6 class properties, such as
        // TokenSession.authToken
        if (pointer && typeof pointer !== 'boolean' && toks[i] in pointer) {
            pointer = pointer[toks[i]];
        } else {
            return defaultVal;
        }
    }

    return pointer;
}

/**
 * Vanilla JS replacement for _.set()
 *
 * @param   {object} obj   Object to set value into
 * @param   {string} path  Dot-separated path to set value at
 * @param   {*}      val   Value to set
 * @returns {object} Returns the now-modified object
 */
function set(obj, path, val) {
    if (obj == null || typeof path !== 'string' || path.length === 0) {
        return obj;
    }

    let pointer = obj;
    const toks = path.split('.');
    for (let i = 0; i < toks.length; i++) {
        if (i === toks.length - 1 && pointer[toks[i]] !== val) {
            pointer[toks[i]] = val;
        } else if (!(toks[i] in pointer)) {
            pointer[toks[i]] = {};
        }
        pointer = pointer[toks[i]];
    }

    return obj;
}

function has(obj, key) {
    if (obj == null ||
        typeof obj !== 'object' ||
        key == null ||
        typeof key !== 'string') {
        return false;
    }
    return Object.hasOwnProperty.call(obj, key);
}

function mapValues(obj, mapper = identity) {
    return Object.keys(obj).reduce((acc, k) => Object.assign(acc, {
        [k]: mapper(obj[k]),
    }), {});
}

function omit(object, keysToRemove) {
    const newObject = { ...object };
    keysToRemove.forEach((key) => {
        delete newObject[key];
    });
    return newObject;
}

module.exports = {
    get,
    set,
    has,
    identity,
    mapValues,
    omit,
};
