<i18n>
[
    "address__firstName--label",
    "address__lastName--label",
    "address__line1--label",
    "address__line2--label",
    "address__city--label",
    "address__zip--label",
]
</i18n>

<template>
    <div>
        <BaseForm ref="addressForm" :onSubmit="onSubmit" class="c-form">
            <div class="c-form--address o-row o-row--nested">
                <div
                    class="o-extra-small--12 u-spacer--4"
                    :class="getGridClasses('firstName')"
                >
                    <BaseInput
                        v-model="formData.firstName"
                        :shouldAutoFocus="shouldAutoFocus && !$mediaQueries.isSmallish"
                        :rules="{ required: true }"
                        :autocomplete="'off'"
                        :isRequired="true"
                        :labelText="$t('address__firstName--label')"
                        name="firstName"
                        type="text"
                    />
                </div>
                <div
                    class="o-extra-small--12 u-spacer--4"
                    :class="getGridClasses('lastName')"
                >
                    <BaseInput
                        v-model="formData.lastName"
                        :rules="{ required: true }"
                        :autocomplete="'off'"
                        :isRequired="true"
                        :labelText="$t('address__lastName--label')"
                        name="lastName"
                        type="text"
                    />
                </div>
                <div
                    class="o-extra-small--12 u-spacer--4"
                    :class="getGridClasses('line1')"
                >
                    <BaseInput
                        v-model="formData.line1"
                        :rules="{
                            required: true,
                            regex: /\d{1}/
                        }"
                        :autocomplete="'off'"
                        :isRequired="true"
                        :labelText="$t('address__line1--label')"
                        :maxLength="35"
                        name="line1"
                        formTarget="line1Form"
                        type="text"
                        @blur="onAddressInputBlur"
                        @focus="onAddressInputFocus"
                    />
                    <AddressAutocompleteDropdown
                        :isActive="displayAutocompleteDropdown"
                        :searchResults="searchResults"
                        @on-result-selected="onResultSelected"
                    />
                </div>
                <template v-if="showSecondaryForm">
                    <div
                        class="o-extra-small--12 u-spacer--4"
                        :class="getGridClasses('line2')"
                    >
                        <BaseInput
                            v-model="formData.line2"
                            :autocomplete="'off'"
                            :labelText="$t('address__line2--label')"
                            :maxLength="35"
                            name="line2"
                            type="text"
                        />
                    </div>
                    <div
                        class="o-extra-small--12 u-spacer--4"
                        :class="getGridClasses('city')"
                    >
                        <BaseInput
                            v-model="formData.city"
                            :rules="{ required: true }"
                            :autocomplete="'off'"
                            :isRequired="true"
                            :labelText="$t('address__city--label')"
                            name="city"
                            type="text"
                        />
                    </div>
                    <div
                        class="o-extra-small--12 u-spacer--4"
                        :class="getGridClasses('state')"
                    >
                        <BaseSelect
                            v-model="formData.state"
                            :labelText="'State'"
                            :options="usStates"
                            :isRequired="true"
                            name="state"
                        />
                    </div>
                    <div
                        class="o-extra-small--12 u-spacer--6"
                        :class="getGridClasses('zip')"
                    >
                        <BaseInput
                            v-model="formData.zip"
                            :rules="{ required: true, min: 5 }"
                            :mask="'#####'"
                            :name="'zip'"
                            :autocomplete="'off'"
                            :isRequired="true"
                            :labelText="$t('address__zip--label')"
                            type="text"
                        />
                    </div>
                </template>
            </div>
            <div class="o-row o-row--nested">
                <div class="o-extra-small--12">
                    <slot name="submitButton" :showSecondaryForm="showSecondaryForm"></slot>
                </div>
            </div>
        </BaseForm>

        <!-- This form is used for the line1 field, to prevent google's autocomplete menu from showing
            on top of the google places API driven dropdown field ( AddressAutocompleteDropdown.vue ) -->
        <form
            :id="`${formId}-line1Form`"
            class="hidden"
            novalidate
            autocomplete="off"
            @submit.prevent
        ></form>
    </div>
</template>

<script>
import uuidv4 from 'uuid/v4';
import { get } from 'lodash-es';
import { getGoogleAutocompleteSuggestions, getGooglePlaceDetails } from '~coreModules/google-maps/js/google-maps-api';
import { US_STATES } from '~coreModules/core/js/constants';
import BaseForm from '~coreModules/core/components/ui/forms/BaseForm.vue';
import BaseSelect from '~coreModules/core/components/ui/form-elements/dropdowns/BaseSelect.vue';
import BaseInput from '~coreModules/core/components/ui/form-elements/text-inputs/BaseInput.vue';
import AddressAutocompleteDropdown from '~coreModules/google-maps/components/AddressAutocompleteDropdown.vue';

export default {
    name: 'AutocompleteAddressForm',
    components: {
        BaseForm,
        BaseSelect,
        BaseInput,
        AddressAutocompleteDropdown,
    },
    props: {
        formId: {
            type: String,
            required: true,
        },
        initialFormState: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        /* Override grid classes for field inputs */
        columnWidthOverrides: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        // controls whether the first form field is autofocused on component load
        shouldAutoFocus: {
            type: Boolean,
            default: false,
        },
        handleFormSubmit: {
            type: Function,
            required: true,
            default: null,
        },
    },
    data() {
        return {
            displayAutocompleteDropdown: true,
            formData: {
                firstName: get(this, 'initialFormState.firstName', ''),
                lastName: get(this, 'initialFormState.lastName', ''),
                line1: get(this, 'initialFormState.line1', ''),
                line2: get(this, 'initialFormState.line2', ''),
                city: get(this, 'initialFormState.city', ''),
                zip: get(this, 'initialFormState.zip', ''),
                state: '',
                country: 'US',
            },
            sessionToken: '',
            showSecondaryForm: Boolean(get(this, 'initialFormState.line1', '')),
            usStates: US_STATES,
            addressSetFromGoogle: false,
            serviceErrorMessage: null,
            searchResults: null,
        };
    },
    watch: {
        /* Fetches google autocomplete results as line1 field changes */
        // eslint-disable-next-line func-names
        'formData.line1': function (val) {
            if (val.length >= 3) {
                if (!this.addressSetFromGoogle) {
                    this.displayAutocompleteDropdown = true;
                    getGoogleAutocompleteSuggestions(this.$r15Svc, this.formData.line1, this.sessionToken)
                        .then((searchResults) => {
                            this.searchResults = searchResults.predictions;
                        })
                        .catch((error) => {
                            this.serviceErrorMessage = error.message;
                        });
                } else {
                    this.addressSetFromGoogle = false;
                }
            } else {
                this.displayAutocompleteDropdown = false;
            }
        },
    },
    mounted() {
        this.sessionToken = uuidv4();
        const initialStateCode = get(this, 'initialFormState.state', '');

        // Setting the initial value for 'state' here, instead of in the data property above
        // this is because the BaseSelect component was not setting the inital value when set with 'data'
        if (initialStateCode) {
            this.formData.state = initialStateCode;
        }
    },
    methods: {
        async onSubmit() {
            await this.handleFormSubmit(this.formData);
        },
        async triggerFormSubmit() {
            await this.$refs.addressForm.triggerFormSubmit();
        },
        onAddressInputBlur() {
            setTimeout(() => {
                this.displayAutocompleteDropdown = false;
                this.showSecondaryForm = true;
            }, 150);
        },
        onAddressInputFocus() {
            this.displayAutocompleteDropdown = true;
        },
        onResultSelected(placeId) {
            this.showSecondaryForm = true;
            getGooglePlaceDetails(this.$r15Svc, placeId, this.sessionToken)
                .then(selectedGooglePlaceAddress => this.autofillFormWithGoogleAddress(selectedGooglePlaceAddress))
                .catch((error) => {
                    this.serviceErrorMessage = error;
                });
        },
        autofillFormWithGoogleAddress(googleAddress) {
            this.addressSetFromGoogle = true;
            this.displayAutocompleteDropdown = false;

            const { addressFields } = googleAddress;

            if (addressFields.line1) {
                this.formData.line1 = addressFields.line1;
            }

            this.formData.line2 = '';
            this.formData.city = addressFields.city;
            this.formData.state = addressFields.state;
            this.formData.zip = addressFields.zip;
        },
        getGridClasses(fieldName) {
            const gridOverride = this.columnWidthOverrides[fieldName];
            const defaultClasses = fieldName === 'line1' ? '' : 'o-medium--6';

            return gridOverride || defaultClasses;
        },
    },
};
</script>

<style lang="scss">
    .c-form {
        &--address {
            text-align: left;
        }
    }
</style>
