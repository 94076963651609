import { isEmpty } from 'lodash-es';

import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';
import { shapeContentfulContentPageResponse } from '~coreModules/contentful/js/contentful-utils';
import { flattenReview } from '~coreRoutes/review/js/review-utils';

import { CONTENT_TYPES } from '~coreModules/contentful/js/contentful-constants';
import { SET_404_ERROR } from '~coreModules/core/js/store';
import { REVIEW_SCHEME_TYPES } from '~coreRoutes/review/js/review-constants';

export const REVIEW_MODULE_NAME = 'review';
export const SAVE_REVIEW_SECTION = 'SAVE_REVIEW_SECTION';
export const SET_CLOSE_LINK = 'SET_CLOSE_LINK';
export const FETCH_REVIEW_SCHEME = 'FETCH_REVIEW_SCHEME';
export const SET_REVIEW_SCHEME = 'SET_REVIEW_SCHEME';
export const SET_CURRENT_REVIEW_SLUG = 'SET_CURRENT_REVIEW_SLUG';
export const ADVANCE_REVIEW_SLIDE = 'ADVANCE_REVIEW_SLIDE';
export const FETCH_QUESTION_SET = 'FETCH_QUESTION_SET';
export const REPLACE_QUESTION_SET = 'REPLACE_QUESTION_SET';
export const RESTART_QUESTION_SET = 'RESTART_QUESTION_SET';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_UPLOADED_IMAGE = 'SET_UPLOADED_IMAGE';
export const RESET_STATE = 'RESET_STATE';
export const DELETE_SKIPPED_SLUG_ANSWERS = 'DELETE_SKIPPED_SLUG_ANSWERS';
export const UPDATE_REVIEW_SECTION_ANSWERS = 'UPDATE_REVIEW_SECTION_ANSWERS';

const getDefaultState = () => ({
    closeLink: '/',
    currentReviewSlug: '',
    previousReviewSlugStack: [],
    product: {},
    reviewScheme: {},
    review: [],
    images: [],
});

export default function createReviewStore() {
    return {
        namespaced: true,

        state: () => (getDefaultState()),

        /* eslint-disable no-param-reassign */
        mutations: {
            [SAVE_REVIEW_SECTION](state, newReviewSection) {
                const reviewSlug = Object.keys(newReviewSection)[0];
                const existingSectionIndex = state.review.findIndex(section => Object.keys(section)[0] === reviewSlug);
                if (existingSectionIndex >= 0) {
                    const newReview = [...state.review];
                    newReview[existingSectionIndex] = newReviewSection;
                    state.review = newReview;
                } else {
                    state.review.push(newReviewSection);
                }
            },
            [UPDATE_REVIEW_SECTION_ANSWERS](state, updatedReviewSection) {
                const reviewSlug = Object.keys(updatedReviewSection)[0];
                const existingSectionIndex = state.review.findIndex(section => Object.keys(section)[0] === reviewSlug);
                if (existingSectionIndex >= 0) {
                    const existingReviews = [...state.review];
                    existingReviews[existingSectionIndex] = {
                        [reviewSlug]: {
                            ...existingReviews[existingSectionIndex]?.[reviewSlug],
                            ...updatedReviewSection?.[reviewSlug],
                        },
                    };
                    state.review = existingReviews;
                } else {
                    state.review.push(updatedReviewSection);
                }
            },
            [SET_CLOSE_LINK](state, newCloseLink) {
                state.closeLink = newCloseLink;
            },
            [SET_REVIEW_SCHEME](state, { res, initialSlideIndex }) {
                const questionSets = res.questionSets ? res.questionSets
                    .map(set => shapeContentfulContentPageResponse(set)) : [];

                state.reviewScheme = {
                    ...res,
                    questionSets,
                };
                state.currentReviewSlug = questionSets[initialSlideIndex].slug;
            },
            [SET_CURRENT_REVIEW_SLUG](state, newCurrentSlug) {
                if (!state.previousReviewSlugStack.find(slug => slug === newCurrentSlug)) {
                    state.previousReviewSlugStack.push(state.currentReviewSlug);
                } else {
                    state.previousReviewSlugStack.pop();
                }
                state.currentReviewSlug = newCurrentSlug;
            },
            [REPLACE_QUESTION_SET](state, { newQuestionSet, slugToReplace }) {
                const {
                    reviewScheme: {
                        questionSets,
                    },
                } = state;
                const targetQuestionSetIndex = questionSets.findIndex(qSet => qSet.slug === slugToReplace);
                const {
                    conditionalQuestion,
                    conditionalQuestionId,
                    conditionalAnswerId,
                    questionSetType,
                } = questionSets[targetQuestionSetIndex];

                questionSets.splice(targetQuestionSetIndex, 1, {
                    ...newQuestionSet,
                    conditionalQuestion,
                    conditionalQuestionId,
                    conditionalAnswerId,
                    questionSetType,
                });
            },
            [SET_PRODUCT](state, newProduct) {
                state.product = newProduct;
            },
            [SET_UPLOADED_IMAGE](state, newImage) {
                state.images = newImage ? [newImage] : [];
                state.review.push({ uploadedImages: { userUploadedImages: newImage } });
            },
            // eslint-disable-next-line no-unused-vars
            [RESET_STATE](state) {
                Object.assign(state, getDefaultState());
            },
            [RESTART_QUESTION_SET](state) {
                const defaultState = getDefaultState();
                state.currentReviewSlug = state.reviewScheme.questionSets[0].slug;
                state.previousReviewSlugStack = defaultState.previousReviewSlugStack;
                state.product = defaultState.product;
                state.review = defaultState.review;
                state.images = defaultState.images;
            },
            [DELETE_SKIPPED_SLUG_ANSWERS](state, skippedSlugs) {
                state.review = state.review.filter(answer => !skippedSlugs.includes(Object.keys(answer)[0]));
            },
        },

        /* eslint-enable no-param-reassign */
        actions: {
            [FETCH_REVIEW_SCHEME]({ commit }, { contentfulSlug }) {
                return getContentfulPageContentForSlug({
                    contentfulSvc: this.$contentfulSvc,
                    contentType: CONTENT_TYPES.REVIEWS_SCHEME,
                    slug: contentfulSlug,
                    isTimed: false,
                })
                    .then((res) => {
                        if (!res) {
                            throw Error(`Contentful review scheme not found for slug ${contentfulSlug}`);
                        }
                        return Promise.resolve(res);
                    })
                    .catch((error) => {
                        const errMsg = `Failed to fetch contentful review scheme for ${contentfulSlug}`;
                        this.$logger.debugError(errMsg, ': ', error);
                        commit(SET_404_ERROR, { message: errMsg }, { root: true });
                    });
            },
            [FETCH_QUESTION_SET]({ commit }, {
                contentfulSlug,
                defaultQuestionSet,
                dynamicField,
                dynamicFieldValue,
                slugToReplace,
            }) {
                return getContentfulPageContentForSlug({
                    contentfulSvc: this.$contentfulSvc,
                    contentType: CONTENT_TYPES.REVIEWS_QUESTION_SET,
                    slug: contentfulSlug,
                    isTimed: false,
                    queryParams: {
                        [`fields.${dynamicField}`]: dynamicFieldValue,
                    },
                })
                    .then((res) => {
                        const newQuestionSet = res ? shapeContentfulContentPageResponse(res) : defaultQuestionSet;

                        commit(REPLACE_QUESTION_SET, { newQuestionSet, slugToReplace });
                        return commit(SET_CURRENT_REVIEW_SLUG, newQuestionSet.slug);
                    })
                    .catch((error) => {
                        this.$logger.debugError(`Failed to fetch contentful question set for
                            ${contentfulSlug}: `, error);
                        commit(SET_404_ERROR, { message: error }, { root: true });
                    });
            },
        },

        getters: {
            /* current set of questions to show a user in a review-scheme */
            currentQuestionSet(state) {
                const {
                    currentReviewSlug,
                    reviewScheme: {
                        questionSets,
                    },
                } = state;
                return (questionSets && questionSets.find(qSet => qSet.slug === currentReviewSlug)) || {};
            },
            /* all feedback answers, keyed by question slug */
            indexedReview(state) {
                const { review } = state;
                return review.reduce((reviewObj, currentAnswers) => {
                    const reviewSlug = Object.keys(currentAnswers)[0];
                    return {
                        ...reviewObj,
                        ...currentAnswers[reviewSlug],
                    };
                }, {});
            },
            /* current saved feedback answers for active question-set slug, used to hydrate initial form answers */
            currentReviewAnswers(state) {
                const { currentReviewSlug, review } = state;
                const currentAnswers = review.find(section => Object.keys(section)[0] === currentReviewSlug);
                return currentAnswers ? currentAnswers[currentReviewSlug] : {};
            },
            /* Feedback answers in the format needed to submit to service */
            flattenedReview(state, { isProductReview }) {
                const { review, product } = state;
                const flattenedReview = flattenReview(review);

                return {
                    ...flattenedReview,
                    ...(isProductReview && {
                        skuId: [product.skuId],
                    }),
                };
            },
            previousReviewSlug(state) {
                const lastIndex = state.previousReviewSlugStack.length - 1;
                if (lastIndex >= 0) {
                    return state.previousReviewSlugStack[lastIndex];
                }
                return '';
            },
            slugIndex: state => (slug) => {
                const { questionSets } = state.reviewScheme;

                if (isEmpty(questionSets)) return null;

                return questionSets.findIndex(qSet => qSet.slug === slug);
            },
            isProductReview(state) {
                return state.reviewScheme.schemeType === REVIEW_SCHEME_TYPES.sku;
            },
        },

        modules: {},
    };
}
