import { get, isNull, isUndefined } from 'lodash-es';

import { ANALYTICS_MODULE_NAME, UPDATE_ANALYTICS_TRACKING_PREFERENCE } from '~coreModules/analytics/js/analytics-store';
import { PROFILE_MODULE_NAME, UPDATE_TRACKING_PREFERENCE } from '~coreModules/profile/js/profile-store';
import { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';

import { NUU_COOKIE_TRACKING, MAX_AGE_100_YEARS } from '~coreModules/core/js/cookie-constants';

import { COMPLIANCE_CONFIGURATION } from '~coreModules/compliance/js/compliance-constants';
import { FOOTER_NAV_LEGAL_LINKS_SLUG } from '~coreModules/nav/js/nav-constants';

export const COMPLIANCE_MODULE_NAME = 'compliance';

export const GET_TRACKING_PREFERENCE = 'GET_TRACKING_PREFERENCE';
export const SET_COMPLIANCE_TRACKING_PREFERENCE = 'SET_COMPLIANCE_TRACKING_PREFERENCE';
export const UPDATE_COMPLIANCE_TRACKING_PREFERENCE = 'UPDATE_COMPLIANCE_TRACKING_PREFERENCE';

export default {
    namespaced: true,

    state: () => ({
        trackingOptIn: null,
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_COMPLIANCE_TRACKING_PREFERENCE](state, trackingOptIn) {
            // forcing trackingOptIn to false if the browser has enabled GPC setting
            if (typeof window !== 'undefined' && window?.navigator?.globalPrivacyControl) {
                state.trackingOptIn = false;
            } else {
                state.trackingOptIn = trackingOptIn;
            }
        },
    },

    /* eslint-enable no-param-reassign */
    actions: {
        [GET_TRACKING_PREFERENCE]({
            dispatch,
            commit,
            rootState,
            rootGetters,
        }, globalPrivacyControlHeader) {
            const complianceActive = rootGetters[`${SITE_SETTINGS_MODULE_NAME}/complianceActive`];
            if (!complianceActive) {
                this.$logger.debug('compliance is disabled');
                return Promise.resolve();
            }

            // The user has opted out of tracking at the browser level
            // https://global-privacy-control.glitch.me/
            if (globalPrivacyControlHeader === '1') {
                this.$cookies.set(
                    NUU_COOKIE_TRACKING,
                    false,
                    { maxAge: MAX_AGE_100_YEARS },
                );
                commit(SET_COMPLIANCE_TRACKING_PREFERENCE, false);

                return Promise.resolve();
            }

            const { loggedIn } = rootGetters;
            let trackingCookie = this.$cookies.get(NUU_COOKIE_TRACKING);
            trackingCookie = trackingCookie && trackingCookie === 'true';

            if (loggedIn) {
                const { trackingOptIn } = rootState[PROFILE_MODULE_NAME];
                let trackingPreference = trackingOptIn;

                if (!isNull(trackingOptIn)) {
                    if (!isUndefined(trackingCookie)) {
                        // if trackingOptIn is true and trackingCookie is false
                        // use the trackingCookie value and update trackingOptIn on profile
                        if (trackingOptIn && !trackingCookie) {
                            trackingPreference = trackingCookie;
                        }
                    }

                    if (trackingPreference !== trackingCookie) {
                        this.$cookies.set(
                            NUU_COOKIE_TRACKING,
                            trackingPreference,
                            { maxAge: MAX_AGE_100_YEARS },
                        );
                    }
                } else if (!isUndefined(trackingCookie)) {
                    trackingPreference = trackingCookie;
                }

                if (!isNull(trackingPreference)) {
                    return Promise.resolve()
                        .then(() => {
                            if (trackingPreference !== trackingOptIn) {
                                return dispatch(`${PROFILE_MODULE_NAME}/${UPDATE_TRACKING_PREFERENCE}`,
                                    { trackingOptIn: trackingPreference },
                                    { root: true });
                            }
                            return true;
                        })
                        .then(() => {
                            commit(SET_COMPLIANCE_TRACKING_PREFERENCE, trackingPreference);
                        });
                }

                return Promise.resolve();
            }

            if (!isUndefined(trackingCookie)) {
                commit(SET_COMPLIANCE_TRACKING_PREFERENCE, trackingCookie);
                return Promise.resolve();
            }

            return Promise.resolve();
        },
        async [UPDATE_COMPLIANCE_TRACKING_PREFERENCE]({ commit, dispatch, rootGetters }, { trackingPreference }) {
            const { loggedIn } = rootGetters;

            try {
                if (loggedIn) {
                    await dispatch(`${PROFILE_MODULE_NAME}/${UPDATE_TRACKING_PREFERENCE}`,
                        { trackingOptIn: trackingPreference },
                        { root: true });
                }

                // only update trackingOptIn cookie and vuex state if the browser has not enabled GPC setting
                if (!window?.navigator?.globalPrivacyControl) {
                    this.$cookies.set(
                        NUU_COOKIE_TRACKING,
                        trackingPreference,
                        { maxAge: MAX_AGE_100_YEARS },
                    );

                    commit(SET_COMPLIANCE_TRACKING_PREFERENCE, trackingPreference);
                }

                await dispatch(
                    `${ANALYTICS_MODULE_NAME}/${UPDATE_ANALYTICS_TRACKING_PREFERENCE}`,
                    null,
                    { root: true },
                );
            } catch (error) {
                this.$logger.debugError(error);
            }
        },
    },

    getters: {
        shouldTrack(state) {
            return state.trackingOptIn || isNull(state.trackingOptIn);
        },
        isComplianceNotificationActive(state, getters, rootState, rootGetters) {
            const complianceActive = rootGetters[`${SITE_SETTINGS_MODULE_NAME}/complianceActive`];
            const { userCountry, userRegion } = getters.userLocation;

            return complianceActive &&
                    isNull(state.trackingOptIn) && !!get(COMPLIANCE_CONFIGURATION, `${userCountry}.${userRegion}`);
        },
        userLocation(state, getters, rootState, rootGetters) {
            const complianceActive = rootGetters[`${SITE_SETTINGS_MODULE_NAME}/complianceActive`];

            if (!complianceActive) {
                return false;
            }

            const { geolocation: { country, region } } = rootState[SITE_SETTINGS_MODULE_NAME];
            const { billingAddress } = rootState[PROFILE_MODULE_NAME];

            let userCountry;
            let userRegion;

            if (country && region) {
                userCountry = country;
                userRegion = region;
            }

            if (billingAddress?.country && billingAddress?.state) {
                userCountry = billingAddress.country;
                userRegion = billingAddress.state;
            }

            return { userCountry, userRegion };
        },
        footerLegalLinksSlug(state, getters, rootState, rootGetters) {
            const complianceActive = rootGetters[`${SITE_SETTINGS_MODULE_NAME}/complianceActive`];
            const { userCountry, userRegion } = getters.userLocation;

            if (!complianceActive) {
                return FOOTER_NAV_LEGAL_LINKS_SLUG;
            }

            return get(
                COMPLIANCE_CONFIGURATION,
                `${userCountry}.${userRegion}.footerLegalLinksSlug`,
                FOOTER_NAV_LEGAL_LINKS_SLUG,
            );
        },
        stickyNotification(state, getters, rootState, rootGetters) {
            const complianceActive = rootGetters[`${SITE_SETTINGS_MODULE_NAME}/complianceActive`];
            const { userCountry, userRegion } = getters.userLocation;

            if (!complianceActive) {
                return false;
            }

            return get(COMPLIANCE_CONFIGURATION, `${userCountry}.${userRegion}.stickyNotification`);
        },
    },
};
