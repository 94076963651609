import axios from 'axios';

import { apiUrls } from '~config/config';

/**
 * Gets users profile information
 */
export function getProfile(r15Svc) {
    return r15Svc.get(`${apiUrls.profileService}/me`);
}

/**
 * Updates the email address saved for a users account
 * @param {Object} email - object w/ single email KVP
 */
export function updateEmail(r15Svc, email) {
    return r15Svc.put(`${apiUrls.profileService}/email`, email);
}

/**
 * Updates the communication preferences for a users account,
 * Can submit one or more KVPs to update
 * @param {Object} newPreferences - communication preferences to update
 * @returns {Promise} - api call
 */
export function updateCommunicationPreferences(r15Svc, newPreferences) {
    return r15Svc.put(`${apiUrls.profileService}/communication`, newPreferences);
}

/**
 * Updates the first name and username saved for a users account
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {Object} newNames - names to update on users profile
 * @param {String} newNames.firstName - users firstName
 * @param {String} newNames.username - users username
 * @return {Promise} service response
 */
export function updateName(r15Svc, newNames) {
    return r15Svc.put(`${apiUrls.profileService}/name`, newNames);
}

/**
 * Subscribe a user to email communication
 * @param {String} email   - email address to subscribe
 * @param {String} location - where the subscribe occurred
 * @return {Promise} - service request
 */
export function subscribeEmail(r15Svc, email, optInLocation = '') {
    return r15Svc.post(`${apiUrls.profileService}/email/subscribe`, { email, optInLocation });
}

/**
 * Unsubscribe an email from notifications
 * @param {String} email - email to unsubscribe
 * @returns {Promise}
 */
export function unsubscribeEmail(r15Svc, email) {
    return r15Svc.post(`${apiUrls.profileService}/unsubscribe`, { email });
}

/**
 * Get a signed url from Google Cloud Storage to upload an image to
 * @param {String} fileName - the filename of the image
 * @return {Promise} Promise to be resolved with google cloud signed url
 */
export function getImageUploadSignedUrl(r15Svc, fileName) {
    return r15Svc.get(`${apiUrls.profileService}/picture/signedUrl`, { fileName });
}

/**
 * Get a signed url from Google Cloud Storage to upload an image to
 * @param {String} uploadUrl - url to upload image to
 * @param {String} imageFile - html5 image file to upload to Google Cloud Storage imageFile
 * @return {Promise} empty Promise if successful
 */
export function uploadImageToGoogleCloudStorage(uploadUrl, imageFile) {
    const requestConfig = {
        headers: {
            'content-type': 'application/octet-stream',
        },
    };

    // Using a generic axios client here rather than our instance, since this call is not made to r15 svc
    return axios.put(uploadUrl, imageFile, requestConfig);
}
