export const AB_TEST_GROUP_CONTROL = '0';
export const AB_TEST_GROUP_VARIANT = '1';

export const AB_TEST_SPLIT_50_50 = '50-50';
export const AB_TEST_SPLIT_20_80 = '20-80';
export const AB_TEST_SPLIT_80_20 = '80-20';

export const AB_TEST_GATEWAY_PAGE = 'gatewayPageTest';
export const AB_TEST_BROWSE_PAGE = 'browsePageTest';
export const AB_TEST_AUTH_HOME_PAGE = 'authHomePageTest';

export const AB_TEST_GLOBAL_REQUIRED_FIELDS = [
    'title',
    'testId',
    'split',
    'testEndDate',
    'testType',
];

export const AB_TEST_REQUIRED_FIELDS = {
    [AB_TEST_GATEWAY_PAGE]: ['controlSlug', 'variantSlug'],
    [AB_TEST_BROWSE_PAGE]: ['controlSlug', 'variantSlug'],
    [AB_TEST_AUTH_HOME_PAGE]: ['controlSlug', 'variantSlug'],
};
