import { isNonEmptyArray, isNonEmptyObject } from '~coreModules/core/js/utils';

/**
 * @description
 * Convert an array of objects in to HTML tags.  Each key value pair in the
 * incoming object is converted in to an attribute on the tag
 *
 * @example
 * const tags = [{
*     name: 'foo',
*     content: 'foo-content',
* }, {
*     name: 'bar',
*     content: 'bar-content',
* };
* generateTags(tags, 'meta');
*
* // Returns:
* // <meta name="foo" content="foo-content">
* // <meta name="bar" content="bar-content">
*
* @param   {Array}  tags  Array of tag objects, where each object contains
*                         key/value pairs for the attributes for the given tag
*                         type
* @param   {string} type  HTML tag type
* @returns {string} Newline-delimited string of output HTML tags
*/
export function generateTags(tags, type) {
    if (!isNonEmptyArray(tags) || !type) {
        return '';
    }

    const validTags = tags
        .filter(tag => isNonEmptyObject(tag))
        .map((tag) => {
            const attrs = Object.keys(tag)
                .map(key => (key && tag[key] ? `${key}="${tag[key]}"` : ''))
                .reduce((acc, attr) => (attr ? `${acc} ${attr}` : acc));
            return `<${type} ${attrs}>\n`;
        });

    return isNonEmptyArray(validTags) ?
        validTags.reduce((acc, tag) => `${acc}${tag}`) : undefined;
}

/**
* @description
* Generate `<meta>` tags for the input array of attribute value objects
*
* @param   {Object[]} metaTags Array of attribute-value objects for each meta tag
* @returns {string}   Newline-delimited HTML meta tags
*/
export function generateMetaTags(metaTags) {
    return generateTags(metaTags, 'meta');
}

/**
* @description
* Generate `<link>` tags for the input array of attribute value objects
*
* @param   {Object[]} linkTags Array of attribute-value objects for each link tag
* @returns {string}   Newline-delimited HTML link tags
*/
export function generateLinkTags(linkTags) {
    return generateTags(linkTags, 'link');
}

/**
* @description
* Generate `<link>` tags for the input array of attribute value objects
*
* @param   {Boolean} noIndex the noIndex flag (whether pages should be indexed by search engines)
* @param   {Boolean} noFollow the noFollow flag (whether links on the page should be followed)
* @returns {string}  meta robots tag setting
*/
export function getRobots(noIndex = false, noFollow = false) {
    let robots = noIndex ? 'noindex' : 'index';
    robots += noFollow ? ',nofollow' : ',follow';
    return robots;
}

/**
* @description
* Return tags after combine two tags array
*
* @param   {Array} tags the default site tags
* @param   {Array} routeTags the specific route tags
* @param   {Array} propertyKeys the specific property keys which need to be checked
* @returns {Array} tags setting
*/
export function combineTags(tags, routeTags, propertyKeys) {
    let newRouteTags = routeTags;

    propertyKeys.forEach((val) => {
        let tempArr = [];
        const routeTagsPropertyArr = [];
        routeTags.forEach((e) => {
            if (e[val]) {
                routeTagsPropertyArr.push(e[val]);
            }
        });
        tempArr = tags.filter(e => !routeTagsPropertyArr.includes(e[val]) && e[val]);
        newRouteTags = newRouteTags.concat(tempArr);
    });

    return newRouteTags;
}

/**
* @description
* Return tags after combine two meta tags array
*
* @param   {Array} tags the default site meta tags
* @param   {Array} routeTags the specific route meta tags
* @returns {Array} tags setting
*/
export function combineMetaTags(tags, routeTags) {
    const propertyKeys = ['name', 'property'];
    const newRouteTags = combineTags(tags, routeTags, propertyKeys);

    return newRouteTags;
}

/**
* @description
* Return link tags after combine two link tags array
*
* @param   {Array} tags the default site link tags
* @param   {Array} routeTags the specific route link tags
* @returns {Array} tags setting
*/
export function combineLinkTags(tags, routeTags) {
    const propertyKeys = ['rel'];
    const newRouteTags = combineTags(tags, routeTags, propertyKeys);

    return newRouteTags;
}
