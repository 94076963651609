export default {
    props: {
        name: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        isDisabled: {
            type: [Boolean, String],
            default: false,
        },
        isRequired: {
            type: [Boolean, String],
            default: false,
        },
        isLoading: {
            type: [Boolean, String],
            default: false,
        },
        tabIndex: {
            type: Number,
            default: 0,
        },
        // This text is the initial "placeholder" text which slides up to become the label on focus
        labelText: {
            type: String,
            default: '',
        },
        // This text appears below the input, e.g. the password requirement hint
        supportingText: {
            type: String,
            default: '',
        },
        errorText: {
            type: String,
            default: '',
        },
    },
};
