import { inject } from 'vue';

export const r15SvcInjectionKey = Symbol('r15Svc');

/**
 * A composable that provides access to the r15 client
 * @function
 * @returns {Object} the injected r15Svc made available to the application
 */
export const useR15Svc = () => inject(r15SvcInjectionKey);

/**
 * Provides the r15 client to the application
 * @param {Object} app - the application instance
 * @param {Object} r15Svc - the r15Svc object to be provided
 * @returns {Object} the provided custom r15 client
 */
export function provideR15Svc(app, r15Svc) {
    // provide the r15Svc to the app so it can be used in script setup
    app.provide(r15SvcInjectionKey, r15Svc);
    return r15Svc;
}
