import { CATALOG_GRID_LAYOUTS } from '~modules/catalog/js/catalog-constants';

export const NUU_COOKIE_CATALOG_GRID_LAYOUT = 'x-urbn-catalog-grid-layout';
export const NUU_COOKIE_CATALOG_AVAILABILITY_FILTER = 'x-urbn-catalog-availability-filter';
export const NUU_COOKIE_OVERDUE_PAYMENT_SUCCESS = 'x_nuuly_overdue_payment_success';
export const USER_PREFERENCE_KEY_GRID_LAYOUT = 'catalogGridLayout';
export const USER_PREFERENCE_KEY_OVERDUE_PAYMENT_SUCCESS = 'overduePaymentSuccess';
export const USER_PREFERENCE_KEY_AVAILABILITY_FILTER = 'applyAvailabilityFilterPreference';

export const USER_PREFERENCE_COOKIES = {
    [NUU_COOKIE_CATALOG_GRID_LAYOUT]: {
        key: NUU_COOKIE_CATALOG_GRID_LAYOUT,
        acceptedValues: [CATALOG_GRID_LAYOUTS.LARGE, CATALOG_GRID_LAYOUTS.SMALL],
        vuexKey: USER_PREFERENCE_KEY_GRID_LAYOUT,
    },
    [NUU_COOKIE_OVERDUE_PAYMENT_SUCCESS]: {
        key: NUU_COOKIE_OVERDUE_PAYMENT_SUCCESS,
        acceptedValues: [true],
        vuexKey: USER_PREFERENCE_KEY_OVERDUE_PAYMENT_SUCCESS,
    },
    [NUU_COOKIE_CATALOG_AVAILABILITY_FILTER]: {
        key: NUU_COOKIE_CATALOG_AVAILABILITY_FILTER,
        acceptedValues: ['true', 'false'],
        vuexKey: USER_PREFERENCE_KEY_AVAILABILITY_FILTER,
    },
};

export const PAGE_HEADER_TYPES = {
    CLOSET: 'closetHeader',
    SEARCH: 'searchHeader',
    ACCOUNT: 'myAccountHeader',
    BROWSE_SIMILAR: 'browseSimilarHeader',
};
